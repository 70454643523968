/*
    Table of Contents

    Name                 : Innap
    Author               : DexignZone
    Author Portfolio     : https://themeforest.net/user/dexignzone/portfolio
    


    Table of Sass Contents

    1. Abstracts
        _bs-custom
        _inheritance
        _maps
        _mixin
        _variable

    2. bootstrap 
        -Here goes all official bootstrap scss files

    3. Global/Base
        _color
        _custom-grid
        _fonts
        _helper
        _reset

    4. layout
        +footer
        +header
        +rtl
        +sidebar
        +tables
        +theme
        +version-dark

    5. Conponents
        +app
        +charts
        +forms
        +map
        +uc
        +ui
        +widget

    6. Page
        _index-1
        _page-error
        _page-register
        _page-signin
        _page-user-lock
        _page-user-lock

*/
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700");
@import url("https://fonts.googleapis.com/css?family=Nunito:400,600,700");
@import url("./../icons/simple-line-icons/css/simple-line-icons.css");
@import url("./../icons/fontawesome/css/all.min.css");
@import url("./../icons/material-design-iconic-font/css/materialdesignicons.min.css");
@import url("./../icons/themify-icons/css/themify-icons.css");
@import url("./../icons/line-awesome/css/line-awesome.min.css");
@import url("./../icons/avasta/css/style.css");
@import url("./../icons/flaticon/flaticon.css");
@import url("./../icons/flaticon_1/flaticon_1.css");
@import url("./../icons/icomoon/icomoon.css");
@import url("./../icons/bootstrap-icons/font/bootstrap-icons.css");
@import url(./../vendor/animate/animate.min.css);
@import url(./../vendor/aos/css/aos.min.css);
@import url(./../vendor/perfect-scrollbar/css/perfect-scrollbar.css);
@import url(./../vendor/metismenu/css/metisMenu.min.css);

:root {
  --primary: #1362FC;
  --secondary: #627EEA;
  --primary-hover: #034bd9;
  --primary-light: #aac7fe;
  --primary-dark: #012875;
  --rgba-primary-1: rgba(19, 98, 252, 0.1);
  --rgba-primary-2: rgba(19, 98, 252, 0.2);
  --rgba-primary-3: rgba(19, 98, 252, 0.3);
  --rgba-primary-4: rgba(19, 98, 252, 0.4);
  --rgba-primary-5: rgba(19, 98, 252, 0.5);
  --rgba-primary-6: rgba(19, 98, 252, 0.6);
  --rgba-primary-7: rgba(19, 98, 252, 0.7);
  --rgba-primary-8: rgba(19, 98, 252, 0.8);
  --rgba-primary-9: rgba(19, 98, 252, 0.9);
  --font-family-base: Roboto, sans-serif;
  --font-family-title: Roboto, sans-serif;
  --title: #000;
}

.gradient_one {
  background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%, rgba(103, 25, 255, 0.85) 100%);
}

.gradient-1 {
  background: #59bcff;
  background: -moz-linear-gradient(45deg, #59bcff 21%, #59d7ff 100%);
  background: -webkit-linear-gradient(45deg, #59bcff 21%, #59d7ff 100%);
  background: linear-gradient(45deg, #59bcff 21%, #59d7ff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#59bcff', endColorstr='#59d7ff', GradientType=1);
}

.gradient-2 {
  background: #4ad994;
  background: -moz-linear-gradient(45deg, #4ad994 0%, #57f998 100%);
  background: -webkit-linear-gradient(45deg, #4ad994 0%, #57f998 100%);
  background: linear-gradient(45deg, #4ad994 0%, #57f998 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4ad994', endColorstr='#57f998', GradientType=1);
}

.gradient-3 {
  background: #fdb765;
  background: -moz-linear-gradient(45deg, #fdb765 0%, #ffe485 100%);
  background: -webkit-linear-gradient(45deg, #fdb765 0%, #ffe485 100%);
  background: linear-gradient(45deg, #fdb765 0%, #ffe485 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdb765', endColorstr='#ffe485', GradientType=1);
}

.gradient-4 {
  background: #ff6d59;
  background: -moz-linear-gradient(45deg, #ff6d59 0%, #ffd584 100%);
  background: -webkit-linear-gradient(45deg, #ff6d59 0%, #ffd584 100%);
  background: linear-gradient(45deg, #ff6d59 0%, #ffd584 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff6d59', endColorstr='#ffd584', GradientType=1);
}

.gradient-5 {
  background: -moz-linear-gradient(left, #f6943a 0%, rgba(255, 209, 48, 0.74) 100%);
  background: -webkit-linear-gradient(left, #f6943a 0%, rgba(255, 209, 48, 0.74) 100%);
  background: linear-gradient(to right, #f6943a 0%, rgba(255, 209, 48, 0.74) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f6943a', endColorstr='#bdffd130', GradientType=1);
}

.gradient-6 {
  background: -moz-linear-gradient(45deg, #42ae94 25%, rgba(66, 174, 148, 0.99) 29%, rgba(89, 216, 243, 0.74) 100%);
  background: -webkit-linear-gradient(45deg, #42ae94 25%, rgba(66, 174, 148, 0.99) 29%, rgba(89, 216, 243, 0.74) 100%);
  background: linear-gradient(45deg, #42ae94 25%, rgba(66, 174, 148, 0.99) 29%, rgba(89, 216, 243, 0.74) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#42ae94', endColorstr='#bd59d8f3', GradientType=1);
}

.gradient-7 {
  background: -moz-linear-gradient(left, #9658c7 0%, rgba(237, 60, 177, 0.74) 100%);
  background: -webkit-linear-gradient(left, #9658c7 0%, rgba(237, 60, 177, 0.74) 100%);
  background: linear-gradient(to right, #9658c7 0%, rgba(237, 60, 177, 0.74) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9658c7', endColorstr='#bded3cb1', GradientType=1);
}

.gradient-8 {
  background: #F7B00F;
  background: -moz-linear-gradient(top, #F7B00F 0%, #F25521 100%);
  background: -webkit-linear-gradient(top, #F7B00F 0%, #F25521 100%);
  background: linear-gradient(to bottom, #F7B00F 0%, #F25521 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F7B00F', endColorstr='#F25521', GradientType=1);
}

.gradient-9,
.datepicker.datepicker-dropdown td.day:hover,
.datepicker.datepicker-dropdown th.next:hover,
.datepicker.datepicker-dropdown th.prev:hover,
.datepicker table tr td.selected,
.datepicker table tr td.active,
.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background: #f31e7a !important;
  background: -moz-linear-gradient(left, #f31e7a 0%, #fd712c 100%);
  background: -webkit-linear-gradient(left, #f31e7a 0%, #fd712c 100%);
  background: linear-gradient(to right, #f31e7a 0%, #fd712c 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f31e7a', endColorstr='#fd712c', GradientType=1);
}

.gradient-10 {
  background: #f25521 !important;
  background: -moz-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: -webkit-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: linear-gradient(to top, #f25521 0%, #f9c70a 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f25521', endColorstr='#f9c70a', GradientType=1);
}

.gradient-11 {
  background: #3398fb;
  background: -moz-linear-gradient(left, #3398fb 0%, #8553ee 100%);
  background: -webkit-linear-gradient(left, #3398fb 0%, #8553ee 100%);
  background: linear-gradient(to right, #3398fb 0%, #8553ee 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3398fb', endColorstr='#8553ee', GradientType=1);
}

.gradient-12 {
  background: #36e1b4;
  background: -moz-linear-gradient(left, #36e1b4 0%, #11cae7 100%);
  background: -webkit-linear-gradient(left, #36e1b4 0%, #11cae7 100%);
  background: linear-gradient(to right, #36e1b4 0%, #11cae7 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#36e1b4', endColorstr='#11cae7', GradientType=1);
}

.gradient-13 {
  background: #ffbf31;
  background: -moz-linear-gradient(left, #ffbf31 0%, #ff890e 100%);
  background: -webkit-linear-gradient(left, #ffbf31 0%, #ff890e 100%);
  background: linear-gradient(to right, #ffbf31 0%, #ff890e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffbf31', endColorstr='#ff890e', GradientType=1);
}

.gradient-14 {
  background: #23bdb8;
  background: -moz-linear-gradient(-45deg, #23bdb8 0%, #43e794 100%);
  background: -webkit-linear-gradient(-45deg, #23bdb8 0%, #43e794 100%);
  background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#23bdb8', endColorstr='#43e794', GradientType=1);
}

.gradient-15 {
  background: #9a56ff;
  background: -moz-linear-gradient(-45deg, #9a56ff 0%, #e36cd9 100%);
  background: -webkit-linear-gradient(-45deg, #9a56ff 0%, #e36cd9 100%);
  background: linear-gradient(135deg, #9a56ff 0%, #e36cd9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9a56ff', endColorstr='#e36cd9', GradientType=1);
}

.gradient-16 {
  background: #f48665;
  background: -moz-linear-gradient(-45deg, #f48665 0%, #fda23f 100%);
  background: -webkit-linear-gradient(-45deg, #f48665 0%, #fda23f 100%);
  background: linear-gradient(135deg, #f48665 0%, #fda23f 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f48665', endColorstr='#fda23f', GradientType=1);
}

.gradient-17 {
  background: #e36cd9;
  background: -moz-linear-gradient(-45deg, #e36cd9 0%, #fe60ae 100%);
  background: -webkit-linear-gradient(-45deg, #e36cd9 0%, #fe60ae 100%);
  background: linear-gradient(135deg, #e36cd9 0%, #fe60ae 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e36cd9', endColorstr='#fe60ae', GradientType=1);
}

.gradient-18 {
  background: #a15cff;
  background: -moz-linear-gradient(left, #a15cff 0%, #ce82fd 100%);
  background: -webkit-linear-gradient(left, #a15cff 0%, #ce82fd 100%);
  background: linear-gradient(to right, #a15cff 0%, #ce82fd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a15cff', endColorstr='#ce82fd', GradientType=1);
}

/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/
/*!
 * Bootstrap v5.0.0-beta2 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #5e72e4;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #e83e8c;
  --bs-red: #EE3232;
  --bs-orange: #ff9900;
  --bs-yellow: #FFFA6F;
  --bs-green: #297F00;
  --bs-teal: #20c997;
  --bs-cyan: #3065D0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #1362FC;
  --bs-secondary: #627EEA;
  --bs-success: #37D159;
  --bs-info: #b48dd3;
  --bs-warning: #FFC368;
  --bs-danger: #FF6746;
  --bs-light: #c8c8c8;
  --bs-dark: #6e6e6e;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4f5966;
  background-color: #FCFCFC;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  color: #000;
}

h1,
.h1 {
  font-size: calc(1.35rem + 1.2vw);
}

@media (min-width: 1200px) {

  h1,
  .h1 {
    font-size: 2.25rem;
  }
}

h2,
.h2 {
  font-size: calc(1.3125rem + 0.75vw);
}

@media (min-width: 1200px) {

  h2,
  .h2 {
    font-size: 1.875rem;
  }
}

h3,
.h3 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {

  h3,
  .h3 {
    font-size: 1.5rem;
  }
}

h4,
.h4 {
  font-size: 1.125rem;
}

h5,
.h5 {
  font-size: 1rem;
}

h6,
.h6 {
  font-size: 0.938rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small,
.small {
  font-size: 0.875em;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #1362FC;
  text-decoration: underline;
}

a:hover {
  color: #0f4eca;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr
    /* rtl:ignore */
  ;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #e83e8c;
  word-wrap: break-word;
}

a>code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #89879f;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend+* {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem;
}

.blockquote> :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #FCFCFC;
  border: 1px solid #dee2e6;
  border-radius: 0.75rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.9375rem);
  padding-left: var(--bs-gutter-x, 0.9375rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 36rem) {

  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 48rem) {

  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 62rem) {

  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 75rem) {

  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1440) {

  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}

.row {
  --bs-gutter-x: 1.875rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}

.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto>* {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1>* {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2>* {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3>* {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4>* {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5>* {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6>* {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 36rem) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }

  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 48rem) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }

  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 62rem) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }

  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 75rem) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }

  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1440) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6666666667%;
  }

  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.table {
  --bs-table-bg: transparent;
  --bs-table-striped-color: #969ba0;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #969ba0;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #969ba0;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #4f5966;
  vertical-align: top;
  border-color: #F2F2F2;
}

.table> :not(caption)>*>* {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.table>tbody {
  vertical-align: inherit;
}

.table>thead {
  vertical-align: bottom;
}

.table> :not(:last-child)> :last-child>* {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm> :not(caption)>*>* {
  padding: 0.25rem 0.25rem;
}

.table-bordered> :not(caption)>* {
  border-width: 1px 0;
}

.table-bordered> :not(caption)>*>* {
  border-width: 0 1px;
}

.table-borderless> :not(caption)>*>* {
  border-bottom-width: 0;
}

.table-striped>tbody>tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover>tbody>tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #d0e0fe;
  --bs-table-striped-bg: #c6d5f1;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bbcae5;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c0cfeb;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bbcae5;
}

.table-secondary {
  --bs-table-bg: #e0e5fb;
  --bs-table-striped-bg: #d5daee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cacee2;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cfd4e8;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cacee2;
}

.table-success {
  --bs-table-bg: #d7f6de;
  --bs-table-striped-bg: #ccead3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c2ddc8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c7e4cd;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #c2ddc8;
}

.table-info {
  --bs-table-bg: #f0e8f6;
  --bs-table-striped-bg: #e4dcea;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d8d1dd;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ded7e4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #d8d1dd;
}

.table-warning {
  --bs-table-bg: #fff3e1;
  --bs-table-striped-bg: #f2e7d6;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbcb;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1d0;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dbcb;
}

.table-danger {
  --bs-table-bg: #ffe1da;
  --bs-table-striped-bg: #f2d6cf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6cbc4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecd0ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6cbc4;
}

.table-light {
  --bs-table-bg: #c8c8c8;
  --bs-table-striped-bg: #bebebe;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b4b4b4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #b9b9b9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #b4b4b4;
}

.table-dark {
  --bs-table-bg: #6e6e6e;
  --bs-table-striped-bg: #757575;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #7d7d7d;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #797979;
  --bs-table-hover-color: #000;
  color: #fff;
  border-color: #7d7d7d;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 35.98rem) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 47.98rem) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 61.98rem) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 74.98rem) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1439.98) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.09375rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.765625rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #89879f;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #969ba0;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #F2F2F2;
  appearance: none;
  border-radius: 0.75rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: #969ba0;
  background-color: #fff;
  border-color: #89b1fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(19, 98, 252, 0.25);
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #969ba0;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #969ba0;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #969ba0;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  border-radius: 0.2rem;
}

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: 0.3rem;
}

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.75rem;
}

.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.75rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #969ba0;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #F2F2F2;
  border-radius: 0.75rem;
  appearance: none;
}

.form-select:focus {
  border-color: #89b1fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(19, 98, 252, 0.25);
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}

.form-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #969ba0;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.765625rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.09375rem;
}

.form-check {
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #89b1fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(19, 98, 252, 0.25);
}

.form-check-input:checked {
  background-color: #1362FC;
  border-color: #1362FC;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #1362FC;
  border-color: #1362FC;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled]~.form-check-label,
.form-check-input:disabled~.form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2389b1fe'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check[disabled]+.btn,
.btn-check:disabled+.btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #FCFCFC, 0 0 0 0.25rem rgba(19, 98, 252, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #FCFCFC, 0 0 0 0.25rem rgba(19, 98, 252, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #1362FC;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #b8d0fe;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #1362FC;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #b8d0fe;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}

.form-floating>.form-control,
.form-floating>.form-select {
  height: calc(3.5rem + 2px);
  padding: 1rem 0.75rem;
}

.form-floating>label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating>label {
    transition: none;
  }
}

.form-floating>.form-control::placeholder {
  color: transparent;
}

.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-select~label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating>.form-control:-webkit-autofill~label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group>.form-control,
.input-group>.form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group>.form-control:focus,
.input-group>.form-select:focus {
  z-index: 3;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #969ba0;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #F2F2F2;
  border-radius: 0.75rem;
}

.input-group-lg>.form-control,
.input-group-lg>.form-select,
.input-group-lg>.input-group-text,
.input-group-lg>.btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: 0.3rem;
}

.input-group-sm>.form-control,
.input-group-sm>.form-select,
.input-group-sm>.input-group-text,
.input-group-sm>.btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  border-radius: 0.2rem;
}

.input-group-lg>.form-select,
.input-group-sm>.form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation> :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation>.dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #37D159;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.765625rem;
  color: #000;
  background-color: rgba(55, 209, 89, 0.9);
  border-radius: 0.75rem;
}

.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip,
.is-valid~.valid-feedback,
.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #37D159;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2337D159' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #37D159;
  box-shadow: 0 0 0 0.25rem rgba(55, 209, 89, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: #37D159;
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2337D159' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
  border-color: #37D159;
  box-shadow: 0 0 0 0.25rem rgba(55, 209, 89, 0.25);
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border-color: #37D159;
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: #37D159;
}

.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(55, 209, 89, 0.25);
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
  color: #37D159;
}

.form-check-inline .form-check-input~.valid-feedback {
  margin-left: .5em;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #FF6746;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.765625rem;
  color: #000;
  background-color: rgba(255, 103, 70, 0.9);
  border-radius: 0.75rem;
}

.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip,
.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #FF6746;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23FF6746'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FF6746' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #FF6746;
  box-shadow: 0 0 0 0.25rem rgba(255, 103, 70, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
  border-color: #FF6746;
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23FF6746'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FF6746' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  border-color: #FF6746;
  box-shadow: 0 0 0 0.25rem rgba(255, 103, 70, 0.25);
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  border-color: #FF6746;
}

.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: #FF6746;
}

.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 103, 70, 0.25);
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
  color: #FF6746;
}

.form-check-inline .form-check-input~.invalid-feedback {
  margin-left: .5em;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #4f5966;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 0.75rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #969ba0;
}

.btn-check:focus+.btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(19, 98, 252, 0.25);
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #1362FC;
  border-color: #1362FC;
}

.btn-primary:hover {
  color: #fff;
  background-color: #1053d6;
  border-color: #0f4eca;
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: #1053d6;
  border-color: #0f4eca;
  box-shadow: 0 0 0 0.25rem rgba(54, 122, 252, 0.5);
}

.btn-check:checked+.btn-primary,
.btn-check:active+.btn-primary,
.btn-primary:active,
.btn-primary.active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0f4eca;
  border-color: #0e4abd;
}

.btn-check:checked+.btn-primary:focus,
.btn-check:active+.btn-primary:focus,
.btn-primary:active:focus,
.btn-primary.active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(54, 122, 252, 0.5);
}

.btn-primary:disabled,
.btn-primary.disabled {
  color: #fff;
  background-color: #1362FC;
  border-color: #1362FC;
}

.btn-secondary {
  color: #000;
  background-color: #627EEA;
  border-color: #627EEA;
}

.btn-secondary:hover {
  color: #000;
  background-color: #7a91ed;
  border-color: #728bec;
}

.btn-check:focus+.btn-secondary,
.btn-secondary:focus {
  color: #000;
  background-color: #7a91ed;
  border-color: #728bec;
  box-shadow: 0 0 0 0.25rem rgba(83, 107, 199, 0.5);
}

.btn-check:checked+.btn-secondary,
.btn-check:active+.btn-secondary,
.btn-secondary:active,
.btn-secondary.active,
.show>.btn-secondary.dropdown-toggle {
  color: #000;
  background-color: #8198ee;
  border-color: #728bec;
}

.btn-check:checked+.btn-secondary:focus,
.btn-check:active+.btn-secondary:focus,
.btn-secondary:active:focus,
.btn-secondary.active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(83, 107, 199, 0.5);
}

.btn-secondary:disabled,
.btn-secondary.disabled {
  color: #000;
  background-color: #627EEA;
  border-color: #627EEA;
}

.btn-success {
  color: #000;
  background-color: #37D159;
  border-color: #37D159;
}

.btn-success:hover {
  color: #000;
  background-color: #55d872;
  border-color: #4bd66a;
}

.btn-check:focus+.btn-success,
.btn-success:focus {
  color: #000;
  background-color: #55d872;
  border-color: #4bd66a;
  box-shadow: 0 0 0 0.25rem rgba(47, 178, 76, 0.5);
}

.btn-check:checked+.btn-success,
.btn-check:active+.btn-success,
.btn-success:active,
.btn-success.active,
.show>.btn-success.dropdown-toggle {
  color: #000;
  background-color: #5fda7a;
  border-color: #4bd66a;
}

.btn-check:checked+.btn-success:focus,
.btn-check:active+.btn-success:focus,
.btn-success:active:focus,
.btn-success.active:focus,
.show>.btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(47, 178, 76, 0.5);
}

.btn-success:disabled,
.btn-success.disabled {
  color: #000;
  background-color: #37D159;
  border-color: #37D159;
}

.btn-info {
  color: #000;
  background-color: #b48dd3;
  border-color: #b48dd3;
}

.btn-info:hover {
  color: #000;
  background-color: #bf9eda;
  border-color: #bc98d7;
}

.btn-check:focus+.btn-info,
.btn-info:focus {
  color: #000;
  background-color: #bf9eda;
  border-color: #bc98d7;
  box-shadow: 0 0 0 0.25rem rgba(153, 120, 179, 0.5);
}

.btn-check:checked+.btn-info,
.btn-check:active+.btn-info,
.btn-info:active,
.btn-info.active,
.show>.btn-info.dropdown-toggle {
  color: #000;
  background-color: #c3a4dc;
  border-color: #bc98d7;
}

.btn-check:checked+.btn-info:focus,
.btn-check:active+.btn-info:focus,
.btn-info:active:focus,
.btn-info.active:focus,
.show>.btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(153, 120, 179, 0.5);
}

.btn-info:disabled,
.btn-info.disabled {
  color: #000;
  background-color: #b48dd3;
  border-color: #b48dd3;
}

.btn-warning {
  color: #000;
  background-color: #FFC368;
  border-color: #FFC368;
}

.btn-warning:hover {
  color: #000;
  background-color: #ffcc7f;
  border-color: #ffc977;
}

.btn-check:focus+.btn-warning,
.btn-warning:focus {
  color: #000;
  background-color: #ffcc7f;
  border-color: #ffc977;
  box-shadow: 0 0 0 0.25rem rgba(217, 166, 88, 0.5);
}

.btn-check:checked+.btn-warning,
.btn-check:active+.btn-warning,
.btn-warning:active,
.btn-warning.active,
.show>.btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffcf86;
  border-color: #ffc977;
}

.btn-check:checked+.btn-warning:focus,
.btn-check:active+.btn-warning:focus,
.btn-warning:active:focus,
.btn-warning.active:focus,
.show>.btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 166, 88, 0.5);
}

.btn-warning:disabled,
.btn-warning.disabled {
  color: #000;
  background-color: #FFC368;
  border-color: #FFC368;
}

.btn-danger {
  color: #000;
  background-color: #FF6746;
  border-color: #FF6746;
}

.btn-danger:hover {
  color: #000;
  background-color: #ff7e62;
  border-color: #ff7659;
}

.btn-check:focus+.btn-danger,
.btn-danger:focus {
  color: #000;
  background-color: #ff7e62;
  border-color: #ff7659;
  box-shadow: 0 0 0 0.25rem rgba(217, 88, 60, 0.5);
}

.btn-check:checked+.btn-danger,
.btn-check:active+.btn-danger,
.btn-danger:active,
.btn-danger.active,
.show>.btn-danger.dropdown-toggle {
  color: #000;
  background-color: #ff856b;
  border-color: #ff7659;
}

.btn-check:checked+.btn-danger:focus,
.btn-check:active+.btn-danger:focus,
.btn-danger:active:focus,
.btn-danger.active:focus,
.show>.btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 88, 60, 0.5);
}

.btn-danger:disabled,
.btn-danger.disabled {
  color: #000;
  background-color: #FF6746;
  border-color: #FF6746;
}

.btn-light {
  color: #000;
  background-color: #c8c8c8;
  border-color: #c8c8c8;
}

.btn-light:hover {
  color: #000;
  background-color: #d0d0d0;
  border-color: #cecece;
}

.btn-check:focus+.btn-light,
.btn-light:focus {
  color: #000;
  background-color: #d0d0d0;
  border-color: #cecece;
  box-shadow: 0 0 0 0.25rem rgba(170, 170, 170, 0.5);
}

.btn-check:checked+.btn-light,
.btn-check:active+.btn-light,
.btn-light:active,
.btn-light.active,
.show>.btn-light.dropdown-toggle {
  color: #000;
  background-color: lightgray;
  border-color: #cecece;
}

.btn-check:checked+.btn-light:focus,
.btn-check:active+.btn-light:focus,
.btn-light:active:focus,
.btn-light.active:focus,
.show>.btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(170, 170, 170, 0.5);
}

.btn-light:disabled,
.btn-light.disabled {
  color: #000;
  background-color: #c8c8c8;
  border-color: #c8c8c8;
}

.btn-dark {
  color: #fff;
  background-color: #6e6e6e;
  border-color: #6e6e6e;
}

.btn-dark:hover {
  color: #fff;
  background-color: #5e5e5e;
  border-color: #585858;
}

.btn-check:focus+.btn-dark,
.btn-dark:focus {
  color: #fff;
  background-color: #5e5e5e;
  border-color: #585858;
  box-shadow: 0 0 0 0.25rem rgba(132, 132, 132, 0.5);
}

.btn-check:checked+.btn-dark,
.btn-check:active+.btn-dark,
.btn-dark:active,
.btn-dark.active,
.show>.btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #585858;
  border-color: #535353;
}

.btn-check:checked+.btn-dark:focus,
.btn-check:active+.btn-dark:focus,
.btn-dark:active:focus,
.btn-dark.active:focus,
.show>.btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(132, 132, 132, 0.5);
}

.btn-dark:disabled,
.btn-dark.disabled {
  color: #fff;
  background-color: #6e6e6e;
  border-color: #6e6e6e;
}

.btn-outline-primary {
  color: #1362FC;
  border-color: #1362FC;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #1362FC;
  border-color: #1362FC;
}

.btn-check:focus+.btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(19, 98, 252, 0.5);
}

.btn-check:checked+.btn-outline-primary,
.btn-check:active+.btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #1362FC;
  border-color: #1362FC;
}

.btn-check:checked+.btn-outline-primary:focus,
.btn-check:active+.btn-outline-primary:focus,
.btn-outline-primary:active:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(19, 98, 252, 0.5);
}

.btn-outline-primary:disabled,
.btn-outline-primary.disabled {
  color: #1362FC;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #627EEA;
  border-color: #627EEA;
}

.btn-outline-secondary:hover {
  color: #000;
  background-color: #627EEA;
  border-color: #627EEA;
}

.btn-check:focus+.btn-outline-secondary,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(98, 126, 234, 0.5);
}

.btn-check:checked+.btn-outline-secondary,
.btn-check:active+.btn-outline-secondary,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show {
  color: #000;
  background-color: #627EEA;
  border-color: #627EEA;
}

.btn-check:checked+.btn-outline-secondary:focus,
.btn-check:active+.btn-outline-secondary:focus,
.btn-outline-secondary:active:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(98, 126, 234, 0.5);
}

.btn-outline-secondary:disabled,
.btn-outline-secondary.disabled {
  color: #627EEA;
  background-color: transparent;
}

.btn-outline-success {
  color: #37D159;
  border-color: #37D159;
}

.btn-outline-success:hover {
  color: #000;
  background-color: #37D159;
  border-color: #37D159;
}

.btn-check:focus+.btn-outline-success,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(55, 209, 89, 0.5);
}

.btn-check:checked+.btn-outline-success,
.btn-check:active+.btn-outline-success,
.btn-outline-success:active,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show {
  color: #000;
  background-color: #37D159;
  border-color: #37D159;
}

.btn-check:checked+.btn-outline-success:focus,
.btn-check:active+.btn-outline-success:focus,
.btn-outline-success:active:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(55, 209, 89, 0.5);
}

.btn-outline-success:disabled,
.btn-outline-success.disabled {
  color: #37D159;
  background-color: transparent;
}

.btn-outline-info {
  color: #b48dd3;
  border-color: #b48dd3;
}

.btn-outline-info:hover {
  color: #000;
  background-color: #b48dd3;
  border-color: #b48dd3;
}

.btn-check:focus+.btn-outline-info,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(180, 141, 211, 0.5);
}

.btn-check:checked+.btn-outline-info,
.btn-check:active+.btn-outline-info,
.btn-outline-info:active,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #b48dd3;
  border-color: #b48dd3;
}

.btn-check:checked+.btn-outline-info:focus,
.btn-check:active+.btn-outline-info:focus,
.btn-outline-info:active:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(180, 141, 211, 0.5);
}

.btn-outline-info:disabled,
.btn-outline-info.disabled {
  color: #b48dd3;
  background-color: transparent;
}

.btn-outline-warning {
  color: #FFC368;
  border-color: #FFC368;
}

.btn-outline-warning:hover {
  color: #000;
  background-color: #FFC368;
  border-color: #FFC368;
}

.btn-check:focus+.btn-outline-warning,
.btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 195, 104, 0.5);
}

.btn-check:checked+.btn-outline-warning,
.btn-check:active+.btn-outline-warning,
.btn-outline-warning:active,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #FFC368;
  border-color: #FFC368;
}

.btn-check:checked+.btn-outline-warning:focus,
.btn-check:active+.btn-outline-warning:focus,
.btn-outline-warning:active:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 195, 104, 0.5);
}

.btn-outline-warning:disabled,
.btn-outline-warning.disabled {
  color: #FFC368;
  background-color: transparent;
}

.btn-outline-danger {
  color: #FF6746;
  border-color: #FF6746;
}

.btn-outline-danger:hover {
  color: #000;
  background-color: #FF6746;
  border-color: #FF6746;
}

.btn-check:focus+.btn-outline-danger,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 103, 70, 0.5);
}

.btn-check:checked+.btn-outline-danger,
.btn-check:active+.btn-outline-danger,
.btn-outline-danger:active,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show {
  color: #000;
  background-color: #FF6746;
  border-color: #FF6746;
}

.btn-check:checked+.btn-outline-danger:focus,
.btn-check:active+.btn-outline-danger:focus,
.btn-outline-danger:active:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 103, 70, 0.5);
}

.btn-outline-danger:disabled,
.btn-outline-danger.disabled {
  color: #FF6746;
  background-color: transparent;
}

.btn-outline-light {
  color: #c8c8c8;
  border-color: #c8c8c8;
}

.btn-outline-light:hover {
  color: #000;
  background-color: #c8c8c8;
  border-color: #c8c8c8;
}

.btn-check:focus+.btn-outline-light,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(200, 200, 200, 0.5);
}

.btn-check:checked+.btn-outline-light,
.btn-check:active+.btn-outline-light,
.btn-outline-light:active,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #c8c8c8;
  border-color: #c8c8c8;
}

.btn-check:checked+.btn-outline-light:focus,
.btn-check:active+.btn-outline-light:focus,
.btn-outline-light:active:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(200, 200, 200, 0.5);
}

.btn-outline-light:disabled,
.btn-outline-light.disabled {
  color: #c8c8c8;
  background-color: transparent;
}

.btn-outline-dark {
  color: #6e6e6e;
  border-color: #6e6e6e;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #6e6e6e;
  border-color: #6e6e6e;
}

.btn-check:focus+.btn-outline-dark,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(110, 110, 110, 0.5);
}

.btn-check:checked+.btn-outline-dark,
.btn-check:active+.btn-outline-dark,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #6e6e6e;
  border-color: #6e6e6e;
}

.btn-check:checked+.btn-outline-dark:focus,
.btn-check:active+.btn-outline-dark:focus,
.btn-outline-dark:active:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(110, 110, 110, 0.5);
}

.btn-outline-dark:disabled,
.btn-outline-dark.disabled {
  color: #6e6e6e;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #1362FC;
  text-decoration: underline;
}

.btn-link:hover {
  color: #0f4eca;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
}

.btn-lg,
.btn-group-lg>.btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: 0.3rem;
}

.btn-sm,
.btn-group-sm>.btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  border-radius: 0.2rem;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 0.875rem;
  color: #969ba0;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.75rem;
}

.dropdown-menu[data-bs-popper] {
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  right: auto
    /* rtl:ignore */
  ;
  left: 0
    /* rtl:ignore */
  ;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0
    /* rtl:ignore */
  ;
  left: auto
    /* rtl:ignore */
  ;
}

@media (min-width: 36rem) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto
      /* rtl:ignore */
    ;
    left: 0
      /* rtl:ignore */
    ;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0
      /* rtl:ignore */
    ;
    left: auto
      /* rtl:ignore */
    ;
  }
}

@media (min-width: 48rem) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    right: auto
      /* rtl:ignore */
    ;
    left: 0
      /* rtl:ignore */
    ;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    right: 0
      /* rtl:ignore */
    ;
    left: auto
      /* rtl:ignore */
    ;
  }
}

@media (min-width: 62rem) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto
      /* rtl:ignore */
    ;
    left: 0
      /* rtl:ignore */
    ;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0
      /* rtl:ignore */
    ;
    left: auto
      /* rtl:ignore */
    ;
  }
}

@media (min-width: 75rem) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto
      /* rtl:ignore */
    ;
    left: 0
      /* rtl:ignore */
    ;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0
      /* rtl:ignore */
    ;
    left: auto
      /* rtl:ignore */
    ;
  }
}

@media (min-width: 1440) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto
      /* rtl:ignore */
    ;
    left: 0
      /* rtl:ignore */
    ;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0
      /* rtl:ignore */
    ;
    left: auto
      /* rtl:ignore */
    ;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
}

.dropup .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
}

.dropend .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
}

.dropstart .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #1e2125;
  background-color: #e9ecef;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #1362FC;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.765625rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-item:hover,
.dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}

.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #1362FC;
}

.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}

.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group>.btn,
.btn-group-vertical>.btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group>.btn-check:checked+.btn,
.btn-group>.btn-check:focus+.btn,
.btn-group>.btn:hover,
.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn-check:checked+.btn,
.btn-group-vertical>.btn-check:focus+.btn,
.btn-group-vertical>.btn:hover,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group>.btn:not(:first-child),
.btn-group>.btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn-group:not(:last-child)>.btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group>.btn:nth-child(n + 3),
.btn-group> :not(.btn-check)+.btn,
.btn-group>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
  width: 100%;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn~.btn,
.btn-group-vertical>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #FCFCFC;
  border-color: #dee2e6 #dee2e6 #FCFCFC;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.75rem;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #fff;
  background-color: #1362FC;
}

.nav-fill>.nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified>.nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content>.tab-pane {
  display: none;
}

.tab-content>.active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-sm,
.navbar>.container-md,
.navbar>.container-lg,
.navbar>.container-xl,
.navbar>.container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: 0.3359375rem;
  padding-bottom: 0.3359375rem;
  margin-right: 1rem;
  font-size: 1.09375rem;
  text-decoration: none;
  white-space: nowrap;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.75rem;
  transition: box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 36rem) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (min-width: 48rem) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (min-width: 62rem) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (min-width: 75rem) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1440) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show>.nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem;
}

.card>hr {
  margin-right: 0;
  margin-left: 0;
}

.card>.list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card>.list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.75rem - 1px);
  border-top-right-radius: calc(0.75rem - 1px);
}

.card>.list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.75rem - 1px);
  border-bottom-left-radius: calc(0.75rem - 1px);
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link+.card-link {
  margin-left: 1rem
    /* rtl:ignore */
  ;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.75rem - 1px) calc(0.75rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.75rem - 1px) calc(0.75rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: #fff;
  border-bottom-color: #fff;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.75rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.75rem - 1px);
  border-top-right-radius: calc(0.75rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.75rem - 1px);
  border-bottom-left-radius: calc(0.75rem - 1px);
}

.card-group>.card {
  margin-bottom: 0.9375rem;
}

@media (min-width: 36rem) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }

  .card-group>.card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group>.card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-top,
  .card-group>.card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-bottom,
  .card-group>.card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-top,
  .card-group>.card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-bottom,
  .card-group>.card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 0.875rem;
  color: #969ba0;
  text-align: left;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button.collapsed {
  border-bottom-width: 0;
}

.accordion-button:not(.collapsed) {
  color: #1158e3;
  background-color: #e7efff;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%231158e3'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(180deg);
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23969ba0'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #89b1fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(19, 98, 252, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-width: 1px;
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-width: 1px;
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.accordion-collapse {
  border: solid rgba(0, 0, 0, 0.125);
  border-width: 0 1px;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-button {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item:first-of-type .accordion-button {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion-flush .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item+.breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/")
    /* rtl: var(--bs-breadcrumb-divider, "/") */
  ;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #1362FC;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: #0f4eca;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  color: #0f4eca;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(19, 98, 252, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #1362FC;
  border-color: #1362FC;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.09375rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.75rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.75rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #0b3b97;
  background-color: #d0e0fe;
  border-color: #b8d0fe;
}

.alert-primary .alert-link {
  color: #092f79;
}

.alert-secondary {
  color: #3b4c8c;
  background-color: #e0e5fb;
  border-color: #d0d8f9;
}

.alert-secondary .alert-link {
  color: #2f3d70;
}

.alert-success {
  color: #165424;
  background-color: #d7f6de;
  border-color: #c3f1cd;
}

.alert-success .alert-link {
  color: #12431d;
}

.alert-info {
  color: #6c557f;
  background-color: #f0e8f6;
  border-color: #e9ddf2;
}

.alert-info .alert-link {
  color: #564466;
}

.alert-warning {
  color: #664e2a;
  background-color: #fff3e1;
  border-color: #ffedd2;
}

.alert-warning .alert-link {
  color: #523e22;
}

.alert-danger {
  color: #993e2a;
  background-color: #ffe1da;
  border-color: #ffd1c8;
}

.alert-danger .alert-link {
  color: #7a3222;
}

.alert-light {
  color: #505050;
  background-color: #f4f4f4;
  border-color: #efefef;
}

.alert-light .alert-link {
  color: #404040;
}

.alert-dark {
  color: #424242;
  background-color: #e2e2e2;
  border-color: #d4d4d4;
}

.alert-dark .alert-link {
  color: #353535;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color: #e9ecef;
  border-radius: 0.75rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #1362FC;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.75rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #969ba0;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #1362FC;
  border-color: #1362FC;
}

.list-group-item+.list-group-item {
  border-top-width: 0;
}

.list-group-item+.list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal>.list-group-item:first-child {
  border-bottom-left-radius: 0.75rem;
  border-top-right-radius: 0;
}

.list-group-horizontal>.list-group-item:last-child {
  border-top-right-radius: 0.75rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal>.list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 36rem) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm>.list-group-item:first-child {
    border-bottom-left-radius: 0.75rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item:last-child {
    border-top-right-radius: 0.75rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 48rem) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md>.list-group-item:first-child {
    border-bottom-left-radius: 0.75rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item:last-child {
    border-top-right-radius: 0.75rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 62rem) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg>.list-group-item:first-child {
    border-bottom-left-radius: 0.75rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item:last-child {
    border-top-right-radius: 0.75rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 75rem) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl>.list-group-item:first-child {
    border-bottom-left-radius: 0.75rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item:last-child {
    border-top-right-radius: 0.75rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1440) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl>.list-group-item:first-child {
    border-bottom-left-radius: 0.75rem;
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl>.list-group-item:last-child {
    border-top-right-radius: 0.75rem;
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }

  .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush>.list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush>.list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #0b3b97;
  background-color: #d0e0fe;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #0b3b97;
  background-color: #bbcae5;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #0b3b97;
  border-color: #0b3b97;
}

.list-group-item-secondary {
  color: #3b4c8c;
  background-color: #e0e5fb;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #3b4c8c;
  background-color: #cacee2;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #3b4c8c;
  border-color: #3b4c8c;
}

.list-group-item-success {
  color: #165424;
  background-color: #d7f6de;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #165424;
  background-color: #c2ddc8;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #165424;
  border-color: #165424;
}

.list-group-item-info {
  color: #6c557f;
  background-color: #f0e8f6;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #6c557f;
  background-color: #d8d1dd;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #6c557f;
  border-color: #6c557f;
}

.list-group-item-warning {
  color: #664e2a;
  background-color: #fff3e1;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #664e2a;
  background-color: #e6dbcb;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664e2a;
  border-color: #664e2a;
}

.list-group-item-danger {
  color: #993e2a;
  background-color: #ffe1da;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #993e2a;
  background-color: #e6cbc4;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #993e2a;
  border-color: #993e2a;
}

.list-group-item-light {
  color: #505050;
  background-color: #f4f4f4;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #505050;
  background-color: gainsboro;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #505050;
  border-color: #505050;
}

.list-group-item-dark {
  color: #424242;
  background-color: #e2e2e2;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #424242;
  background-color: #cbcbcb;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #424242;
  border-color: #424242;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.75rem;
  opacity: 0.5;
}

.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(19, 98, 252, 0.25);
  opacity: 1;
}

.btn-close:disabled,
.btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.75rem;
}

.toast:not(.showing):not(.show) {
  opacity: 0;
}

.toast.hide {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container> :not(:last-child) {
  margin-bottom: 0.9375rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.75rem - 1px);
  border-top-right-radius: calc(0.75rem - 1px);
}

.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #F2F2F2;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #F2F2F2;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer>* {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 36rem) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 62rem) {

  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 75rem) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 35.98rem) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 47.98rem) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 61.98rem) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 74.98rem) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1439.98) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.765625rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[data-popper-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end,
.bs-tooltip-auto[data-popper-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start,
.bs-tooltip-auto[data-popper-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.75rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0
    /* rtl:ignore */
  ;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.765625rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}

.popover .popover-arrow::before,
.popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top>.popover-arrow,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end>.popover-arrow,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-end>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-end>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom>.popover-arrow,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start>.popover-arrow,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-start>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-start>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #000;
  background-color: #f0f0f0;
  border-bottom: 1px solid #d8d8d8;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #969ba0;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg)
      /* rtl:ignore */
    ;
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {

  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #1362FC;
}

.link-primary:hover,
.link-primary:focus {
  color: #0f4eca;
}

.link-secondary {
  color: #627EEA;
}

.link-secondary:hover,
.link-secondary:focus {
  color: #8198ee;
}

.link-success {
  color: #37D159;
}

.link-success:hover,
.link-success:focus {
  color: #5fda7a;
}

.link-info {
  color: #b48dd3;
}

.link-info:hover,
.link-info:focus {
  color: #c3a4dc;
}

.link-warning {
  color: #FFC368;
}

.link-warning:hover,
.link-warning:focus {
  color: #ffcf86;
}

.link-danger {
  color: #FF6746;
}

.link-danger:hover,
.link-danger:focus {
  color: #ff856b;
}

.link-light {
  color: #c8c8c8;
}

.link-light:hover,
.link-light:focus {
  color: lightgray;
}

.link-dark {
  color: #6e6e6e;
}

.link-dark:hover,
.link-dark:focus {
  color: #585858;
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.ratio>* {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 36rem) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 48rem) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 62rem) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 75rem) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1440) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #F2F2F2 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #F2F2F2 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #F2F2F2 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #F2F2F2 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #F2F2F2 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #1362FC !important;
}

.border-secondary {
  border-color: #627EEA !important;
}

.border-success {
  border-color: #37D159 !important;
}

.border-info {
  border-color: #b48dd3 !important;
}

.border-warning {
  border-color: #FFC368 !important;
}

.border-danger {
  border-color: #FF6746 !important;
}

.border-light {
  border-color: #c8c8c8 !important;
}

.border-dark {
  border-color: #6e6e6e !important;
}

.border-white {
  border-color: #fff !important;
}

.border-0 {
  border-width: 0 !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.fs-1 {
  font-size: calc(1.35rem + 1.2vw) !important;
}

.fs-2 {
  font-size: calc(1.3125rem + 0.75vw) !important;
}

.fs-3 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-4 {
  font-size: 1.125rem !important;
}

.fs-5 {
  font-size: 1rem !important;
}

.fs-6 {
  font-size: 0.938rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-primary {
  color: #1362FC !important;
}

.text-secondary {
  color: #627EEA !important;
}

.text-success {
  color: #37D159 !important;
}

.text-info {
  color: #b48dd3 !important;
}

.text-warning {
  color: #FFC368 !important;
}

.text-danger {
  color: #FF6746 !important;
}

.text-light {
  color: #c8c8c8 !important;
}

.text-dark {
  color: #6e6e6e !important;
}

.text-white {
  color: #fff !important;
}

.text-body {
  color: #969ba0 !important;
}

.text-muted {
  color: #89879f !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.bg-primary {
  background-color: #1362FC !important;
}

.bg-secondary {
  background-color: #627EEA !important;
}

.bg-success {
  background-color: #37D159 !important;
}

.bg-info {
  background-color: #b48dd3 !important;
}

.bg-warning {
  background-color: #FFC368 !important;
}

.bg-danger {
  background-color: #FF6746 !important;
}

.bg-light {
  background-color: #c8c8c8 !important;
}

.bg-dark {
  background-color: #6e6e6e !important;
}

.bg-body {
  background-color: #FCFCFC !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.75rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.75rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
}

.rounded-end {
  border-top-right-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.75rem !important;
  border-top-left-radius: 0.75rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 36rem) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 48rem) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 62rem) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 75rem) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1440) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.25rem !important;
  }

  .fs-2 {
    font-size: 1.875rem !important;
  }

  .fs-3 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

.c-pointer {
  cursor: pointer;
}

* {
  outline: none;
  padding: 0;
}

*::after {
  margin: 0;
  padding: 0;
}

*::before {
  margin: 0;
  padding: 0;
}

::selection {
  color: #fff;
  background: var(--primary);
}

body {
  overflow-x: hidden;
  height: 100%;
  position: relative;
  max-width: 100%;
  font-size: 0.875rem;
}

@media only screen and (max-width: 61.9375rem) {
  body {
    font-size: 0.875rem;
  }
}

p {
  line-height: 1.8;
}

@media only screen and (max-width: 100rem) {
  :root {
    font-size: 14px;
  }
}

h2,
.h2,
.h2 {
  font-size: 2.25rem;
  font-weight: 600;
}

.box-shadow-none {
  box-shadow: none !important;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

#main-wrapper {
  opacity: 0;
  transition: all 0.25s ease-in;
  overflow: hidden;
  position: relative;
}

#main-wrapper.show {
  opacity: 1;
}

.rounded-lg {
  border-radius: 1.375rem;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  color: #969ba0;
  text-decoration: none;
}

a:hover,
a:focus,
a.active {
  text-decoration: none;
}

.btn-link:hover,
.btn-link:focus,
.btn-link.active {
  text-decoration: none;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.text-primary {
  color: var(--primary) !important;
}

.fs-12 {
  font-size: 0.75rem !important;
  line-height: 1.5;
}

.fs-13 {
  font-size: 0.8125rem !important;
  line-height: 1.5;
}

.fs-14 {
  font-size: 0.875rem !important;
  line-height: 1.6;
}

.fs-15 {
  font-size: 0.875rem !important;
  line-height: 1.5;
}

.fs-16 {
  font-size: 1rem !important;
  line-height: 1.6;
}

@media only screen and (max-width: 35.9375rem) {
  .fs-16 {
    font-size: 0.875rem !important;
  }
}

.fs-18 {
  font-size: 1.125rem !important;
  line-height: 1.5;
}

@media only screen and (max-width: 35.9375rem) {
  .fs-18 {
    font-size: 1rem !important;
  }
}

.fs-20 {
  font-size: 1.25rem !important;
  line-height: 1.5;
}

.fs-22 {
  font-size: 1.375rem !important;
  line-height: 1.5;
}

.fs-24 {
  font-size: 1.5rem !important;
  line-height: 1.4;
}

.fs-26 {
  font-size: 1.625rem !important;
  line-height: 1.4;
}

.fs-28 {
  font-size: 1.75rem !important;
  line-height: 1.4;
}

@media only screen and (max-width: 35.9375rem) {
  .fs-28 {
    font-size: 1.5rem !important;
  }
}

.fs-30 {
  font-size: 1.875rem !important;
  line-height: 1.4;
}

.fs-32 {
  font-size: 2rem !important;
  line-height: 1.25;
}

.fs-34 {
  font-size: 2.125rem !important;
  line-height: 1.25;
}

.fs-35 {
  font-size: 2.1875rem !important;
  line-height: 1.25;
}

.fs-36 {
  font-size: 2.25rem !important;
  line-height: 1.25;
}

.fs-38 {
  font-size: 2.375rem !important;
  line-height: 1.25;
}

.fs-46 {
  font-size: 2.875rem !important;
  line-height: 1.25;
}

.fs-48 {
  font-size: 3rem !important;
  line-height: 1.25;
}

.font-w100 {
  font-weight: 100;
}

.font-w200 {
  font-weight: 200;
}

.font-w300 {
  font-weight: 300;
}

.font-w400 {
  font-weight: 400;
}

.font-w500 {
  font-weight: 500;
}

.font-w600 {
  font-weight: 600;
}

.font-w700 {
  font-weight: 700;
}

.font-w800 {
  font-weight: 800;
}

.font-w900 {
  font-weight: 900;
}

.w-space-no {
  white-space: nowrap;
}

.content-body .container {
  margin-top: 2.5rem;
}

.content-body .container-fluid,
.content-body .container-sm,
.content-body .container-md,
.content-body .container-lg,
.content-body .container-xl,
.content-body .container-xxl {
  padding-top: 2.25rem;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

@media only screen and (max-width: 75rem) {

  .content-body .container-fluid,
  .content-body .container-sm,
  .content-body .container-md,
  .content-body .container-lg,
  .content-body .container-xl,
  .content-body .container-xxl {
    padding-top: 1.875rem;
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }
}

@media only screen and (max-width: 47.9375rem) {

  .content-body .container-fluid,
  .content-body .container-sm,
  .content-body .container-md,
  .content-body .container-lg,
  .content-body .container-xl,
  .content-body .container-xxl {
    padding-top: 1.25rem;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

@media only screen and (max-width: 35.9375rem) {

  .content-body .container-fluid,
  .content-body .container-sm,
  .content-body .container-md,
  .content-body .container-lg,
  .content-body .container-xl,
  .content-body .container-xxl {
    padding-top: 0.9375rem;
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

/*sp*/
.row.sp4,
.sp4 {
  margin-left: -0.125rem;
  margin-right: -0.125rem;
}

.row.sp4 [class*="col-"],
.sp4 [class*="col-"] {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}

.op1 {
  opacity: 0.1;
}

.op2 {
  opacity: 0.2;
}

.op3 {
  opacity: 0.3;
}

.op4 {
  opacity: 0.4;
}

.op5 {
  opacity: 0.5;
}

.op6 {
  opacity: 0.6;
}

.op7 {
  opacity: 0.7;
}

.op8 {
  opacity: 0.8;
}

.op9 {
  opacity: 0.9;
}

.content-heading {
  font-size: 1rem;
  margin-bottom: 1.875rem;
  margin-top: 3.125rem;
  border-bottom: 0.0625rem solid #F2F2F2;
  padding-bottom: 0.625rem;
}

[direction="rtl"] .content-heading {
  text-align: right;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
}

.vh-100 {
  height: 100vh !important;
}

.support-ticket {
  position: fixed;
  bottom: 1.875rem;
  right: 0.9375rem;
  z-index: 999999;
}

.support-ticket-btn {
  width: 6.25rem;
  background: #7CB442;
  animation: 0.7s ease-in-out 0s infinite alternate none running crescendo;
  border-radius: 3.125rem;
  color: #fff;
  font-size: 0.5rem;
  font-size: 1rem;
  padding: 0.3125rem 0.625rem 0.4375rem;
  text-align: center;
  display: inline-block;
  box-shadow: rgba(124, 180, 66, 0.7) 0rem 0.5rem 2.1875rem 0rem;
}

.support-ticket-btn:hover,
.support-ticket-btn:focus {
  color: #fff;
}

.text-blue {
  color: #5e72e4;
}

.text-indigo {
  color: #6610f2;
}

.text-purple {
  color: #6f42c1;
}

.text-pink {
  color: #e83e8c;
}

.text-red {
  color: #EE3232;
}

.text-orange {
  color: #ff9900;
}

.text-yellow {
  color: #FFFA6F;
}

.text-green {
  color: #297F00;
}

.text-teal {
  color: #20c997;
}

.text-cyan {
  color: #3065D0;
}

/* .bg-gray-dark{
	background:$color_pallate_2 !important;
}
.bg-purpal{
	background:$color_pallate_4 !important;
}
.bg-red{
	background:$color_pallate_5 !important;
}
.bg-blue-dark{
	background:$color_pallate_6 !important;
} */
.bg-blue {
  background: #496ecc !important;
}

.bg-orange {
  background: #ed8030 !important;
}

.bg-green {
  background: #299e4a !important;
}

.bg-purpel {
  background: #9517c1 !important;
}

.bg-dark-blue {
  background: #251e71 !important;
}

/* .bg-blue-light{
	background:$color_pallate_8 !important;
}
.bg-green-light{
	background:$color_pallate_9 !important;
}
.bg-green{
	background:$color_pallate_10 !important;
} */
.bg-black {
  background: #000;
}

.text-black {
  color: #000 !important;
}

.dz-scroll {
  position: relative;
}

.scale1 {
  transform: scale(1.1);
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  display: inline-block;
}

.scale2 {
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  display: inline-block;
}

.scale3 {
  transform: scale(1.3);
  -moz-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  display: inline-block;
}

.scale4 {
  transform: scale(1.4);
  -moz-transform: scale(1.4);
  -webkit-transform: scale(1.4);
  -ms-transform: scale(1.4);
  -o-transform: scale(1.4);
  display: inline-block;
}

.scale5 {
  transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  display: inline-block;
}

.scale-2 {
  transform: scale(2);
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
  -ms-transform: scale(2);
  -o-transform: scale(2);
  display: inline-block;
}

@-webkit-keyframes crescendo {
  from {
    -webkit-transform: translateY(0.3125rem) scale(0.8);
    -ms-transform: translateY(0.3125rem) scale(0.8);
    transform: translateY(0.3125rem) scale(0.8);
  }

  to {
    -webkit-transform: translateY(0rem) scale(1);
    -ms-transform: translateY(0rem) scale(1);
    transform: translateY(0rem) scale(1);
  }
}

.height10 {
  height: 0.625rem;
}

.height20 {
  height: 1.25rem;
}

.height30 {
  height: 1.875rem;
}

.height40 {
  height: 2.5rem;
}

.height50 {
  height: 3.125rem;
}

.height60 {
  height: 3.75rem;
}

.height70 {
  height: 4.375rem;
}

.height80 {
  height: 5rem;
}

.height90 {
  height: 5.625rem;
}

.height100 {
  height: 6.25rem;
}

.height110 {
  height: 6.875rem;
}

.height120 {
  height: 7.5rem;
}

.height130 {
  height: 8.125rem;
}

.height140 {
  height: 8.75rem;
}

.height150 {
  height: 9.375rem;
}

.height160 {
  height: 10rem;
}

.height170 {
  height: 10.625rem;
}

.height180 {
  height: 11.25rem;
}

.height190 {
  height: 11.875rem;
}

.height200 {
  height: 12.5rem;
}

.height210 {
  height: 13.125rem;
}

.height220 {
  height: 13.75rem;
}

.height230 {
  height: 14.375rem;
}

.height240 {
  height: 15rem;
}

.height250 {
  height: 15.625rem;
}

.height260 {
  height: 16.25rem;
}

.height270 {
  height: 16.875rem;
}

.height280 {
  height: 17.5rem;
}

.height290 {
  height: 18.125rem;
}

.height300 {
  height: 18.75rem;
}

.height310 {
  height: 19.375rem;
}

.height320 {
  height: 20rem;
}

.height330 {
  height: 20.625rem;
}

.height340 {
  height: 21.25rem;
}

.height350 {
  height: 21.875rem;
}

.height360 {
  height: 22.5rem;
}

.height370 {
  height: 23.125rem;
}

.height380 {
  height: 23.75rem;
}

.height390 {
  height: 24.375rem;
}

.height400 {
  height: 25rem;
}

.height415 {
  height: 25.9375rem;
}

.height500 {
  height: 31.25rem;
}

.height550 {
  height: 34.375rem;
}

.height600 {
  height: 37.5rem;
}

.height630 {
  height: 39.375rem;
}

.height720 {
  height: 45rem;
}

.height750 {
  height: 46.875rem;
}

.height800 {
  height: 50rem;
}

.width10 {
  width: 0.625rem;
}

.width20 {
  width: 1.25rem;
}

.width30 {
  width: 1.875rem;
}

.width40 {
  width: 2.5rem;
}

.width50 {
  width: 3.125rem;
}

.width60 {
  width: 3.75rem;
}

.width70 {
  width: 4.375rem;
}

.width80 {
  width: 5rem;
}

.width90 {
  width: 5.625rem;
}

.width100 {
  width: 6.25rem;
}

.width110 {
  width: 6.875rem;
}

.width120 {
  width: 7.5rem;
}

.width130 {
  width: 8.125rem;
}

.width140 {
  width: 8.75rem;
}

.width150 {
  width: 9.375rem;
}

.width160 {
  width: 10rem;
}

.width170 {
  width: 10.625rem;
}

.width180 {
  width: 11.25rem;
}

.width190 {
  width: 11.875rem;
}

.width200 {
  width: 12.5rem;
}

.width210 {
  width: 13.125rem;
}

.width220 {
  width: 13.75rem;
}

.width230 {
  width: 14.375rem;
}

.width240 {
  width: 15rem;
}

.width250 {
  width: 15.625rem;
}

.width260 {
  width: 16.25rem;
}

.width270 {
  width: 16.875rem;
}

.width280 {
  width: 17.5rem;
}

.width290 {
  width: 18.125rem;
}

.width300 {
  width: 18.75rem;
}

.width310 {
  width: 19.375rem;
}

.width320 {
  width: 20rem;
}

.width330 {
  width: 20.625rem;
}

.width340 {
  width: 21.25rem;
}

.width350 {
  width: 21.875rem;
}

.width360 {
  width: 22.5rem;
}

.width370 {
  width: 23.125rem;
}

.width380 {
  width: 23.75rem;
}

.width390 {
  width: 24.375rem;
}

.width400 {
  width: 25rem;
}

.rounded {
  border-radius: 1.375rem !important;
}

label {
  margin-bottom: 0.5rem;
}

@keyframes gXGDoR {
  from {
    -webkit-transform: translateY(0.3125rem) scale(0.8);
    -ms-transform: translateY(0.3125rem) scale(0.8);
    transform: translateY(0.3125rem) scale(0.8);
  }

  to {
    -webkit-transform: translateY(0rem) scale(1);
    -ms-transform: translateY(0rem) scale(1);
    transform: translateY(0rem) scale(1);
  }
}

@keyframes crescendo {
  from {
    -webkit-transform: translateY(0.3125rem) scale(0.8);
    -ms-transform: translateY(0.3125rem) scale(0.8);
    transform: translateY(0.3125rem) scale(0.8);
  }

  to {
    -webkit-transform: translateY(0rem) scale(1);
    -ms-transform: translateY(0rem) scale(1);
    transform: translateY(0rem) scale(1);
  }
}

@keyframes gXGDoR {
  from {
    -webkit-transform: translateY(0.3125rem) scale(0.8);
    -ms-transform: translateY(0.3125rem) scale(0.8);
    transform: translateY(0.3125rem) scale(0.8);
  }

  to {
    -webkit-transform: translateY(0rem) scale(1);
    -ms-transform: translateY(0rem) scale(1);
    transform: translateY(0rem) scale(1);
  }
}

@media only screen and (min-width: 75rem) and (max-width: 100rem) {
  .col-xxl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xxl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xxl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xxl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xxl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
}

/* Editable */
.waviy {
  position: relative;
  -webkit-box-reflect: below -1.25rem linear-gradient(transparent, rgba(0, 0, 0, 0.2));
}

.waviy span {
  position: relative;
  display: inline-block;
  font-size: 2.5rem;
  color: #000;
  text-transform: uppercase;
  animation: waviy 1s infinite;
  animation-delay: calc(.1s * var(--i));
}

@keyframes waviy {

  0%,
  40%,
  100% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(-1.25rem);
  }
}

#preloader {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  background-color: #fff;
}

[data-theme-version="dark"] #preloader {
  background: #171622;
}

[data-theme-version="dark"] .waviy span {
  color: #fff;
}

.footer {
  padding-left: 17.1875rem;
  background-color: #FCFCFC;
  font-size: 0.8125rem;
}

.footer .copyright {
  padding: 0.9375rem;
}

.footer .copyright p {
  text-align: center;
  margin: 0;
  color: #000;
}

.footer .copyright a {
  color: var(--primary);
}

[data-sidebar-style="mini"] .nav-control,
[data-layout="horizontal"] .nav-control {
  display: none;
}

@media only screen and (max-width: 47.9375rem) {
  [data-sidebar-style="overlay"] .nav-header .logo-abbr {
    display: block;
  }
}

[data-header-position="fixed"] .nav-header {
  position: fixed;
  left: 0;
}

@media only screen and (max-width: 47.9375rem) {
  [data-header-position="fixed"] .nav-header {
    left: 0;
  }
}

.nav-header {
  height: 6.5rem;
  width: 20.5rem;
  display: inline-block;
  text-align: left;
  position: absolute;
  /*   left: 1.25rem; */
  top: 0;
  background-color: var(--nav-headbg);
  transition: all .2s ease;
  z-index: 5;
}

.nav-header .logo-abbr {
  max-width: 2.9375rem;
  border-radius: 0.75rem;
}

@media only screen and (max-width: 87.5rem) {
  .nav-header .logo-abbr {
    max-width: 2.8125rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .nav-header .logo-abbr {
    width: 2.1875rem;
    height: 2.1875rem;
  }
}

.nav-header .rect-primary-rect {
  fill: var(--primary);
}

.nav-header .logo-compact {
  display: none;
}

@media only screen and (max-width: 87.5rem) {
  .nav-header {
    height: 5.6rem;
    width: 17rem;
  }
}

.nav-header .brand-logo {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1.125rem;
  color: #fff;
  text-decoration: none;
  padding-left: 0rem;
  padding-right: 1.875rem;
  font-weight: 700;
}

@media only screen and (max-width: 87.5rem) {
  .nav-header .brand-logo {
    padding-left: 0rem;
    padding-right: 2.25rem;
  }
}

[data-sidebar-style="compact"] .nav-header .brand-logo,
[data-sidebar-style="mini"] .nav-header .brand-logo {
  padding-left: 0;
  padding-right: 0;
  justify-content: center;
}

@media only screen and (max-width: 47.9375rem) {
  .nav-header .brand-logo {
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
  }
}

.nav-header .brand-title {
  margin-left: 0.9375rem;
  max-width: 8.75rem;
  font-size: 2.375rem;
  color: #000;
}

[data-theme-version="dark"] .nav-header .brand-title {
  background-position: 0 120%;
}

@media only screen and (max-width: 87.5rem) {
  .nav-header .brand-title {
    max-width: 6rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .nav-header {
    top: 0;
    background: transparent;
  }
}

.nav-header .svg-logo-primary-path {
  fill: var(--primary);
}

@media only screen and (max-width: 74.9375rem) {
  .nav-header {
    height: 5.6rem;
  }
}

@media only screen and (max-width: 63.9375rem) {
  .nav-header {
    width: 5rem;
  }

  .nav-header .brand-title {
    display: none;
  }
}

.nav-control {
  cursor: pointer;
  position: absolute;
  right: 1.75rem;
  text-align: center;
  top: 55%;
  transform: translateY(-50%);
  z-index: 9999;
  font-size: 1.4rem;
  padding: 0.125rem 0.5rem 0;
  border-radius: 0.125rem;
}

@media only screen and (max-width: 87.5rem) {
  .nav-control {
    right: 1rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .nav-control {
    right: -3rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .nav-control {
    right: -3rem;
  }
}

.hamburger {
  display: inline-block;
  left: 0rem;
  position: relative;
  top: 0rem;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  width: 1.625rem;
  z-index: 999;
}

.hamburger .line {
  background: #000;
  display: block;
  height: 0.1875rem;
  border-radius: 0.1875rem;
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
  margin-right: auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hamburger .line:nth-child(1) {
  width: 1.625rem;
}

.hamburger .line:nth-child(2) {
  width: 1.625rem;
}

.hamburger .line:nth-child(3) {
  width: 0.9375rem;
}

.hamburger:hover {
  cursor: pointer;
}

.hamburger:hover .line {
  width: 1.625rem;
}

.hamburger.is-active .line:nth-child(1),
.hamburger.is-active .line:nth-child(3) {
  width: 0.625rem;
  height: 0.125rem;
}

.hamburger.is-active .line:nth-child(2) {
  -webkit-transform: translateX(0rem);
  transform: translateX(0rem);
  width: 1.375rem;
  height: 0.125rem;
}

.hamburger.is-active .line:nth-child(1) {
  -webkit-transform: translateY(0.25rem) translateX(0.75rem) rotate(45deg);
  transform: translateY(0.25rem) translateX(0.75rem) rotate(45deg);
}

.hamburger.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-0.25rem) translateX(0.75rem) rotate(-45deg);
  transform: translateY(-0.25rem) translateX(0.75rem) rotate(-45deg);
}

@media (min-width: 47.9375rem) {
  [data-sidebar-style="compact"] .nav-control {
    display: none;
  }

  [data-sidebar-style="compact"] .nav-header {
    width: 11.25rem;
  }
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .brand-title {
  display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .logo-abbr {
  display: block;
}

[data-sidebar-style="full"][data-layout="horizontal"] .logo-compact {
  display: none;
}

[data-sidebar-style="mini"] .nav-header .logo-abbr {
  display: block;
}

[data-sidebar-style="compact"][data-layout="vertical"] .nav-header .brand-title {
  display: none;
}

[data-sidebar-style="compact"][data-layout="vertical"] .nav-header .logo-compact {
  max-width: 4.6875rem;
}

[data-sidebar-style="compact"][data-layout="horizontal"] .nav-header .brand-logo {
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  justify-content: start;
}

[data-sidebar-style="modern"][data-layout="vertical"] .nav-header {
  width: 10.625rem;
}

[data-sidebar-style="modern"][data-layout="vertical"] .nav-header .brand-title {
  display: none;
}

[data-sidebar-style="modern"][data-layout="vertical"] .nav-header .logo-compact {
  display: none;
}

.header {
  height: 6.5rem;
  z-index: 1;
  position: relative;
  padding: 0rem;
  background: var(--headerbg);
  z-index: 3;
  padding-left: 20.563rem;
  padding-top: 0rem;
  transition: all .2s ease;
}

.header .header-content {
  height: 100%;
  padding-left: 3rem;
  padding-right: 1.875rem;
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 87.5rem) {
  .header .header-content {
    padding-left: 2rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .header .header-content {
    padding-left: 3.75rem;
    padding-right: 0.938rem;
  }
}

.header .navbar {
  padding: 0;
  height: 100%;
  width: 100%;
}

.header .navbar .navbar-collapse {
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 87.5rem) {
  .header {
    height: 5.5rem;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .header {
    height: 5.6rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .header {
    padding-top: 0;
  }
}

/* pulse in SVG */
svg.pulse-svg {
  overflow: visible;
}

svg.pulse-svg .first-circle,
svg.pulse-svg .second-circle,
svg.pulse-svg .third-circle {
  -webkit-transform: scale(0.3);
  transform: scale(0.3);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-animation: pulse-me 3s linear infinite;
  animation: pulse-me 3s linear infinite;
  fill: var(--primary);
}

svg.pulse-svg .second-circle {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

svg.pulse-svg .third-circle {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

/* pulse in CSS */
.pulse-css {
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  border-radius: 3.5rem;
  height: 1rem;
  position: absolute;
  background: #FFC368;
  right: 0.375rem;
  top: 0.3125rem;
  border: 0.1875rem solid #fff;
  width: 1rem;
}

.pulse-css:after,
.pulse-css:before {
  content: '';
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: -.2rem;
  background-color: #D8B9C3;
  margin: auto;
  -webkit-transform: scale(0.3);
  transform: scale(0.3);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-animation: pulse-me 3s linear infinite;
  animation: pulse-me 3s linear infinite;
}

[direction="rtl"] .pulse-css:after,
[direction="rtl"] .pulse-css:before {
  left: auto;
  right: -.2rem;
}

@media only screen and (max-width: 87.5rem) {
  .pulse-css {
    height: 1rem;
    width: 1rem;
  }
}

@-webkit-keyframes pulse-me {
  0% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    opacity: 0;
  }

  50% {
    opacity: 0.1;
  }

  70% {
    opacity: 0.09;
  }

  100% {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0;
  }
}

@keyframes pulse-me {
  0% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    opacity: 0;
  }

  50% {
    opacity: 0.1;
  }

  70% {
    opacity: 0.09;
  }

  100% {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0;
  }
}

[data-sidebar-style="full"] .header,
[data-sidebar-style="overlay"] .header {
  width: 100%;
}

@media only screen and (max-width: 87.5rem) {

  [data-sidebar-style="full"] .header,
  [data-sidebar-style="overlay"] .header {
    width: 100%;
    padding-left: 18.2rem;
  }
}

@media only screen and (max-width: 63.9375rem) {

  [data-sidebar-style="full"] .header,
  [data-sidebar-style="overlay"] .header {
    width: 100%;
    padding-left: 5rem;
  }
}

[data-sidebar-style="mini"] .header {
  width: 100%;
  height: 5.5rem;
  padding-left: 6.3rem;
}

@media only screen and (max-width: 63.9375rem) {
  [data-sidebar-style="mini"] .header {
    height: 5.5rem;
  }
}

[data-sidebar-style="compact"] .header {
  width: 100%;
  padding-left: 12.5rem;
}

[data-sidebar-style="compact"] .header .header-content {
  padding-left: 2.5rem;
}

[data-header-position="fixed"] .header {
  position: fixed;
  top: 0;
  width: 100%;
}

[data-header-position="fixed"] .content-body {
  padding-top: 6.5rem;
}

@media only screen and (max-width: 87.5rem) {
  [data-header-position="fixed"] .content-body {
    padding-top: 6.5rem;
  }
}

@media only screen and (max-width: 74.9375rem) {
  [data-header-position="fixed"] .content-body {
    padding-top: 5rem;
  }
}

[data-header-position="fixed"] .deznav {
  margin-top: 0;
}

[data-sidebar-style="compact"][data-header-position="fixed"][data-container="boxed"][data-layout="vertical"] .header {
  width: 74.9375rem;
}

[data-sidebar-style="modern"] .header {
  width: 100%;
  padding-left: 11.9rem;
}

[data-sidebar-style="modern"][data-layout="horizontal"] .nav-header .brand-logo {
  justify-content: start;
}

[data-sidebar-style="modern"][data-layout="horizontal"] .header .header-content {
  padding-left: 1.875rem;
}

.header-left {
  height: 100%;
  display: flex;
  align-items: center;
}

.header-left .breadcrumb {
  margin-bottom: 0;
}

.header-left .dashboard_bar {
  font-size: 1.875rem;
  font-weight: 600;
  color: #000;
}

@media only screen and (max-width: 74.9375rem) {
  .header-left .dashboard_bar {
    font-size: 2.125rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .header-left .dashboard_bar {
    display: none;
  }
}

.header-left .dashboard_bar.sub-bar {
  font-size: 1.875rem;
}

[data-sidebar-style="compact"] .header-left {
  margin-left: 0;
}

.header-right {
  height: 100%;
}

.header-right .nav-item {
  height: 100%;
  display: flex;
  align-items: center;
}

.header-right .nav-item .nav-link {
  color: #464a53;
  font-size: 1.125rem;
}

.header-right .right-sidebar {
  margin-right: -1.875rem;
}

.header-right .right-sidebar a {
  height: 5rem;
  width: 5rem;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  border-left: 0.0625rem solid #c8c8c8;
}

.header-right>li:not(:first-child) {
  padding-left: 1.25rem;
}

@media only screen and (max-width: 74.9375rem) {
  .header-right>li:not(:first-child) {
    padding-left: 0.5rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .header-right .notification_dropdown {
    position: static;
  }
}

.header-right .notification_dropdown .nav-link {
  position: relative;
  color: var(--primary);
  background: transparent;
  border-radius: 1.375rem;
  background: #fff;
  box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.03);
  padding: 1rem;
  line-height: 1;
}

@media only screen and (max-width: 47.9375rem) {
  .header-right .notification_dropdown .nav-link {
    padding: 0.625rem;
  }
}

.header-right .notification_dropdown .nav-link i {
  font-size: 1.5rem;
}

@media only screen and (max-width: 87.5rem) {
  .header-right .notification_dropdown .nav-link i {
    font-size: 1.125rem;
  }
}

.header-right .notification_dropdown .nav-link svg path {
  fill: var(--primary);
}

@media only screen and (max-width: 87.5rem) {
  .header-right .notification_dropdown .nav-link svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.header-right .notification_dropdown .nav-link .badge {
  position: absolute;
  font-size: 0.625rem;
  border-radius: 50%;
  right: -0.5rem;
  top: -0.25rem;
  border: 0.125rem solid #fff;
  font-weight: normal;
  height: 1.625rem;
  width: 1.625rem;
  line-height: 0.75rem;
  text-align: center;
  padding: 0.3125rem;
  font-size: 0.875rem;
}

@media only screen and (max-width: 35.9375rem) {
  .header-right .notification_dropdown .nav-link .badge {
    height: 1.25rem;
    width: 1.25rem;
    border-width: 0.125rem;
    line-height: 0.4375rem;
    font-size: 0.5625rem;
  }
}

.header-right .notification_dropdown .dropdown-item:focus a,
.header-right .notification_dropdown .dropdown-item:active a {
  color: #fff;
}

.header-right .notification_dropdown .dropdown-item a {
  color: #6e6e6e;
}

.header-right .notification_dropdown .dropdown-item a:hover {
  text-decoration: none;
}

.header-right .dropdown-menu {
  border-width: 0;
  box-shadow: 0 0 2.3125rem rgba(8, 21, 66, 0.05);
}

[data-theme-version="dark"] .header-right .dropdown-menu {
  box-shadow: none;
}

.header-right .header-profile .nav-link {
  display: flex;
  align-items: center;
  padding: 0;
  border-left: 0.0625rem solid #F2F2F2;
  padding-left: 1.875rem;
  margin-left: 0.625rem;
}

.header-right .header-profile .nav-link img {
  border-radius: 1.375rem;
  height: 3.5rem;
  width: 3.5rem;
}

@media only screen and (max-width: 35.9375rem) {
  .header-right .header-profile .nav-link img {
    height: 38px;
    width: 38px;
  }
}

.header-right .header-profile .nav-link .header-info span {
  display: block;
  font-size: 1.125rem;
  line-height: 1.125rem;
  color: #2E2E2E;
  font-weight: 600;
}

.header-right .header-profile .nav-link .header-info small,
.header-right .header-profile .nav-link .header-info .small {
  font-size: 0.75rem;
  color: #2E2E2E;
}

@media only screen and (max-width: 35.9375rem) {
  .header-right .header-profile .nav-link .header-info {
    display: none;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .header-right .header-profile .nav-link {
    padding-left: 0;
  }
}

.search-area {
  max-width: 25rem;
  border-radius: 1.375rem;
  box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.03);
}

.search-area .form-control {
  border: 0;
  font-size: 1rem;
  caret-color: var(--primary);
  background: #fff;
  border-top-left-radius: 1.375rem;
  border-bottom-left-radius: 1.375rem;
}

@media only screen and (max-width: 87.5rem) {
  .search-area .form-control {
    height: 3.75rem;
  }
}

.search-area .input-group-text {
  border-top-right-radius: 1.375rem;
  border-bottom-right-radius: 1.375rem;
  background: #fff;
  border: 0;
}

.search-area .input-group-text i {
  font-size: 1.5625rem;
  color: var(--primary);
}

@media only screen and (max-width: 74.9375rem) {
  .nav-item .search-area {
    display: none;
  }
}

.dz-fullscreen #icon-minimize {
  display: none;
}

.dz-fullscreen.active #icon-full {
  display: none;
}

.dz-fullscreen.active #icon-minimize {
  display: inline-block;
}

.notification_dropdown .dropdown-menu-end {
  min-width: 19.375rem;
  padding: 0rem 0 1rem;
  top: 100%;
}

.notification_dropdown .dropdown-menu-end .notification_title {
  background: var(--primary);
  color: #fff;
  padding: 0.625rem 1.25rem;
}

.notification_dropdown .dropdown-menu-end .notification_title h5,
.notification_dropdown .dropdown-menu-end .notification_title .h5 {
  color: #fff;
  margin-bottom: 0.1875rem;
}

.notification_dropdown .dropdown-menu-end .media {
  width: 2.8125rem;
  height: 2.8125rem;
  font-size: 1.125rem;
}

[data-theme-version="dark"] .notification_dropdown .dropdown-menu-end .media {
  border-color: #2e2e42;
}

.notification_dropdown .dropdown-menu-end .media>span {
  width: 2.1875rem;
  height: 2.1875rem;
  border-radius: 3.125rem;
  display: inline-block;
  padding: 0.4375rem 0.5625rem;
  margin-right: 0.625rem;
}

[direction="rtl"].notification_dropdown .dropdown-menu-end .media>span {
  margin-right: 0;
  margin-left: 0.625rem;
}

.notification_dropdown .dropdown-menu-end .media>span.success {
  background: #c8f2d1;
  color: #37D159;
}

.notification_dropdown .dropdown-menu-end .media>span.primary {
  background: var(--rgba-primary-1);
  color: var(--primary);
}

.notification_dropdown .dropdown-menu-end .media>span.danger {
  background: #ffd4cb;
  color: #FF6746;
}

.notification_dropdown .dropdown-menu-end .media .notify-time {
  width: 100%;
  margin-right: 0;
  color: #828690;
}

.notification_dropdown .dropdown-menu-end .media p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 12.5rem;
  margin-bottom: 0;
  margin-top: 0.3125rem;
}

@media only screen and (max-width: 35.9375rem) {
  .notification_dropdown .dropdown-menu-end .media p {
    max-width: 6.25rem;
  }
}

.notification_dropdown .dropdown-menu-end .all-notification {
  display: block;
  padding: 0.9375rem 1.875rem 0;
  text-align: center;
  border-top: 0.0625rem solid #c8c8c8;
}

.notification_dropdown .dropdown-menu-end .all-notification i {
  margin-left: 0.625rem;
}

[data-container="boxed"] .search-area {
  display: none !important;
}

.nav-label {
  margin: 0.625rem 1.875rem 0;
  padding: 1.5625rem 0 0.625rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.05rem;
  border-top: 0.0625rem solid #eaeaea;
  color: #999999;
}

[data-theme-version="dark"] .nav-label {
  border-color: #2e2e42;
}

.nav-label.first {
  border: 0rem;
  margin-top: 0rem;
}

.nav-badge {
  position: absolute;
  right: 2.8125rem;
  top: 0.625rem;
}

.content-body {
  margin-left: 20.563rem;
  z-index: 0;
  transition: all .2s ease;
}

@media only screen and (max-width: 87.5rem) {
  .content-body {
    margin-left: 17.2rem;
  }
}

.bell img {
  -webkit-animation: ring 8s .7s ease-in-out infinite;
  -webkit-transform-origin: 50% 0.25rem;
  -moz-animation: ring 8s .7s ease-in-out infinite;
  -moz-transform-origin: 50% 0.25rem;
  animation: ring 8s .7s ease-in-out infinite;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }

  1% {
    -webkit-transform: rotateZ(30deg);
  }

  3% {
    -webkit-transform: rotateZ(-28deg);
  }

  5% {
    -webkit-transform: rotateZ(34deg);
  }

  7% {
    -webkit-transform: rotateZ(-32deg);
  }

  9% {
    -webkit-transform: rotateZ(30deg);
  }

  11% {
    -webkit-transform: rotateZ(-28deg);
  }

  13% {
    -webkit-transform: rotateZ(26deg);
  }

  15% {
    -webkit-transform: rotateZ(-24deg);
  }

  17% {
    -webkit-transform: rotateZ(22deg);
  }

  19% {
    -webkit-transform: rotateZ(-20deg);
  }

  21% {
    -webkit-transform: rotateZ(18deg);
  }

  23% {
    -webkit-transform: rotateZ(-16deg);
  }

  25% {
    -webkit-transform: rotateZ(14deg);
  }

  27% {
    -webkit-transform: rotateZ(-12deg);
  }

  29% {
    -webkit-transform: rotateZ(10deg);
  }

  31% {
    -webkit-transform: rotateZ(-8deg);
  }

  33% {
    -webkit-transform: rotateZ(6deg);
  }

  35% {
    -webkit-transform: rotateZ(-4deg);
  }

  37% {
    -webkit-transform: rotateZ(2deg);
  }

  39% {
    -webkit-transform: rotateZ(-1deg);
  }

  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }

  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }

  1% {
    -moz-transform: rotate(30deg);
  }

  3% {
    -moz-transform: rotate(-28deg);
  }

  5% {
    -moz-transform: rotate(34deg);
  }

  7% {
    -moz-transform: rotate(-32deg);
  }

  9% {
    -moz-transform: rotate(30deg);
  }

  11% {
    -moz-transform: rotate(-28deg);
  }

  13% {
    -moz-transform: rotate(26deg);
  }

  15% {
    -moz-transform: rotate(-24deg);
  }

  17% {
    -moz-transform: rotate(22deg);
  }

  19% {
    -moz-transform: rotate(-20deg);
  }

  21% {
    -moz-transform: rotate(18deg);
  }

  23% {
    -moz-transform: rotate(-16deg);
  }

  25% {
    -moz-transform: rotate(14deg);
  }

  27% {
    -moz-transform: rotate(-12deg);
  }

  29% {
    -moz-transform: rotate(10deg);
  }

  31% {
    -moz-transform: rotate(-8deg);
  }

  33% {
    -moz-transform: rotate(6deg);
  }

  35% {
    -moz-transform: rotate(-4deg);
  }

  37% {
    -moz-transform: rotate(2deg);
  }

  39% {
    -moz-transform: rotate(-1deg);
  }

  41% {
    -moz-transform: rotate(1deg);
  }

  43% {
    -moz-transform: rotate(0);
  }

  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }

  1% {
    transform: rotate(30deg);
  }

  3% {
    transform: rotate(-28deg);
  }

  5% {
    transform: rotate(34deg);
  }

  7% {
    transform: rotate(-32deg);
  }

  9% {
    transform: rotate(30deg);
  }

  11% {
    transform: rotate(-28deg);
  }

  13% {
    transform: rotate(26deg);
  }

  15% {
    transform: rotate(-24deg);
  }

  17% {
    transform: rotate(22deg);
  }

  19% {
    transform: rotate(-20deg);
  }

  21% {
    transform: rotate(18deg);
  }

  23% {
    transform: rotate(-16deg);
  }

  25% {
    transform: rotate(14deg);
  }

  27% {
    transform: rotate(-12deg);
  }

  29% {
    transform: rotate(10deg);
  }

  31% {
    transform: rotate(-8deg);
  }

  33% {
    transform: rotate(6deg);
  }

  35% {
    transform: rotate(-4deg);
  }

  37% {
    transform: rotate(2deg);
  }

  39% {
    transform: rotate(-1deg);
  }

  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}

.deznav {
  width: 20.5rem;
  padding-bottom: 0;
  height: calc(100% - 6.4375rem);
  position: absolute;
  top: 6.5rem;
  left: 0;
  padding-top: 0;
  z-index: 4;
  background-color: var(--sidebar-bg);
  transition: all .2s ease;
}

@media only screen and (max-width: 87.5rem) {
  .deznav {
    top: 5.5rem;
    height: calc(100% - 5.2rem);
  }
}

@media only screen and (max-width: 74.9375rem) {
  .deznav {
    /*   background-color: $white; */
    top: 4.9rem;
    height: calc(100% - 4.5rem);
  }
}

@media only screen and (max-width: 47.9375rem) {
  .deznav {
    width: 18rem;
  }
}

.deznav .deznav-scroll {
  position: relative;
  height: 100%;
}

@media only screen and (max-width: 87.5rem) {
  .deznav {
    width: 17rem;
  }
}

.deznav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.deznav .metismenu {
  display: flex;
  flex-direction: column;
  padding-top: 0.9375rem;
}

.deznav .metismenu.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
}

.deznav .metismenu>li {
  display: flex;
  flex-direction: column;
}

.deznav .metismenu>li a>i {
  font-size: 1.3rem;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: 0;
  height: auto;
  width: auto;
  text-align: center;
  margin-right: 1.25rem;
  border-radius: 0.125rem;
  line-height: 1;
  border-radius: 0.125rem;
}

[data-sidebar-style="compact"] .deznav .metismenu>li a>i {
  display: block;
  padding: 0;
  background: rgba(200, 200, 200, 0.2);
  color: rgba(0, 0, 0, 0.3);
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 1.375rem;
  line-height: 3.75rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.3125rem;
}

[data-sidebar-style="compact"] .deznav .metismenu>li a>i[data-theme-version="dark"] {
  color: #fff;
}

@media only screen and (max-width: 84.375rem) {
  .deznav .metismenu>li a>i {
    height: auto;
    line-height: 0.0625rem;
    width: auto;
    font-size: 1.5rem;
    padding: 0;
    color: #969BA0;
  }
}

.deznav .metismenu>li>a {
  font-weight: 500;
  display: inline-block;
  font-size: 1.125rem;
  color: #BFBFBF;
}

.deznav .metismenu>li>a i {
  color: #BFBFBF;
}

.deznav .metismenu>li>a svg {
  max-width: 1.5rem;
  max-height: 1.5rem;
  height: 100%;
  margin-right: 0.3125rem;
  margin-top: -0.1875rem;
  color: var(--primary);
}

.deznav .metismenu>li>a g [fill] {
  fill: #8088a1;
}

.deznav .metismenu>li:hover>a,
.deznav .metismenu>li:focus>a {
  color: var(--primary);
}

.deznav .metismenu>li:hover>a g [fill],
.deznav .metismenu>li:focus>a g [fill] {
  fill: var(--primary);
}

.deznav .metismenu>li.mm-active>a {
  color: #fff;
  font-weight: 600;
  box-shadow: none;
  background: var(--primary);
  box-shadow: 0rem 0.75rem 1.75rem var(--rgba-primary-3);
}

.deznav .metismenu>li.mm-active>a i {
  font-weight: 100;
}

.deznav .metismenu>li.mm-active>a g [fill] {
  fill: var(--primary);
}

.deznav .metismenu>li.mm-active>a:after {
  border-top: 0.3125rem solid var(--primary);
  border-left: 0.3125rem solid var(--primary);
}

[data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a i {
  background: var(--rgba-primary-1);
  color: #fff;
}

.deznav .metismenu li {
  position: relative;
}

.deznav .metismenu ul {
  transition: all .2s ease-in-out;
  position: relative;
  z-index: 1;
  padding: 0.5rem 0;
}

.deznav .metismenu ul a {
  padding-top: .5rem;
  padding-bottom: .5rem;
  position: relative;
  font-size: 1rem;
  padding-left: 5rem;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.deznav .metismenu ul a:hover {
  padding-left: 5.5rem;
}

@media only screen and (max-width: 78.125rem) {
  .deznav .metismenu ul a:hover {
    padding-left: 5rem;
  }
}

.deznav .metismenu ul a:hover:before {
  width: 1.25rem;
  background: var(--primary);
}

@media only screen and (max-width: 84.375rem) {
  .deznav .metismenu ul a {
    padding-left: 4.6rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .deznav .metismenu ul a {
    padding-left: 3.5rem;
    font-size: 0.875rem;
  }
}

.deznav .metismenu ul a:hover,
.deznav .metismenu ul a:focus,
.deznav .metismenu ul a.mm-active {
  text-decoration: none;
  color: var(--primary);
}

.deznav .metismenu ul a:hover:before,
.deznav .metismenu ul a:focus:before,
.deznav .metismenu ul a.mm-active:before {
  background: var(--primary);
}

.deznav .metismenu ul a:before {
  position: absolute;
  content: "";
  height: 0.125rem;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  width: 0.5rem;
  top: 50%;
  left: 2.75rem;
  border-radius: 3.75rem;
  background: #c4c4c4;
  transform: translateY(-50%);
}

@media only screen and (max-width: 84.375rem) {
  .deznav .metismenu ul a:before {
    left: 2.8125rem;
  }
}

.deznav .metismenu a {
  position: relative;
  display: block;
  padding: 0.625rem 1.875rem;
  outline-width: 0;
  color: #BFBFBF;
  text-decoration: none;
}

@media only screen and (max-width: 47.9375rem) {
  .deznav .metismenu a {
    padding: 0.625rem 1.25rem;
  }
}

.deznav .metismenu .has-arrow:after {
  /* width:  .5rem;
      height: .5rem;
      border-color: inherit; */
  border-top: 0.3125rem solid #C8C8C8;
  border-left: 0.3125rem solid #C8C8C8;
  border-bottom: 0.3125rem solid transparent;
  border-right: 0.3125rem solid transparent;
  right: 1.875rem;
  top: 48%;
  -webkit-transform: rotate(-225deg) translateY(-50%);
  transform: rotate(-225deg) translateY(-50%);
}

.deznav .metismenu .has-arrow[aria-expanded=true]:after,
.deznav .metismenu .mm-active>.has-arrow:after {
  -webkit-transform: rotate(-135deg) translateY(-50%);
  transform: rotate(-135deg) translateY(-50%);
}

.deznav .header-profile {
  margin-bottom: 1.5625rem;
}

.deznav .header-profile>a.nav-link {
  padding: 0.625rem 0.9375rem;
  align-items: center;
}

.deznav .header-profile>a.nav-link i {
  font-weight: 700;
}

.deznav .header-profile>a.nav-link .header-info {
  text-align: center;
}

@media only screen and (max-width: 87.5rem) {
  .deznav .header-profile>a.nav-link .header-info {
    padding-left: 0.625rem;
  }
}

.deznav .header-profile>a.nav-link .header-info span {
  font-size: 1.25rem;
  color: #000;
  display: block;
  font-weight: 600;
}

.deznav .header-profile>a.nav-link .header-info strong {
  color: #6e6e6e;
}

.deznav .header-profile>a.nav-link .header-info small,
.deznav .header-profile>a.nav-link .header-info .small {
  font-size: 0.875rem;
  color: #89879f;
  font-weight: 400;
  line-height: 1.2;
}

@media only screen and (max-width: 87.5rem) {
  .deznav .header-profile>a.nav-link {
    margin-left: 0rem;
    padding-left: 0rem;
  }

  .deznav .header-profile>a.nav-link .header-info span {
    font-size: 1rem;
  }
}

.deznav .header-profile .dropdown-menu {
  padding: 0.9375rem 0;
  min-width: 12.5rem;
}

.deznav .header-profile .dropdown-menu a:hover,
.deznav .header-profile .dropdown-menu a:focus,
.deznav .header-profile .dropdown-menu a.active {
  color: var(--primary);
}

.deznav .header-profile img {
  width: 5.8125rem;
  height: 5.8125rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.25rem;
  border-radius: 50%;
}

.deznav .header-profile .dropdown-toggle i {
  font-size: 1.25rem;
}

@media only screen and (max-width: 35.9375rem) {
  .deznav .header-profile .dropdown-toggle span {
    display: none;
  }
}

.deznav .header-profile .profile_title {
  background: var(--primary);
  color: #fff;
  padding: 0.625rem 1.25rem;
}

.deznav .header-profile .profile_title h5,
.deznav .header-profile .profile_title .h5 {
  color: #fff;
  margin-bottom: 0.1875rem;
}

.deznav .header-profile .dropdown-item {
  padding: 0.5rem 1.5rem;
}

.copyright {
  padding: 0 1.875rem;
  color: #9fa4a6;
  margin-top: 1.875rem;
}

.copyright p {
  font-size: 0.75rem;
  color: #bfbfbf;
}

.copyright strong {
  display: block;
  font-size: 0.875rem;
}

@media only screen and (max-width: 63.9375rem) {
  .nav-header {
    width: 5rem;
  }
}

@media (max-width: 47.9375rem) {
  .brand-title {
    display: none;
  }

  .footer {
    padding-left: 0;
  }

  .deznav {
    left: 0;
    top: 5rem;
  }
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu>ul.collapse:not(.in),
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu>ul.collapse:not(.in) {
  height: 15.75rem !important;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu:hover>a {
  width: calc(70vw + 3.75rem);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu:hover>ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 13.75rem;
  width: 70vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu:hover>ul ul a {
  width: 101%;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-xl:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-xl:hover>a {
  width: calc(70vw + 3rem);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-xl:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-xl:hover>ul {
  max-height: 12.5rem;
  width: 70vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-xl:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-xl:hover>ul ul a {
  width: 101%;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-lg:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-lg:hover>a {
  width: calc(55vw + 3rem);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-lg:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-lg:hover>ul {
  max-height: 12.5rem;
  width: 55vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-lg:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-lg:hover>ul ul a {
  width: 101%;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-md:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-md:hover>a {
  width: calc(45vw + 3);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-md:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-md:hover>ul {
  max-height: 18.75rem;
  width: 45vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-md:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-md:hover>ul ul a {
  width: 101%;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-sm:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-sm:hover>a {
  width: calc(30vw + 3);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-sm:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-sm:hover>ul {
  max-height: 18.125rem;
  width: 30vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mega-menu-sm:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mega-menu-sm:hover>ul ul a {
  width: 101%;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu:hover>a {
  width: calc(60vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu:hover>ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 25rem;
  width: 60vw;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu:hover>ul ul a {
  width: 101%;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu-xl:hover>a {
  width: calc(60vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu-xl:hover>ul {
  max-height: 25.625rem;
  width: 60vw;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu-lg:hover>a {
  width: calc(50vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu-lg:hover>ul {
  max-height: 16.25rem;
  width: 50vw;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu-md:hover>a {
  width: calc(40vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu-md:hover>ul {
  max-height: 18.75rem;
  width: 40vw;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu-sm:hover>a {
  width: calc(22vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .deznav .metismenu>li.mega-menu-sm:hover>ul {
  max-height: 18.125rem;
  width: 22vw;
}

[data-layout="horizontal"] .deznav .metismenu>li.mega-menu:not(:last-child) {
  position: static;
}

[data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul {
  left: 0;
  right: 0;
}

[data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li {
  border-color: #2e2e42;
}

[data-sibebarbg="color_2"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li {
  border-color: #3d0894;
}

[data-sibebarbg="color_3"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li {
  border-color: #023aa7;
}

[data-sibebarbg="color_4"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li {
  border-color: #1f0243;
}

[data-sibebarbg="color_5"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li {
  border-color: #921925;
}

[data-sibebarbg="color_6"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li {
  border-color: #aa4e01;
}

[data-sibebarbg="color_7"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li {
  border-color: #a07800;
}

[data-sibebarbg="color_8"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li {
  border-color: #cccccc;
}

[data-sibebarbg="color_9"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li {
  border-color: #127155;
}

[data-sibebarbg="color_10"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li {
  border-color: #0c525d;
}

[data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li a {
  transition: all .4s ease-in-out;
}

[data-layout="horizontal"] .deznav .metismenu>li.mega-menu ul li a:hover {
  border-radius: 0.25rem;
}

[data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 13.75rem;
  width: 70vw;
  z-index: 99;
}

[data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul::after {
  content: "";
  height: 100%;
  width: 0.0625rem;
  position: absolute;
  background-color: #fff;
  right: 2.8125rem;
  top: 0;
}

[data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #1e1c2c;
}

[data-sibebarbg="color_2"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #510bc4;
}

[data-sibebarbg="color_3"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #034bd9;
}

[data-sibebarbg="color_4"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #360474;
}

[data-sibebarbg="color_5"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #bd2130;
}

[data-sibebarbg="color_6"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #dc6502;
}

[data-sibebarbg="color_7"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #d39e00;
}

[data-sibebarbg="color_8"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #e6e6e6;
}

[data-sibebarbg="color_9"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #199d76;
}

[data-sibebarbg="color_10"][data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #117a8b;
}

[data-layout="horizontal"] .deznav .metismenu>li.mega-menu:hover>ul ul a {
  width: 101%;
}

[data-layout="horizontal"] .deznav .metismenu>li.mega-menu-xl:hover>ul {
  max-height: 13.125rem;
  width: 70vw;
}

[data-layout="horizontal"] .deznav .metismenu>li.mega-menu-lg:hover>ul {
  max-height: 13.125rem;
  width: 43.75rem;
  height: 13.125rem !important;
}

@media only screen and (min-width: 75rem) and (max-width: 93.75rem) {
  [data-layout="horizontal"] .deznav .metismenu>li.mega-menu-lg:hover>ul {
    width: 43.75rem;
  }
}

[data-layout="horizontal"] .deznav .metismenu>li.mega-menu-md:hover>ul {
  max-height: 20rem;
  width: 54vw;
}

@media only screen and (min-width: 75rem) and (max-width: 93.75rem) {
  [data-layout="horizontal"] .deznav .metismenu>li.mega-menu-md:hover>ul {
    width: 60vw;
  }
}

[data-layout="horizontal"] .deznav .metismenu>li.mega-menu-sm:hover>ul {
  max-height: 20rem;
  width: 25vw;
}

@media only screen and (min-width: 75rem) and (max-width: 93.75rem) {
  [data-layout="horizontal"] .deznav .metismenu>li.mega-menu-sm:hover>ul {
    width: 35vw;
  }
}

[data-layout="horizontal"][data-container="boxed"] .deznav .metismenu>li.mega-menu:hover>ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

[data-layout="horizontal"][data-container="boxed"] .deznav .metismenu>li.mega-menu-xl:hover>ul {
  max-height: 21.875rem;
  width: 100%;
}

[data-layout="horizontal"][data-container="boxed"] .deznav .metismenu>li.mega-menu-lg:hover>ul {
  max-height: 21.875rem;
  width: 55vw;
}

[data-layout="horizontal"][data-container="boxed"] .deznav .metismenu>li.mega-menu-md:hover>ul {
  max-height: 18.75rem;
  width: 45vw;
}

[data-layout="horizontal"][data-container="boxed"] .deznav .metismenu>li.mega-menu-sm:hover>ul {
  max-height: 18.125rem;
  width: 50vw;
}

:root {
  --primary: #1362FC;
  --secondary: #627EEA;
  --primary-hover: #034bd9;
  --primary-light: #aac7fe;
  --primary-dark: #012875;
  --rgba-primary-1: rgba(19, 98, 252, 0.1);
  --rgba-primary-2: rgba(19, 98, 252, 0.2);
  --rgba-primary-3: rgba(19, 98, 252, 0.3);
  --rgba-primary-4: rgba(19, 98, 252, 0.4);
  --rgba-primary-5: rgba(19, 98, 252, 0.5);
  --rgba-primary-6: rgba(19, 98, 252, 0.6);
  --rgba-primary-7: rgba(19, 98, 252, 0.7);
  --rgba-primary-8: rgba(19, 98, 252, 0.8);
  --rgba-primary-9: rgba(19, 98, 252, 0.9);
  --font-family-base: Roboto, sans-serif;
  --font-family-title: Roboto, sans-serif;
  --title: #000;
}

/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/
[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li {
  padding: 0 2.1875rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li>a {
  font-size: 1.125rem;
  padding: 1.125rem 2.1875rem;
  border-radius: 1.375rem;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
  color: #fff;
  font-weight: 300;
}

[data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li .has-arrow:after {
  right: 1.5rem;
}

@media only screen and (max-width: 87.5rem) {
  [data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li {
    padding: 0 0.9375rem;
  }

  [data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li>a {
    font-size: 1rem;
    padding: 0.9375rem 1.25rem;
  }
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header {
  width: 6.5rem;
  z-index: 999;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .brand-logo {
  padding-left: 0;
  padding-right: 0;
  justify-content: center;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control {
  right: -4rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #000;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .copyright,
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .plus-box {
  display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .header {
  padding-left: 7.5rem;
  width: 100%;
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .header {
  padding: 0 0.9375rem;
  padding-right: 7.5rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .header .header-content {
  padding-left: 5rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav {
  width: 6.5rem;
  overflow: visible;
  position: absolute;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .nav-text {
  display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .slimScrollDiv,
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .deznav-scroll {
  overflow: visible !important;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .header-profile {
  margin-bottom: 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .header-profile>a.nav-link {
  padding: 0.3125rem 0.3125rem !important;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .header-profile>a.nav-link .header-info {
  display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .header-profile>a.nav-link img {
  height: 3.75rem;
  width: 3.75rem;
  background: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li {
  position: relative;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li a {
  background: transparent;
  margin: 0.125rem 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li a svg {
  max-width: 1.5rem;
  max-height: 1.5rem;
  margin-right: 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li a:before {
  content: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li a i {
  margin: 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul {
  position: absolute;
  left: 5.8rem;
  top: 0;
  width: 12rem;
  z-index: 1001;
  display: none;
  padding-left: 0.0625rem;
  height: auto !important;
  box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
  border-radius: 0.375rem;
  margin-left: 0;
  border: 0;
  background: #fff;
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul {
  left: auto;
  right: 5rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul li:hover ul {
  left: 11.8125rem;
  top: 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li>ul li:hover ul:after {
  content: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu li:hover>ul {
  display: block;
  height: auto;
  overflow: visible;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li {
  transition: all 0.4s ease-in-out;
  padding: 0 0.9375rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li>a {
  padding: 1.25rem 1.125rem;
  text-align: center;
  border-radius: 3rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li>a.has-arrow:after {
  display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a {
  background: var(--primary);
  border-radius: 1rem;
  box-shadow: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li.mm-active>a i {
  color: #fff;
  padding: 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover:nth-last-child(-n + 1)>ul {
  bottom: 0;
  top: auto;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a {
  border-radius: 3rem;
  background: var(--primary);
  color: var(--primary);
}

[data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a {
  background: #212130;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>a i {
  color: #fff;
  /* background:$primary; */
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>ul {
  height: auto !important;
  padding: 0.625rem 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>ul a {
  padding: 0.375rem 1.25rem 0.375rem 1.25rem;
  margin-left: -.1rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>ul ul {
  padding: 0.625rem 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu>li:hover>ul ul a {
  padding: 0.375rem 1.25rem 0.375rem 1.25rem;
  margin-left: -.1rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu .nav-label,
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .deznav .metismenu .nav-badge {
  display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .content-body {
  margin-left: 6.5rem;
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .content-body {
  margin-right: 5.7rem;
  margin-left: auto;
  border: 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle+.footer {
  padding-left: 5.7rem;
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle+.footer {
  padding-left: 0;
  padding-right: 5.7rem;
}

[data-sidebar-style="full"][data-layout="horizontal"] .header .header-content {
  padding-left: 1.875rem;
}

/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/
:root {
  --primary: #1362FC;
  --secondary: #627EEA;
  --primary-hover: #034bd9;
  --primary-light: #aac7fe;
  --primary-dark: #012875;
  --rgba-primary-1: rgba(19, 98, 252, 0.1);
  --rgba-primary-2: rgba(19, 98, 252, 0.2);
  --rgba-primary-3: rgba(19, 98, 252, 0.3);
  --rgba-primary-4: rgba(19, 98, 252, 0.4);
  --rgba-primary-5: rgba(19, 98, 252, 0.5);
  --rgba-primary-6: rgba(19, 98, 252, 0.6);
  --rgba-primary-7: rgba(19, 98, 252, 0.7);
  --rgba-primary-8: rgba(19, 98, 252, 0.8);
  --rgba-primary-9: rgba(19, 98, 252, 0.9);
  --font-family-base: Roboto, sans-serif;
  --font-family-title: Roboto, sans-serif;
  --title: #000;
}

@media only screen and (min-width: 48rem) {
  [data-sidebar-style="mini"] .nav-header {
    width: 6.25rem;
  }

  [data-sidebar-style="mini"] .nav-header .nav-control {
    z-index: -1;
  }

  [data-sidebar-style="mini"] .nav-header .nav-control .hamburger {
    left: 6.25rem !important;
  }

  [data-sidebar-style="mini"] .nav-header .nav-control .hamburger .line {
    background-color: #6e6e6e !important;
  }

  [data-sidebar-style="mini"] .nav-header .brand-title {
    display: none;
  }

  [data-sidebar-style="mini"] .nav-header .hamburger {
    display: none;
  }

  [data-sidebar-style="mini"] .header .header-content {
    padding-left: 1.875rem;
  }

  [direction="rtl"][data-sidebar-style="mini"] .header .header-content {
    padding-right: 1.875rem;
  }

  [data-sidebar-style="mini"] .deznav {
    width: 6.25rem;
    overflow: visible;
    position: absolute;
    z-index: 2;
    top: 5.5rem;
  }

  [data-sidebar-style="mini"] .deznav .copyright,
  [data-sidebar-style="mini"] .deznav .plus-box {
    display: none;
  }

  [data-sidebar-style="mini"] .deznav .nav-text {
    display: none;
  }

  [data-sidebar-style="mini"] .deznav .slimScrollDiv,
  [data-sidebar-style="mini"] .deznav .deznav-scroll {
    overflow: visible !important;
  }

  [data-sidebar-style="mini"] .deznav .nav-user {
    padding: 0.6875rem;
  }

  [data-sidebar-style="mini"] .deznav .nav-user .media-body {
    display: none;
  }

  [data-sidebar-style="mini"] .deznav .header-profile {
    margin-bottom: 0;
    margin-top: 0.75rem;
  }

  [data-sidebar-style="mini"] .deznav .header-profile:hover>a.nav-link {
    border-radius: 3rem;
  }

  [data-sidebar-style="mini"] .deznav .header-profile img {
    width: 3rem;
    height: 3rem;
  }

  [data-sidebar-style="mini"] .deznav .header-profile>a.nav-link {
    border-radius: 3rem;
    padding: 0.3125rem 0.3125rem;
  }

  [data-sidebar-style="mini"] .deznav .header-profile .header-info {
    display: none;
  }

  [data-sidebar-style="mini"] .deznav .metismenu li a {
    padding: 0.813rem 0.875rem;
  }

  [data-sidebar-style="mini"] .deznav .metismenu li a svg {
    margin-right: 0;
  }

  [data-sidebar-style="mini"] .deznav .metismenu li a i {
    height: auto;
    width: auto;
    line-height: 1;
    margin: 0;
  }

  [data-sidebar-style="mini"] .deznav .metismenu li>ul {
    position: absolute;
    left: 6.25rem;
    top: 2.9375rem;
    width: 11.875rem;
    z-index: 1001;
    display: none;
    padding-left: 0.0625rem;
    box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
    height: auto !important;
    border-radius: 1.375rem;
    background: #fff;
  }

  [direction="rtl"]:not([data-layout="horizontal"])[data-sidebar-style="mini"] .deznav .metismenu li>ul {
    left: auto;
    right: 6.25rem;
    box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
  }

  [data-sidebar-style="mini"] .deznav .metismenu>li {
    padding: 0.125rem 1.0625rem;
  }

  [data-sidebar-style="mini"] .deznav .metismenu>li>a {
    padding: 1.125rem 0.875rem;
    /* width: 64px;
        height: 64px; */
    text-align: center;
    line-height: 1;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
  }

  [data-sidebar-style="mini"] .deznav .metismenu>li>a>i {
    padding: 0;
    font-size: 1.375rem;
  }

  [data-sidebar-style="mini"] .deznav .metismenu>li>a.has-arrow:after {
    display: none;
  }

  [data-sidebar-style="mini"] .deznav .metismenu .nav-label,
  [data-sidebar-style="mini"] .deznav .metismenu .nav-badge {
    display: none;
  }
}

@media only screen and (min-width: 48rem) and (max-width: 63.9375rem) {
  [data-sidebar-style="mini"] .deznav {
    top: 5.5rem;
  }
}

@media only screen and (min-width: 48rem) {
  [data-sidebar-style="mini"] .content-body {
    margin-left: 6.5rem;
  }

  [data-sidebar-style="mini"] .footer {
    padding-left: 6.5rem;
  }

  [data-sidebar-style="mini"][data-header-position="fixed"] .content-body {
    padding-top: 6.5rem;
  }
}

@media only screen and (min-width: 48rem) and (max-width: 63.9375rem) {
  [data-sidebar-style="mini"][data-header-position="fixed"] .content-body {
    padding-top: 5.5rem;
  }
}

@media only screen and (min-width: 48rem) {
  [data-sidebar-style="mini"][data-layout="vertical"] .deznav {
    position: absolute !important;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu li:hover>ul {
    display: block;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:nth-last-child(-n + 1)>ul {
    bottom: 0;
    top: auto !important;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:nth-last-child(-n + 1)>ul:after {
    top: auto;
    bottom: 1.25rem;
  }
}

@media only screen and (min-width: 48rem) and (max-width: 74.9375rem) {
  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:nth-last-child(-n + 1)>ul {
    bottom: 0;
    top: auto !important;
  }
}

@media only screen and (min-width: 48rem) {
  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li>ul {
    overflow: visible;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li>ul:after {
    content: none;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li>ul li:hover ul {
    padding: 0.625rem 0;
    width: 13rem;
    left: 13rem;
    top: -0.625rem;
    border: 0;
    margin: 0;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li>ul li:hover ul:after {
    content: none;
  }

  [direction="rtl"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li>ul li:hover ul {
    left: auto;
    right: 13rem;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a {
    background: var(--primary);
    color: #fff;
    border-radius: 1.375rem;
    box-shadow: none;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
    color: #fff;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
    background: var(--primary);
    color: var(--primary);
    box-shadow: 0 0.75rem 0.9375rem 0 var(--rgba-primary-1);
    border-radius: 1.375rem;
    position: unset;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a i {
    color: #fff;
  }

  [direction="rtl"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>a .nav-text {
    padding-left: auto;
    padding-right: 1.6875rem;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>ul {
    height: auto !important;
    overflow: visible;
    border: 0;
    margin-left: 0;
    left: 6.25rem;
    width: 13rem;
    border-radius: 1.375rem;
    border: 0;
    padding: 0.625rem 0;
    top: 0;
  }

  [data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>ul {
    box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
  }

  [direction="rtl"][data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>ul {
    left: auto;
    right: 6.25rem;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>ul a {
    padding: 0.375rem 1.25rem 0.375rem 1.25rem;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>ul a:before {
    content: none;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>ul a.has-arrow:after {
    right: 1.25rem;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>ul ul a {
    padding: 0.375rem 1.25rem 0.375rem 1.25rem;
    margin-left: -0.1rem;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .deznav .metismenu>li:hover>ul ul a:before {
    content: none;
  }

  [data-sidebar-style="mini"][data-header-position="fixed"][data-container="boxed"][data-layout="vertical"] .header {
    width: 74.9375rem;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .deznav .metismenu>li {
    padding: 0;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .deznav .metismenu>li>a {
    padding: 1.125rem;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .deznav .metismenu>li>a i {
    padding: 0;
    margin: 0;
  }

  [direction="rtl"][data-sidebar-style="mini"][data-layout="horizontal"] .deznav .metismenu>li>a {
    padding: 1.125rem;
  }

  [direction="rtl"][data-sidebar-style="mini"][data-layout="horizontal"] .deznav .metismenu>li>a svg {
    margin-left: 0;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .deznav .metismenu>li>a svg {
    margin-right: 0;
    margin-top: 0;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .deznav .metismenu>li>ul li a {
    padding: 0.5rem 1.25rem 0.5rem 3rem;
  }
}

@media only screen and (max-width: 74.9375rem) {
  [data-sidebar-style="mini"] .deznav li.mm-active ul {
    height: auto !important;
  }

  [data-sidebar-style="mini"] .deznav li a.has-arrow::after {
    transform: rotate(-45deg) translateY(-50%);
  }
}

:root {
  --primary: #1362FC;
  --secondary: #627EEA;
  --primary-hover: #034bd9;
  --primary-light: #aac7fe;
  --primary-dark: #012875;
  --rgba-primary-1: rgba(19, 98, 252, 0.1);
  --rgba-primary-2: rgba(19, 98, 252, 0.2);
  --rgba-primary-3: rgba(19, 98, 252, 0.3);
  --rgba-primary-4: rgba(19, 98, 252, 0.4);
  --rgba-primary-5: rgba(19, 98, 252, 0.5);
  --rgba-primary-6: rgba(19, 98, 252, 0.6);
  --rgba-primary-7: rgba(19, 98, 252, 0.7);
  --rgba-primary-8: rgba(19, 98, 252, 0.8);
  --rgba-primary-9: rgba(19, 98, 252, 0.9);
  --font-family-base: Roboto, sans-serif;
  --font-family-title: Roboto, sans-serif;
  --title: #000;
}

@media (min-width: 64rem) {
  [data-layout="horizontal"] .nav-header {
    width: 21.563rem;
    height: 6.5rem;
    top: 0;
  }

  [data-layout="horizontal"] .nav-header .nav-control {
    display: none;
  }

  [data-layout="horizontal"] .nav-header .brand-logo {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  [data-layout="horizontal"] .header {
    width: 100%;
    height: 6.5rem;
    padding-left: 21.563rem;
    padding-top: 0;
  }

  [data-layout="horizontal"] .deznav {
    width: 100%;
    position: relative;
    height: auto;
    padding-bottom: 0;
    top: 0;
    z-index: 2;
  }

  [data-layout="horizontal"] .deznav .slimScrollDiv {
    overflow: visible !important;
  }

  [data-layout="horizontal"] .deznav .slimScrollDiv .deznav-scroll {
    overflow: visible !important;
  }

  [data-layout="horizontal"] .deznav .deznav-scroll {
    overflow: visible !important;
  }

  [data-layout="horizontal"] .deznav .slimScrollBar {
    display: none !important;
  }

  [data-layout="horizontal"] .deznav .header-profile {
    margin-right: 0.9375rem;
    margin-bottom: 0;
    display: none;
  }

  [data-layout="horizontal"] .deznav .header-profile:hover>a.nav-link {
    border-radius: 3rem;
  }

  [data-layout="horizontal"] .deznav .header-profile img {
    height: 2.8125rem;
    width: 2.8125rem;
  }

  [data-layout="horizontal"] .deznav .header-profile>a.nav-link {
    border-radius: 3rem;
    padding: 0.3125rem 0.3125rem;
  }

  [data-layout="horizontal"] .deznav .header-profile .header-info {
    display: none;
  }

  [data-layout="horizontal"] .deznav .nav-user,
  [data-layout="horizontal"] .deznav .nav-label {
    display: none;
  }

  [data-layout="horizontal"] .deznav .metismenu {
    flex-direction: row;
    padding: 0.625rem 1.25rem;
    margin-bottom: 0;
    display: inline-flex;
    flex-wrap: wrap;
  }

  [data-layout="horizontal"] .deznav .metismenu .collapse.in {
    display: none;
  }

  [data-layout="horizontal"] .deznav .metismenu ul {
    border-left: 0;
  }

  [data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu ul {
    box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
  }

  [data-layout="horizontal"] .deznav .metismenu li {
    flex-direction: column;
    position: relative;
  }

  [data-layout="horizontal"] .deznav .metismenu li:hover>ul {
    display: block;
  }

  [data-layout="horizontal"] .deznav .metismenu li>ul {
    position: absolute;
    height: auto !important;
    top: 100%;
    width: 100%;
    min-width: 13.75rem;
    z-index: 999;
    left: auto;
    right: auto;
    padding: 0.5rem 0;
    display: none;
    box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
    margin: 0;
    background: #fff;
    border-radius: 1.375rem;
  }

  [data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu li>ul {
    box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
    background: #212130;
  }

  [data-layout="horizontal"] .deznav .metismenu li>ul li {
    padding: 0;
  }

  [data-layout="horizontal"] .deznav .metismenu li>ul li a {
    transition: all .4s ease-in-out;
    padding: 0.5rem 1.25rem 0.5rem 1.875rem;
    margin-left: -.1rem;
  }

  [direction="rtl"][data-layout="horizontal"] .deznav .metismenu li>ul li a {
    padding: 0.5rem 1.25rem 0.5rem 1.25rem;
    text-align: right;
  }

  [data-layout="horizontal"] .deznav .metismenu li>ul li a:hover {
    border-radius: .4rem;
  }

  [data-layout="horizontal"] .deznav .metismenu li>ul li a:before {
    left: 1.375rem;
    display: none;
  }

  [direction="rtl"][data-layout="horizontal"] .deznav .metismenu li>ul li a:before {
    left: auto;
    right: 0.375rem;
  }

  [data-layout="horizontal"] .deznav .metismenu li>ul ul {
    left: 100%;
    top: 0;
    box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
  }

  [direction="rtl"][data-layout="horizontal"] .deznav .metismenu li>ul ul {
    left: auto;
    right: 100%;
  }

  [data-layout="horizontal"] .deznav .metismenu>li {
    flex: 0 0 auto;
    position: relative;
  }

  [data-layout="horizontal"] .deznav .metismenu>li>a i {
    margin-right: 0.3125rem;
  }

  [data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu>li {
    border-color: rgba(255, 255, 255, 0.07);
  }

  [data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu>li.mm-active {
    border-color: transparent;
  }

  [data-layout="horizontal"] .deznav .metismenu>li:hover,
  [data-layout="horizontal"] .deznav .metismenu>li.mm-active {
    padding: 0;
  }

  [data-layout="horizontal"] .deznav .metismenu>li:hover>a,
  [data-layout="horizontal"] .deznav .metismenu>li.mm-active>a {
    background: var(--primary);
    color: #fff;
    border-radius: 1rem;
    box-shadow: none;
  }

  [data-layout="horizontal"] .deznav .metismenu>li:hover>a i,
  [data-layout="horizontal"] .deznav .metismenu>li.mm-active>a i {
    color: #fff;
    background: transparent;
    box-shadow: none;
  }

  [direction="rtl"][data-layout="horizontal"] .deznav .metismenu>li:first-child {
    border-right: 0;
  }

  [data-theme-version="dark"][direction="rtl"][data-layout="horizontal"] .deznav .metismenu>li {
    border-color: #2e2e42;
  }

  [data-layout="horizontal"] .deznav .metismenu>li>a {
    padding: 0.9375rem 2.5rem 0.9375rem 0.9375rem;
    margin: 0.125rem 0.125rem;
  }

  [direction="rtl"][data-layout="horizontal"] .deznav .metismenu>li>a {
    padding: 0.9375rem 0.9375rem 0.9375rem 2.5rem;
  }

  [data-layout="horizontal"] .deznav .metismenu>li>a i {
    padding: 0 0.4375rem 0 0;
    height: auto;
    width: auto;
    line-height: 1;
  }

  [direction="rtl"][data-layout="horizontal"] .deznav .metismenu>li>a i {
    padding: 0 0 0 0.4375rem;
  }

  [data-layout="horizontal"] .deznav .metismenu>li>a .nav-badge {
    display: none;
  }

  [data-layout="horizontal"] .deznav .metismenu>li>a:after {
    right: 1.25rem;
    transform: rotate(-135deg) translateY(-50%);
  }

  [data-layout="horizontal"] .deznav .metismenu>li:hover {
    border-color: transparent;
  }

  [data-layout="horizontal"] .deznav .metismenu>li:hover>ul {
    display: flex !important;
    flex-direction: column;
    flex-wrap: wrap;
    height: auto !important;
    box-shadow: 0.3125rem 0.3125rem 1.875rem 0rem rgba(20, 0, 30, 0.1);
    border-radius: 1.375rem;
  }

  [data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu>li:hover>ul {
    box-shadow: 0.3125rem 0.3125rem 1.875rem 0rem rgba(0, 0, 0, 0.1);
    background: #212130;
  }

  [data-layout="horizontal"] .deznav .metismenu>li>ul>li:hover ul.collapse {
    display: block !important;
    position: absolute;
    left: auto !important;
    right: -100% !important;
    top: 0 !important;
  }

  [data-layout="horizontal"] .deznav .metismenu>li:nth-last-child(-n + 5)>ul {
    left: auto;
    right: 0;
  }

  [data-layout="horizontal"] .deznav .metismenu>li:nth-last-child(-n + 5)>ul>li:hover ul.collapse {
    right: auto !important;
    left: -100% !important;
  }

  [data-layout="horizontal"] .deznav .metismenu>li:nth-last-child(-n + 5)>ul.left {
    left: 0;
  }

  [data-layout="horizontal"] .deznav .metismenu>li:nth-last-child(-n + 5)>ul.left>li:hover ul.collapse {
    left: 100% !important;
  }

  [direction="rtl"][data-layout="horizontal"] .deznav .metismenu>li:nth-last-child(-n + 5)>ul.left {
    left: auto;
    right: 0;
  }

  [data-layout="horizontal"] .deznav .metismenu>li:last-child>ul ul {
    left: -100%;
  }

  [data-layout="horizontal"] .deznav .metismenu>li:last-child>ul.left ul {
    left: 100%;
  }

  [direction="rtl"][data-layout="horizontal"] .deznav .metismenu>li:last-child>ul.left ul {
    left: auto;
    right: 100%;
  }

  [direction="rtl"][data-layout="horizontal"] .deznav .metismenu>li:nth-last-child(-n + 3)>ul {
    left: 0;
    right: auto;
  }

  [direction="rtl"][data-layout="horizontal"] .deznav .metismenu>li:nth-last-child(-n + 3)>ul>li:hover ul.collapse {
    right: -100% !important;
    left: auto !important;
  }

  [data-layout="horizontal"] .deznav .copyright,
  [data-layout="horizontal"] .deznav .plus-box {
    display: none;
  }

  [data-layout="horizontal"] .content-body {
    margin-left: 0;
  }

  [data-layout="horizontal"] .content-body .container-fluid,
  [data-layout="horizontal"] .content-body .container-sm,
  [data-layout="horizontal"] .content-body .container-md,
  [data-layout="horizontal"] .content-body .container-lg,
  [data-layout="horizontal"] .content-body .container-xl,
  [data-layout="horizontal"] .content-body .container-xxl {
    padding-top: 2.5rem;
  }

  [data-layout="horizontal"] .content-body .page-titles {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 1.875rem;
  }

  [data-layout="horizontal"] .footer {
    margin-left: 0;
    margin: 0 auto;
    padding-left: 0;
  }

  [data-header-position="fixed"][data-layout="horizontal"] .deznav {
    top: 6.5rem;
  }

  [data-header-position="fixed"][data-layout="horizontal"] .header {
    height: 6.5rem;
  }

  [data-header-position="fixed"][data-sidebar-position="fixed"] .deznav {
    position: fixed;
  }

  [data-header-position="fixed"][data-layout="horizontal"][data-sidebar-position="fixed"] .content-body {
    padding-top: 11.5rem;
  }

  [data-header-position="fixed"][data-layout="horizontal"][data-sidebar-position="fixed"][data-sidebar-style="modern"] .content-body {
    padding-top: 13.125rem;
  }

  [data-layout="horizontal"][data-container="boxed"] .footer {
    margin-left: 0;
    max-width: 74.9375rem;
    margin: 0 auto;
  }

  [data-layout="horizontal"][data-container="wide"] .page-titles {
    margin-left: -1.875rem;
    margin-right: -1.875rem;
  }

  [data-layout="horizontal"][data-sidebar-style="modern"] .deznav .header-profile {
    margin-bottom: 0;
  }

  [data-layout="horizontal"][data-sidebar-style="modern"] .deznav .header-profile img {
    height: 3.75rem;
    width: 3.75rem;
    margin-bottom: 0 !important;
  }

  [data-layout="horizontal"][data-sidebar-style="modern"] .deznav .header-profile>a.nav-link {
    border: 0.0625rem solid #F2F2F2;
    padding: 0.25rem 0.25rem;
    border-radius: 3rem;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .page-titles {
    margin-top: 0;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .deznav .header-profile {
    margin-bottom: 0;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .deznav .header-profile img {
    height: 3.75rem;
    width: 3.75rem;
    margin-bottom: 0 !important;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .deznav .header-profile>a.nav-link {
    border: 0.0625rem solid #F2F2F2;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .deznav .metismenu>li>ul {
    top: 4.5rem;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .deznav .metismenu>li>a {
    padding: 1.125rem 1.25rem 0.625rem 1.25rem;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .deznav .metismenu>li>a::after {
    display: none;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .deznav .metismenu>li>a .nav-text {
    margin-top: 0.3125rem;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .deznav .metismenu>li>a>i {
    width: auto;
    margin: 0;
    height: auto;
    line-height: 1;
    padding: 0;
    background: transparent;
    border-radius: 0;
    margin-bottom: 0;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .deznav .metismenu>li li {
    text-align: left;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .nav-header {
    width: 7.75rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .nav-header .brand-logo {
    justify-content: start;
    padding-left: 0;
    padding-right: 0;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .header {
    width: 100%;
    padding-left: 7.75rem;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .metismenu>li a {
    width: auto;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .metismenu>li:hover a .nav-text {
    display: none;
  }

  [data-header-position="fixed"][data-layout="horizontal"][data-sidebar-position="fixed"][data-sidebar-style="compact"] .content-body {
    padding-top: 13.125rem;
  }

  [data-sidebar-position="fixed"][data-layout="horizontal"] .deznav.fixed {
    position: fixed;
    padding: 0 0.9375rem;
    left: 0;
    top: 0;
    border-radius: 0;
    width: 100%;
  }
}

@media (min-width: 47.9375rem) {
  [data-sidebar-style="compact"] .nav-header {
    width: 11.25rem;
  }

  [data-sidebar-style="compact"] .deznav .header-profile {
    margin-bottom: 0.3125rem;
  }

  [data-sidebar-style="compact"] .deznav .header-profile>a.nav-link {
    display: block;
    text-align: center;
    border: 0;
  }

  [data-sidebar-style="compact"] .deznav .header-profile>a.nav-link img {
    margin-bottom: 0.3125rem;
  }

  [data-sidebar-style="compact"] .deznav .header-profile>a.nav-link .header-info {
    margin-left: 0 !important;
    text-align: center;
    display: none;
  }

  [data-sidebar-style="compact"] .deznav .header-profile>a.nav-link .header-info small,
  [data-sidebar-style="compact"] .deznav .header-profile>a.nav-link .header-info .small {
    text-align: center !important;
  }

  [data-sidebar-style="compact"] .deznav .header-profile .dropdown-menu {
    min-width: 11rem;
  }

  [data-sidebar-style="compact"] .deznav .header-profile a svg {
    display: unset !important;
  }

  [data-sidebar-style="compact"] .deznav .nav-user {
    display: none;
  }

  [data-sidebar-style="compact"] .deznav .metismenu li {
    text-align: center;
  }

  [data-sidebar-style="compact"] .deznav .metismenu li a {
    padding: 0.7rem 0.5rem;
  }

  [data-sidebar-style="compact"] .deznav .metismenu li a svg {
    max-width: 1.3125rem;
    max-height: 1.3125rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  [data-sidebar-style="compact"] .deznav .metismenu li a i {
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
  }

  [data-sidebar-style="compact"] .deznav .metismenu li ul:after {
    content: none;
  }

  [data-sidebar-style="compact"] .deznav .metismenu li>a {
    background: transparent !important;
    box-shadow: none;
    font-size: 0.9375rem;
  }

  [data-sidebar-style="compact"] .deznav .plus-box,
  [data-sidebar-style="compact"] .deznav .copyright {
    display: none;
  }

  [data-sidebar-style="compact"] .deznav .copyright {
    padding: 0 1.25rem;
    margin-top: 1.25rem;
  }

  [data-sidebar-style="compact"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a {
    color: #fff;
    background: var(--primary) !important;
  }

  [data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a {
    color: var(--primary);
    background: transparent;
  }

  [data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
    background: var(--primary);
  }

  [data-sidebar-style="compact"] .nav-text {
    display: inline-block;
    margin-top: 0.3125rem;
  }

  [data-sidebar-style="compact"] .nav-label.first {
    display: none;
  }

  [data-sidebar-style="compact"] .nav-badge {
    display: none;
  }

  [data-sidebar-style="compact"] .footer {
    padding-left: 12.5rem;
  }

  [data-sidebar-style="compact"] .content-body {
    margin-left: 11.4rem;
  }

  [data-sidebar-style="compact"][data-theme-version="dark"][data-layout="horizontal"] .deznav .metismenu li>a i {
    color: #fff;
  }

  [data-sidebar-style="compact"][data-theme-version="dark"][data-layout="vertical"] .deznav .metismenu li.mm-active>a {
    background: var(--primary);
    color: #fff;
  }

  [data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu li:hover>a i {
    background: var(--rgba-primary-1);
    color: var(--primary);
  }
}

[data-layout="horizontal"][data-sidebar-style="compact"] .footer {
  padding-left: 0;
}

[data-layout="horizontal"][data-sidebar-style="compact"] .content-body {
  margin-left: 0;
}

[data-layout="horizontal"][data-sidebar-style="compact"] .deznav {
  margin-bottom: 0;
}

[data-layout="horizontal"][data-sidebar-style="compact"] .nav-header {
  width: 21.75rem;
}

[data-layout="horizontal"][data-sidebar-style="compact"] .nav-header .brand-logo {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

[data-layout="horizontal"][data-sidebar-style="compact"] .deznav .metismenu li:hover>a i {
  /* background:var(--rgba-primary-1)!important; */
  color: #fff;
}

[data-layout="vertical"][data-sidebar-style="compact"] .deznav {
  width: 11.25rem;
}

[data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu ul {
  margin-left: 0;
  border: 0;
  background: rgba(0, 0, 0, 0.02);
  padding: 0.625rem 0;
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu ul {
  background: 255, 255, 255, 0.05;
}

[data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu ul a:before {
  content: none;
}

[data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu ul ul a {
  padding: 0.625rem 0.9375rem;
}

[data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu>li>a {
  padding: 1.2rem 0.5rem;
}

[data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu>li>a.has-arrow::after {
  top: 0.0625rem;
  display: inline-block;
  right: auto;
  margin-left: 0.3125rem;
  position: relative;
  width: 0.4375rem;
  height: 0.4375rem;
  border-width: 0.125rem 0rem 0rem 0.125rem;
}

[direction="rtl"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu>li>a.has-arrow::after {
  left: auto;
  margin-left: 0;
  margin-right: 0.3125rem;
}

@media (min-width: 47.9375rem) {
  [data-sidebar-style="icon-hover"][data-layout="horizontal"] .header .header-content {
    padding-left: 1.875rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu li.mm-active>ul {
    display: none;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .header-profile {
    display: none;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .deznav .metismenu li.mm-active>ul {
    display: block;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .deznav .header-profile {
    display: block;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header {
    width: 7rem;
    border-radius: 0 0 0 0 !important;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .brand-logo {
    padding-left: 1.875rem;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .brand-logo {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .brand-logo .logo-abbr {
    display: block;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .brand-logo .brand-title {
    display: none;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .nav-control {
    display: none;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .header {
    padding-left: 7rem;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .header {
    padding-right: 7rem;
    padding-left: 0.9375rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .header .header-content {
    padding-left: 2rem;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .header .header-content {
    padding-right: 1.375rem;
    padding-left: 0;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav {
    overflow: visible;
    position: absolute;
    left: -13.5rem;
  }
}

@media only screen and (min-width: 47.9375rem) and (min-width: 47.9375rem) and (max-width: 87.5rem) {
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav {
    left: -10rem;
  }
}

@media (min-width: 47.9375rem) {
  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav {
    left: auto;
    right: -14.563rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .nav-label {
    display: none;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .header-profile img {
    order: 1;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .header-profile .header-info {
    margin-left: 0 !important;
    padding-left: 0 !important;
    margin-right: 0.625rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu>li {
    padding: 0.625rem 1.125rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu>li>a {
    display: flex;
    justify-content: space-between;
    padding: 1.25rem 1.25rem;
    border-radius: 1rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu>li>a>svg,
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu>li>a>i {
    order: 1;
    margin-right: 0;
    margin-top: 0;
    padding-right: 0;
    height: auto;
    width: auto;
    line-height: 1;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu>li>a>svg,
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu>li>a>i {
    padding-left: 0;
    padding-right: 0;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a {
    background: var(--primary);
    color: #fff;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu>li:hover>a i,
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
    color: #fff;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
    border-left: 0;
    padding-left: 0;
    padding-right: 0;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
    padding-right: 0;
    padding-left: 0;
  }

  [data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul {
    border-color: #2e2e42;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul:after {
    left: auto;
    right: 1.75rem;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul:after {
    left: 1.75rem;
    right: auto;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul a {
    position: relative;
    padding-left: 3.3rem;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul a {
    padding-right: 3.3rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul a::before {
    left: 1.25rem;
    right: auto;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu ul a::before {
    right: auto;
    left: -0.3125rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu .has-arrow::after {
    right: 5rem;
    opacity: 0;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .metismenu .has-arrow::after {
    right: auto;
    left: 5rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .copyright,
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav .plus-box {
    display: none;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .deznav.mm-show {
    display: none;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .nav-header {
    width: 20.5rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .nav-header .brand-logo {
    padding-left: 1.6rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .nav-header .brand-logo .brand-title {
    display: block;
  }
}

@media only screen and (min-width: 47.9375rem) and (max-width: 87.5rem) {
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .nav-header {
    width: 17rem;
  }
}

@media (min-width: 47.9375rem) {
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle.mm-show {
    display: block;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .header {
    padding-left: 4.38rem;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .header {
    padding-right: 4.38rem;
    padding-left: 0.9375rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .deznav {
    left: 0;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .deznav .metismenu .has-arrow:after {
    opacity: 1;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .deznav {
    left: auto;
    right: 0;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .content-body {
    margin-left: 7rem;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .content-body {
    margin-left: 0;
    margin-right: 7rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .footer {
    padding-left: 7rem;
  }

  [direction="rtl"][data-sidebar-style="icon-hover"][data-layout="vertical"] .footer {
    margin-left: 0;
    margin-right: 7rem;
  }
}

@media (min-width: 47.9375rem) {
  [data-sidebar-style="modern"] .nav-header {
    width: 10.625rem;
  }

  [data-sidebar-style="modern"] .nav-header .brand-logo {
    justify-content: center;
    padding: 0 1rem;
  }

  [data-sidebar-style="modern"] .nav-header .hamburger .line {
    background: #000;
  }

  [data-sidebar-style="modern"] .deznav .header-profile {
    margin-bottom: 0.3125rem;
  }

  [data-sidebar-style="modern"] .deznav .header-profile>a.nav-link {
    display: block;
    text-align: center;
    margin: 0 -0.625rem 0.9375rem;
    padding: 0.9375rem 0.625rem;
    border-radius: 1.375rem;
  }

  [data-sidebar-style="modern"] .deznav .header-profile>a.nav-link img {
    margin-bottom: 0.3125rem;
  }

  [data-sidebar-style="modern"] .deznav .header-profile>a.nav-link .header-info {
    margin-left: 0 !important;
    text-align: center;
    display: none;
  }

  [data-sidebar-style="modern"] .deznav .header-profile>a.nav-link .header-info small,
  [data-sidebar-style="modern"] .deznav .header-profile>a.nav-link .header-info .small {
    text-align: center !important;
  }

  [data-sidebar-style="modern"] .deznav .metismenu>li {
    text-align: center;
  }

  [data-sidebar-style="modern"] .deznav .metismenu>li>a {
    padding: 1.25rem 0.9375rem 1.25rem 0.9375rem;
    margin: 0.125rem 0;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
  }

  [data-sidebar-style="modern"] .deznav .metismenu>li>a::after {
    display: none;
  }

  [data-sidebar-style="modern"] .deznav .metismenu>li>a:hover>a,
  [data-sidebar-style="modern"] .deznav .metismenu>li>a:focus>a,
  [data-sidebar-style="modern"] .deznav .metismenu>li>a:active>a,
  [data-sidebar-style="modern"] .deznav .metismenu>li>a.mm-active>a {
    background-color: var(--primary-dark);
  }

  [data-sidebar-style="modern"] .deznav .metismenu>li:hover,
  [data-sidebar-style="modern"] .deznav .metismenu>li.mm-active {
    padding: 0;
  }

  [data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
  [data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a {
    background: var(--primary);
    color: #fff;
    box-shadow: none;
    border-radius: 1rem;
  }

  [data-sidebar-style="modern"] .deznav .metismenu>li:hover>a i,
  [data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a i {
    color: #fff;
  }

  [data-sidebar-style="modern"] .deznav .metismenu>li li {
    text-align: left;
  }

  [direction="rtl"][data-sidebar-style="modern"] .deznav .metismenu>li li {
    text-align: right;
  }

  [data-sidebar-style="modern"] .deznav .metismenu li a {
    padding: 0.625rem 0.9375rem 0.625rem 0.9375rem;
    font-size: 0.9375rem;
  }

  [data-sidebar-style="modern"] .deznav .metismenu li ul:after {
    content: none;
  }

  [data-sidebar-style="modern"] .deznav .metismenu li>ul {
    height: auto !important;
  }

  [data-sidebar-style="modern"] .deznav .metismenu .nav-label {
    display: none;
  }

  [data-sidebar-style="modern"] .deznav .nav-label {
    display: none;
  }

  [data-sidebar-style="modern"] .deznav .nav-text {
    display: block;
    margin-top: 0.3125rem;
  }

  [data-sidebar-style="modern"] .deznav .plus-box,
  [data-sidebar-style="modern"] .deznav .copyright {
    display: none;
  }

  [data-sidebar-style="modern"] .footer {
    padding-left: 11.9rem;
  }

  [data-sidebar-style="modern"] .content-body {
    margin-left: 10.9rem;
  }

  [data-sidebar-style="modern"][data-layout="horizontal"] .deznav .metismenu li>a i {
    padding: 0;
    margin: 0;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav {
    width: 10.625rem;
    left: 0;
  }

  [direction="rtl"][data-sidebar-style="modern"][data-layout="vertical"] .deznav {
    left: auto;
    right: 0;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .slimScrollDiv,
  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .deznav-scroll {
    overflow: visible !important;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu {
    padding: 0.625rem 0.9375rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu>li>a {
    padding: 1.375rem 0.6rem 0.9375rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu>li>a i {
    padding: 0;
    height: auto;
    width: auto;
    margin: 0;
    line-height: 1;
    margin-bottom: 0.3125rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu>li>ul {
    display: none;
    padding: 1.875rem 0.9375rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li {
    position: relative;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li a {
    padding: 1rem 1rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li a:before {
    content: none;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li ul {
    position: absolute;
    left: 105%;
    top: 0;
    bottom: auto;
    background-color: #fff;
    border: 0.0625rem solid #F2F2F2;
    width: 12.5rem;
  }

  [data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li ul {
    background: #212130;
    box-shadow: 0rem 0rem 0.8125rem 0rem rgba(0, 0, 0, 0.1);
  }

  [direction="rtl"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li ul {
    left: auto;
    right: 105%;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li:hover>ul {
    display: block;
    left: 100%;
    padding: 1rem 0;
    margin-left: 0;
    border: 0;
    box-shadow: 0.3125rem 0.3125rem 1.875rem 0rem rgba(20, 0, 30, 0.1);
    border-radius: 1.375rem;
  }

  [data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li:hover>ul {
    box-shadow: 0.3125rem 0.3125rem 1.875rem 0rem rgba(20, 0, 30, 0.1);
  }

  [direction="rtl"][data-sidebar-style="modern"][data-layout="vertical"] .deznav .metismenu li:hover>ul {
    left: auto;
    right: 100%;
    box-shadow: -0.3125rem 0.3125rem 1.875rem 0rem rgba(20, 0, 30, 0.1);
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .nav-label {
    display: none;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .deznav .nav-text {
    display: block;
    margin-top: 0;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .nav-control {
    right: -4.25rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .header .header-content {
    padding-left: 6rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .deznav,
  [data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .nav-header {
    left: -10.625rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .header {
    padding-left: 0;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .content-body {
    margin-left: 0;
  }

  [data-sidebar-style="modern"][data-layout="horizontal"] .nav-header {
    width: 21.75rem;
  }

  [data-sidebar-style="modern"][data-layout="horizontal"] .header {
    padding-left: 21.75rem;
  }

  [data-sidebar-style="modern"][data-layout="horizontal"] .footer,
  [data-sidebar-style="modern"][data-layout="horizontal"] .content-body {
    margin-left: 0;
  }

  [data-sidebar-style="modern"][data-layout="horizontal"] .deznav .metismenu>li>a {
    padding: 0.9375rem 1.5625rem 0.75rem 1.5625rem;
    margin: 0 0.125rem;
  }

  [data-sidebar-style="modern"][data-layout="horizontal"] .deznav .metismenu>li>ul {
    top: 4.8rem;
  }

  [data-sidebar-style="modern"][data-layout="horizontal"][data-container="boxed"] .deznav .metismenu>li>a {
    padding: 0.8125rem 1.25rem;
  }
}

[data-sidebar-style="overlay"][data-layout="vertical"] .deznav {
  border-radius: 0 0 0 0 !important;
}

@media only screen and (max-width: 47.9375rem) {
  [data-sidebar-style="overlay"][data-layout="vertical"] .deznav {
    border-radius: 0 0 0 0 !important;
  }
}

[data-sidebar-style="overlay"][data-layout="vertical"] .nav-header {
  border-radius: 0;
}

[data-sidebar-style="overlay"][data-layout="vertical"] .menu-toggle .nav-header {
  border-radius: 0 0 0 0;
}

[data-sidebar-style="overlay"] .deznav {
  left: -100%;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.2);
}

[data-sidebar-style="overlay"] .deznav .metismenu>li {
  padding: 0.625rem 0.625rem;
}

[data-sidebar-style="overlay"] .deznav .metismenu>li>a {
  font-size: 1rem;
  padding: 1.25rem 1.25rem;
  color: #7f7184;
  border-radius: 1rem;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

[data-sidebar-style="overlay"] .deznav .metismenu>li>a i {
  height: auto;
  width: auto;
  line-height: 1;
}

[data-sidebar-style="overlay"] .deznav .metismenu>li:hover>a {
  color: var(--primary);
}

[data-sidebar-style="overlay"] .deznav .metismenu>li:hover>a i {
  color: var(--primary);
}

[data-sidebar-style="overlay"] .deznav .metismenu>li.mm-active>a {
  background: var(--primary);
  color: #fff;
  box-shadow: none;
}

[data-sidebar-style="overlay"] .deznav .metismenu>li.mm-active>a i {
  color: #fff;
}

@media only screen and (max-width: 35.9375rem) {
  [data-sidebar-style="overlay"] .deznav .metismenu>li {
    padding: 0rem 0.9375rem;
  }
}

[data-sidebar-style="overlay"] .deznav .metismenu ul a {
  padding-top: .5rem;
  padding-bottom: .5rem;
  position: relative;
  font-size: 0.9375rem;
  padding-left: 4rem;
}

[data-sidebar-style="overlay"] .deznav .metismenu ul a:before {
  left: 1.5625rem;
}

[direction="rtl"][data-sidebar-style="overlay"] .deznav {
  left: auto;
  right: -100%;
}

@media only screen and (max-width: 47.9375rem) {
  [data-sidebar-style="overlay"] .deznav .metismenu>li>a {
    font-size: 0.875rem;
    padding: 0.75rem 0.875rem;
  }

  [data-sidebar-style="overlay"] .deznav .metismenu>li>a i {
    font-size: 1.125rem;
  }

  [data-sidebar-style="overlay"] .deznav .metismenu ul li a {
    padding-left: 3.4rem;
  }
}

[data-sidebar-style="overlay"] .content-body {
  margin-left: 0;
}

[data-sidebar-style="overlay"] .nav-header {
  position: absolute;
}

[data-sidebar-style="overlay"] .nav-header .hamburger.is-active {
  left: 0;
}

[data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: var(--primary);
}

[data-sidebar-style="overlay"] .menu-toggle .nav-header {
  position: absolute;
  left: auto;
}

[data-sidebar-style="overlay"] .menu-toggle .deznav {
  left: 0;
}

[direction="rtl"][data-sidebar-style="overlay"] .menu-toggle .deznav {
  left: auto;
  right: 0;
}

[data-sidebar-style="overlay"] .footer {
  padding-left: 0;
}

[data-sidebar-style="overlay"][data-header-position="fixed"] .nav-header {
  position: fixed;
}

[data-sidebar-position="fixed"][data-header-position="fixed"] .nav-header {
  position: fixed;
}

[data-sidebar-position="fixed"][data-layout="vertical"] .nav-header {
  position: fixed;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

[data-sidebar-position="fixed"][data-layout="vertical"] .deznav {
  position: fixed;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

[data-sidebar-position="fixed"][data-layout="vertical"] .deznav .deznav-scroll {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

[data-sidebar-position="fixed"][data-layout="vertical"] .menu-toggle .deznav {
  position: fixed;
}

[data-layout="vertical"] .nav-header {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

[data-layout="vertical"] .deznav {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

[data-sidebar-style="icon-hover"][data-layout="vertical"][data-container="wide-boxed"] .deznav,
[data-sidebar-style="icon-hover"][data-layout="vertical"][data-container="boxed"] .deznav,
[data-header-position="fixed"][data-sidebar-position="fixed"][data-sidebar-style="icon-hover"][data-layout="vertical"][data-container="wide-boxed"] .deznav,
[data-header-position="fixed"][data-sidebar-position="fixed"][data-sidebar-style="icon-hover"][data-layout="vertical"][data-container="boxed"] .deznav,
[data-sidebar-style="overlay"][data-layout="vertical"][data-container="wide-boxed"] .deznav,
[data-sidebar-style="overlay"][data-layout="vertical"][data-container="boxed"] .deznav,
[data-header-position="fixed"][data-sidebar-position="fixed"][data-sidebar-style="overlay"][data-layout="vertical"][data-container="wide-boxed"] .deznav,
[data-header-position="fixed"][data-sidebar-position="fixed"][data-sidebar-style="overlay"][data-layout="vertical"][data-container="boxed"] .deznav {
  position: absolute;
}

.sidebar-right {
  right: -50rem;
  position: fixed;
  top: 0;
  width: 50rem;
  background-color: #fff;
  margin-top: 3.5rem;
  transition: all .5s ease-in-out;
  border-radius: 1.375rem;
  z-index: 9999;
}

.sidebar-right .bg-label-pattern {
  background: transparent;
  background-size: 130%;
}

.sidebar-right .bootstrap-select {
  height: 3rem;
  border-radius: 0.375rem;
}

.sidebar-right .bootstrap-select .btn {
  padding: 0.75rem 0.9375rem;
  font-size: 0.9375rem;
  border-color: #d1d1d1;
  border-radius: 0.375rem;
}

.sidebar-right .sidebar-right-trigger {
  position: absolute;
  z-index: 9;
  top: 14.75rem;
  right: 100%;
  background-color: #1362FC;
  color: #fff;
  display: inline-block;
  height: 3rem;
  width: 3rem;
  text-align: center;
  font-size: 1.75rem;
  line-height: 3rem;
  border-radius: 0.3125rem 0 0 0.3125rem;
  box-shadow: -0.3125rem 0.1875rem 0.3125rem 0rem rgba(119, 119, 119, 0.15);
}

[data-theme-version="dark"] .sidebar-right .sidebar-right-trigger {
  color: #fff;
}

@media only screen and (max-width: 63.9375rem) {
  .sidebar-right .sidebar-right-trigger {
    display: none;
  }
}

[direction="rtl"] .sidebar-right .slimScrollDiv {
  overflow: visible !important;
}

.sidebar-right .sidebar-close-trigger {
  position: absolute;
  z-index: 2;
  font-size: 1.75rem;
  top: 0;
  right: -3rem;
  height: 3rem;
  width: 3rem;
  line-height: 3rem;
  text-align: center;
  background: black;
  color: #fff;
}

.sidebar-right.show {
  right: 5.25rem;
  box-shadow: 0rem 0rem 3.125rem rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

.sidebar-right.show .bg-overlay {
  position: fixed;
  width: 100%;
  cursor: pointer;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
}

.sidebar-right .card-tabs .nav-tabs {
  justify-content: space-between;
  position: sticky;
  top: 0;
  width: 100%;
  border-radius: 0;
  padding: 0;
  border-bottom: 0.25rem solid var(--rgba-primary-1);
  background-color: #fff;
  z-index: 2;
  overflow: unset;
}

.sidebar-right .card-tabs .nav-tabs .nav-item {
  margin-bottom: 0;
  flex: 1;
}

.sidebar-right .card-tabs .nav-tabs .nav-item .nav-link {
  border: 0;
  font-size: 1.125rem;
  position: relative;
  text-align: center;
  border-radius: 0;
  padding: 0.5rem 1rem !important;
  margin: 0;
  background-color: #fff;
}

.sidebar-right .card-tabs .nav-tabs .nav-item .nav-link.active {
  background: var(--rgba-primary-1);
  color: #000;
}

[data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs .nav-item .nav-link.active {
  border-right: none;
  border-left: none;
  border-top: none;
}

.sidebar-right .sidebar-right-inner>h4,
.sidebar-right .sidebar-right-inner>.h4 {
  padding: 0.625rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  background: #fff;
  margin: 0rem 0rem 0rem 0rem;
}

.sidebar-right .tab-content {
  padding: 1.25rem 1.25rem 0;
  min-height: 23.125rem;
  background: #fff;
}

.sidebar-right .tab-content .tab-pane .admin-settings .row>div {
  margin-bottom: 1.25rem;
}

.sidebar-right .tab-content .tab-pane .admin-settings p {
  color: #353535;
  font-weight: 500;
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"] {
  display: none;
}

.sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"]+label {
  display: inline-block;
  width: 2.1875rem;
  height: 2.1875rem;
  cursor: pointer;
  transition: all .1s ease;
  border-radius: 0.25rem;
  margin-right: 0.3125rem;
  margin-bottom: 0.1875rem;
}

.sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"]:checked+label {
  position: relative;
}

.sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"]:checked+label::after {
  height: 2.0625rem;
  width: 2.0625rem;
  left: -0.25rem;
  top: -0.25rem;
  content: "";
  position: absolute;
  background-color: inherit;
  border-radius: 0.375rem;
  opacity: 0.4;
}

.sidebar-right #nav_header_color_1+label,
.sidebar-right #header_color_1+label,
.sidebar-right #sidebar_color_1+label,
.sidebar-right #primary_color_1+label {
  background-color: #fff;
}

.sidebar-right #nav_header_color_2+label,
.sidebar-right #header_color_2+label,
.sidebar-right #sidebar_color_2+label,
.sidebar-right #primary_color_2+label {
  background-color: #6610f2;
}

.sidebar-right #nav_header_color_3+label,
.sidebar-right #header_color_3+label,
.sidebar-right #sidebar_color_3+label,
.sidebar-right #primary_color_3+label {
  background-color: #1362FC;
}

.sidebar-right #nav_header_color_4+label,
.sidebar-right #header_color_4+label,
.sidebar-right #sidebar_color_4+label,
.sidebar-right #primary_color_4+label {
  background-color: #4d06a5;
}

.sidebar-right #nav_header_color_5+label,
.sidebar-right #header_color_5+label,
.sidebar-right #sidebar_color_5+label,
.sidebar-right #primary_color_5+label {
  background-color: #dc3545;
}

.sidebar-right #nav_header_color_6+label,
.sidebar-right #header_color_6+label,
.sidebar-right #sidebar_color_6+label,
.sidebar-right #primary_color_6+label {
  background-color: #fd7e14;
}

.sidebar-right #nav_header_color_7+label,
.sidebar-right #header_color_7+label,
.sidebar-right #sidebar_color_7+label,
.sidebar-right #primary_color_7+label {
  background-color: #ffc107;
}

.sidebar-right #nav_header_color_8+label,
.sidebar-right #header_color_8+label,
.sidebar-right #sidebar_color_8+label,
.sidebar-right #primary_color_8+label {
  background-color: #fff;
}

.sidebar-right #nav_header_color_9+label,
.sidebar-right #header_color_9+label,
.sidebar-right #sidebar_color_9+label,
.sidebar-right #primary_color_9+label {
  background-color: #20c997;
}

.sidebar-right #nav_header_color_10+label,
.sidebar-right #header_color_10+label,
.sidebar-right #sidebar_color_10+label,
.sidebar-right #primary_color_10+label {
  background-color: #17a2b8;
}

.sidebar-right #nav_header_color_11+label,
.sidebar-right #header_color_11+label,
.sidebar-right #sidebar_color_11+label,
.sidebar-right #primary_color_11+label {
  background-color: #94618E;
}

.sidebar-right #nav_header_color_12+label,
.sidebar-right #header_color_12+label,
.sidebar-right #sidebar_color_12+label,
.sidebar-right #primary_color_12+label {
  background-color: #343a40;
}

.sidebar-right #nav_header_color_13+label,
.sidebar-right #header_color_13+label,
.sidebar-right #sidebar_color_13+label,
.sidebar-right #primary_color_13+label {
  background-color: #2a2a2a;
}

.sidebar-right #nav_header_color_14+label,
.sidebar-right #header_color_14+label,
.sidebar-right #sidebar_color_14+label,
.sidebar-right #primary_color_14+label {
  background-color: #4885ed;
}

.sidebar-right #nav_header_color_15+label,
.sidebar-right #header_color_15+label,
.sidebar-right #sidebar_color_15+label,
.sidebar-right #primary_color_15+label {
  background-color: #4cb32b;
}

.sidebar-right #nav_header_color_1+label,
.sidebar-right #header_color_1+label,
.sidebar-right #sidebar_color_1+label,
.sidebar-right #primary_color_1+label {
  border: 0.0625rem solid #c4c4c4;
}

.sidebar-right.style-1 {
  height: 100vh;
  width: 15.625rem;
  margin-top: 0;
  right: -15.625rem;
}

.sidebar-right.style-1 .sidebar-right-inner {
  background: #fff;
}

.sidebar-right.style-1 .sidebar-right-trigger {
  top: 12.4rem;
}

.sidebar-right.style-1.show {
  right: 0;
}

.sidebar-right.style-1.show .sidebar-right-trigger {
  display: block;
}

@media only screen and (max-width: 61.9375rem) {
  .sidebar-right {
    width: 71%;
  }
}

@keyframes bounce {
  0% {
    transform: translateX(-8%);
    -webkit-transform: translateX(-8%);
  }

  50% {
    transform: translateX(8%);
    -webkit-transform: translateX(8%);
  }

  100% {
    transform: translateX(-8%);
    -webkit-transform: translateX(-8%);
  }
}

@-webkit-keyframes bounce {
  0% {
    transform: translateX(-8%);
    -webkit-transform: translateX(-8%);
  }

  50% {
    transform: translateX(8%);
    -webkit-transform: translateX(8%);
  }

  100% {
    transform: translateY(-8%);
    -webkit-transform: translateY(-8%);
  }
}

.nice-select.wide {
  line-height: 2.8125rem;
}

@media only screen and (max-width: 87.5rem) {
  .nice-select.wide {
    line-height: 1.75rem;
  }
}

.nav-user {
  background: var(--primary);
  margin-bottom: 0.625rem;
  padding: 1.25rem 1.5625rem 0.9375rem;
}

@media only screen and (min-width: 48rem) and (max-width: 74.9375rem) {
  .nav-user {
    padding: 1.25rem 0.9375rem 0.9375rem;
  }
}

.nav-user img {
  width: 2.1875rem;
  height: 2.1875rem;
}

@media only screen and (min-width: 48rem) and (max-width: 74.9375rem) {
  .nav-user img {
    width: 2.1875rem;
    height: 2.1875rem;
    margin-bottom: 0.625rem;
  }
}

.nav-user h5,
.nav-user .h5 {
  margin-left: 0.625rem;
  margin-bottom: 0.1875rem;
  color: #fff;
}

@media only screen and (min-width: 48rem) and (max-width: 74.9375rem) {

  .nav-user h5,
  .nav-user .h5 {
    display: none;
  }
}

[data-sibebarbg="color_2"] .nav-user h5,
[data-sibebarbg="color_2"] .nav-user .h5 {
  color: #fff;
}

.nav-user p {
  margin-left: 0.625rem;
  margin-bottom: 0.5rem;
  color: #afcff7;
}

@media only screen and (min-width: 48rem) and (max-width: 74.9375rem) {
  .nav-user p {
    display: none;
  }
}

@media only screen and (min-width: 48rem) and (max-width: 74.9375rem) {
  .nav-user i {
    margin-top: 0.9375rem;
    display: block;
  }
}

.menu-toggle .nav-user {
  padding: 1.25rem 0.9375rem 0.9375rem;
}

.menu-toggle .nav-user img {
  width: 2.1875rem;
  height: 2.1875rem;
  margin-bottom: 0.625rem;
}

.menu-toggle .nav-user h5,
.menu-toggle .nav-user .h5,
.menu-toggle .nav-user p {
  display: none;
}

.menu-toggle .nav-user i {
  margin-top: 0.9375rem;
  display: block;
}

.menu-toggle .nav-user .dropdown-menu {
  left: 2.8125rem !important;
  top: 1.375rem !important;
}

.chatbox {
  width: 21.25rem;
  height: 100vh;
  position: fixed;
  right: -31.25rem;
  top: 0;
  z-index: 999;
  background: #fff;
  box-shadow: 0rem 0rem 1.875rem 0rem rgba(82, 63, 105, 0.15);
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

[data-theme-version="dark"] .chatbox {
  background: #212130;
}

.chatbox .chatbox-close {
  position: absolute;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  width: 0;
  height: 100%;
  right: 21.25rem;
  background: #000;
  z-index: 1;
  opacity: 0.1;
  cursor: pointer;
}

.chatbox .card-fotter {
  padding: 0.75rem 1rem;
}

.chatbox .card-body {
  padding: 1rem;
}

.chatbox.active {
  right: 0;
}

.chatbox.active .chatbox-close {
  width: 100vw;
}

.chatbox .type_msg {
  padding-top: 0.625rem;
}

.chatbox .nav {
  padding: 1rem 1rem 0 1rem;
  background: var(--rgba-primary-1);
  border: 0;
  justify-content: space-between;
}

.chatbox .nav .nav-link {
  color: var(--primary);
  opacity: 0.7;
  text-transform: uppercase;
}

.chatbox .nav .nav-link:hover,
.chatbox .nav .nav-link.active {
  background: transparent;
  color: var(--primary);
  opacity: 1;
  border-color: var(--primary);
}

.chatbox .img_cont {
  width: 2.5rem;
  border-radius: 2.5rem;
  margin-right: 0.625rem;
  position: relative;
  height: 2.5rem;
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 0.875rem;
  min-width: 2.5rem;
  min-height: 2.5rem;
}

.chatbox .img_cont .icon {
  color: #fff;
}

.chatbox .img_cont.primary {
  background: var(--rgba-primary-1);
  color: var(--primary);
}

[data-theme-version="dark"] .chatbox .img_cont.primary {
  background: var(--rgba-primary-1);
}

.chatbox .img_cont.warning {
  background: #ffecce;
  color: #FFC368;
}

[data-theme-version="dark"] .chatbox .img_cont.warning {
  background: rgba(255, 195, 104, 0.1);
}

.chatbox .img_cont.success {
  background: #c8f2d1;
  color: #37D159;
}

[data-theme-version="dark"] .chatbox .img_cont.success {
  background: rgba(55, 209, 89, 0.1);
}

.chatbox .img_cont.info {
  background: #e4d7ef;
  color: #b48dd3;
}

[data-theme-version="dark"] .chatbox .img_cont.info {
  background: rgba(180, 141, 211, 0.1);
}

.chatbox .img_cont img {
  width: 100%;
}

.chatbox .img_cont .online_icon {
  background: #37D159;
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 0.9375rem;
  right: -0.0625rem;
  bottom: 0rem;
  border: 0.125rem solid #fff;
}

.chatbox .img_cont .online_icon.offline {
  background: #FF6746;
}

.chatbox .card {
  box-shadow: none;
}

.chatbox .search {
  height: 2.5rem;
}

.chatbox .user_info span {
  font-size: 0.9375rem;
  color: #000;
  display: block;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 0.3125rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  max-width: 10.625rem;
}

[data-theme-version="dark"] .chatbox .user_info span {
  color: #fff;
}

.chatbox .user_info p {
  font-size: 0.8125rem;
  margin-bottom: 0;
  line-height: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  max-width: 10.625rem;
}

.chatbox .contacts li {
  padding: 0.4375rem 1rem;
  border-bottom: 0.0625rem solid #eee;
  cursor: pointer;
}

[data-theme-version="dark"] .chatbox .contacts li {
  border-color: #2e2e42;
}

.chatbox .contacts li>div {
  display: flex;
  align-items: center;
}

.chatbox .contacts li:hover {
  background: #f4f7ff;
}

[data-theme-version="dark"] .chatbox .contacts li:hover {
  background-color: #171622;
}

.chatbox .contacts .name-first-letter {
  background: #FCFCFC;
  padding: 0.25rem 1rem;
  font-weight: 700;
  color: #000;
  position: sticky;
  top: 0;
  z-index: 1;
}

[data-theme-version="dark"] .chatbox .contacts .name-first-letter {
  color: #fff;
  background: #171622;
}

.chatbox .contacts_body {
  height: calc(100vh - 7.5rem);
}

.chatbox .card-header {
  background: #f4f7ff;
  padding: 0.9375rem 1.25rem;
  justify-content: center;
}

.chatbox .card-header h6,
.chatbox .card-header .h6 {
  font-size: 0.9375rem;
}

.chatbox .card-header p {
  line-height: 1.2;
  font-size: 0.75rem;
  color: #969ba0;
}

.chatbox .chat-list-header {
  justify-content: space-between;
  background: #fff;
}

[data-theme-version="dark"] .chatbox .chat-list-header {
  background: #212130;
}

.chatbox .chat-list-header a {
  text-align: center;
  width: 1.875rem;
  height: 1.875rem;
  background: #FCFCFC;
  border-radius: 0.375rem;
  line-height: 1.875rem;
  display: block;
}

[data-theme-version="dark"] .chatbox .chat-list-header a {
  background: var(--rgba-primary-1);
}

[data-theme-version="dark"] .chatbox .chat-list-header a svg g [fill] {
  fill: #fff;
}

.chatbox .img_cont_msg {
  width: 1.875rem;
  height: 1.875rem;
  display: block;
  max-width: 1.875rem;
  min-width: 1.875rem;
}

.chatbox .img_cont_msg img {
  width: 100%;
}

.chatbox .msg_cotainer {
  background: var(--primary);
  margin-left: 0.625rem;
  border-radius: 0 1.375rem 1.375rem 1.375rem;
  padding: 0.625rem 0.9375rem;
  color: #fff;
  position: relative;
}

.chatbox .msg_cotainer .msg_time {
  display: block;
  font-size: 0.6875rem;
  color: #fff;
  margin-top: 0.3125rem;
  opacity: 0.5;
}

.chatbox .msg_cotainer:after {
  content: "";
  position: absolute;
  left: -0.625rem;
  border-right: 0.625rem solid var(--primary);
  border-bottom: 0.625rem solid transparent;
  border-top: 0rem solid;
  top: 0;
}

.chatbox .msg_cotainer_send {
  background: #FCFCFC;
  padding: 0.625rem 0.9375rem;
  border-radius: 0.375rem 0rem 0.375rem 0.375rem;
  margin-right: 0.625rem;
  color: #222;
  position: relative;
  text-align: right;
}

[data-theme-version="dark"] .chatbox .msg_cotainer_send {
  background: #171622;
  color: #fff;
}

.chatbox .msg_cotainer_send .msg_time_send {
  display: block;
  font-size: 0.6875rem;
  text-align: right;
  margin-top: 0.3125rem;
  opacity: 0.6;
}

.chatbox .msg_cotainer_send:after {
  content: "";
  position: absolute;
  right: -0.625rem;
  border-left: 0.625rem solid #FCFCFC;
  border-bottom: 0.625rem solid transparent;
  border-top: 0rem solid;
  top: 0;
}

[data-theme-version="dark"] .chatbox .msg_cotainer_send:after {
  border-left: 0.625rem solid #171622;
}

.chatbox .type_msg .form-control {
  padding: 0.625rem 0;
  height: 3.125rem;
  border: 0;
  resize: none;
}

.chatbox .type_msg .form-control:focus {
  z-index: 0;
}

.chatbox .type_msg .btn {
  font-size: 1.125rem;
  border-radius: 2.375rem !important;
  width: 2.375rem;
  height: 2.375rem;
  padding: 0;
  margin-top: 0.375rem;
}

.chatbox .video_cam {
  margin-left: 0.9375rem;
}

.chatbox .video_cam span {
  width: 2.1875rem;
  height: 2.1875rem;
  background: #10ca93;
  text-align: center;
  line-height: 2.1875rem;
  border-radius: 2.1875rem;
  color: #fff;
  margin-right: 0.3125rem;
  align-self: center;
  font-size: 1rem;
  padding: 0 0.1875rem;
  display: inline-block;
}

.chatbox .note_card .contacts li {
  padding: 0.75rem 1rem;
}

@media only screen and (max-width: 36rem) {
  .chatbox {
    width: 17.5rem;
  }

  .chatbox .chatbox-close {
    right: 17.5rem;
  }
}

.dz-demo-panel {
  right: -23.75rem;
  position: fixed;
  top: 0;
  width: 23.75rem;
  background-color: #fff;
  height: 100vh;
  transition: all .5s ease-in-out;
  z-index: 9999;
}

.dz-demo-panel .dz-demo-trigger {
  position: absolute;
  z-index: 9;
  top: 21.75rem;
  right: 100%;
  background-color: #627EEA;
  color: #fff;
  display: inline-block;
  height: 3rem;
  width: 3rem;
  text-align: center;
  font-size: 1.75rem;
  line-height: 3rem;
  border-radius: 0.3125rem 0 0 0.3125rem;
  box-shadow: -0.3125rem 0.1875rem 0.3125rem 0rem rgba(119, 119, 119, 0.15);
}

@media only screen and (max-width: 63.9375rem) {
  .dz-demo-panel .dz-demo-trigger {
    display: none;
  }
}

.dz-demo-panel .dz-demo-close {
  height: 1.875rem;
  color: #fff;
  width: 1.875rem;
  border-radius: 1.375rem;
  background: rgba(0, 0, 0, 0.5);
  line-height: 1.875rem;
  text-align: center;
}

.dz-demo-panel.show {
  right: 0;
  box-shadow: 0rem 0rem 3.125rem rgba(0, 0, 0, 0.2);
  z-index: 99999;
  overflow: hidden;
}

.dz-demo-panel.show .sidebar-right-trigger {
  display: none;
}

.dz-demo-panel.show .bg-close {
  position: fixed;
  z-index: -2;
  cursor: pointer;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.15);
}

.dz-demo-panel .dz-demo-inner {
  padding: 1.875rem 1.875rem;
  background: #fff;
}

.dz-demo-panel .dz-demo-content {
  height: calc(100vh - 140px);
}

.dz-demo-panel .dz-demo-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;
}

.dz-demo-panel .dz-demo-header h4,
.dz-demo-panel .dz-demo-header .h4 {
  margin-bottom: 0;
  color: #000;
}

.dz-demo-panel .dz-demo-bx {
  height: 12.5rem;
  overflow: hidden;
  border: 0.3125rem solid #efefef;
  box-shadow: 0rem 0rem 0.3125rem rgba(0, 0, 0, 0.1);
  margin-bottom: 0.625rem;
}

.dz-demo-panel .dz-demo-bx.demo-active {
  border-color: #627EEA;
}

.dz-demo-panel .dz-demo-bx.demo-active .overlay-layer {
  opacity: 1;
}

.dz-demo-panel .overlay-bx {
  position: relative;
}

.dz-demo-panel .overlay-bx .overlay-layer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  opacity: 0;
}

.dz-demo-panel .overlay-bx:hover .overlay-layer {
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  opacity: 1;
}

:root {
  --nav-headbg: #FCFCFC;
  --sidebar-bg: #FCFCFC;
  --headerbg: #FCFCFC;
}

[data-nav-headerbg="color_2"][data-theme-version="dark"],
[data-nav-headerbg="color_2"] {
  --nav-headbg: #6610f2;
}

[data-nav-headerbg="color_2"][data-theme-version="dark"] .brand-logo .logo-abbr .rect-primary-rect,
[data-nav-headerbg="color_2"] .brand-logo .logo-abbr .rect-primary-rect {
  fill: #fff;
}

[data-nav-headerbg="color_2"][data-theme-version="dark"] .brand-logo .logo-abbr circle,
[data-nav-headerbg="color_2"] .brand-logo .logo-abbr circle {
  fill: #6610f2;
}

[data-nav-headerbg="color_2"][data-theme-version="dark"] .brand-logo .logo-abbr .rect-primary-rect-1,
[data-nav-headerbg="color_2"] .brand-logo .logo-abbr .rect-primary-rect-1 {
  fill: #fff;
  stroke: #6610f2;
}

[data-nav-headerbg="color_2"][data-theme-version="dark"] .brand-logo .logo-abbr path,
[data-nav-headerbg="color_2"] .brand-logo .logo-abbr path {
  stroke: #6610f2;
}

[data-nav-headerbg="color_2"][data-theme-version="dark"] .brand-logo .brand-title path,
[data-nav-headerbg="color_2"] .brand-logo .brand-title path {
  fill: #fff;
}

[data-nav-headerbg="color_2"][data-theme-version="dark"] .hamburger .line,
[data-nav-headerbg="color_2"] .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_2"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_2"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #6610f2;
}

[data-nav-headerbg="color_2"][data-theme-version="dark"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line,
[data-nav-headerbg="color_2"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #fff;
}

[data-nav-headerbg="color_2"][data-theme-version="dark"][data-nav-headerbg="color_8"] .brand-title path,
[data-nav-headerbg="color_2"][data-nav-headerbg="color_8"] .brand-title path {
  fill: #000;
}

[data-nav-headerbg="color_2"][data-theme-version="dark"][data-nav-headerbg="color_8"] .hamburger .line,
[data-nav-headerbg="color_2"][data-nav-headerbg="color_8"] .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_3"][data-theme-version="dark"],
[data-nav-headerbg="color_3"] {
  --nav-headbg: #1362FC;
}

[data-nav-headerbg="color_3"][data-theme-version="dark"] .brand-logo .logo-abbr .rect-primary-rect,
[data-nav-headerbg="color_3"] .brand-logo .logo-abbr .rect-primary-rect {
  fill: #fff;
}

[data-nav-headerbg="color_3"][data-theme-version="dark"] .brand-logo .logo-abbr circle,
[data-nav-headerbg="color_3"] .brand-logo .logo-abbr circle {
  fill: #1362FC;
}

[data-nav-headerbg="color_3"][data-theme-version="dark"] .brand-logo .logo-abbr .rect-primary-rect-1,
[data-nav-headerbg="color_3"] .brand-logo .logo-abbr .rect-primary-rect-1 {
  fill: #fff;
  stroke: #1362FC;
}

[data-nav-headerbg="color_3"][data-theme-version="dark"] .brand-logo .logo-abbr path,
[data-nav-headerbg="color_3"] .brand-logo .logo-abbr path {
  stroke: #1362FC;
}

[data-nav-headerbg="color_3"][data-theme-version="dark"] .brand-logo .brand-title path,
[data-nav-headerbg="color_3"] .brand-logo .brand-title path {
  fill: #fff;
}

[data-nav-headerbg="color_3"][data-theme-version="dark"] .hamburger .line,
[data-nav-headerbg="color_3"] .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_3"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_3"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #1362FC;
}

[data-nav-headerbg="color_3"][data-theme-version="dark"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line,
[data-nav-headerbg="color_3"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #fff;
}

[data-nav-headerbg="color_3"][data-theme-version="dark"][data-nav-headerbg="color_8"] .brand-title path,
[data-nav-headerbg="color_3"][data-nav-headerbg="color_8"] .brand-title path {
  fill: #000;
}

[data-nav-headerbg="color_3"][data-theme-version="dark"][data-nav-headerbg="color_8"] .hamburger .line,
[data-nav-headerbg="color_3"][data-nav-headerbg="color_8"] .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_4"][data-theme-version="dark"],
[data-nav-headerbg="color_4"] {
  --nav-headbg: #4d06a5;
}

[data-nav-headerbg="color_4"][data-theme-version="dark"] .brand-logo .logo-abbr .rect-primary-rect,
[data-nav-headerbg="color_4"] .brand-logo .logo-abbr .rect-primary-rect {
  fill: #fff;
}

[data-nav-headerbg="color_4"][data-theme-version="dark"] .brand-logo .logo-abbr circle,
[data-nav-headerbg="color_4"] .brand-logo .logo-abbr circle {
  fill: #4d06a5;
}

[data-nav-headerbg="color_4"][data-theme-version="dark"] .brand-logo .logo-abbr .rect-primary-rect-1,
[data-nav-headerbg="color_4"] .brand-logo .logo-abbr .rect-primary-rect-1 {
  fill: #fff;
  stroke: #4d06a5;
}

[data-nav-headerbg="color_4"][data-theme-version="dark"] .brand-logo .logo-abbr path,
[data-nav-headerbg="color_4"] .brand-logo .logo-abbr path {
  stroke: #4d06a5;
}

[data-nav-headerbg="color_4"][data-theme-version="dark"] .brand-logo .brand-title path,
[data-nav-headerbg="color_4"] .brand-logo .brand-title path {
  fill: #fff;
}

[data-nav-headerbg="color_4"][data-theme-version="dark"] .hamburger .line,
[data-nav-headerbg="color_4"] .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_4"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_4"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #4d06a5;
}

[data-nav-headerbg="color_4"][data-theme-version="dark"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line,
[data-nav-headerbg="color_4"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #fff;
}

[data-nav-headerbg="color_4"][data-theme-version="dark"][data-nav-headerbg="color_8"] .brand-title path,
[data-nav-headerbg="color_4"][data-nav-headerbg="color_8"] .brand-title path {
  fill: #000;
}

[data-nav-headerbg="color_4"][data-theme-version="dark"][data-nav-headerbg="color_8"] .hamburger .line,
[data-nav-headerbg="color_4"][data-nav-headerbg="color_8"] .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_5"][data-theme-version="dark"],
[data-nav-headerbg="color_5"] {
  --nav-headbg: #dc3545;
}

[data-nav-headerbg="color_5"][data-theme-version="dark"] .brand-logo .logo-abbr .rect-primary-rect,
[data-nav-headerbg="color_5"] .brand-logo .logo-abbr .rect-primary-rect {
  fill: #fff;
}

[data-nav-headerbg="color_5"][data-theme-version="dark"] .brand-logo .logo-abbr circle,
[data-nav-headerbg="color_5"] .brand-logo .logo-abbr circle {
  fill: #dc3545;
}

[data-nav-headerbg="color_5"][data-theme-version="dark"] .brand-logo .logo-abbr .rect-primary-rect-1,
[data-nav-headerbg="color_5"] .brand-logo .logo-abbr .rect-primary-rect-1 {
  fill: #fff;
  stroke: #dc3545;
}

[data-nav-headerbg="color_5"][data-theme-version="dark"] .brand-logo .logo-abbr path,
[data-nav-headerbg="color_5"] .brand-logo .logo-abbr path {
  stroke: #dc3545;
}

[data-nav-headerbg="color_5"][data-theme-version="dark"] .brand-logo .brand-title path,
[data-nav-headerbg="color_5"] .brand-logo .brand-title path {
  fill: #fff;
}

[data-nav-headerbg="color_5"][data-theme-version="dark"] .hamburger .line,
[data-nav-headerbg="color_5"] .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_5"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_5"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #dc3545;
}

[data-nav-headerbg="color_5"][data-theme-version="dark"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line,
[data-nav-headerbg="color_5"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #fff;
}

[data-nav-headerbg="color_5"][data-theme-version="dark"][data-nav-headerbg="color_8"] .brand-title path,
[data-nav-headerbg="color_5"][data-nav-headerbg="color_8"] .brand-title path {
  fill: #000;
}

[data-nav-headerbg="color_5"][data-theme-version="dark"][data-nav-headerbg="color_8"] .hamburger .line,
[data-nav-headerbg="color_5"][data-nav-headerbg="color_8"] .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_6"][data-theme-version="dark"],
[data-nav-headerbg="color_6"] {
  --nav-headbg: #fd7e14;
}

[data-nav-headerbg="color_6"][data-theme-version="dark"] .brand-logo .logo-abbr .rect-primary-rect,
[data-nav-headerbg="color_6"] .brand-logo .logo-abbr .rect-primary-rect {
  fill: #fff;
}

[data-nav-headerbg="color_6"][data-theme-version="dark"] .brand-logo .logo-abbr circle,
[data-nav-headerbg="color_6"] .brand-logo .logo-abbr circle {
  fill: #fd7e14;
}

[data-nav-headerbg="color_6"][data-theme-version="dark"] .brand-logo .logo-abbr .rect-primary-rect-1,
[data-nav-headerbg="color_6"] .brand-logo .logo-abbr .rect-primary-rect-1 {
  fill: #fff;
  stroke: #fd7e14;
}

[data-nav-headerbg="color_6"][data-theme-version="dark"] .brand-logo .logo-abbr path,
[data-nav-headerbg="color_6"] .brand-logo .logo-abbr path {
  stroke: #fd7e14;
}

[data-nav-headerbg="color_6"][data-theme-version="dark"] .brand-logo .brand-title path,
[data-nav-headerbg="color_6"] .brand-logo .brand-title path {
  fill: #fff;
}

[data-nav-headerbg="color_6"][data-theme-version="dark"] .hamburger .line,
[data-nav-headerbg="color_6"] .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_6"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_6"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #fd7e14;
}

[data-nav-headerbg="color_6"][data-theme-version="dark"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line,
[data-nav-headerbg="color_6"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #fff;
}

[data-nav-headerbg="color_6"][data-theme-version="dark"][data-nav-headerbg="color_8"] .brand-title path,
[data-nav-headerbg="color_6"][data-nav-headerbg="color_8"] .brand-title path {
  fill: #000;
}

[data-nav-headerbg="color_6"][data-theme-version="dark"][data-nav-headerbg="color_8"] .hamburger .line,
[data-nav-headerbg="color_6"][data-nav-headerbg="color_8"] .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_7"][data-theme-version="dark"],
[data-nav-headerbg="color_7"] {
  --nav-headbg: #ffc107;
}

[data-nav-headerbg="color_7"][data-theme-version="dark"] .brand-logo .logo-abbr .rect-primary-rect,
[data-nav-headerbg="color_7"] .brand-logo .logo-abbr .rect-primary-rect {
  fill: #fff;
}

[data-nav-headerbg="color_7"][data-theme-version="dark"] .brand-logo .logo-abbr circle,
[data-nav-headerbg="color_7"] .brand-logo .logo-abbr circle {
  fill: #ffc107;
}

[data-nav-headerbg="color_7"][data-theme-version="dark"] .brand-logo .logo-abbr .rect-primary-rect-1,
[data-nav-headerbg="color_7"] .brand-logo .logo-abbr .rect-primary-rect-1 {
  fill: #fff;
  stroke: #ffc107;
}

[data-nav-headerbg="color_7"][data-theme-version="dark"] .brand-logo .logo-abbr path,
[data-nav-headerbg="color_7"] .brand-logo .logo-abbr path {
  stroke: #ffc107;
}

[data-nav-headerbg="color_7"][data-theme-version="dark"] .brand-logo .brand-title path,
[data-nav-headerbg="color_7"] .brand-logo .brand-title path {
  fill: #fff;
}

[data-nav-headerbg="color_7"][data-theme-version="dark"] .hamburger .line,
[data-nav-headerbg="color_7"] .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_7"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_7"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #ffc107;
}

[data-nav-headerbg="color_7"][data-theme-version="dark"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line,
[data-nav-headerbg="color_7"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #fff;
}

[data-nav-headerbg="color_7"][data-theme-version="dark"][data-nav-headerbg="color_8"] .brand-title path,
[data-nav-headerbg="color_7"][data-nav-headerbg="color_8"] .brand-title path {
  fill: #000;
}

[data-nav-headerbg="color_7"][data-theme-version="dark"][data-nav-headerbg="color_8"] .hamburger .line,
[data-nav-headerbg="color_7"][data-nav-headerbg="color_8"] .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_8"][data-theme-version="dark"],
[data-nav-headerbg="color_8"] {
  --nav-headbg: #fff;
}

[data-nav-headerbg="color_8"][data-theme-version="dark"] .brand-logo .logo-abbr .rect-primary-rect,
[data-nav-headerbg="color_8"] .brand-logo .logo-abbr .rect-primary-rect {
  fill: #fff;
}

[data-nav-headerbg="color_8"][data-theme-version="dark"] .brand-logo .logo-abbr circle,
[data-nav-headerbg="color_8"] .brand-logo .logo-abbr circle {
  fill: #fff;
}

[data-nav-headerbg="color_8"][data-theme-version="dark"] .brand-logo .logo-abbr .rect-primary-rect-1,
[data-nav-headerbg="color_8"] .brand-logo .logo-abbr .rect-primary-rect-1 {
  fill: #fff;
  stroke: #fff;
}

[data-nav-headerbg="color_8"][data-theme-version="dark"] .brand-logo .logo-abbr path,
[data-nav-headerbg="color_8"] .brand-logo .logo-abbr path {
  stroke: #fff;
}

[data-nav-headerbg="color_8"][data-theme-version="dark"] .brand-logo .brand-title path,
[data-nav-headerbg="color_8"] .brand-logo .brand-title path {
  fill: #fff;
}

[data-nav-headerbg="color_8"][data-theme-version="dark"] .hamburger .line,
[data-nav-headerbg="color_8"] .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_8"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_8"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #fff;
}

[data-nav-headerbg="color_8"][data-theme-version="dark"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line,
[data-nav-headerbg="color_8"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #fff;
}

[data-nav-headerbg="color_8"][data-theme-version="dark"][data-nav-headerbg="color_8"] .brand-title path,
[data-nav-headerbg="color_8"][data-nav-headerbg="color_8"] .brand-title path {
  fill: #000;
}

[data-nav-headerbg="color_8"][data-theme-version="dark"][data-nav-headerbg="color_8"] .hamburger .line,
[data-nav-headerbg="color_8"][data-nav-headerbg="color_8"] .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_9"][data-theme-version="dark"],
[data-nav-headerbg="color_9"] {
  --nav-headbg: #20c997;
}

[data-nav-headerbg="color_9"][data-theme-version="dark"] .brand-logo .logo-abbr .rect-primary-rect,
[data-nav-headerbg="color_9"] .brand-logo .logo-abbr .rect-primary-rect {
  fill: #fff;
}

[data-nav-headerbg="color_9"][data-theme-version="dark"] .brand-logo .logo-abbr circle,
[data-nav-headerbg="color_9"] .brand-logo .logo-abbr circle {
  fill: #20c997;
}

[data-nav-headerbg="color_9"][data-theme-version="dark"] .brand-logo .logo-abbr .rect-primary-rect-1,
[data-nav-headerbg="color_9"] .brand-logo .logo-abbr .rect-primary-rect-1 {
  fill: #fff;
  stroke: #20c997;
}

[data-nav-headerbg="color_9"][data-theme-version="dark"] .brand-logo .logo-abbr path,
[data-nav-headerbg="color_9"] .brand-logo .logo-abbr path {
  stroke: #20c997;
}

[data-nav-headerbg="color_9"][data-theme-version="dark"] .brand-logo .brand-title path,
[data-nav-headerbg="color_9"] .brand-logo .brand-title path {
  fill: #fff;
}

[data-nav-headerbg="color_9"][data-theme-version="dark"] .hamburger .line,
[data-nav-headerbg="color_9"] .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_9"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_9"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #20c997;
}

[data-nav-headerbg="color_9"][data-theme-version="dark"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line,
[data-nav-headerbg="color_9"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #fff;
}

[data-nav-headerbg="color_9"][data-theme-version="dark"][data-nav-headerbg="color_8"] .brand-title path,
[data-nav-headerbg="color_9"][data-nav-headerbg="color_8"] .brand-title path {
  fill: #000;
}

[data-nav-headerbg="color_9"][data-theme-version="dark"][data-nav-headerbg="color_8"] .hamburger .line,
[data-nav-headerbg="color_9"][data-nav-headerbg="color_8"] .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_10"][data-theme-version="dark"],
[data-nav-headerbg="color_10"] {
  --nav-headbg: #17a2b8;
}

[data-nav-headerbg="color_10"][data-theme-version="dark"] .brand-logo .logo-abbr .rect-primary-rect,
[data-nav-headerbg="color_10"] .brand-logo .logo-abbr .rect-primary-rect {
  fill: #fff;
}

[data-nav-headerbg="color_10"][data-theme-version="dark"] .brand-logo .logo-abbr circle,
[data-nav-headerbg="color_10"] .brand-logo .logo-abbr circle {
  fill: #17a2b8;
}

[data-nav-headerbg="color_10"][data-theme-version="dark"] .brand-logo .logo-abbr .rect-primary-rect-1,
[data-nav-headerbg="color_10"] .brand-logo .logo-abbr .rect-primary-rect-1 {
  fill: #fff;
  stroke: #17a2b8;
}

[data-nav-headerbg="color_10"][data-theme-version="dark"] .brand-logo .logo-abbr path,
[data-nav-headerbg="color_10"] .brand-logo .logo-abbr path {
  stroke: #17a2b8;
}

[data-nav-headerbg="color_10"][data-theme-version="dark"] .brand-logo .brand-title path,
[data-nav-headerbg="color_10"] .brand-logo .brand-title path {
  fill: #fff;
}

[data-nav-headerbg="color_10"][data-theme-version="dark"] .hamburger .line,
[data-nav-headerbg="color_10"] .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_10"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_10"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #17a2b8;
}

[data-nav-headerbg="color_10"][data-theme-version="dark"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line,
[data-nav-headerbg="color_10"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #fff;
}

[data-nav-headerbg="color_10"][data-theme-version="dark"][data-nav-headerbg="color_8"] .brand-title path,
[data-nav-headerbg="color_10"][data-nav-headerbg="color_8"] .brand-title path {
  fill: #000;
}

[data-nav-headerbg="color_10"][data-theme-version="dark"][data-nav-headerbg="color_8"] .hamburger .line,
[data-nav-headerbg="color_10"][data-nav-headerbg="color_8"] .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_11"][data-theme-version="dark"],
[data-nav-headerbg="color_11"] {
  --nav-headbg: #94618E;
}

[data-nav-headerbg="color_11"][data-theme-version="dark"] .brand-logo .logo-abbr .rect-primary-rect,
[data-nav-headerbg="color_11"] .brand-logo .logo-abbr .rect-primary-rect {
  fill: #fff;
}

[data-nav-headerbg="color_11"][data-theme-version="dark"] .brand-logo .logo-abbr circle,
[data-nav-headerbg="color_11"] .brand-logo .logo-abbr circle {
  fill: #94618E;
}

[data-nav-headerbg="color_11"][data-theme-version="dark"] .brand-logo .logo-abbr .rect-primary-rect-1,
[data-nav-headerbg="color_11"] .brand-logo .logo-abbr .rect-primary-rect-1 {
  fill: #fff;
  stroke: #94618E;
}

[data-nav-headerbg="color_11"][data-theme-version="dark"] .brand-logo .logo-abbr path,
[data-nav-headerbg="color_11"] .brand-logo .logo-abbr path {
  stroke: #94618E;
}

[data-nav-headerbg="color_11"][data-theme-version="dark"] .brand-logo .brand-title path,
[data-nav-headerbg="color_11"] .brand-logo .brand-title path {
  fill: #fff;
}

[data-nav-headerbg="color_11"][data-theme-version="dark"] .hamburger .line,
[data-nav-headerbg="color_11"] .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_11"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_11"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #94618E;
}

[data-nav-headerbg="color_11"][data-theme-version="dark"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line,
[data-nav-headerbg="color_11"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #fff;
}

[data-nav-headerbg="color_11"][data-theme-version="dark"][data-nav-headerbg="color_8"] .brand-title path,
[data-nav-headerbg="color_11"][data-nav-headerbg="color_8"] .brand-title path {
  fill: #000;
}

[data-nav-headerbg="color_11"][data-theme-version="dark"][data-nav-headerbg="color_8"] .hamburger .line,
[data-nav-headerbg="color_11"][data-nav-headerbg="color_8"] .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_12"][data-theme-version="dark"],
[data-nav-headerbg="color_12"] {
  --nav-headbg: #343a40;
}

[data-nav-headerbg="color_12"][data-theme-version="dark"] .brand-logo .logo-abbr .rect-primary-rect,
[data-nav-headerbg="color_12"] .brand-logo .logo-abbr .rect-primary-rect {
  fill: #fff;
}

[data-nav-headerbg="color_12"][data-theme-version="dark"] .brand-logo .logo-abbr circle,
[data-nav-headerbg="color_12"] .brand-logo .logo-abbr circle {
  fill: #343a40;
}

[data-nav-headerbg="color_12"][data-theme-version="dark"] .brand-logo .logo-abbr .rect-primary-rect-1,
[data-nav-headerbg="color_12"] .brand-logo .logo-abbr .rect-primary-rect-1 {
  fill: #fff;
  stroke: #343a40;
}

[data-nav-headerbg="color_12"][data-theme-version="dark"] .brand-logo .logo-abbr path,
[data-nav-headerbg="color_12"] .brand-logo .logo-abbr path {
  stroke: #343a40;
}

[data-nav-headerbg="color_12"][data-theme-version="dark"] .brand-logo .brand-title path,
[data-nav-headerbg="color_12"] .brand-logo .brand-title path {
  fill: #fff;
}

[data-nav-headerbg="color_12"][data-theme-version="dark"] .hamburger .line,
[data-nav-headerbg="color_12"] .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_12"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_12"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #343a40;
}

[data-nav-headerbg="color_12"][data-theme-version="dark"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line,
[data-nav-headerbg="color_12"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #fff;
}

[data-nav-headerbg="color_12"][data-theme-version="dark"][data-nav-headerbg="color_8"] .brand-title path,
[data-nav-headerbg="color_12"][data-nav-headerbg="color_8"] .brand-title path {
  fill: #000;
}

[data-nav-headerbg="color_12"][data-theme-version="dark"][data-nav-headerbg="color_8"] .hamburger .line,
[data-nav-headerbg="color_12"][data-nav-headerbg="color_8"] .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_13"][data-theme-version="dark"],
[data-nav-headerbg="color_13"] {
  --nav-headbg: #2a2a2a;
}

[data-nav-headerbg="color_13"][data-theme-version="dark"] .brand-logo .logo-abbr .rect-primary-rect,
[data-nav-headerbg="color_13"] .brand-logo .logo-abbr .rect-primary-rect {
  fill: #fff;
}

[data-nav-headerbg="color_13"][data-theme-version="dark"] .brand-logo .logo-abbr circle,
[data-nav-headerbg="color_13"] .brand-logo .logo-abbr circle {
  fill: #2a2a2a;
}

[data-nav-headerbg="color_13"][data-theme-version="dark"] .brand-logo .logo-abbr .rect-primary-rect-1,
[data-nav-headerbg="color_13"] .brand-logo .logo-abbr .rect-primary-rect-1 {
  fill: #fff;
  stroke: #2a2a2a;
}

[data-nav-headerbg="color_13"][data-theme-version="dark"] .brand-logo .logo-abbr path,
[data-nav-headerbg="color_13"] .brand-logo .logo-abbr path {
  stroke: #2a2a2a;
}

[data-nav-headerbg="color_13"][data-theme-version="dark"] .brand-logo .brand-title path,
[data-nav-headerbg="color_13"] .brand-logo .brand-title path {
  fill: #fff;
}

[data-nav-headerbg="color_13"][data-theme-version="dark"] .hamburger .line,
[data-nav-headerbg="color_13"] .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_13"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_13"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #2a2a2a;
}

[data-nav-headerbg="color_13"][data-theme-version="dark"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line,
[data-nav-headerbg="color_13"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #fff;
}

[data-nav-headerbg="color_13"][data-theme-version="dark"][data-nav-headerbg="color_8"] .brand-title path,
[data-nav-headerbg="color_13"][data-nav-headerbg="color_8"] .brand-title path {
  fill: #000;
}

[data-nav-headerbg="color_13"][data-theme-version="dark"][data-nav-headerbg="color_8"] .hamburger .line,
[data-nav-headerbg="color_13"][data-nav-headerbg="color_8"] .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_14"][data-theme-version="dark"],
[data-nav-headerbg="color_14"] {
  --nav-headbg: #4885ed;
}

[data-nav-headerbg="color_14"][data-theme-version="dark"] .brand-logo .logo-abbr .rect-primary-rect,
[data-nav-headerbg="color_14"] .brand-logo .logo-abbr .rect-primary-rect {
  fill: #fff;
}

[data-nav-headerbg="color_14"][data-theme-version="dark"] .brand-logo .logo-abbr circle,
[data-nav-headerbg="color_14"] .brand-logo .logo-abbr circle {
  fill: #4885ed;
}

[data-nav-headerbg="color_14"][data-theme-version="dark"] .brand-logo .logo-abbr .rect-primary-rect-1,
[data-nav-headerbg="color_14"] .brand-logo .logo-abbr .rect-primary-rect-1 {
  fill: #fff;
  stroke: #4885ed;
}

[data-nav-headerbg="color_14"][data-theme-version="dark"] .brand-logo .logo-abbr path,
[data-nav-headerbg="color_14"] .brand-logo .logo-abbr path {
  stroke: #4885ed;
}

[data-nav-headerbg="color_14"][data-theme-version="dark"] .brand-logo .brand-title path,
[data-nav-headerbg="color_14"] .brand-logo .brand-title path {
  fill: #fff;
}

[data-nav-headerbg="color_14"][data-theme-version="dark"] .hamburger .line,
[data-nav-headerbg="color_14"] .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_14"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_14"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #4885ed;
}

[data-nav-headerbg="color_14"][data-theme-version="dark"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line,
[data-nav-headerbg="color_14"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #fff;
}

[data-nav-headerbg="color_14"][data-theme-version="dark"][data-nav-headerbg="color_8"] .brand-title path,
[data-nav-headerbg="color_14"][data-nav-headerbg="color_8"] .brand-title path {
  fill: #000;
}

[data-nav-headerbg="color_14"][data-theme-version="dark"][data-nav-headerbg="color_8"] .hamburger .line,
[data-nav-headerbg="color_14"][data-nav-headerbg="color_8"] .hamburger .line {
  background: #000;
}

[data-nav-headerbg="color_15"][data-theme-version="dark"],
[data-nav-headerbg="color_15"] {
  --nav-headbg: #4cb32b;
}

[data-nav-headerbg="color_15"][data-theme-version="dark"] .brand-logo .logo-abbr .rect-primary-rect,
[data-nav-headerbg="color_15"] .brand-logo .logo-abbr .rect-primary-rect {
  fill: #fff;
}

[data-nav-headerbg="color_15"][data-theme-version="dark"] .brand-logo .logo-abbr circle,
[data-nav-headerbg="color_15"] .brand-logo .logo-abbr circle {
  fill: #4cb32b;
}

[data-nav-headerbg="color_15"][data-theme-version="dark"] .brand-logo .logo-abbr .rect-primary-rect-1,
[data-nav-headerbg="color_15"] .brand-logo .logo-abbr .rect-primary-rect-1 {
  fill: #fff;
  stroke: #4cb32b;
}

[data-nav-headerbg="color_15"][data-theme-version="dark"] .brand-logo .logo-abbr path,
[data-nav-headerbg="color_15"] .brand-logo .logo-abbr path {
  stroke: #4cb32b;
}

[data-nav-headerbg="color_15"][data-theme-version="dark"] .brand-logo .brand-title path,
[data-nav-headerbg="color_15"] .brand-logo .brand-title path {
  fill: #fff;
}

[data-nav-headerbg="color_15"][data-theme-version="dark"] .hamburger .line,
[data-nav-headerbg="color_15"] .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_15"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-nav-headerbg="color_15"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #4cb32b;
}

[data-nav-headerbg="color_15"][data-theme-version="dark"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line,
[data-nav-headerbg="color_15"][data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: #fff;
}

[data-nav-headerbg="color_15"][data-theme-version="dark"][data-nav-headerbg="color_8"] .brand-title path,
[data-nav-headerbg="color_15"][data-nav-headerbg="color_8"] .brand-title path {
  fill: #000;
}

[data-nav-headerbg="color_15"][data-theme-version="dark"][data-nav-headerbg="color_8"] .hamburger .line,
[data-nav-headerbg="color_15"][data-nav-headerbg="color_8"] .hamburger .line {
  background: #000;
}

[data-sibebarbg="color_2"][data-theme-version="dark"],
[data-sibebarbg="color_2"] {
  --sidebar-bg: #6610f2;
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_2"] .menu-toggle .deznav .metismenu li>ul {
  background: #6610f2 !important;
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_2"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_2"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sibebarbg="color_2"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sibebarbg="color_2"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sibebarbg="color_2"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_2"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul {
  background-color: #8540f5;
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_2"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_2"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_2"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_2"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_2"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a {
  background-color: #8540f5 !important;
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover,
[data-sibebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i,
[data-sibebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i {
  background: #6f1ff3 !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i,
[data-sibebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i {
  background: #8540f5 !important;
  color: #fff !important;
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before {
  background: rgba(0, 0, 0, 0.5);
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a,
[data-sibebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active {
  color: #000 !important;
  /* background:rgba($black,0.15)!important; */
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(0, 0, 0, 0.85) transparent transparent rgba(0, 0, 0, 0.85);
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a,
[data-sibebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a {
  color: var(--primary);
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i {
  color: rgba(0, 0, 0, 0.6) !important;
  background: var(--rgba-primary-1) !important;
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a {
  background: transparent;
  box-shadow: none;
  color: var(--primary) !important;
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i {
  color: var(--primary) !important;
  background: var(--rgba-primary-1) !important;
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .copyright p,
[data-sibebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .copyright p {
  color: rgba(0, 0, 0, 0.6);
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .book-box,
[data-sibebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .book-box {
  background: rgba(0, 0, 0, 0.4);
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info span,
[data-sibebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info span {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info .small,
[data-sibebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info .small {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li a:hover,
[data-sibebarbg="color_2"][data-sidebar-style="icon-hover"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li>a>i,
[data-sibebarbg="color_2"][data-sidebar-style="icon-hover"] .deznav .metismenu>li>a>i {
  /* background: lighten($color: $color, $amount: 3%)!important; */
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_2"][data-sidebar-style="icon-hover"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"][data-sidebar-style="icon-hover"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: #8540f5 !important;
  color: #fff;
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu ul a:hover:before,
[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu ul a:focus:before,
[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu ul a.mm-active:before,
[data-sibebarbg="color_2"] .deznav .metismenu ul a:hover:before,
[data-sibebarbg="color_2"] .deznav .metismenu ul a:focus:before,
[data-sibebarbg="color_2"] .deznav .metismenu ul a.mm-active:before {
  border-color: #fff;
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_2"] .deznav .metismenu ul a:before {
  background: #fff;
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu a,
[data-sibebarbg="color_2"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.8);
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu li ul a:hover,
[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu li ul a:focus,
[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
[data-sibebarbg="color_2"] .deznav .metismenu li ul a:hover,
[data-sibebarbg="color_2"] .deznav .metismenu li ul a:focus,
[data-sibebarbg="color_2"] .deznav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sibebarbg="color_2"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_2"] .deznav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_2"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"] .deznav .metismenu>li:hover>a {
  background: #8540f5 !important;
  box-shadow: none;
  color: #fff !important;
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu>li:hover>a i,
[data-sibebarbg="color_2"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_2"] .deznav .metismenu>li:hover>a i {
  color: #fff !important;
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_2"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85);
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .header-profile>a.nav-link,
[data-sibebarbg="color_2"] .deznav .header-profile>a.nav-link {
  border-color: rgba(255, 255, 255, 0.3);
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info span,
[data-sibebarbg="color_2"] .deznav .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info small,
[data-sibebarbg="color_2"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info .small,
[data-sibebarbg="color_2"] .deznav .header-profile>a.nav-link .header-info small,
[data-sibebarbg="color_2"] .deznav .header-profile>a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.8);
}

[data-sibebarbg="color_2"][data-theme-version="dark"] .copyright,
[data-sibebarbg="color_2"] .copyright {
  color: #fff;
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_2"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before {
  background: rgba(0, 0, 0, 0.5);
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a,
[data-sibebarbg="color_2"][data-sibebarbg="color_8"] .deznav .metismenu a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_2"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_2"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_2"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active {
  color: #000;
  /* background:rgba($black,0.15)!important; */
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_2"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(0, 0, 0, 0.85) transparent transparent rgba(0, 0, 0, 0.85);
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li>a,
[data-sibebarbg="color_2"][data-sibebarbg="color_8"] .deznav .metismenu>li>a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_2"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_2"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a {
  background: rgba(0, 0, 0, 0.6) !important;
  box-shadow: none;
  color: #fff !important;
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_2"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .copyright p,
[data-sibebarbg="color_2"][data-sibebarbg="color_8"] .deznav .copyright p {
  color: rgba(0, 0, 0, 0.6);
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .book-box,
[data-sibebarbg="color_2"][data-sibebarbg="color_8"] .deznav .book-box {
  background: rgba(0, 0, 0, 0.4);
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info span,
[data-sibebarbg="color_2"][data-sibebarbg="color_8"] .deznav .header-info span {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_2"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_2"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info .small,
[data-sibebarbg="color_2"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_2"][data-sibebarbg="color_8"] .deznav .header-info .small {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_3"][data-theme-version="dark"],
[data-sibebarbg="color_3"] {
  --sidebar-bg: #1362FC;
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_3"] .menu-toggle .deznav .metismenu li>ul {
  background: #1362FC !important;
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_3"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_3"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sibebarbg="color_3"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sibebarbg="color_3"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sibebarbg="color_3"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_3"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul {
  background-color: #4584fd;
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_3"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_3"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_3"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_3"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_3"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a {
  background-color: #4584fd !important;
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover,
[data-sibebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i,
[data-sibebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i {
  background: #226cfc !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i,
[data-sibebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i {
  background: #4584fd !important;
  color: #fff !important;
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before {
  background: rgba(0, 0, 0, 0.5);
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a,
[data-sibebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active {
  color: #000 !important;
  /* background:rgba($black,0.15)!important; */
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(0, 0, 0, 0.85) transparent transparent rgba(0, 0, 0, 0.85);
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a,
[data-sibebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a {
  color: var(--primary);
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i {
  color: rgba(0, 0, 0, 0.6) !important;
  background: var(--rgba-primary-1) !important;
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a {
  background: transparent;
  box-shadow: none;
  color: var(--primary) !important;
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i {
  color: var(--primary) !important;
  background: var(--rgba-primary-1) !important;
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .copyright p,
[data-sibebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .copyright p {
  color: rgba(0, 0, 0, 0.6);
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .book-box,
[data-sibebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .book-box {
  background: rgba(0, 0, 0, 0.4);
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info span,
[data-sibebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info span {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info .small,
[data-sibebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info .small {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li a:hover,
[data-sibebarbg="color_3"][data-sidebar-style="icon-hover"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li>a>i,
[data-sibebarbg="color_3"][data-sidebar-style="icon-hover"] .deznav .metismenu>li>a>i {
  /* background: lighten($color: $color, $amount: 3%)!important; */
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_3"][data-sidebar-style="icon-hover"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"][data-sidebar-style="icon-hover"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: #4584fd !important;
  color: #fff;
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu ul a:hover:before,
[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu ul a:focus:before,
[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu ul a.mm-active:before,
[data-sibebarbg="color_3"] .deznav .metismenu ul a:hover:before,
[data-sibebarbg="color_3"] .deznav .metismenu ul a:focus:before,
[data-sibebarbg="color_3"] .deznav .metismenu ul a.mm-active:before {
  border-color: #fff;
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_3"] .deznav .metismenu ul a:before {
  background: #fff;
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu a,
[data-sibebarbg="color_3"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.8);
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu li ul a:hover,
[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu li ul a:focus,
[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
[data-sibebarbg="color_3"] .deznav .metismenu li ul a:hover,
[data-sibebarbg="color_3"] .deznav .metismenu li ul a:focus,
[data-sibebarbg="color_3"] .deznav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sibebarbg="color_3"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_3"] .deznav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_3"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"] .deznav .metismenu>li:hover>a {
  background: #4584fd !important;
  box-shadow: none;
  color: #fff !important;
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu>li:hover>a i,
[data-sibebarbg="color_3"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_3"] .deznav .metismenu>li:hover>a i {
  color: #fff !important;
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_3"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85);
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .header-profile>a.nav-link,
[data-sibebarbg="color_3"] .deznav .header-profile>a.nav-link {
  border-color: rgba(255, 255, 255, 0.3);
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info span,
[data-sibebarbg="color_3"] .deznav .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info small,
[data-sibebarbg="color_3"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info .small,
[data-sibebarbg="color_3"] .deznav .header-profile>a.nav-link .header-info small,
[data-sibebarbg="color_3"] .deznav .header-profile>a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.8);
}

[data-sibebarbg="color_3"][data-theme-version="dark"] .copyright,
[data-sibebarbg="color_3"] .copyright {
  color: #fff;
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_3"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before {
  background: rgba(0, 0, 0, 0.5);
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a,
[data-sibebarbg="color_3"][data-sibebarbg="color_8"] .deznav .metismenu a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_3"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_3"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_3"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active {
  color: #000;
  /* background:rgba($black,0.15)!important; */
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_3"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(0, 0, 0, 0.85) transparent transparent rgba(0, 0, 0, 0.85);
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li>a,
[data-sibebarbg="color_3"][data-sibebarbg="color_8"] .deznav .metismenu>li>a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_3"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_3"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a {
  background: rgba(0, 0, 0, 0.6) !important;
  box-shadow: none;
  color: #fff !important;
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_3"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .copyright p,
[data-sibebarbg="color_3"][data-sibebarbg="color_8"] .deznav .copyright p {
  color: rgba(0, 0, 0, 0.6);
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .book-box,
[data-sibebarbg="color_3"][data-sibebarbg="color_8"] .deznav .book-box {
  background: rgba(0, 0, 0, 0.4);
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info span,
[data-sibebarbg="color_3"][data-sibebarbg="color_8"] .deznav .header-info span {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_3"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_3"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info .small,
[data-sibebarbg="color_3"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_3"][data-sibebarbg="color_8"] .deznav .header-info .small {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_4"][data-theme-version="dark"],
[data-sibebarbg="color_4"] {
  --sidebar-bg: #4d06a5;
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_4"] .menu-toggle .deznav .metismenu li>ul {
  background: #4d06a5 !important;
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_4"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_4"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sibebarbg="color_4"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sibebarbg="color_4"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sibebarbg="color_4"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_4"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul {
  background-color: #6408d6;
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_4"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_4"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_4"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_4"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_4"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a {
  background-color: #6408d6 !important;
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover,
[data-sibebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i,
[data-sibebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i {
  background: #5407b4 !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i,
[data-sibebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i {
  background: #6408d6 !important;
  color: #fff !important;
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before {
  background: rgba(0, 0, 0, 0.5);
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a,
[data-sibebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active {
  color: #000 !important;
  /* background:rgba($black,0.15)!important; */
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(0, 0, 0, 0.85) transparent transparent rgba(0, 0, 0, 0.85);
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a,
[data-sibebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a {
  color: var(--primary);
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i {
  color: rgba(0, 0, 0, 0.6) !important;
  background: var(--rgba-primary-1) !important;
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a {
  background: transparent;
  box-shadow: none;
  color: var(--primary) !important;
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i {
  color: var(--primary) !important;
  background: var(--rgba-primary-1) !important;
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .copyright p,
[data-sibebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .copyright p {
  color: rgba(0, 0, 0, 0.6);
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .book-box,
[data-sibebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .book-box {
  background: rgba(0, 0, 0, 0.4);
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info span,
[data-sibebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info span {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info .small,
[data-sibebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info .small {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li a:hover,
[data-sibebarbg="color_4"][data-sidebar-style="icon-hover"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li>a>i,
[data-sibebarbg="color_4"][data-sidebar-style="icon-hover"] .deznav .metismenu>li>a>i {
  /* background: lighten($color: $color, $amount: 3%)!important; */
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_4"][data-sidebar-style="icon-hover"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"][data-sidebar-style="icon-hover"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: #6408d6 !important;
  color: #fff;
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu ul a:hover:before,
[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu ul a:focus:before,
[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu ul a.mm-active:before,
[data-sibebarbg="color_4"] .deznav .metismenu ul a:hover:before,
[data-sibebarbg="color_4"] .deznav .metismenu ul a:focus:before,
[data-sibebarbg="color_4"] .deznav .metismenu ul a.mm-active:before {
  border-color: #fff;
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_4"] .deznav .metismenu ul a:before {
  background: #fff;
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu a,
[data-sibebarbg="color_4"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.8);
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu li ul a:hover,
[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu li ul a:focus,
[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
[data-sibebarbg="color_4"] .deznav .metismenu li ul a:hover,
[data-sibebarbg="color_4"] .deznav .metismenu li ul a:focus,
[data-sibebarbg="color_4"] .deznav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sibebarbg="color_4"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_4"] .deznav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_4"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"] .deznav .metismenu>li:hover>a {
  background: #6408d6 !important;
  box-shadow: none;
  color: #fff !important;
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu>li:hover>a i,
[data-sibebarbg="color_4"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_4"] .deznav .metismenu>li:hover>a i {
  color: #fff !important;
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_4"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85);
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .header-profile>a.nav-link,
[data-sibebarbg="color_4"] .deznav .header-profile>a.nav-link {
  border-color: rgba(255, 255, 255, 0.3);
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info span,
[data-sibebarbg="color_4"] .deznav .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info small,
[data-sibebarbg="color_4"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info .small,
[data-sibebarbg="color_4"] .deznav .header-profile>a.nav-link .header-info small,
[data-sibebarbg="color_4"] .deznav .header-profile>a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.8);
}

[data-sibebarbg="color_4"][data-theme-version="dark"] .copyright,
[data-sibebarbg="color_4"] .copyright {
  color: #fff;
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_4"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before {
  background: rgba(0, 0, 0, 0.5);
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a,
[data-sibebarbg="color_4"][data-sibebarbg="color_8"] .deznav .metismenu a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_4"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_4"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_4"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active {
  color: #000;
  /* background:rgba($black,0.15)!important; */
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_4"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(0, 0, 0, 0.85) transparent transparent rgba(0, 0, 0, 0.85);
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li>a,
[data-sibebarbg="color_4"][data-sibebarbg="color_8"] .deznav .metismenu>li>a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_4"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_4"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a {
  background: rgba(0, 0, 0, 0.6) !important;
  box-shadow: none;
  color: #fff !important;
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_4"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .copyright p,
[data-sibebarbg="color_4"][data-sibebarbg="color_8"] .deznav .copyright p {
  color: rgba(0, 0, 0, 0.6);
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .book-box,
[data-sibebarbg="color_4"][data-sibebarbg="color_8"] .deznav .book-box {
  background: rgba(0, 0, 0, 0.4);
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info span,
[data-sibebarbg="color_4"][data-sibebarbg="color_8"] .deznav .header-info span {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_4"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_4"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info .small,
[data-sibebarbg="color_4"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_4"][data-sibebarbg="color_8"] .deznav .header-info .small {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_5"][data-theme-version="dark"],
[data-sibebarbg="color_5"] {
  --sidebar-bg: #dc3545;
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_5"] .menu-toggle .deznav .metismenu li>ul {
  background: #dc3545 !important;
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_5"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_5"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sibebarbg="color_5"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sibebarbg="color_5"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sibebarbg="color_5"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_5"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul {
  background-color: #e4606d;
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_5"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_5"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_5"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_5"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_5"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a {
  background-color: #e4606d !important;
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover,
[data-sibebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i,
[data-sibebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i {
  background: #de4251 !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i,
[data-sibebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i {
  background: #e4606d !important;
  color: #fff !important;
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before {
  background: rgba(0, 0, 0, 0.5);
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a,
[data-sibebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active {
  color: #000 !important;
  /* background:rgba($black,0.15)!important; */
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(0, 0, 0, 0.85) transparent transparent rgba(0, 0, 0, 0.85);
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a,
[data-sibebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a {
  color: var(--primary);
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i {
  color: rgba(0, 0, 0, 0.6) !important;
  background: var(--rgba-primary-1) !important;
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a {
  background: transparent;
  box-shadow: none;
  color: var(--primary) !important;
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i {
  color: var(--primary) !important;
  background: var(--rgba-primary-1) !important;
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .copyright p,
[data-sibebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .copyright p {
  color: rgba(0, 0, 0, 0.6);
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .book-box,
[data-sibebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .book-box {
  background: rgba(0, 0, 0, 0.4);
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info span,
[data-sibebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info span {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info .small,
[data-sibebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info .small {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li a:hover,
[data-sibebarbg="color_5"][data-sidebar-style="icon-hover"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li>a>i,
[data-sibebarbg="color_5"][data-sidebar-style="icon-hover"] .deznav .metismenu>li>a>i {
  /* background: lighten($color: $color, $amount: 3%)!important; */
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_5"][data-sidebar-style="icon-hover"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"][data-sidebar-style="icon-hover"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: #e4606d !important;
  color: #fff;
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu ul a:hover:before,
[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu ul a:focus:before,
[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu ul a.mm-active:before,
[data-sibebarbg="color_5"] .deznav .metismenu ul a:hover:before,
[data-sibebarbg="color_5"] .deznav .metismenu ul a:focus:before,
[data-sibebarbg="color_5"] .deznav .metismenu ul a.mm-active:before {
  border-color: #fff;
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_5"] .deznav .metismenu ul a:before {
  background: #fff;
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu a,
[data-sibebarbg="color_5"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.8);
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu li ul a:hover,
[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu li ul a:focus,
[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
[data-sibebarbg="color_5"] .deznav .metismenu li ul a:hover,
[data-sibebarbg="color_5"] .deznav .metismenu li ul a:focus,
[data-sibebarbg="color_5"] .deznav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sibebarbg="color_5"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_5"] .deznav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_5"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"] .deznav .metismenu>li:hover>a {
  background: #e4606d !important;
  box-shadow: none;
  color: #fff !important;
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu>li:hover>a i,
[data-sibebarbg="color_5"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_5"] .deznav .metismenu>li:hover>a i {
  color: #fff !important;
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_5"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85);
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .header-profile>a.nav-link,
[data-sibebarbg="color_5"] .deznav .header-profile>a.nav-link {
  border-color: rgba(255, 255, 255, 0.3);
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info span,
[data-sibebarbg="color_5"] .deznav .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info small,
[data-sibebarbg="color_5"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info .small,
[data-sibebarbg="color_5"] .deznav .header-profile>a.nav-link .header-info small,
[data-sibebarbg="color_5"] .deznav .header-profile>a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.8);
}

[data-sibebarbg="color_5"][data-theme-version="dark"] .copyright,
[data-sibebarbg="color_5"] .copyright {
  color: #fff;
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_5"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before {
  background: rgba(0, 0, 0, 0.5);
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a,
[data-sibebarbg="color_5"][data-sibebarbg="color_8"] .deznav .metismenu a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_5"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_5"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_5"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active {
  color: #000;
  /* background:rgba($black,0.15)!important; */
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_5"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(0, 0, 0, 0.85) transparent transparent rgba(0, 0, 0, 0.85);
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li>a,
[data-sibebarbg="color_5"][data-sibebarbg="color_8"] .deznav .metismenu>li>a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_5"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_5"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a {
  background: rgba(0, 0, 0, 0.6) !important;
  box-shadow: none;
  color: #fff !important;
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_5"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .copyright p,
[data-sibebarbg="color_5"][data-sibebarbg="color_8"] .deznav .copyright p {
  color: rgba(0, 0, 0, 0.6);
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .book-box,
[data-sibebarbg="color_5"][data-sibebarbg="color_8"] .deznav .book-box {
  background: rgba(0, 0, 0, 0.4);
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info span,
[data-sibebarbg="color_5"][data-sibebarbg="color_8"] .deznav .header-info span {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_5"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_5"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info .small,
[data-sibebarbg="color_5"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_5"][data-sibebarbg="color_8"] .deznav .header-info .small {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_6"][data-theme-version="dark"],
[data-sibebarbg="color_6"] {
  --sidebar-bg: #fd7e14;
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_6"] .menu-toggle .deznav .metismenu li>ul {
  background: #fd7e14 !important;
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_6"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_6"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sibebarbg="color_6"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sibebarbg="color_6"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sibebarbg="color_6"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_6"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul {
  background-color: #fd9a47;
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_6"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_6"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_6"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_6"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_6"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a {
  background-color: #fd9a47 !important;
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover,
[data-sibebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i,
[data-sibebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i {
  background: #fd8623 !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i,
[data-sibebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i {
  background: #fd9a47 !important;
  color: #fff !important;
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before {
  background: rgba(0, 0, 0, 0.5);
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a,
[data-sibebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active {
  color: #000 !important;
  /* background:rgba($black,0.15)!important; */
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(0, 0, 0, 0.85) transparent transparent rgba(0, 0, 0, 0.85);
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a,
[data-sibebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a {
  color: var(--primary);
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i {
  color: rgba(0, 0, 0, 0.6) !important;
  background: var(--rgba-primary-1) !important;
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a {
  background: transparent;
  box-shadow: none;
  color: var(--primary) !important;
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i {
  color: var(--primary) !important;
  background: var(--rgba-primary-1) !important;
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .copyright p,
[data-sibebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .copyright p {
  color: rgba(0, 0, 0, 0.6);
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .book-box,
[data-sibebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .book-box {
  background: rgba(0, 0, 0, 0.4);
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info span,
[data-sibebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info span {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info .small,
[data-sibebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info .small {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li a:hover,
[data-sibebarbg="color_6"][data-sidebar-style="icon-hover"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li>a>i,
[data-sibebarbg="color_6"][data-sidebar-style="icon-hover"] .deznav .metismenu>li>a>i {
  /* background: lighten($color: $color, $amount: 3%)!important; */
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_6"][data-sidebar-style="icon-hover"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"][data-sidebar-style="icon-hover"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: #fd9a47 !important;
  color: #fff;
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu ul a:hover:before,
[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu ul a:focus:before,
[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu ul a.mm-active:before,
[data-sibebarbg="color_6"] .deznav .metismenu ul a:hover:before,
[data-sibebarbg="color_6"] .deznav .metismenu ul a:focus:before,
[data-sibebarbg="color_6"] .deznav .metismenu ul a.mm-active:before {
  border-color: #fff;
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_6"] .deznav .metismenu ul a:before {
  background: #fff;
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu a,
[data-sibebarbg="color_6"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.8);
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu li ul a:hover,
[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu li ul a:focus,
[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
[data-sibebarbg="color_6"] .deznav .metismenu li ul a:hover,
[data-sibebarbg="color_6"] .deznav .metismenu li ul a:focus,
[data-sibebarbg="color_6"] .deznav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sibebarbg="color_6"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_6"] .deznav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_6"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"] .deznav .metismenu>li:hover>a {
  background: #fd9a47 !important;
  box-shadow: none;
  color: #fff !important;
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu>li:hover>a i,
[data-sibebarbg="color_6"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_6"] .deznav .metismenu>li:hover>a i {
  color: #fff !important;
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_6"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85);
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .header-profile>a.nav-link,
[data-sibebarbg="color_6"] .deznav .header-profile>a.nav-link {
  border-color: rgba(255, 255, 255, 0.3);
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info span,
[data-sibebarbg="color_6"] .deznav .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info small,
[data-sibebarbg="color_6"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info .small,
[data-sibebarbg="color_6"] .deznav .header-profile>a.nav-link .header-info small,
[data-sibebarbg="color_6"] .deznav .header-profile>a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.8);
}

[data-sibebarbg="color_6"][data-theme-version="dark"] .copyright,
[data-sibebarbg="color_6"] .copyright {
  color: #fff;
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_6"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before {
  background: rgba(0, 0, 0, 0.5);
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a,
[data-sibebarbg="color_6"][data-sibebarbg="color_8"] .deznav .metismenu a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_6"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_6"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_6"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active {
  color: #000;
  /* background:rgba($black,0.15)!important; */
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_6"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(0, 0, 0, 0.85) transparent transparent rgba(0, 0, 0, 0.85);
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li>a,
[data-sibebarbg="color_6"][data-sibebarbg="color_8"] .deznav .metismenu>li>a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_6"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_6"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a {
  background: rgba(0, 0, 0, 0.6) !important;
  box-shadow: none;
  color: #fff !important;
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_6"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .copyright p,
[data-sibebarbg="color_6"][data-sibebarbg="color_8"] .deznav .copyright p {
  color: rgba(0, 0, 0, 0.6);
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .book-box,
[data-sibebarbg="color_6"][data-sibebarbg="color_8"] .deznav .book-box {
  background: rgba(0, 0, 0, 0.4);
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info span,
[data-sibebarbg="color_6"][data-sibebarbg="color_8"] .deznav .header-info span {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_6"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_6"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info .small,
[data-sibebarbg="color_6"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_6"][data-sibebarbg="color_8"] .deznav .header-info .small {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_7"][data-theme-version="dark"],
[data-sibebarbg="color_7"] {
  --sidebar-bg: #ffc107;
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_7"] .menu-toggle .deznav .metismenu li>ul {
  background: #ffc107 !important;
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_7"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_7"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sibebarbg="color_7"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sibebarbg="color_7"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sibebarbg="color_7"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_7"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul {
  background-color: #ffce3a;
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_7"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_7"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_7"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_7"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_7"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a {
  background-color: #ffce3a !important;
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover,
[data-sibebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i,
[data-sibebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i {
  background: #ffc516 !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i,
[data-sibebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i {
  background: #ffce3a !important;
  color: #fff !important;
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before {
  background: rgba(0, 0, 0, 0.5);
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a,
[data-sibebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active {
  color: #000 !important;
  /* background:rgba($black,0.15)!important; */
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(0, 0, 0, 0.85) transparent transparent rgba(0, 0, 0, 0.85);
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a,
[data-sibebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a {
  color: var(--primary);
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i {
  color: rgba(0, 0, 0, 0.6) !important;
  background: var(--rgba-primary-1) !important;
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a {
  background: transparent;
  box-shadow: none;
  color: var(--primary) !important;
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i {
  color: var(--primary) !important;
  background: var(--rgba-primary-1) !important;
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .copyright p,
[data-sibebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .copyright p {
  color: rgba(0, 0, 0, 0.6);
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .book-box,
[data-sibebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .book-box {
  background: rgba(0, 0, 0, 0.4);
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info span,
[data-sibebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info span {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info .small,
[data-sibebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info .small {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li a:hover,
[data-sibebarbg="color_7"][data-sidebar-style="icon-hover"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li>a>i,
[data-sibebarbg="color_7"][data-sidebar-style="icon-hover"] .deznav .metismenu>li>a>i {
  /* background: lighten($color: $color, $amount: 3%)!important; */
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_7"][data-sidebar-style="icon-hover"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"][data-sidebar-style="icon-hover"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: #ffce3a !important;
  color: #fff;
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu ul a:hover:before,
[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu ul a:focus:before,
[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu ul a.mm-active:before,
[data-sibebarbg="color_7"] .deznav .metismenu ul a:hover:before,
[data-sibebarbg="color_7"] .deznav .metismenu ul a:focus:before,
[data-sibebarbg="color_7"] .deznav .metismenu ul a.mm-active:before {
  border-color: #fff;
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_7"] .deznav .metismenu ul a:before {
  background: #fff;
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu a,
[data-sibebarbg="color_7"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.8);
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu li ul a:hover,
[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu li ul a:focus,
[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
[data-sibebarbg="color_7"] .deznav .metismenu li ul a:hover,
[data-sibebarbg="color_7"] .deznav .metismenu li ul a:focus,
[data-sibebarbg="color_7"] .deznav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sibebarbg="color_7"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_7"] .deznav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_7"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"] .deznav .metismenu>li:hover>a {
  background: #ffce3a !important;
  box-shadow: none;
  color: #fff !important;
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu>li:hover>a i,
[data-sibebarbg="color_7"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_7"] .deznav .metismenu>li:hover>a i {
  color: #fff !important;
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_7"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85);
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .header-profile>a.nav-link,
[data-sibebarbg="color_7"] .deznav .header-profile>a.nav-link {
  border-color: rgba(255, 255, 255, 0.3);
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info span,
[data-sibebarbg="color_7"] .deznav .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info small,
[data-sibebarbg="color_7"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info .small,
[data-sibebarbg="color_7"] .deznav .header-profile>a.nav-link .header-info small,
[data-sibebarbg="color_7"] .deznav .header-profile>a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.8);
}

[data-sibebarbg="color_7"][data-theme-version="dark"] .copyright,
[data-sibebarbg="color_7"] .copyright {
  color: #fff;
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_7"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before {
  background: rgba(0, 0, 0, 0.5);
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a,
[data-sibebarbg="color_7"][data-sibebarbg="color_8"] .deznav .metismenu a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_7"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_7"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_7"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active {
  color: #000;
  /* background:rgba($black,0.15)!important; */
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_7"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(0, 0, 0, 0.85) transparent transparent rgba(0, 0, 0, 0.85);
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li>a,
[data-sibebarbg="color_7"][data-sibebarbg="color_8"] .deznav .metismenu>li>a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_7"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_7"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a {
  background: rgba(0, 0, 0, 0.6) !important;
  box-shadow: none;
  color: #fff !important;
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_7"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .copyright p,
[data-sibebarbg="color_7"][data-sibebarbg="color_8"] .deznav .copyright p {
  color: rgba(0, 0, 0, 0.6);
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .book-box,
[data-sibebarbg="color_7"][data-sibebarbg="color_8"] .deznav .book-box {
  background: rgba(0, 0, 0, 0.4);
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info span,
[data-sibebarbg="color_7"][data-sibebarbg="color_8"] .deznav .header-info span {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_7"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_7"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info .small,
[data-sibebarbg="color_7"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_7"][data-sibebarbg="color_8"] .deznav .header-info .small {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_8"][data-theme-version="dark"],
[data-sibebarbg="color_8"] {
  --sidebar-bg: #fff;
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_8"] .menu-toggle .deznav .metismenu li>ul {
  background: #fff !important;
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_8"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_8"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sibebarbg="color_8"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sibebarbg="color_8"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sibebarbg="color_8"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_8"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul {
  background-color: white;
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_8"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_8"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_8"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_8"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_8"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a {
  background-color: white !important;
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover,
[data-sibebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i,
[data-sibebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i {
  background: white !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i,
[data-sibebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i {
  background: white !important;
  color: #fff !important;
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before {
  background: rgba(0, 0, 0, 0.5);
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a,
[data-sibebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active {
  color: #000 !important;
  /* background:rgba($black,0.15)!important; */
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(0, 0, 0, 0.85) transparent transparent rgba(0, 0, 0, 0.85);
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a,
[data-sibebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a {
  color: var(--primary);
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i {
  color: rgba(0, 0, 0, 0.6) !important;
  background: var(--rgba-primary-1) !important;
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a {
  background: transparent;
  box-shadow: none;
  color: var(--primary) !important;
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i {
  color: var(--primary) !important;
  background: var(--rgba-primary-1) !important;
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .copyright p,
[data-sibebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .copyright p {
  color: rgba(0, 0, 0, 0.6);
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .book-box,
[data-sibebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .book-box {
  background: rgba(0, 0, 0, 0.4);
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info span,
[data-sibebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info span {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info .small,
[data-sibebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info .small {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li a:hover,
[data-sibebarbg="color_8"][data-sidebar-style="icon-hover"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li>a>i,
[data-sibebarbg="color_8"][data-sidebar-style="icon-hover"] .deznav .metismenu>li>a>i {
  /* background: lighten($color: $color, $amount: 3%)!important; */
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_8"][data-sidebar-style="icon-hover"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"][data-sidebar-style="icon-hover"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: white !important;
  color: #fff;
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu ul a:hover:before,
[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu ul a:focus:before,
[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu ul a.mm-active:before,
[data-sibebarbg="color_8"] .deznav .metismenu ul a:hover:before,
[data-sibebarbg="color_8"] .deznav .metismenu ul a:focus:before,
[data-sibebarbg="color_8"] .deznav .metismenu ul a.mm-active:before {
  border-color: #fff;
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_8"] .deznav .metismenu ul a:before {
  background: #fff;
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu a,
[data-sibebarbg="color_8"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.8);
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu li ul a:hover,
[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu li ul a:focus,
[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
[data-sibebarbg="color_8"] .deznav .metismenu li ul a:hover,
[data-sibebarbg="color_8"] .deznav .metismenu li ul a:focus,
[data-sibebarbg="color_8"] .deznav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sibebarbg="color_8"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_8"] .deznav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"] .deznav .metismenu>li:hover>a {
  background: white !important;
  box-shadow: none;
  color: #fff !important;
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu>li:hover>a i,
[data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_8"] .deznav .metismenu>li:hover>a i {
  color: #fff !important;
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85);
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .header-profile>a.nav-link,
[data-sibebarbg="color_8"] .deznav .header-profile>a.nav-link {
  border-color: rgba(255, 255, 255, 0.3);
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info span,
[data-sibebarbg="color_8"] .deznav .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info small,
[data-sibebarbg="color_8"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info .small,
[data-sibebarbg="color_8"] .deznav .header-profile>a.nav-link .header-info small,
[data-sibebarbg="color_8"] .deznav .header-profile>a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.8);
}

[data-sibebarbg="color_8"][data-theme-version="dark"] .copyright,
[data-sibebarbg="color_8"] .copyright {
  color: #fff;
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_8"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before {
  background: rgba(0, 0, 0, 0.5);
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a,
[data-sibebarbg="color_8"][data-sibebarbg="color_8"] .deznav .metismenu a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_8"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_8"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_8"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active {
  color: #000;
  /* background:rgba($black,0.15)!important; */
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_8"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(0, 0, 0, 0.85) transparent transparent rgba(0, 0, 0, 0.85);
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li>a,
[data-sibebarbg="color_8"][data-sibebarbg="color_8"] .deznav .metismenu>li>a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_8"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_8"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a {
  background: rgba(0, 0, 0, 0.6) !important;
  box-shadow: none;
  color: #fff !important;
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_8"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .copyright p,
[data-sibebarbg="color_8"][data-sibebarbg="color_8"] .deznav .copyright p {
  color: rgba(0, 0, 0, 0.6);
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .book-box,
[data-sibebarbg="color_8"][data-sibebarbg="color_8"] .deznav .book-box {
  background: rgba(0, 0, 0, 0.4);
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info span,
[data-sibebarbg="color_8"][data-sibebarbg="color_8"] .deznav .header-info span {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_8"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_8"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info .small,
[data-sibebarbg="color_8"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_8"][data-sibebarbg="color_8"] .deznav .header-info .small {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_9"][data-theme-version="dark"],
[data-sibebarbg="color_9"] {
  --sidebar-bg: #20c997;
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_9"] .menu-toggle .deznav .metismenu li>ul {
  background: #20c997 !important;
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_9"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_9"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sibebarbg="color_9"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sibebarbg="color_9"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sibebarbg="color_9"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_9"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul {
  background-color: #3ce0af;
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_9"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_9"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_9"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_9"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_9"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a {
  background-color: #3ce0af !important;
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover,
[data-sibebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i,
[data-sibebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i {
  background: #22d6a1 !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i,
[data-sibebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i {
  background: #3ce0af !important;
  color: #fff !important;
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before {
  background: rgba(0, 0, 0, 0.5);
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a,
[data-sibebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active {
  color: #000 !important;
  /* background:rgba($black,0.15)!important; */
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(0, 0, 0, 0.85) transparent transparent rgba(0, 0, 0, 0.85);
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a,
[data-sibebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a {
  color: var(--primary);
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i {
  color: rgba(0, 0, 0, 0.6) !important;
  background: var(--rgba-primary-1) !important;
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a {
  background: transparent;
  box-shadow: none;
  color: var(--primary) !important;
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i {
  color: var(--primary) !important;
  background: var(--rgba-primary-1) !important;
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .copyright p,
[data-sibebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .copyright p {
  color: rgba(0, 0, 0, 0.6);
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .book-box,
[data-sibebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .book-box {
  background: rgba(0, 0, 0, 0.4);
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info span,
[data-sibebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info span {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info .small,
[data-sibebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info .small {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li a:hover,
[data-sibebarbg="color_9"][data-sidebar-style="icon-hover"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li>a>i,
[data-sibebarbg="color_9"][data-sidebar-style="icon-hover"] .deznav .metismenu>li>a>i {
  /* background: lighten($color: $color, $amount: 3%)!important; */
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_9"][data-sidebar-style="icon-hover"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"][data-sidebar-style="icon-hover"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: #3ce0af !important;
  color: #fff;
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu ul a:hover:before,
[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu ul a:focus:before,
[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu ul a.mm-active:before,
[data-sibebarbg="color_9"] .deznav .metismenu ul a:hover:before,
[data-sibebarbg="color_9"] .deznav .metismenu ul a:focus:before,
[data-sibebarbg="color_9"] .deznav .metismenu ul a.mm-active:before {
  border-color: #fff;
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_9"] .deznav .metismenu ul a:before {
  background: #fff;
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu a,
[data-sibebarbg="color_9"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.8);
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu li ul a:hover,
[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu li ul a:focus,
[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
[data-sibebarbg="color_9"] .deznav .metismenu li ul a:hover,
[data-sibebarbg="color_9"] .deznav .metismenu li ul a:focus,
[data-sibebarbg="color_9"] .deznav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sibebarbg="color_9"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_9"] .deznav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_9"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"] .deznav .metismenu>li:hover>a {
  background: #3ce0af !important;
  box-shadow: none;
  color: #fff !important;
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu>li:hover>a i,
[data-sibebarbg="color_9"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_9"] .deznav .metismenu>li:hover>a i {
  color: #fff !important;
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_9"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85);
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .header-profile>a.nav-link,
[data-sibebarbg="color_9"] .deznav .header-profile>a.nav-link {
  border-color: rgba(255, 255, 255, 0.3);
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info span,
[data-sibebarbg="color_9"] .deznav .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info small,
[data-sibebarbg="color_9"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info .small,
[data-sibebarbg="color_9"] .deznav .header-profile>a.nav-link .header-info small,
[data-sibebarbg="color_9"] .deznav .header-profile>a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.8);
}

[data-sibebarbg="color_9"][data-theme-version="dark"] .copyright,
[data-sibebarbg="color_9"] .copyright {
  color: #fff;
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_9"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before {
  background: rgba(0, 0, 0, 0.5);
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a,
[data-sibebarbg="color_9"][data-sibebarbg="color_8"] .deznav .metismenu a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_9"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_9"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_9"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active {
  color: #000;
  /* background:rgba($black,0.15)!important; */
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_9"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(0, 0, 0, 0.85) transparent transparent rgba(0, 0, 0, 0.85);
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li>a,
[data-sibebarbg="color_9"][data-sibebarbg="color_8"] .deznav .metismenu>li>a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_9"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_9"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a {
  background: rgba(0, 0, 0, 0.6) !important;
  box-shadow: none;
  color: #fff !important;
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_9"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .copyright p,
[data-sibebarbg="color_9"][data-sibebarbg="color_8"] .deznav .copyright p {
  color: rgba(0, 0, 0, 0.6);
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .book-box,
[data-sibebarbg="color_9"][data-sibebarbg="color_8"] .deznav .book-box {
  background: rgba(0, 0, 0, 0.4);
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info span,
[data-sibebarbg="color_9"][data-sibebarbg="color_8"] .deznav .header-info span {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_9"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_9"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info .small,
[data-sibebarbg="color_9"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_9"][data-sibebarbg="color_8"] .deznav .header-info .small {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_10"][data-theme-version="dark"],
[data-sibebarbg="color_10"] {
  --sidebar-bg: #17a2b8;
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_10"] .menu-toggle .deznav .metismenu li>ul {
  background: #17a2b8 !important;
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_10"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_10"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sibebarbg="color_10"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sibebarbg="color_10"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sibebarbg="color_10"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_10"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul {
  background-color: #1fc8e3;
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_10"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_10"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_10"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_10"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_10"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a {
  background-color: #1fc8e3 !important;
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover,
[data-sibebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i,
[data-sibebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i {
  background: #19aec6 !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i,
[data-sibebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i {
  background: #1fc8e3 !important;
  color: #fff !important;
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before {
  background: rgba(0, 0, 0, 0.5);
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a,
[data-sibebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active {
  color: #000 !important;
  /* background:rgba($black,0.15)!important; */
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(0, 0, 0, 0.85) transparent transparent rgba(0, 0, 0, 0.85);
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a,
[data-sibebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a {
  color: var(--primary);
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i {
  color: rgba(0, 0, 0, 0.6) !important;
  background: var(--rgba-primary-1) !important;
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a {
  background: transparent;
  box-shadow: none;
  color: var(--primary) !important;
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i {
  color: var(--primary) !important;
  background: var(--rgba-primary-1) !important;
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .copyright p,
[data-sibebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .copyright p {
  color: rgba(0, 0, 0, 0.6);
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .book-box,
[data-sibebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .book-box {
  background: rgba(0, 0, 0, 0.4);
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info span,
[data-sibebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info span {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info .small,
[data-sibebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info .small {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li a:hover,
[data-sibebarbg="color_10"][data-sidebar-style="icon-hover"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li>a>i,
[data-sibebarbg="color_10"][data-sidebar-style="icon-hover"] .deznav .metismenu>li>a>i {
  /* background: lighten($color: $color, $amount: 3%)!important; */
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_10"][data-sidebar-style="icon-hover"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"][data-sidebar-style="icon-hover"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: #1fc8e3 !important;
  color: #fff;
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu ul a:hover:before,
[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu ul a:focus:before,
[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu ul a.mm-active:before,
[data-sibebarbg="color_10"] .deznav .metismenu ul a:hover:before,
[data-sibebarbg="color_10"] .deznav .metismenu ul a:focus:before,
[data-sibebarbg="color_10"] .deznav .metismenu ul a.mm-active:before {
  border-color: #fff;
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_10"] .deznav .metismenu ul a:before {
  background: #fff;
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu a,
[data-sibebarbg="color_10"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.8);
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu li ul a:hover,
[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu li ul a:focus,
[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
[data-sibebarbg="color_10"] .deznav .metismenu li ul a:hover,
[data-sibebarbg="color_10"] .deznav .metismenu li ul a:focus,
[data-sibebarbg="color_10"] .deznav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sibebarbg="color_10"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_10"] .deznav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_10"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"] .deznav .metismenu>li:hover>a {
  background: #1fc8e3 !important;
  box-shadow: none;
  color: #fff !important;
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu>li:hover>a i,
[data-sibebarbg="color_10"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_10"] .deznav .metismenu>li:hover>a i {
  color: #fff !important;
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_10"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85);
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .header-profile>a.nav-link,
[data-sibebarbg="color_10"] .deznav .header-profile>a.nav-link {
  border-color: rgba(255, 255, 255, 0.3);
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info span,
[data-sibebarbg="color_10"] .deznav .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info small,
[data-sibebarbg="color_10"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info .small,
[data-sibebarbg="color_10"] .deznav .header-profile>a.nav-link .header-info small,
[data-sibebarbg="color_10"] .deznav .header-profile>a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.8);
}

[data-sibebarbg="color_10"][data-theme-version="dark"] .copyright,
[data-sibebarbg="color_10"] .copyright {
  color: #fff;
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_10"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before {
  background: rgba(0, 0, 0, 0.5);
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a,
[data-sibebarbg="color_10"][data-sibebarbg="color_8"] .deznav .metismenu a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_10"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_10"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_10"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active {
  color: #000;
  /* background:rgba($black,0.15)!important; */
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_10"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(0, 0, 0, 0.85) transparent transparent rgba(0, 0, 0, 0.85);
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li>a,
[data-sibebarbg="color_10"][data-sibebarbg="color_8"] .deznav .metismenu>li>a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_10"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_10"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a {
  background: rgba(0, 0, 0, 0.6) !important;
  box-shadow: none;
  color: #fff !important;
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_10"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .copyright p,
[data-sibebarbg="color_10"][data-sibebarbg="color_8"] .deznav .copyright p {
  color: rgba(0, 0, 0, 0.6);
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .book-box,
[data-sibebarbg="color_10"][data-sibebarbg="color_8"] .deznav .book-box {
  background: rgba(0, 0, 0, 0.4);
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info span,
[data-sibebarbg="color_10"][data-sibebarbg="color_8"] .deznav .header-info span {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_10"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_10"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info .small,
[data-sibebarbg="color_10"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_10"][data-sibebarbg="color_8"] .deznav .header-info .small {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_11"][data-theme-version="dark"],
[data-sibebarbg="color_11"] {
  --sidebar-bg: #94618E;
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_11"] .menu-toggle .deznav .metismenu li>ul {
  background: #94618E !important;
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_11"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_11"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sibebarbg="color_11"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sibebarbg="color_11"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sibebarbg="color_11"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_11"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul {
  background-color: #aa7ea5;
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_11"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_11"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_11"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_11"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_11"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a {
  background-color: #aa7ea5 !important;
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover,
[data-sibebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i,
[data-sibebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i {
  background: #9c6896 !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i,
[data-sibebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i {
  background: #aa7ea5 !important;
  color: #fff !important;
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before {
  background: rgba(0, 0, 0, 0.5);
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a,
[data-sibebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active {
  color: #000 !important;
  /* background:rgba($black,0.15)!important; */
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(0, 0, 0, 0.85) transparent transparent rgba(0, 0, 0, 0.85);
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a,
[data-sibebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a {
  color: var(--primary);
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i {
  color: rgba(0, 0, 0, 0.6) !important;
  background: var(--rgba-primary-1) !important;
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a {
  background: transparent;
  box-shadow: none;
  color: var(--primary) !important;
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i {
  color: var(--primary) !important;
  background: var(--rgba-primary-1) !important;
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .copyright p,
[data-sibebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .copyright p {
  color: rgba(0, 0, 0, 0.6);
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .book-box,
[data-sibebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .book-box {
  background: rgba(0, 0, 0, 0.4);
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info span,
[data-sibebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info span {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info .small,
[data-sibebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info .small {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li a:hover,
[data-sibebarbg="color_11"][data-sidebar-style="icon-hover"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li>a>i,
[data-sibebarbg="color_11"][data-sidebar-style="icon-hover"] .deznav .metismenu>li>a>i {
  /* background: lighten($color: $color, $amount: 3%)!important; */
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_11"][data-sidebar-style="icon-hover"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"][data-sidebar-style="icon-hover"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: #aa7ea5 !important;
  color: #fff;
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu ul a:hover:before,
[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu ul a:focus:before,
[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu ul a.mm-active:before,
[data-sibebarbg="color_11"] .deznav .metismenu ul a:hover:before,
[data-sibebarbg="color_11"] .deznav .metismenu ul a:focus:before,
[data-sibebarbg="color_11"] .deznav .metismenu ul a.mm-active:before {
  border-color: #fff;
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_11"] .deznav .metismenu ul a:before {
  background: #fff;
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu a,
[data-sibebarbg="color_11"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.8);
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu li ul a:hover,
[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu li ul a:focus,
[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
[data-sibebarbg="color_11"] .deznav .metismenu li ul a:hover,
[data-sibebarbg="color_11"] .deznav .metismenu li ul a:focus,
[data-sibebarbg="color_11"] .deznav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sibebarbg="color_11"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_11"] .deznav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_11"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"] .deznav .metismenu>li:hover>a {
  background: #aa7ea5 !important;
  box-shadow: none;
  color: #fff !important;
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu>li:hover>a i,
[data-sibebarbg="color_11"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_11"] .deznav .metismenu>li:hover>a i {
  color: #fff !important;
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_11"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85);
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .header-profile>a.nav-link,
[data-sibebarbg="color_11"] .deznav .header-profile>a.nav-link {
  border-color: rgba(255, 255, 255, 0.3);
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info span,
[data-sibebarbg="color_11"] .deznav .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info small,
[data-sibebarbg="color_11"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info .small,
[data-sibebarbg="color_11"] .deznav .header-profile>a.nav-link .header-info small,
[data-sibebarbg="color_11"] .deznav .header-profile>a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.8);
}

[data-sibebarbg="color_11"][data-theme-version="dark"] .copyright,
[data-sibebarbg="color_11"] .copyright {
  color: #fff;
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_11"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before {
  background: rgba(0, 0, 0, 0.5);
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a,
[data-sibebarbg="color_11"][data-sibebarbg="color_8"] .deznav .metismenu a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_11"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_11"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_11"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active {
  color: #000;
  /* background:rgba($black,0.15)!important; */
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_11"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(0, 0, 0, 0.85) transparent transparent rgba(0, 0, 0, 0.85);
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li>a,
[data-sibebarbg="color_11"][data-sibebarbg="color_8"] .deznav .metismenu>li>a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_11"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_11"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a {
  background: rgba(0, 0, 0, 0.6) !important;
  box-shadow: none;
  color: #fff !important;
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_11"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .copyright p,
[data-sibebarbg="color_11"][data-sibebarbg="color_8"] .deznav .copyright p {
  color: rgba(0, 0, 0, 0.6);
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .book-box,
[data-sibebarbg="color_11"][data-sibebarbg="color_8"] .deznav .book-box {
  background: rgba(0, 0, 0, 0.4);
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info span,
[data-sibebarbg="color_11"][data-sibebarbg="color_8"] .deznav .header-info span {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_11"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_11"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info .small,
[data-sibebarbg="color_11"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_11"][data-sibebarbg="color_8"] .deznav .header-info .small {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_12"][data-theme-version="dark"],
[data-sibebarbg="color_12"] {
  --sidebar-bg: #343a40;
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_12"] .menu-toggle .deznav .metismenu li>ul {
  background: #343a40 !important;
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_12"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_12"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sibebarbg="color_12"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sibebarbg="color_12"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sibebarbg="color_12"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_12"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul {
  background-color: #4b545c;
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_12"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_12"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_12"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_12"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_12"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a {
  background-color: #4b545c !important;
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover,
[data-sibebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i,
[data-sibebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i {
  background: #3b4248 !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i,
[data-sibebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i {
  background: #4b545c !important;
  color: #fff !important;
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before {
  background: rgba(0, 0, 0, 0.5);
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a,
[data-sibebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active {
  color: #000 !important;
  /* background:rgba($black,0.15)!important; */
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(0, 0, 0, 0.85) transparent transparent rgba(0, 0, 0, 0.85);
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a,
[data-sibebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a {
  color: var(--primary);
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i {
  color: rgba(0, 0, 0, 0.6) !important;
  background: var(--rgba-primary-1) !important;
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a {
  background: transparent;
  box-shadow: none;
  color: var(--primary) !important;
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i {
  color: var(--primary) !important;
  background: var(--rgba-primary-1) !important;
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .copyright p,
[data-sibebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .copyright p {
  color: rgba(0, 0, 0, 0.6);
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .book-box,
[data-sibebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .book-box {
  background: rgba(0, 0, 0, 0.4);
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info span,
[data-sibebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info span {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info .small,
[data-sibebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info .small {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li a:hover,
[data-sibebarbg="color_12"][data-sidebar-style="icon-hover"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li>a>i,
[data-sibebarbg="color_12"][data-sidebar-style="icon-hover"] .deznav .metismenu>li>a>i {
  /* background: lighten($color: $color, $amount: 3%)!important; */
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_12"][data-sidebar-style="icon-hover"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"][data-sidebar-style="icon-hover"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: #4b545c !important;
  color: #fff;
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu ul a:hover:before,
[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu ul a:focus:before,
[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu ul a.mm-active:before,
[data-sibebarbg="color_12"] .deznav .metismenu ul a:hover:before,
[data-sibebarbg="color_12"] .deznav .metismenu ul a:focus:before,
[data-sibebarbg="color_12"] .deznav .metismenu ul a.mm-active:before {
  border-color: #fff;
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_12"] .deznav .metismenu ul a:before {
  background: #fff;
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu a,
[data-sibebarbg="color_12"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.8);
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu li ul a:hover,
[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu li ul a:focus,
[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
[data-sibebarbg="color_12"] .deznav .metismenu li ul a:hover,
[data-sibebarbg="color_12"] .deznav .metismenu li ul a:focus,
[data-sibebarbg="color_12"] .deznav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sibebarbg="color_12"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_12"] .deznav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_12"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"] .deznav .metismenu>li:hover>a {
  background: #4b545c !important;
  box-shadow: none;
  color: #fff !important;
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu>li:hover>a i,
[data-sibebarbg="color_12"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_12"] .deznav .metismenu>li:hover>a i {
  color: #fff !important;
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_12"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85);
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .header-profile>a.nav-link,
[data-sibebarbg="color_12"] .deznav .header-profile>a.nav-link {
  border-color: rgba(255, 255, 255, 0.3);
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info span,
[data-sibebarbg="color_12"] .deznav .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info small,
[data-sibebarbg="color_12"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info .small,
[data-sibebarbg="color_12"] .deznav .header-profile>a.nav-link .header-info small,
[data-sibebarbg="color_12"] .deznav .header-profile>a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.8);
}

[data-sibebarbg="color_12"][data-theme-version="dark"] .copyright,
[data-sibebarbg="color_12"] .copyright {
  color: #fff;
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_12"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before {
  background: rgba(0, 0, 0, 0.5);
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a,
[data-sibebarbg="color_12"][data-sibebarbg="color_8"] .deznav .metismenu a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_12"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_12"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_12"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active {
  color: #000;
  /* background:rgba($black,0.15)!important; */
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_12"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(0, 0, 0, 0.85) transparent transparent rgba(0, 0, 0, 0.85);
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li>a,
[data-sibebarbg="color_12"][data-sibebarbg="color_8"] .deznav .metismenu>li>a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_12"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_12"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a {
  background: rgba(0, 0, 0, 0.6) !important;
  box-shadow: none;
  color: #fff !important;
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_12"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .copyright p,
[data-sibebarbg="color_12"][data-sibebarbg="color_8"] .deznav .copyright p {
  color: rgba(0, 0, 0, 0.6);
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .book-box,
[data-sibebarbg="color_12"][data-sibebarbg="color_8"] .deznav .book-box {
  background: rgba(0, 0, 0, 0.4);
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info span,
[data-sibebarbg="color_12"][data-sibebarbg="color_8"] .deznav .header-info span {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_12"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_12"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info .small,
[data-sibebarbg="color_12"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_12"][data-sibebarbg="color_8"] .deznav .header-info .small {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_13"][data-theme-version="dark"],
[data-sibebarbg="color_13"] {
  --sidebar-bg: #2a2a2a;
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_13"] .menu-toggle .deznav .metismenu li>ul {
  background: #2a2a2a !important;
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_13"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_13"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sibebarbg="color_13"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sibebarbg="color_13"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sibebarbg="color_13"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_13"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul {
  background-color: #444444;
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_13"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_13"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_13"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_13"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_13"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a {
  background-color: #444444 !important;
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover,
[data-sibebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i,
[data-sibebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i {
  background: #323232 !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i,
[data-sibebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i {
  background: #444444 !important;
  color: #fff !important;
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before {
  background: rgba(0, 0, 0, 0.5);
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a,
[data-sibebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active {
  color: #000 !important;
  /* background:rgba($black,0.15)!important; */
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(0, 0, 0, 0.85) transparent transparent rgba(0, 0, 0, 0.85);
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a,
[data-sibebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a {
  color: var(--primary);
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i {
  color: rgba(0, 0, 0, 0.6) !important;
  background: var(--rgba-primary-1) !important;
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a {
  background: transparent;
  box-shadow: none;
  color: var(--primary) !important;
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i {
  color: var(--primary) !important;
  background: var(--rgba-primary-1) !important;
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .copyright p,
[data-sibebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .copyright p {
  color: rgba(0, 0, 0, 0.6);
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .book-box,
[data-sibebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .book-box {
  background: rgba(0, 0, 0, 0.4);
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info span,
[data-sibebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info span {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info .small,
[data-sibebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info .small {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li a:hover,
[data-sibebarbg="color_13"][data-sidebar-style="icon-hover"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li>a>i,
[data-sibebarbg="color_13"][data-sidebar-style="icon-hover"] .deznav .metismenu>li>a>i {
  /* background: lighten($color: $color, $amount: 3%)!important; */
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_13"][data-sidebar-style="icon-hover"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"][data-sidebar-style="icon-hover"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: #444444 !important;
  color: #fff;
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu ul a:hover:before,
[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu ul a:focus:before,
[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu ul a.mm-active:before,
[data-sibebarbg="color_13"] .deznav .metismenu ul a:hover:before,
[data-sibebarbg="color_13"] .deznav .metismenu ul a:focus:before,
[data-sibebarbg="color_13"] .deznav .metismenu ul a.mm-active:before {
  border-color: #fff;
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_13"] .deznav .metismenu ul a:before {
  background: #fff;
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu a,
[data-sibebarbg="color_13"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.8);
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu li ul a:hover,
[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu li ul a:focus,
[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
[data-sibebarbg="color_13"] .deznav .metismenu li ul a:hover,
[data-sibebarbg="color_13"] .deznav .metismenu li ul a:focus,
[data-sibebarbg="color_13"] .deznav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sibebarbg="color_13"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_13"] .deznav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_13"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"] .deznav .metismenu>li:hover>a {
  background: #444444 !important;
  box-shadow: none;
  color: #fff !important;
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu>li:hover>a i,
[data-sibebarbg="color_13"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_13"] .deznav .metismenu>li:hover>a i {
  color: #fff !important;
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_13"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85);
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .header-profile>a.nav-link,
[data-sibebarbg="color_13"] .deznav .header-profile>a.nav-link {
  border-color: rgba(255, 255, 255, 0.3);
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info span,
[data-sibebarbg="color_13"] .deznav .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info small,
[data-sibebarbg="color_13"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info .small,
[data-sibebarbg="color_13"] .deznav .header-profile>a.nav-link .header-info small,
[data-sibebarbg="color_13"] .deznav .header-profile>a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.8);
}

[data-sibebarbg="color_13"][data-theme-version="dark"] .copyright,
[data-sibebarbg="color_13"] .copyright {
  color: #fff;
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_13"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before {
  background: rgba(0, 0, 0, 0.5);
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a,
[data-sibebarbg="color_13"][data-sibebarbg="color_8"] .deznav .metismenu a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_13"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_13"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_13"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active {
  color: #000;
  /* background:rgba($black,0.15)!important; */
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_13"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(0, 0, 0, 0.85) transparent transparent rgba(0, 0, 0, 0.85);
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li>a,
[data-sibebarbg="color_13"][data-sibebarbg="color_8"] .deznav .metismenu>li>a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_13"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_13"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a {
  background: rgba(0, 0, 0, 0.6) !important;
  box-shadow: none;
  color: #fff !important;
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_13"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .copyright p,
[data-sibebarbg="color_13"][data-sibebarbg="color_8"] .deznav .copyright p {
  color: rgba(0, 0, 0, 0.6);
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .book-box,
[data-sibebarbg="color_13"][data-sibebarbg="color_8"] .deznav .book-box {
  background: rgba(0, 0, 0, 0.4);
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info span,
[data-sibebarbg="color_13"][data-sibebarbg="color_8"] .deznav .header-info span {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_13"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_13"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info .small,
[data-sibebarbg="color_13"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_13"][data-sibebarbg="color_8"] .deznav .header-info .small {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_14"][data-theme-version="dark"],
[data-sibebarbg="color_14"] {
  --sidebar-bg: #4885ed;
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_14"] .menu-toggle .deznav .metismenu li>ul {
  background: #4885ed !important;
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_14"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_14"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sibebarbg="color_14"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sibebarbg="color_14"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sibebarbg="color_14"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_14"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul {
  background-color: #76a4f2;
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_14"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_14"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_14"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_14"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_14"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a {
  background-color: #76a4f2 !important;
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover,
[data-sibebarbg="color_14"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i,
[data-sibebarbg="color_14"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i {
  background: #568eee !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_14"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i,
[data-sibebarbg="color_14"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_14"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i {
  background: #76a4f2 !important;
  color: #fff !important;
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_14"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before {
  background: rgba(0, 0, 0, 0.5);
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a,
[data-sibebarbg="color_14"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_14"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_14"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_14"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active {
  color: #000 !important;
  /* background:rgba($black,0.15)!important; */
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_14"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(0, 0, 0, 0.85) transparent transparent rgba(0, 0, 0, 0.85);
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a,
[data-sibebarbg="color_14"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a {
  color: var(--primary);
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_14"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i {
  color: rgba(0, 0, 0, 0.6) !important;
  background: var(--rgba-primary-1) !important;
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a {
  background: transparent;
  box-shadow: none;
  color: var(--primary) !important;
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_14"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i {
  color: var(--primary) !important;
  background: var(--rgba-primary-1) !important;
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .copyright p,
[data-sibebarbg="color_14"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .copyright p {
  color: rgba(0, 0, 0, 0.6);
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .book-box,
[data-sibebarbg="color_14"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .book-box {
  background: rgba(0, 0, 0, 0.4);
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info span,
[data-sibebarbg="color_14"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info span {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info .small,
[data-sibebarbg="color_14"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_14"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info .small {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li a:hover,
[data-sibebarbg="color_14"][data-sidebar-style="icon-hover"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li>a>i,
[data-sibebarbg="color_14"][data-sidebar-style="icon-hover"] .deznav .metismenu>li>a>i {
  /* background: lighten($color: $color, $amount: 3%)!important; */
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_14"][data-sidebar-style="icon-hover"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"][data-sidebar-style="icon-hover"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: #76a4f2 !important;
  color: #fff;
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu ul a:hover:before,
[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu ul a:focus:before,
[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu ul a.mm-active:before,
[data-sibebarbg="color_14"] .deznav .metismenu ul a:hover:before,
[data-sibebarbg="color_14"] .deznav .metismenu ul a:focus:before,
[data-sibebarbg="color_14"] .deznav .metismenu ul a.mm-active:before {
  border-color: #fff;
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_14"] .deznav .metismenu ul a:before {
  background: #fff;
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu a,
[data-sibebarbg="color_14"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.8);
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu li ul a:hover,
[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu li ul a:focus,
[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
[data-sibebarbg="color_14"] .deznav .metismenu li ul a:hover,
[data-sibebarbg="color_14"] .deznav .metismenu li ul a:focus,
[data-sibebarbg="color_14"] .deznav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sibebarbg="color_14"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_14"] .deznav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_14"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"] .deznav .metismenu>li:hover>a {
  background: #76a4f2 !important;
  box-shadow: none;
  color: #fff !important;
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu>li:hover>a i,
[data-sibebarbg="color_14"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_14"] .deznav .metismenu>li:hover>a i {
  color: #fff !important;
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_14"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85);
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .header-profile>a.nav-link,
[data-sibebarbg="color_14"] .deznav .header-profile>a.nav-link {
  border-color: rgba(255, 255, 255, 0.3);
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info span,
[data-sibebarbg="color_14"] .deznav .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info small,
[data-sibebarbg="color_14"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info .small,
[data-sibebarbg="color_14"] .deznav .header-profile>a.nav-link .header-info small,
[data-sibebarbg="color_14"] .deznav .header-profile>a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.8);
}

[data-sibebarbg="color_14"][data-theme-version="dark"] .copyright,
[data-sibebarbg="color_14"] .copyright {
  color: #fff;
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_14"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before {
  background: rgba(0, 0, 0, 0.5);
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a,
[data-sibebarbg="color_14"][data-sibebarbg="color_8"] .deznav .metismenu a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_14"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_14"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_14"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active {
  color: #000;
  /* background:rgba($black,0.15)!important; */
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_14"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(0, 0, 0, 0.85) transparent transparent rgba(0, 0, 0, 0.85);
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li>a,
[data-sibebarbg="color_14"][data-sibebarbg="color_8"] .deznav .metismenu>li>a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_14"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_14"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a {
  background: rgba(0, 0, 0, 0.6) !important;
  box-shadow: none;
  color: #fff !important;
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_14"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .copyright p,
[data-sibebarbg="color_14"][data-sibebarbg="color_8"] .deznav .copyright p {
  color: rgba(0, 0, 0, 0.6);
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .book-box,
[data-sibebarbg="color_14"][data-sibebarbg="color_8"] .deznav .book-box {
  background: rgba(0, 0, 0, 0.4);
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info span,
[data-sibebarbg="color_14"][data-sibebarbg="color_8"] .deznav .header-info span {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_14"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_14"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info .small,
[data-sibebarbg="color_14"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_14"][data-sibebarbg="color_8"] .deznav .header-info .small {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_15"][data-theme-version="dark"],
[data-sibebarbg="color_15"] {
  --sidebar-bg: #4cb32b;
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .menu-toggle .deznav .metismenu li>ul,
[data-sibebarbg="color_15"] .menu-toggle .deznav .metismenu li>ul {
  background: #4cb32b !important;
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_15"][data-sidebar-style="full"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_15"][data-sidebar-style="mini"] .deznav .metismenu li ul,
[data-sibebarbg="color_15"][data-sidebar-style="modern"] .deznav .metismenu li ul,
[data-sibebarbg="color_15"][data-sidebar-style="compact"] .deznav .metismenu li ul,
[data-sibebarbg="color_15"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu li ul,
[data-sibebarbg="color_15"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu li ul {
  background-color: #63d140;
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_15"][data-sidebar-style="mini"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"][data-sidebar-style="mini"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_15"][data-sidebar-style="modern"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"][data-sidebar-style="modern"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_15"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_15"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_15"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"][data-sidebar-style="full"][data-layout="horizontal"] .deznav .metismenu>li:hover>a {
  background-color: #63d140 !important;
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover,
[data-sibebarbg="color_15"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i,
[data-sibebarbg="color_15"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li>a>i {
  background: #51bf2e !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_15"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i,
[data-sibebarbg="color_15"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_15"][data-sidebar-style="compact"][data-layout="vertical"] .deznav .metismenu>li:hover>a i {
  background: #63d140 !important;
  color: #fff !important;
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_15"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before {
  background: rgba(0, 0, 0, 0.5);
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a,
[data-sibebarbg="color_15"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_15"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_15"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_15"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active {
  color: #000 !important;
  /* background:rgba($black,0.15)!important; */
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_15"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(0, 0, 0, 0.85) transparent transparent rgba(0, 0, 0, 0.85);
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a,
[data-sibebarbg="color_15"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a {
  color: var(--primary);
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_15"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i {
  color: rgba(0, 0, 0, 0.6) !important;
  background: var(--rgba-primary-1) !important;
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a {
  background: transparent;
  box-shadow: none;
  color: var(--primary) !important;
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_15"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i {
  color: var(--primary) !important;
  background: var(--rgba-primary-1) !important;
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .copyright p,
[data-sibebarbg="color_15"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .copyright p {
  color: rgba(0, 0, 0, 0.6);
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .book-box,
[data-sibebarbg="color_15"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .book-box {
  background: rgba(0, 0, 0, 0.4);
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info span,
[data-sibebarbg="color_15"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info span {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info .small,
[data-sibebarbg="color_15"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_15"][data-sidebar-style="compact"][data-layout="vertical"][data-sibebarbg="color_8"] .deznav .header-info .small {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li a:hover,
[data-sibebarbg="color_15"][data-sidebar-style="icon-hover"] .deznav .metismenu>li a:hover {
  color: #fff;
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li>a>i,
[data-sibebarbg="color_15"][data-sidebar-style="icon-hover"] .deznav .metismenu>li>a>i {
  /* background: lighten($color: $color, $amount: 3%)!important; */
  color: rgba(255, 255, 255, 0.7);
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="icon-hover"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_15"][data-sidebar-style="icon-hover"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"][data-sidebar-style="icon-hover"] .deznav .metismenu>li:hover>a {
  box-shadow: none;
  background: #63d140 !important;
  color: #fff;
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu ul a:hover:before,
[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu ul a:focus:before,
[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu ul a.mm-active:before,
[data-sibebarbg="color_15"] .deznav .metismenu ul a:hover:before,
[data-sibebarbg="color_15"] .deznav .metismenu ul a:focus:before,
[data-sibebarbg="color_15"] .deznav .metismenu ul a.mm-active:before {
  border-color: #fff;
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_15"] .deznav .metismenu ul a:before {
  background: #fff;
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu a,
[data-sibebarbg="color_15"] .deznav .metismenu a {
  color: rgba(255, 255, 255, 0.8);
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu li ul a:hover,
[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu li ul a:focus,
[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu li ul a.mm-active,
[data-sibebarbg="color_15"] .deznav .metismenu li ul a:hover,
[data-sibebarbg="color_15"] .deznav .metismenu li ul a:focus,
[data-sibebarbg="color_15"] .deznav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu>li>a,
[data-sibebarbg="color_15"] .deznav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_15"] .deznav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-sibebarbg="color_15"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"] .deznav .metismenu>li:hover>a {
  background: #63d140 !important;
  box-shadow: none;
  color: #fff !important;
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu>li:hover>a i,
[data-sibebarbg="color_15"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_15"] .deznav .metismenu>li:hover>a i {
  color: #fff !important;
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_15"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85);
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .header-profile>a.nav-link,
[data-sibebarbg="color_15"] .deznav .header-profile>a.nav-link {
  border-color: rgba(255, 255, 255, 0.3);
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info span,
[data-sibebarbg="color_15"] .deznav .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info small,
[data-sibebarbg="color_15"][data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info .small,
[data-sibebarbg="color_15"] .deznav .header-profile>a.nav-link .header-info small,
[data-sibebarbg="color_15"] .deznav .header-profile>a.nav-link .header-info .small {
  color: rgba(255, 255, 255, 0.8);
}

[data-sibebarbg="color_15"][data-theme-version="dark"] .copyright,
[data-sibebarbg="color_15"] .copyright {
  color: #fff;
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before,
[data-sibebarbg="color_15"][data-sibebarbg="color_8"] .deznav .metismenu ul a:before {
  background: rgba(0, 0, 0, 0.5);
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a,
[data-sibebarbg="color_15"][data-sibebarbg="color_8"] .deznav .metismenu a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active,
[data-sibebarbg="color_15"][data-sibebarbg="color_8"] .deznav .metismenu a:hover,
[data-sibebarbg="color_15"][data-sibebarbg="color_8"] .deznav .metismenu a:focus,
[data-sibebarbg="color_15"][data-sibebarbg="color_8"] .deznav .metismenu a.mm-active {
  color: #000;
  /* background:rgba($black,0.15)!important; */
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after,
[data-sibebarbg="color_15"][data-sibebarbg="color_8"] .deznav .metismenu .has-arrow:after {
  border-color: rgba(0, 0, 0, 0.85) transparent transparent rgba(0, 0, 0, 0.85);
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li>a,
[data-sibebarbg="color_15"][data-sibebarbg="color_8"] .deznav .metismenu>li>a {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i,
[data-sibebarbg="color_15"][data-sibebarbg="color_8"] .deznav .metismenu>li>a i {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a,
[data-sibebarbg="color_15"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a {
  background: rgba(0, 0, 0, 0.6) !important;
  box-shadow: none;
  color: #fff !important;
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i,
[data-sibebarbg="color_15"][data-sibebarbg="color_8"] .deznav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .copyright p,
[data-sibebarbg="color_15"][data-sibebarbg="color_8"] .deznav .copyright p {
  color: rgba(0, 0, 0, 0.6);
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .book-box,
[data-sibebarbg="color_15"][data-sibebarbg="color_8"] .deznav .book-box {
  background: rgba(0, 0, 0, 0.4);
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info span,
[data-sibebarbg="color_15"][data-sibebarbg="color_8"] .deznav .header-info span {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-sibebarbg="color_15"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_15"][data-theme-version="dark"][data-sibebarbg="color_8"] .deznav .header-info .small,
[data-sibebarbg="color_15"][data-sibebarbg="color_8"] .deznav .header-info small,
[data-sibebarbg="color_15"][data-sibebarbg="color_8"] .deznav .header-info .small {
  color: rgba(0, 0, 0, 0.6) !important;
}

[data-headerbg="color_2"][data-theme-version="dark"],
[data-headerbg="color_2"] {
  --headerbg: #6610f2;
}

[data-headerbg="color_2"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_2"][data-theme-version="dark"] .search-area .form-control,
[data-headerbg="color_2"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_2"] .search-area .input-group-text,
[data-headerbg="color_2"] .search-area .form-control,
[data-headerbg="color_2"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-headerbg="color_2"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_2"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_2"] .header-right .search-area .form-control,
[data-headerbg="color_2"] .header-right .search-area .input-group-text {
  background-color: #8540f5;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_2"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_2"] .header-right .search-area .form-control i,
[data-headerbg="color_2"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right .notification_dropdown .ai-icon,
[data-headerbg="color_2"] .header-right .notification_dropdown .ai-icon {
  background-color: #8540f5;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right .notification_dropdown .ai-icon svg path,
[data-headerbg="color_2"] .header-right .notification_dropdown .ai-icon svg path {
  fill: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_2"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_2"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info .small,
[data-headerbg="color_2"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_2"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_2"] .header-right .header-profile .nav-link .header-info .small {
  color: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_2"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .input-group-text,
[data-headerbg="color_2"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .form-control,
[data-headerbg="color_2"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .form-control::placeholder,
[data-headerbg="color_2"][data-headerbg="color_8"] .search-area .input-group-text,
[data-headerbg="color_2"][data-headerbg="color_8"] .search-area .form-control,
[data-headerbg="color_2"][data-headerbg="color_8"] .search-area .form-control::placeholder {
  color: #000;
}

[data-headerbg="color_2"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .form-control,
[data-headerbg="color_2"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .input-group-text,
[data-headerbg="color_2"][data-headerbg="color_8"] .header-right .search-area .form-control,
[data-headerbg="color_2"][data-headerbg="color_8"] .header-right .search-area .input-group-text {
  background: #F1F1F1;
}

[data-headerbg="color_2"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .form-control i,
[data-headerbg="color_2"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .input-group-text i,
[data-headerbg="color_2"][data-headerbg="color_8"] .header-right .search-area .form-control i,
[data-headerbg="color_2"][data-headerbg="color_8"] .header-right .search-area .input-group-text i {
  color: #000;
}

[data-headerbg="color_2"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon,
[data-headerbg="color_2"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon {
  background-color: #F1F1F1;
}

[data-headerbg="color_2"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon svg path,
[data-headerbg="color_2"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon svg path {
  fill: #000;
}

[data-headerbg="color_2"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_2"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_2"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info .small,
[data-headerbg="color_2"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_2"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_2"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info .small {
  color: #000;
}

[data-headerbg="color_2"][data-theme-version="dark"][data-headerbg="color_8"] .header-left .dashboard_bar,
[data-headerbg="color_2"][data-headerbg="color_8"] .header-left .dashboard_bar {
  color: #000;
}

[data-headerbg="color_3"][data-theme-version="dark"],
[data-headerbg="color_3"] {
  --headerbg: #1362FC;
}

[data-headerbg="color_3"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_3"][data-theme-version="dark"] .search-area .form-control,
[data-headerbg="color_3"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_3"] .search-area .input-group-text,
[data-headerbg="color_3"] .search-area .form-control,
[data-headerbg="color_3"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-headerbg="color_3"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_3"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_3"] .header-right .search-area .form-control,
[data-headerbg="color_3"] .header-right .search-area .input-group-text {
  background-color: #4584fd;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_3"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_3"] .header-right .search-area .form-control i,
[data-headerbg="color_3"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right .notification_dropdown .ai-icon,
[data-headerbg="color_3"] .header-right .notification_dropdown .ai-icon {
  background-color: #4584fd;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right .notification_dropdown .ai-icon svg path,
[data-headerbg="color_3"] .header-right .notification_dropdown .ai-icon svg path {
  fill: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_3"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_3"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info .small,
[data-headerbg="color_3"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_3"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_3"] .header-right .header-profile .nav-link .header-info .small {
  color: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_3"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .input-group-text,
[data-headerbg="color_3"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .form-control,
[data-headerbg="color_3"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .form-control::placeholder,
[data-headerbg="color_3"][data-headerbg="color_8"] .search-area .input-group-text,
[data-headerbg="color_3"][data-headerbg="color_8"] .search-area .form-control,
[data-headerbg="color_3"][data-headerbg="color_8"] .search-area .form-control::placeholder {
  color: #000;
}

[data-headerbg="color_3"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .form-control,
[data-headerbg="color_3"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .input-group-text,
[data-headerbg="color_3"][data-headerbg="color_8"] .header-right .search-area .form-control,
[data-headerbg="color_3"][data-headerbg="color_8"] .header-right .search-area .input-group-text {
  background: #F1F1F1;
}

[data-headerbg="color_3"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .form-control i,
[data-headerbg="color_3"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .input-group-text i,
[data-headerbg="color_3"][data-headerbg="color_8"] .header-right .search-area .form-control i,
[data-headerbg="color_3"][data-headerbg="color_8"] .header-right .search-area .input-group-text i {
  color: #000;
}

[data-headerbg="color_3"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon,
[data-headerbg="color_3"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon {
  background-color: #F1F1F1;
}

[data-headerbg="color_3"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon svg path,
[data-headerbg="color_3"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon svg path {
  fill: #000;
}

[data-headerbg="color_3"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_3"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_3"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info .small,
[data-headerbg="color_3"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_3"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_3"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info .small {
  color: #000;
}

[data-headerbg="color_3"][data-theme-version="dark"][data-headerbg="color_8"] .header-left .dashboard_bar,
[data-headerbg="color_3"][data-headerbg="color_8"] .header-left .dashboard_bar {
  color: #000;
}

[data-headerbg="color_4"][data-theme-version="dark"],
[data-headerbg="color_4"] {
  --headerbg: #4d06a5;
}

[data-headerbg="color_4"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_4"][data-theme-version="dark"] .search-area .form-control,
[data-headerbg="color_4"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_4"] .search-area .input-group-text,
[data-headerbg="color_4"] .search-area .form-control,
[data-headerbg="color_4"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-headerbg="color_4"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_4"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_4"] .header-right .search-area .form-control,
[data-headerbg="color_4"] .header-right .search-area .input-group-text {
  background-color: #6408d6;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_4"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_4"] .header-right .search-area .form-control i,
[data-headerbg="color_4"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right .notification_dropdown .ai-icon,
[data-headerbg="color_4"] .header-right .notification_dropdown .ai-icon {
  background-color: #6408d6;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right .notification_dropdown .ai-icon svg path,
[data-headerbg="color_4"] .header-right .notification_dropdown .ai-icon svg path {
  fill: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_4"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_4"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info .small,
[data-headerbg="color_4"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_4"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_4"] .header-right .header-profile .nav-link .header-info .small {
  color: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_4"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .input-group-text,
[data-headerbg="color_4"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .form-control,
[data-headerbg="color_4"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .form-control::placeholder,
[data-headerbg="color_4"][data-headerbg="color_8"] .search-area .input-group-text,
[data-headerbg="color_4"][data-headerbg="color_8"] .search-area .form-control,
[data-headerbg="color_4"][data-headerbg="color_8"] .search-area .form-control::placeholder {
  color: #000;
}

[data-headerbg="color_4"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .form-control,
[data-headerbg="color_4"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .input-group-text,
[data-headerbg="color_4"][data-headerbg="color_8"] .header-right .search-area .form-control,
[data-headerbg="color_4"][data-headerbg="color_8"] .header-right .search-area .input-group-text {
  background: #F1F1F1;
}

[data-headerbg="color_4"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .form-control i,
[data-headerbg="color_4"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .input-group-text i,
[data-headerbg="color_4"][data-headerbg="color_8"] .header-right .search-area .form-control i,
[data-headerbg="color_4"][data-headerbg="color_8"] .header-right .search-area .input-group-text i {
  color: #000;
}

[data-headerbg="color_4"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon,
[data-headerbg="color_4"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon {
  background-color: #F1F1F1;
}

[data-headerbg="color_4"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon svg path,
[data-headerbg="color_4"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon svg path {
  fill: #000;
}

[data-headerbg="color_4"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_4"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_4"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info .small,
[data-headerbg="color_4"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_4"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_4"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info .small {
  color: #000;
}

[data-headerbg="color_4"][data-theme-version="dark"][data-headerbg="color_8"] .header-left .dashboard_bar,
[data-headerbg="color_4"][data-headerbg="color_8"] .header-left .dashboard_bar {
  color: #000;
}

[data-headerbg="color_5"][data-theme-version="dark"],
[data-headerbg="color_5"] {
  --headerbg: #dc3545;
}

[data-headerbg="color_5"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_5"][data-theme-version="dark"] .search-area .form-control,
[data-headerbg="color_5"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_5"] .search-area .input-group-text,
[data-headerbg="color_5"] .search-area .form-control,
[data-headerbg="color_5"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-headerbg="color_5"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_5"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_5"] .header-right .search-area .form-control,
[data-headerbg="color_5"] .header-right .search-area .input-group-text {
  background-color: #e4606d;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_5"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_5"] .header-right .search-area .form-control i,
[data-headerbg="color_5"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right .notification_dropdown .ai-icon,
[data-headerbg="color_5"] .header-right .notification_dropdown .ai-icon {
  background-color: #e4606d;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right .notification_dropdown .ai-icon svg path,
[data-headerbg="color_5"] .header-right .notification_dropdown .ai-icon svg path {
  fill: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_5"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_5"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info .small,
[data-headerbg="color_5"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_5"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_5"] .header-right .header-profile .nav-link .header-info .small {
  color: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_5"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .input-group-text,
[data-headerbg="color_5"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .form-control,
[data-headerbg="color_5"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .form-control::placeholder,
[data-headerbg="color_5"][data-headerbg="color_8"] .search-area .input-group-text,
[data-headerbg="color_5"][data-headerbg="color_8"] .search-area .form-control,
[data-headerbg="color_5"][data-headerbg="color_8"] .search-area .form-control::placeholder {
  color: #000;
}

[data-headerbg="color_5"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .form-control,
[data-headerbg="color_5"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .input-group-text,
[data-headerbg="color_5"][data-headerbg="color_8"] .header-right .search-area .form-control,
[data-headerbg="color_5"][data-headerbg="color_8"] .header-right .search-area .input-group-text {
  background: #F1F1F1;
}

[data-headerbg="color_5"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .form-control i,
[data-headerbg="color_5"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .input-group-text i,
[data-headerbg="color_5"][data-headerbg="color_8"] .header-right .search-area .form-control i,
[data-headerbg="color_5"][data-headerbg="color_8"] .header-right .search-area .input-group-text i {
  color: #000;
}

[data-headerbg="color_5"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon,
[data-headerbg="color_5"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon {
  background-color: #F1F1F1;
}

[data-headerbg="color_5"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon svg path,
[data-headerbg="color_5"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon svg path {
  fill: #000;
}

[data-headerbg="color_5"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_5"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_5"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info .small,
[data-headerbg="color_5"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_5"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_5"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info .small {
  color: #000;
}

[data-headerbg="color_5"][data-theme-version="dark"][data-headerbg="color_8"] .header-left .dashboard_bar,
[data-headerbg="color_5"][data-headerbg="color_8"] .header-left .dashboard_bar {
  color: #000;
}

[data-headerbg="color_6"][data-theme-version="dark"],
[data-headerbg="color_6"] {
  --headerbg: #fd7e14;
}

[data-headerbg="color_6"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_6"][data-theme-version="dark"] .search-area .form-control,
[data-headerbg="color_6"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_6"] .search-area .input-group-text,
[data-headerbg="color_6"] .search-area .form-control,
[data-headerbg="color_6"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-headerbg="color_6"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_6"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_6"] .header-right .search-area .form-control,
[data-headerbg="color_6"] .header-right .search-area .input-group-text {
  background-color: #fd9a47;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_6"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_6"] .header-right .search-area .form-control i,
[data-headerbg="color_6"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right .notification_dropdown .ai-icon,
[data-headerbg="color_6"] .header-right .notification_dropdown .ai-icon {
  background-color: #fd9a47;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right .notification_dropdown .ai-icon svg path,
[data-headerbg="color_6"] .header-right .notification_dropdown .ai-icon svg path {
  fill: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_6"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_6"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info .small,
[data-headerbg="color_6"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_6"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_6"] .header-right .header-profile .nav-link .header-info .small {
  color: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_6"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .input-group-text,
[data-headerbg="color_6"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .form-control,
[data-headerbg="color_6"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .form-control::placeholder,
[data-headerbg="color_6"][data-headerbg="color_8"] .search-area .input-group-text,
[data-headerbg="color_6"][data-headerbg="color_8"] .search-area .form-control,
[data-headerbg="color_6"][data-headerbg="color_8"] .search-area .form-control::placeholder {
  color: #000;
}

[data-headerbg="color_6"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .form-control,
[data-headerbg="color_6"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .input-group-text,
[data-headerbg="color_6"][data-headerbg="color_8"] .header-right .search-area .form-control,
[data-headerbg="color_6"][data-headerbg="color_8"] .header-right .search-area .input-group-text {
  background: #F1F1F1;
}

[data-headerbg="color_6"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .form-control i,
[data-headerbg="color_6"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .input-group-text i,
[data-headerbg="color_6"][data-headerbg="color_8"] .header-right .search-area .form-control i,
[data-headerbg="color_6"][data-headerbg="color_8"] .header-right .search-area .input-group-text i {
  color: #000;
}

[data-headerbg="color_6"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon,
[data-headerbg="color_6"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon {
  background-color: #F1F1F1;
}

[data-headerbg="color_6"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon svg path,
[data-headerbg="color_6"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon svg path {
  fill: #000;
}

[data-headerbg="color_6"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_6"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_6"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info .small,
[data-headerbg="color_6"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_6"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_6"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info .small {
  color: #000;
}

[data-headerbg="color_6"][data-theme-version="dark"][data-headerbg="color_8"] .header-left .dashboard_bar,
[data-headerbg="color_6"][data-headerbg="color_8"] .header-left .dashboard_bar {
  color: #000;
}

[data-headerbg="color_7"][data-theme-version="dark"],
[data-headerbg="color_7"] {
  --headerbg: #ffc107;
}

[data-headerbg="color_7"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_7"][data-theme-version="dark"] .search-area .form-control,
[data-headerbg="color_7"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_7"] .search-area .input-group-text,
[data-headerbg="color_7"] .search-area .form-control,
[data-headerbg="color_7"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-headerbg="color_7"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_7"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_7"] .header-right .search-area .form-control,
[data-headerbg="color_7"] .header-right .search-area .input-group-text {
  background-color: #ffce3a;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_7"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_7"] .header-right .search-area .form-control i,
[data-headerbg="color_7"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right .notification_dropdown .ai-icon,
[data-headerbg="color_7"] .header-right .notification_dropdown .ai-icon {
  background-color: #ffce3a;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right .notification_dropdown .ai-icon svg path,
[data-headerbg="color_7"] .header-right .notification_dropdown .ai-icon svg path {
  fill: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_7"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_7"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info .small,
[data-headerbg="color_7"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_7"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_7"] .header-right .header-profile .nav-link .header-info .small {
  color: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_7"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .input-group-text,
[data-headerbg="color_7"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .form-control,
[data-headerbg="color_7"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .form-control::placeholder,
[data-headerbg="color_7"][data-headerbg="color_8"] .search-area .input-group-text,
[data-headerbg="color_7"][data-headerbg="color_8"] .search-area .form-control,
[data-headerbg="color_7"][data-headerbg="color_8"] .search-area .form-control::placeholder {
  color: #000;
}

[data-headerbg="color_7"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .form-control,
[data-headerbg="color_7"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .input-group-text,
[data-headerbg="color_7"][data-headerbg="color_8"] .header-right .search-area .form-control,
[data-headerbg="color_7"][data-headerbg="color_8"] .header-right .search-area .input-group-text {
  background: #F1F1F1;
}

[data-headerbg="color_7"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .form-control i,
[data-headerbg="color_7"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .input-group-text i,
[data-headerbg="color_7"][data-headerbg="color_8"] .header-right .search-area .form-control i,
[data-headerbg="color_7"][data-headerbg="color_8"] .header-right .search-area .input-group-text i {
  color: #000;
}

[data-headerbg="color_7"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon,
[data-headerbg="color_7"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon {
  background-color: #F1F1F1;
}

[data-headerbg="color_7"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon svg path,
[data-headerbg="color_7"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon svg path {
  fill: #000;
}

[data-headerbg="color_7"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_7"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_7"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info .small,
[data-headerbg="color_7"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_7"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_7"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info .small {
  color: #000;
}

[data-headerbg="color_7"][data-theme-version="dark"][data-headerbg="color_8"] .header-left .dashboard_bar,
[data-headerbg="color_7"][data-headerbg="color_8"] .header-left .dashboard_bar {
  color: #000;
}

[data-headerbg="color_8"][data-theme-version="dark"],
[data-headerbg="color_8"] {
  --headerbg: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_8"][data-theme-version="dark"] .search-area .form-control,
[data-headerbg="color_8"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_8"] .search-area .input-group-text,
[data-headerbg="color_8"] .search-area .form-control,
[data-headerbg="color_8"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-headerbg="color_8"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_8"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_8"] .header-right .search-area .form-control,
[data-headerbg="color_8"] .header-right .search-area .input-group-text {
  background-color: white;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_8"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_8"] .header-right .search-area .form-control i,
[data-headerbg="color_8"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right .notification_dropdown .ai-icon,
[data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon {
  background-color: white;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right .notification_dropdown .ai-icon svg path,
[data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon svg path {
  fill: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_8"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_8"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info .small,
[data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info .small {
  color: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_8"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .input-group-text,
[data-headerbg="color_8"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .form-control,
[data-headerbg="color_8"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .form-control::placeholder,
[data-headerbg="color_8"][data-headerbg="color_8"] .search-area .input-group-text,
[data-headerbg="color_8"][data-headerbg="color_8"] .search-area .form-control,
[data-headerbg="color_8"][data-headerbg="color_8"] .search-area .form-control::placeholder {
  color: #000;
}

[data-headerbg="color_8"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .form-control,
[data-headerbg="color_8"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .input-group-text,
[data-headerbg="color_8"][data-headerbg="color_8"] .header-right .search-area .form-control,
[data-headerbg="color_8"][data-headerbg="color_8"] .header-right .search-area .input-group-text {
  background: #F1F1F1;
}

[data-headerbg="color_8"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .form-control i,
[data-headerbg="color_8"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .input-group-text i,
[data-headerbg="color_8"][data-headerbg="color_8"] .header-right .search-area .form-control i,
[data-headerbg="color_8"][data-headerbg="color_8"] .header-right .search-area .input-group-text i {
  color: #000;
}

[data-headerbg="color_8"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon,
[data-headerbg="color_8"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon {
  background-color: #F1F1F1;
}

[data-headerbg="color_8"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon svg path,
[data-headerbg="color_8"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon svg path {
  fill: #000;
}

[data-headerbg="color_8"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_8"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_8"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info .small,
[data-headerbg="color_8"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_8"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_8"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info .small {
  color: #000;
}

[data-headerbg="color_8"][data-theme-version="dark"][data-headerbg="color_8"] .header-left .dashboard_bar,
[data-headerbg="color_8"][data-headerbg="color_8"] .header-left .dashboard_bar {
  color: #000;
}

[data-headerbg="color_9"][data-theme-version="dark"],
[data-headerbg="color_9"] {
  --headerbg: #20c997;
}

[data-headerbg="color_9"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_9"][data-theme-version="dark"] .search-area .form-control,
[data-headerbg="color_9"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_9"] .search-area .input-group-text,
[data-headerbg="color_9"] .search-area .form-control,
[data-headerbg="color_9"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-headerbg="color_9"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_9"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_9"] .header-right .search-area .form-control,
[data-headerbg="color_9"] .header-right .search-area .input-group-text {
  background-color: #3ce0af;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_9"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_9"] .header-right .search-area .form-control i,
[data-headerbg="color_9"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right .notification_dropdown .ai-icon,
[data-headerbg="color_9"] .header-right .notification_dropdown .ai-icon {
  background-color: #3ce0af;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right .notification_dropdown .ai-icon svg path,
[data-headerbg="color_9"] .header-right .notification_dropdown .ai-icon svg path {
  fill: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_9"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_9"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info .small,
[data-headerbg="color_9"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_9"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_9"] .header-right .header-profile .nav-link .header-info .small {
  color: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_9"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .input-group-text,
[data-headerbg="color_9"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .form-control,
[data-headerbg="color_9"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .form-control::placeholder,
[data-headerbg="color_9"][data-headerbg="color_8"] .search-area .input-group-text,
[data-headerbg="color_9"][data-headerbg="color_8"] .search-area .form-control,
[data-headerbg="color_9"][data-headerbg="color_8"] .search-area .form-control::placeholder {
  color: #000;
}

[data-headerbg="color_9"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .form-control,
[data-headerbg="color_9"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .input-group-text,
[data-headerbg="color_9"][data-headerbg="color_8"] .header-right .search-area .form-control,
[data-headerbg="color_9"][data-headerbg="color_8"] .header-right .search-area .input-group-text {
  background: #F1F1F1;
}

[data-headerbg="color_9"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .form-control i,
[data-headerbg="color_9"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .input-group-text i,
[data-headerbg="color_9"][data-headerbg="color_8"] .header-right .search-area .form-control i,
[data-headerbg="color_9"][data-headerbg="color_8"] .header-right .search-area .input-group-text i {
  color: #000;
}

[data-headerbg="color_9"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon,
[data-headerbg="color_9"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon {
  background-color: #F1F1F1;
}

[data-headerbg="color_9"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon svg path,
[data-headerbg="color_9"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon svg path {
  fill: #000;
}

[data-headerbg="color_9"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_9"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_9"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info .small,
[data-headerbg="color_9"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_9"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_9"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info .small {
  color: #000;
}

[data-headerbg="color_9"][data-theme-version="dark"][data-headerbg="color_8"] .header-left .dashboard_bar,
[data-headerbg="color_9"][data-headerbg="color_8"] .header-left .dashboard_bar {
  color: #000;
}

[data-headerbg="color_10"][data-theme-version="dark"],
[data-headerbg="color_10"] {
  --headerbg: #17a2b8;
}

[data-headerbg="color_10"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_10"][data-theme-version="dark"] .search-area .form-control,
[data-headerbg="color_10"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_10"] .search-area .input-group-text,
[data-headerbg="color_10"] .search-area .form-control,
[data-headerbg="color_10"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-headerbg="color_10"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_10"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_10"] .header-right .search-area .form-control,
[data-headerbg="color_10"] .header-right .search-area .input-group-text {
  background-color: #1fc8e3;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_10"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_10"] .header-right .search-area .form-control i,
[data-headerbg="color_10"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right .notification_dropdown .ai-icon,
[data-headerbg="color_10"] .header-right .notification_dropdown .ai-icon {
  background-color: #1fc8e3;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right .notification_dropdown .ai-icon svg path,
[data-headerbg="color_10"] .header-right .notification_dropdown .ai-icon svg path {
  fill: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_10"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_10"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info .small,
[data-headerbg="color_10"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_10"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_10"] .header-right .header-profile .nav-link .header-info .small {
  color: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_10"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .input-group-text,
[data-headerbg="color_10"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .form-control,
[data-headerbg="color_10"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .form-control::placeholder,
[data-headerbg="color_10"][data-headerbg="color_8"] .search-area .input-group-text,
[data-headerbg="color_10"][data-headerbg="color_8"] .search-area .form-control,
[data-headerbg="color_10"][data-headerbg="color_8"] .search-area .form-control::placeholder {
  color: #000;
}

[data-headerbg="color_10"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .form-control,
[data-headerbg="color_10"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .input-group-text,
[data-headerbg="color_10"][data-headerbg="color_8"] .header-right .search-area .form-control,
[data-headerbg="color_10"][data-headerbg="color_8"] .header-right .search-area .input-group-text {
  background: #F1F1F1;
}

[data-headerbg="color_10"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .form-control i,
[data-headerbg="color_10"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .input-group-text i,
[data-headerbg="color_10"][data-headerbg="color_8"] .header-right .search-area .form-control i,
[data-headerbg="color_10"][data-headerbg="color_8"] .header-right .search-area .input-group-text i {
  color: #000;
}

[data-headerbg="color_10"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon,
[data-headerbg="color_10"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon {
  background-color: #F1F1F1;
}

[data-headerbg="color_10"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon svg path,
[data-headerbg="color_10"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon svg path {
  fill: #000;
}

[data-headerbg="color_10"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_10"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_10"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info .small,
[data-headerbg="color_10"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_10"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_10"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info .small {
  color: #000;
}

[data-headerbg="color_10"][data-theme-version="dark"][data-headerbg="color_8"] .header-left .dashboard_bar,
[data-headerbg="color_10"][data-headerbg="color_8"] .header-left .dashboard_bar {
  color: #000;
}

[data-headerbg="color_11"][data-theme-version="dark"],
[data-headerbg="color_11"] {
  --headerbg: #94618E;
}

[data-headerbg="color_11"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_11"][data-theme-version="dark"] .search-area .form-control,
[data-headerbg="color_11"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_11"] .search-area .input-group-text,
[data-headerbg="color_11"] .search-area .form-control,
[data-headerbg="color_11"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-headerbg="color_11"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_11"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_11"] .header-right .search-area .form-control,
[data-headerbg="color_11"] .header-right .search-area .input-group-text {
  background-color: #aa7ea5;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_11"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_11"] .header-right .search-area .form-control i,
[data-headerbg="color_11"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right .notification_dropdown .ai-icon,
[data-headerbg="color_11"] .header-right .notification_dropdown .ai-icon {
  background-color: #aa7ea5;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right .notification_dropdown .ai-icon svg path,
[data-headerbg="color_11"] .header-right .notification_dropdown .ai-icon svg path {
  fill: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_11"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_11"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info .small,
[data-headerbg="color_11"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_11"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_11"] .header-right .header-profile .nav-link .header-info .small {
  color: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_11"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .input-group-text,
[data-headerbg="color_11"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .form-control,
[data-headerbg="color_11"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .form-control::placeholder,
[data-headerbg="color_11"][data-headerbg="color_8"] .search-area .input-group-text,
[data-headerbg="color_11"][data-headerbg="color_8"] .search-area .form-control,
[data-headerbg="color_11"][data-headerbg="color_8"] .search-area .form-control::placeholder {
  color: #000;
}

[data-headerbg="color_11"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .form-control,
[data-headerbg="color_11"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .input-group-text,
[data-headerbg="color_11"][data-headerbg="color_8"] .header-right .search-area .form-control,
[data-headerbg="color_11"][data-headerbg="color_8"] .header-right .search-area .input-group-text {
  background: #F1F1F1;
}

[data-headerbg="color_11"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .form-control i,
[data-headerbg="color_11"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .input-group-text i,
[data-headerbg="color_11"][data-headerbg="color_8"] .header-right .search-area .form-control i,
[data-headerbg="color_11"][data-headerbg="color_8"] .header-right .search-area .input-group-text i {
  color: #000;
}

[data-headerbg="color_11"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon,
[data-headerbg="color_11"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon {
  background-color: #F1F1F1;
}

[data-headerbg="color_11"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon svg path,
[data-headerbg="color_11"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon svg path {
  fill: #000;
}

[data-headerbg="color_11"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_11"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_11"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info .small,
[data-headerbg="color_11"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_11"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_11"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info .small {
  color: #000;
}

[data-headerbg="color_11"][data-theme-version="dark"][data-headerbg="color_8"] .header-left .dashboard_bar,
[data-headerbg="color_11"][data-headerbg="color_8"] .header-left .dashboard_bar {
  color: #000;
}

[data-headerbg="color_12"][data-theme-version="dark"],
[data-headerbg="color_12"] {
  --headerbg: #343a40;
}

[data-headerbg="color_12"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_12"][data-theme-version="dark"] .search-area .form-control,
[data-headerbg="color_12"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_12"] .search-area .input-group-text,
[data-headerbg="color_12"] .search-area .form-control,
[data-headerbg="color_12"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-headerbg="color_12"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_12"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_12"] .header-right .search-area .form-control,
[data-headerbg="color_12"] .header-right .search-area .input-group-text {
  background-color: #4b545c;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_12"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_12"] .header-right .search-area .form-control i,
[data-headerbg="color_12"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right .notification_dropdown .ai-icon,
[data-headerbg="color_12"] .header-right .notification_dropdown .ai-icon {
  background-color: #4b545c;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right .notification_dropdown .ai-icon svg path,
[data-headerbg="color_12"] .header-right .notification_dropdown .ai-icon svg path {
  fill: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_12"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_12"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info .small,
[data-headerbg="color_12"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_12"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_12"] .header-right .header-profile .nav-link .header-info .small {
  color: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_12"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .input-group-text,
[data-headerbg="color_12"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .form-control,
[data-headerbg="color_12"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .form-control::placeholder,
[data-headerbg="color_12"][data-headerbg="color_8"] .search-area .input-group-text,
[data-headerbg="color_12"][data-headerbg="color_8"] .search-area .form-control,
[data-headerbg="color_12"][data-headerbg="color_8"] .search-area .form-control::placeholder {
  color: #000;
}

[data-headerbg="color_12"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .form-control,
[data-headerbg="color_12"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .input-group-text,
[data-headerbg="color_12"][data-headerbg="color_8"] .header-right .search-area .form-control,
[data-headerbg="color_12"][data-headerbg="color_8"] .header-right .search-area .input-group-text {
  background: #F1F1F1;
}

[data-headerbg="color_12"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .form-control i,
[data-headerbg="color_12"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .input-group-text i,
[data-headerbg="color_12"][data-headerbg="color_8"] .header-right .search-area .form-control i,
[data-headerbg="color_12"][data-headerbg="color_8"] .header-right .search-area .input-group-text i {
  color: #000;
}

[data-headerbg="color_12"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon,
[data-headerbg="color_12"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon {
  background-color: #F1F1F1;
}

[data-headerbg="color_12"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon svg path,
[data-headerbg="color_12"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon svg path {
  fill: #000;
}

[data-headerbg="color_12"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_12"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_12"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info .small,
[data-headerbg="color_12"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_12"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_12"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info .small {
  color: #000;
}

[data-headerbg="color_12"][data-theme-version="dark"][data-headerbg="color_8"] .header-left .dashboard_bar,
[data-headerbg="color_12"][data-headerbg="color_8"] .header-left .dashboard_bar {
  color: #000;
}

[data-headerbg="color_13"][data-theme-version="dark"],
[data-headerbg="color_13"] {
  --headerbg: #2a2a2a;
}

[data-headerbg="color_13"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_13"][data-theme-version="dark"] .search-area .form-control,
[data-headerbg="color_13"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_13"] .search-area .input-group-text,
[data-headerbg="color_13"] .search-area .form-control,
[data-headerbg="color_13"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-headerbg="color_13"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_13"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_13"] .header-right .search-area .form-control,
[data-headerbg="color_13"] .header-right .search-area .input-group-text {
  background-color: #444444;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_13"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_13"] .header-right .search-area .form-control i,
[data-headerbg="color_13"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right .notification_dropdown .ai-icon,
[data-headerbg="color_13"] .header-right .notification_dropdown .ai-icon {
  background-color: #444444;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right .notification_dropdown .ai-icon svg path,
[data-headerbg="color_13"] .header-right .notification_dropdown .ai-icon svg path {
  fill: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_13"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_13"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info .small,
[data-headerbg="color_13"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_13"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_13"] .header-right .header-profile .nav-link .header-info .small {
  color: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_13"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .input-group-text,
[data-headerbg="color_13"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .form-control,
[data-headerbg="color_13"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .form-control::placeholder,
[data-headerbg="color_13"][data-headerbg="color_8"] .search-area .input-group-text,
[data-headerbg="color_13"][data-headerbg="color_8"] .search-area .form-control,
[data-headerbg="color_13"][data-headerbg="color_8"] .search-area .form-control::placeholder {
  color: #000;
}

[data-headerbg="color_13"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .form-control,
[data-headerbg="color_13"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .input-group-text,
[data-headerbg="color_13"][data-headerbg="color_8"] .header-right .search-area .form-control,
[data-headerbg="color_13"][data-headerbg="color_8"] .header-right .search-area .input-group-text {
  background: #F1F1F1;
}

[data-headerbg="color_13"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .form-control i,
[data-headerbg="color_13"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .input-group-text i,
[data-headerbg="color_13"][data-headerbg="color_8"] .header-right .search-area .form-control i,
[data-headerbg="color_13"][data-headerbg="color_8"] .header-right .search-area .input-group-text i {
  color: #000;
}

[data-headerbg="color_13"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon,
[data-headerbg="color_13"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon {
  background-color: #F1F1F1;
}

[data-headerbg="color_13"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon svg path,
[data-headerbg="color_13"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon svg path {
  fill: #000;
}

[data-headerbg="color_13"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_13"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_13"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info .small,
[data-headerbg="color_13"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_13"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_13"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info .small {
  color: #000;
}

[data-headerbg="color_13"][data-theme-version="dark"][data-headerbg="color_8"] .header-left .dashboard_bar,
[data-headerbg="color_13"][data-headerbg="color_8"] .header-left .dashboard_bar {
  color: #000;
}

[data-headerbg="color_14"][data-theme-version="dark"],
[data-headerbg="color_14"] {
  --headerbg: #4885ed;
}

[data-headerbg="color_14"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_14"][data-theme-version="dark"] .search-area .form-control,
[data-headerbg="color_14"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_14"] .search-area .input-group-text,
[data-headerbg="color_14"] .search-area .form-control,
[data-headerbg="color_14"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_14"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-headerbg="color_14"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #fff;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_14"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_14"] .header-right .search-area .form-control,
[data-headerbg="color_14"] .header-right .search-area .input-group-text {
  background-color: #76a4f2;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_14"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_14"] .header-right .search-area .form-control i,
[data-headerbg="color_14"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-right .notification_dropdown .ai-icon,
[data-headerbg="color_14"] .header-right .notification_dropdown .ai-icon {
  background-color: #76a4f2;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-right .notification_dropdown .ai-icon svg path,
[data-headerbg="color_14"] .header-right .notification_dropdown .ai-icon svg path {
  fill: #fff;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_14"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_14"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info .small,
[data-headerbg="color_14"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_14"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_14"] .header-right .header-profile .nav-link .header-info .small {
  color: #fff;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_14"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_14"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .input-group-text,
[data-headerbg="color_14"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .form-control,
[data-headerbg="color_14"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .form-control::placeholder,
[data-headerbg="color_14"][data-headerbg="color_8"] .search-area .input-group-text,
[data-headerbg="color_14"][data-headerbg="color_8"] .search-area .form-control,
[data-headerbg="color_14"][data-headerbg="color_8"] .search-area .form-control::placeholder {
  color: #000;
}

[data-headerbg="color_14"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .form-control,
[data-headerbg="color_14"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .input-group-text,
[data-headerbg="color_14"][data-headerbg="color_8"] .header-right .search-area .form-control,
[data-headerbg="color_14"][data-headerbg="color_8"] .header-right .search-area .input-group-text {
  background: #F1F1F1;
}

[data-headerbg="color_14"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .form-control i,
[data-headerbg="color_14"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .input-group-text i,
[data-headerbg="color_14"][data-headerbg="color_8"] .header-right .search-area .form-control i,
[data-headerbg="color_14"][data-headerbg="color_8"] .header-right .search-area .input-group-text i {
  color: #000;
}

[data-headerbg="color_14"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon,
[data-headerbg="color_14"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon {
  background-color: #F1F1F1;
}

[data-headerbg="color_14"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon svg path,
[data-headerbg="color_14"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon svg path {
  fill: #000;
}

[data-headerbg="color_14"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_14"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_14"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info .small,
[data-headerbg="color_14"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_14"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_14"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info .small {
  color: #000;
}

[data-headerbg="color_14"][data-theme-version="dark"][data-headerbg="color_8"] .header-left .dashboard_bar,
[data-headerbg="color_14"][data-headerbg="color_8"] .header-left .dashboard_bar {
  color: #000;
}

[data-headerbg="color_15"][data-theme-version="dark"],
[data-headerbg="color_15"] {
  --headerbg: #4cb32b;
}

[data-headerbg="color_15"][data-theme-version="dark"] .search-area .input-group-text,
[data-headerbg="color_15"][data-theme-version="dark"] .search-area .form-control,
[data-headerbg="color_15"][data-theme-version="dark"] .search-area .form-control::placeholder,
[data-headerbg="color_15"] .search-area .input-group-text,
[data-headerbg="color_15"] .search-area .form-control,
[data-headerbg="color_15"] .search-area .form-control::placeholder {
  color: #fff;
}

[data-headerbg="color_15"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line,
[data-headerbg="color_15"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #fff;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_15"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_15"] .header-right .search-area .form-control,
[data-headerbg="color_15"] .header-right .search-area .input-group-text {
  background-color: #63d140;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_15"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_15"] .header-right .search-area .form-control i,
[data-headerbg="color_15"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-right .notification_dropdown .ai-icon,
[data-headerbg="color_15"] .header-right .notification_dropdown .ai-icon {
  background-color: #63d140;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-right .notification_dropdown .ai-icon svg path,
[data-headerbg="color_15"] .header-right .notification_dropdown .ai-icon svg path {
  fill: #fff;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_15"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_15"][data-theme-version="dark"] .header-right .header-profile .nav-link .header-info .small,
[data-headerbg="color_15"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_15"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_15"] .header-right .header-profile .nav-link .header-info .small {
  color: #fff;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-left .dashboard_bar,
[data-headerbg="color_15"] .header-left .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_15"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .input-group-text,
[data-headerbg="color_15"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .form-control,
[data-headerbg="color_15"][data-theme-version="dark"][data-headerbg="color_8"] .search-area .form-control::placeholder,
[data-headerbg="color_15"][data-headerbg="color_8"] .search-area .input-group-text,
[data-headerbg="color_15"][data-headerbg="color_8"] .search-area .form-control,
[data-headerbg="color_15"][data-headerbg="color_8"] .search-area .form-control::placeholder {
  color: #000;
}

[data-headerbg="color_15"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .form-control,
[data-headerbg="color_15"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .input-group-text,
[data-headerbg="color_15"][data-headerbg="color_8"] .header-right .search-area .form-control,
[data-headerbg="color_15"][data-headerbg="color_8"] .header-right .search-area .input-group-text {
  background: #F1F1F1;
}

[data-headerbg="color_15"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .form-control i,
[data-headerbg="color_15"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .search-area .input-group-text i,
[data-headerbg="color_15"][data-headerbg="color_8"] .header-right .search-area .form-control i,
[data-headerbg="color_15"][data-headerbg="color_8"] .header-right .search-area .input-group-text i {
  color: #000;
}

[data-headerbg="color_15"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon,
[data-headerbg="color_15"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon {
  background-color: #F1F1F1;
}

[data-headerbg="color_15"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon svg path,
[data-headerbg="color_15"][data-headerbg="color_8"] .header-right .notification_dropdown .ai-icon svg path {
  fill: #000;
}

[data-headerbg="color_15"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_15"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_15"][data-theme-version="dark"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info .small,
[data-headerbg="color_15"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info span,
[data-headerbg="color_15"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info small,
[data-headerbg="color_15"][data-headerbg="color_8"] .header-right .header-profile .nav-link .header-info .small {
  color: #000;
}

[data-headerbg="color_15"][data-theme-version="dark"][data-headerbg="color_8"] .header-left .dashboard_bar,
[data-headerbg="color_15"][data-headerbg="color_8"] .header-left .dashboard_bar {
  color: #000;
}

@media (min-width: 62rem) {
  [data-container="boxed"] #main-wrapper {
    max-width: 74.9375rem;
    margin: 0 auto;
    box-shadow: 0 0 1.875rem 0 rgba(0, 0, 0, 0.1);
  }

  [direction="rtl"][data-container="boxed"] #main-wrapper {
    text-align: right;
  }
}

@media only screen and (min-width: 84.375rem) {
  [data-layout="vertical"][data-sidebar-style="overlay"][data-container="boxed"][data-header-position="fixed"] .header {
    width: 74.9375rem;
  }
}

@media only screen and (min-width: 75rem) and (max-width: 84.3125rem) {
  [data-layout="vertical"][data-sidebar-style="overlay"][data-container="boxed"][data-header-position="fixed"] .header {
    width: 74.9375rem;
  }
}

[data-header-position="fixed"][data-layout="horizontal"][data-container="boxed"] .deznav {
  max-width: 74.9375rem;
}

[data-layout="vertical"][data-container="boxed"][data-header-position="fixed"] .header,
[data-layout="horizontal"][data-container="boxed"][data-header-position="fixed"] .header {
  width: 74.9375rem;
}

[data-layout="horizontal"][data-container="boxed"][data-header-position="fixed"][data-sidebar-style="mini"] .header {
  width: 74.9375rem;
}

[data-container="boxed"] .metismenu.fixed {
  left: auto;
  max-width: 74.9375rem;
}

[data-container="boxed"] .page-titles {
  margin-bottom: 3rem;
  padding: 0.9375rem 0.9375rem;
}

[data-container="boxed"] .content-body .container-fluid,
[data-container="boxed"] .content-body .container-sm,
[data-container="boxed"] .content-body .container-md,
[data-container="boxed"] .content-body .container-lg,
[data-container="boxed"] .content-body .container-xl,
[data-container="boxed"] .content-body .container-xxl {
  padding: 2.5rem;
}

[data-container="boxed"][data-layout="vertical"] .page-titles {
  margin-left: 0;
  margin-right: 0;
}

[data-layout="vertical"][data-container="boxed"][data-sidebar-position="fixed"][data-header-position="static"][data-sidebar-style="overlay"] .nav-header {
  position: absolute;
}

[data-layout="vertical"][data-container="boxed"][data-sidebar-position="fixed"][data-header-position="static"][data-sidebar-style="overlay"] .menu-toggle .deznav {
  position: absolute;
}

[data-container="boxed"][data-sidebar-position="fixed"][data-layout="horizontal"] .deznav.fixed {
  left: auto;
  max-width: 74.9375rem;
}

@media (min-width: 62rem) {
  [data-container="wide-boxed"] #main-wrapper {
    max-width: 92.5rem;
    margin: 0 auto;
    box-shadow: 0 0 1.875rem 0 rgba(0, 0, 0, 0.1);
  }

  [direction="rtl"][data-container="wide-boxed"] #main-wrapper {
    text-align: right;
  }
}

@media only screen and (min-width: 84.375rem) {
  [data-layout="vertical"][data-sidebar-style="overlay"][data-container="wide-boxed"][data-header-position="fixed"] .header {
    width: 92.5rem;
  }
}

@media only screen and (min-width: 75rem) and (max-width: 100rem) {
  [data-layout="vertical"][data-sidebar-style="overlay"][data-container="wide-boxed"][data-header-position="fixed"] .header {
    width: 92.5rem;
  }
}

[data-sidebar-style="compact"][data-header-position="fixed"][data-container="wide-boxed"][data-layout="vertical"] .header {
  width: 92.5rem;
}

[data-header-position="fixed"][data-layout="horizontal"][data-container="wide-boxed"] .deznav {
  max-width: 92.5rem;
}

[data-layout="vertical"][data-container="wide-boxed"][data-header-position="fixed"] .header,
[data-layout="horizontal"][data-container="wide-boxed"][data-header-position="fixed"] .header {
  width: 92.5rem;
}

[data-layout="horizontal"][data-container="wide-boxed"][data-header-position="fixed"][data-sidebar-style="mini"] .header {
  width: 92.5rem;
}

[data-container="wide-boxed"] .metismenu.fixed {
  left: auto;
  max-width: 92.5rem;
}

[data-container="wide-boxed"] .page-titles {
  margin-bottom: 3rem;
  padding: 0.9375rem 0.9375rem;
}

[data-container="wide-boxed"] .content-body .container-fluid,
[data-container="wide-boxed"] .content-body .container-sm,
[data-container="wide-boxed"] .content-body .container-md,
[data-container="wide-boxed"] .content-body .container-lg,
[data-container="wide-boxed"] .content-body .container-xl,
[data-container="wide-boxed"] .content-body .container-xxl {
  padding: 2.5rem;
}

[data-container="wide-boxed"][data-layout="vertical"] .page-titles {
  margin-left: 0;
  margin-right: 0;
}

[data-layout="vertical"][data-container="wide-boxed"][data-sidebar-position="fixed"][data-header-position="static"][data-sidebar-style="overlay"] .nav-header {
  position: absolute;
}

[data-layout="vertical"][data-container="wide-boxed"][data-sidebar-position="fixed"][data-header-position="static"][data-sidebar-style="overlay"] .menu-toggle .deznav {
  position: absolute;
}

[data-container="wide-boxed"][data-sidebar-position="fixed"][data-layout="horizontal"] .deznav.fixed {
  left: auto;
  max-width: 92.5rem;
}

[data-primary="color_2"] {
  --primary: #6610f2;
  --secondary: #627EEA;
  --primary-hover: #510bc4;
  --primary-dark: #290564;
  --rgba-primary-1: rgba(102, 16, 242, 0.1);
  --rgba-primary-2: rgba(102, 16, 242, 0.2);
  --rgba-primary-3: rgba(102, 16, 242, 0.3);
  --rgba-primary-4: rgba(102, 16, 242, 0.4);
  --rgba-primary-5: rgba(102, 16, 242, 0.5);
  --rgba-primary-6: rgba(102, 16, 242, 0.6);
  --rgba-primary-7: rgba(102, 16, 242, 0.7);
  --rgba-primary-8: rgba(102, 16, 242, 0.8);
  --rgba-primary-9: rgba(102, 16, 242, 0.9);
}

[data-primary="color_3"] {
  --primary: #1362FC;
  --secondary: #627EEA;
  --primary-hover: #034bd9;
  --primary-dark: #012875;
  --rgba-primary-1: rgba(19, 98, 252, 0.1);
  --rgba-primary-2: rgba(19, 98, 252, 0.2);
  --rgba-primary-3: rgba(19, 98, 252, 0.3);
  --rgba-primary-4: rgba(19, 98, 252, 0.4);
  --rgba-primary-5: rgba(19, 98, 252, 0.5);
  --rgba-primary-6: rgba(19, 98, 252, 0.6);
  --rgba-primary-7: rgba(19, 98, 252, 0.7);
  --rgba-primary-8: rgba(19, 98, 252, 0.8);
  --rgba-primary-9: rgba(19, 98, 252, 0.9);
}

[data-primary="color_4"] {
  --primary: #4d06a5;
  --secondary: #627EEA;
  --primary-hover: #360474;
  --primary-dark: #080111;
  --rgba-primary-1: rgba(77, 6, 165, 0.1);
  --rgba-primary-2: rgba(77, 6, 165, 0.2);
  --rgba-primary-3: rgba(77, 6, 165, 0.3);
  --rgba-primary-4: rgba(77, 6, 165, 0.4);
  --rgba-primary-5: rgba(77, 6, 165, 0.5);
  --rgba-primary-6: rgba(77, 6, 165, 0.6);
  --rgba-primary-7: rgba(77, 6, 165, 0.7);
  --rgba-primary-8: rgba(77, 6, 165, 0.8);
  --rgba-primary-9: rgba(77, 6, 165, 0.9);
}

[data-primary="color_5"] {
  --primary: #dc3545;
  --secondary: #627EEA;
  --primary-hover: #bd2130;
  --primary-dark: #66121a;
  --rgba-primary-1: rgba(220, 53, 69, 0.1);
  --rgba-primary-2: rgba(220, 53, 69, 0.2);
  --rgba-primary-3: rgba(220, 53, 69, 0.3);
  --rgba-primary-4: rgba(220, 53, 69, 0.4);
  --rgba-primary-5: rgba(220, 53, 69, 0.5);
  --rgba-primary-6: rgba(220, 53, 69, 0.6);
  --rgba-primary-7: rgba(220, 53, 69, 0.7);
  --rgba-primary-8: rgba(220, 53, 69, 0.8);
  --rgba-primary-9: rgba(220, 53, 69, 0.9);
}

[data-primary="color_6"] {
  --primary: #fd7e14;
  --secondary: #627EEA;
  --primary-hover: #dc6502;
  --primary-dark: #773701;
  --rgba-primary-1: rgba(253, 126, 20, 0.1);
  --rgba-primary-2: rgba(253, 126, 20, 0.2);
  --rgba-primary-3: rgba(253, 126, 20, 0.3);
  --rgba-primary-4: rgba(253, 126, 20, 0.4);
  --rgba-primary-5: rgba(253, 126, 20, 0.5);
  --rgba-primary-6: rgba(253, 126, 20, 0.6);
  --rgba-primary-7: rgba(253, 126, 20, 0.7);
  --rgba-primary-8: rgba(253, 126, 20, 0.8);
  --rgba-primary-9: rgba(253, 126, 20, 0.9);
}

[data-primary="color_7"] {
  --primary: #ffc107;
  --secondary: #627EEA;
  --primary-hover: #d39e00;
  --primary-dark: #6d5200;
  --rgba-primary-1: rgba(255, 193, 7, 0.1);
  --rgba-primary-2: rgba(255, 193, 7, 0.2);
  --rgba-primary-3: rgba(255, 193, 7, 0.3);
  --rgba-primary-4: rgba(255, 193, 7, 0.4);
  --rgba-primary-5: rgba(255, 193, 7, 0.5);
  --rgba-primary-6: rgba(255, 193, 7, 0.6);
  --rgba-primary-7: rgba(255, 193, 7, 0.7);
  --rgba-primary-8: rgba(255, 193, 7, 0.8);
  --rgba-primary-9: rgba(255, 193, 7, 0.9);
}

[data-primary="color_8"] {
  --primary: #fff;
  --secondary: #627EEA;
  --primary-hover: #e6e6e6;
  --primary-dark: #b3b3b3;
  --rgba-primary-1: rgba(255, 255, 255, 0.1);
  --rgba-primary-2: rgba(255, 255, 255, 0.2);
  --rgba-primary-3: rgba(255, 255, 255, 0.3);
  --rgba-primary-4: rgba(255, 255, 255, 0.4);
  --rgba-primary-5: rgba(255, 255, 255, 0.5);
  --rgba-primary-6: rgba(255, 255, 255, 0.6);
  --rgba-primary-7: rgba(255, 255, 255, 0.7);
  --rgba-primary-8: rgba(255, 255, 255, 0.8);
  --rgba-primary-9: rgba(255, 255, 255, 0.9);
}

[data-primary="color_9"] {
  --primary: #20c997;
  --secondary: #627EEA;
  --primary-hover: #199d76;
  --primary-dark: #0b4534;
  --rgba-primary-1: rgba(32, 201, 151, 0.1);
  --rgba-primary-2: rgba(32, 201, 151, 0.2);
  --rgba-primary-3: rgba(32, 201, 151, 0.3);
  --rgba-primary-4: rgba(32, 201, 151, 0.4);
  --rgba-primary-5: rgba(32, 201, 151, 0.5);
  --rgba-primary-6: rgba(32, 201, 151, 0.6);
  --rgba-primary-7: rgba(32, 201, 151, 0.7);
  --rgba-primary-8: rgba(32, 201, 151, 0.8);
  --rgba-primary-9: rgba(32, 201, 151, 0.9);
}

[data-primary="color_10"] {
  --primary: #17a2b8;
  --secondary: #627EEA;
  --primary-hover: #117a8b;
  --primary-dark: #062a30;
  --rgba-primary-1: rgba(23, 162, 184, 0.1);
  --rgba-primary-2: rgba(23, 162, 184, 0.2);
  --rgba-primary-3: rgba(23, 162, 184, 0.3);
  --rgba-primary-4: rgba(23, 162, 184, 0.4);
  --rgba-primary-5: rgba(23, 162, 184, 0.5);
  --rgba-primary-6: rgba(23, 162, 184, 0.6);
  --rgba-primary-7: rgba(23, 162, 184, 0.7);
  --rgba-primary-8: rgba(23, 162, 184, 0.8);
  --rgba-primary-9: rgba(23, 162, 184, 0.9);
}

[data-primary="color_11"] {
  --primary: #94618E;
  --secondary: #627EEA;
  --primary-hover: #754d70;
  --primary-dark: #382435;
  --rgba-primary-1: rgba(148, 97, 142, 0.1);
  --rgba-primary-2: rgba(148, 97, 142, 0.2);
  --rgba-primary-3: rgba(148, 97, 142, 0.3);
  --rgba-primary-4: rgba(148, 97, 142, 0.4);
  --rgba-primary-5: rgba(148, 97, 142, 0.5);
  --rgba-primary-6: rgba(148, 97, 142, 0.6);
  --rgba-primary-7: rgba(148, 97, 142, 0.7);
  --rgba-primary-8: rgba(148, 97, 142, 0.8);
  --rgba-primary-9: rgba(148, 97, 142, 0.9);
}

[data-primary="color_12"] {
  --primary: #343a40;
  --secondary: #627EEA;
  --primary-hover: #1d2124;
  --primary-dark: black;
  --rgba-primary-1: rgba(52, 58, 64, 0.1);
  --rgba-primary-2: rgba(52, 58, 64, 0.2);
  --rgba-primary-3: rgba(52, 58, 64, 0.3);
  --rgba-primary-4: rgba(52, 58, 64, 0.4);
  --rgba-primary-5: rgba(52, 58, 64, 0.5);
  --rgba-primary-6: rgba(52, 58, 64, 0.6);
  --rgba-primary-7: rgba(52, 58, 64, 0.7);
  --rgba-primary-8: rgba(52, 58, 64, 0.8);
  --rgba-primary-9: rgba(52, 58, 64, 0.9);
}

[data-primary="color_13"] {
  --primary: #2a2a2a;
  --secondary: #627EEA;
  --primary-hover: #111111;
  --primary-dark: black;
  --rgba-primary-1: rgba(42, 42, 42, 0.1);
  --rgba-primary-2: rgba(42, 42, 42, 0.2);
  --rgba-primary-3: rgba(42, 42, 42, 0.3);
  --rgba-primary-4: rgba(42, 42, 42, 0.4);
  --rgba-primary-5: rgba(42, 42, 42, 0.5);
  --rgba-primary-6: rgba(42, 42, 42, 0.6);
  --rgba-primary-7: rgba(42, 42, 42, 0.7);
  --rgba-primary-8: rgba(42, 42, 42, 0.8);
  --rgba-primary-9: rgba(42, 42, 42, 0.9);
}

[data-primary="color_14"] {
  --primary: #4885ed;
  --secondary: #627EEA;
  --primary-hover: #1a66e8;
  --primary-dark: #0e3d8e;
  --rgba-primary-1: rgba(72, 133, 237, 0.1);
  --rgba-primary-2: rgba(72, 133, 237, 0.2);
  --rgba-primary-3: rgba(72, 133, 237, 0.3);
  --rgba-primary-4: rgba(72, 133, 237, 0.4);
  --rgba-primary-5: rgba(72, 133, 237, 0.5);
  --rgba-primary-6: rgba(72, 133, 237, 0.6);
  --rgba-primary-7: rgba(72, 133, 237, 0.7);
  --rgba-primary-8: rgba(72, 133, 237, 0.8);
  --rgba-primary-9: rgba(72, 133, 237, 0.9);
}

[data-primary="color_15"] {
  --primary: #4cb32b;
  --secondary: #627EEA;
  --primary-hover: #3b8a21;
  --primary-dark: #18380d;
  --rgba-primary-1: rgba(76, 179, 43, 0.1);
  --rgba-primary-2: rgba(76, 179, 43, 0.2);
  --rgba-primary-3: rgba(76, 179, 43, 0.3);
  --rgba-primary-4: rgba(76, 179, 43, 0.4);
  --rgba-primary-5: rgba(76, 179, 43, 0.5);
  --rgba-primary-6: rgba(76, 179, 43, 0.6);
  --rgba-primary-7: rgba(76, 179, 43, 0.7);
  --rgba-primary-8: rgba(76, 179, 43, 0.8);
  --rgba-primary-9: rgba(76, 179, 43, 0.9);
}

[data-typography="opensans"] {
  font-family: 'Open Sans', sans-serif;
}

[data-typography="poppins"] {
  font-family: 'poppins', sans-serif;
}

[data-typography="cairo"] {
  font-family: 'Cairo', sans-serif;
}

[data-typography="roboto"] {
  font-family: 'Roboto', sans-serif;
}

[data-typography="helvetica"] {
  font-family: 'HelveticaNeue';
}

:root {
  --primary: #1362FC;
  --secondary: #627EEA;
  --primary-hover: #034bd9;
  --primary-light: #aac7fe;
  --primary-dark: #012875;
  --rgba-primary-1: rgba(19, 98, 252, 0.1);
  --rgba-primary-2: rgba(19, 98, 252, 0.2);
  --rgba-primary-3: rgba(19, 98, 252, 0.3);
  --rgba-primary-4: rgba(19, 98, 252, 0.4);
  --rgba-primary-5: rgba(19, 98, 252, 0.5);
  --rgba-primary-6: rgba(19, 98, 252, 0.6);
  --rgba-primary-7: rgba(19, 98, 252, 0.7);
  --rgba-primary-8: rgba(19, 98, 252, 0.8);
  --rgba-primary-9: rgba(19, 98, 252, 0.9);
  --font-family-base: Roboto, sans-serif;
  --font-family-title: Roboto, sans-serif;
  --title: #000;
}

[data-theme-version="transparent"] {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  position: relative;
  color: #fff;
}

[data-theme-version="transparent"] h1,
[data-theme-version="transparent"] .h1,
[data-theme-version="transparent"] h2,
[data-theme-version="transparent"] .h2,
[data-theme-version="transparent"] h3,
[data-theme-version="transparent"] .h3,
[data-theme-version="transparent"] h4,
[data-theme-version="transparent"] .h4,
[data-theme-version="transparent"] h5,
[data-theme-version="transparent"] .h5,
[data-theme-version="transparent"] h6,
[data-theme-version="transparent"] .h6 {
  color: #fff !important;
}

[data-theme-version="transparent"] a.link {
  color: #ddd;
}

[data-theme-version="transparent"] a.link:focus,
[data-theme-version="transparent"] a.link:hover {
  color: #b48dd3;
}

[data-theme-version="transparent"] a {
  color: #fff;
}

[data-theme-version="transparent"] a:hover {
  color: #828690;
}

[data-theme-version="transparent"] .border-right {
  border-right: 0.0625rem solid #2e2e42 !important;
}

[data-theme-version="transparent"] .border-left {
  border-left: 0.0625rem solid #2e2e42 !important;
}

[data-theme-version="transparent"] .border-top {
  border-top: 0.0625rem solid #2e2e42 !important;
}

[data-theme-version="transparent"] .border-bottom {
  border-bottom: 0.0625rem solid #2e2e42 !important;
}

[data-theme-version="transparent"] .border {
  border: 0.0625rem solid #2e2e42 !important;
}

[data-theme-version="transparent"] .dropdown-menu {
  background-color: #212130;
}

[data-theme-version="transparent"] .dropdown-item:hover,
[data-theme-version="transparent"] .dropdown-item:focus {
  background-color: #171622;
  color: #fff;
}

[data-theme-version="transparent"] .form-control {
  background-color: #171622;
  border-color: #2e2e42;
  color: #fff;
}

[data-theme-version="transparent"] .card {
  background-color: rgba(0, 0, 0, 0.15);
}

[data-theme-version="transparent"] .header {
  background-color: rgba(0, 0, 0, 0.15);
}

[data-theme-version="transparent"] .header-left input {
  border-color: #2e2e42;
  color: #fff;
}

[data-theme-version="transparent"] .header-left input:focus {
  box-shadow: none;
  border-color: #1362FC;
}

[data-theme-version="transparent"] .header-left input::placeholder {
  color: #fff;
}

[data-theme-version="transparent"] .header-right .dropdown .nav-link:hover {
  color: #fff;
}

[data-theme-version="transparent"] .header-right .notification_dropdown .dropdown-item a {
  color: #fff;
}

[data-theme-version="transparent"] .nav-header,
[data-theme-version="transparent"] .nav-control {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

[data-theme-version="transparent"] .nav-control {
  color: #fff;
}

[data-theme-version="transparent"] .brand-logo {
  color: #fff;
}

[data-theme-version="transparent"] .brand-logo:hover {
  color: #fff;
}

[data-theme-version="transparent"] .nav-header,
[data-theme-version="transparent"] .nav-control {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

[data-theme-version="transparent"] .nav-control {
  color: #fff;
}

[data-theme-version="transparent"] .brand-logo {
  color: #fff;
}

[data-theme-version="transparent"] .brand-logo:hover {
  color: #fff;
}

[data-theme-version="transparent"] .deznav {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

[data-theme-version="transparent"] .deznav .metismenu>li>a {
  color: rgba(0, 0, 0, 0.15);
}

[data-theme-version="transparent"] .deznav .metismenu>li:hover>a,
[data-theme-version="transparent"] .deznav .metismenu>li:focus>a,
[data-theme-version="transparent"] .deznav .metismenu>li.mm-active>a {
  background-color: rgba(0, 0, 0, 0.15) !important;
  color: #fff;
}

[data-theme-version="transparent"] .deznav .metismenu>li:hover>a::after,
[data-theme-version="transparent"] .deznav .metismenu>li:focus>a::after,
[data-theme-version="transparent"] .deznav .metismenu>li.mm-active>a::after {
  border-color: transparent transparent #fff transparent;
}

[data-theme-version="transparent"] .deznav .metismenu>li.mm-active ul ul {
  background-color: transparent;
}

[data-theme-version="transparent"] .deznav .metismenu ul {
  background-color: rgba(0, 0, 0, 0.15);
}

[data-theme-version="transparent"] .deznav .metismenu ul a:hover,
[data-theme-version="transparent"] .deznav .metismenu ul a:focus,
[data-theme-version="transparent"] .deznav .metismenu ul a.mm-active {
  color: #fff;
}

[data-theme-version="transparent"] .deznav .metismenu a {
  color: rgba(0, 0, 0, 0.15);
}

[data-theme-version="transparent"] .deznav .metismenu ul {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

[data-theme-version="transparent"] .deznav .metismenu .has-arrow:after {
  border-color: transparent transparent rgba(0, 0, 0, 0.15) transparent;
}

:root {
  --primary: #1362FC;
  --secondary: #627EEA;
  --primary-hover: #034bd9;
  --primary-light: #aac7fe;
  --primary-dark: #012875;
  --rgba-primary-1: rgba(19, 98, 252, 0.1);
  --rgba-primary-2: rgba(19, 98, 252, 0.2);
  --rgba-primary-3: rgba(19, 98, 252, 0.3);
  --rgba-primary-4: rgba(19, 98, 252, 0.4);
  --rgba-primary-5: rgba(19, 98, 252, 0.5);
  --rgba-primary-6: rgba(19, 98, 252, 0.6);
  --rgba-primary-7: rgba(19, 98, 252, 0.7);
  --rgba-primary-8: rgba(19, 98, 252, 0.8);
  --rgba-primary-9: rgba(19, 98, 252, 0.9);
  --font-family-base: Roboto, sans-serif;
  --font-family-title: Roboto, sans-serif;
  --title: #000;
}

[data-theme-version="dark"] {
  background: #171622;
  color: #828690;
  --nav-headbg: #171622;
  --sidebar-bg: #171622;
  --headerbg: #171622;
}

[data-theme-version="dark"] h1,
[data-theme-version="dark"] .h1,
[data-theme-version="dark"] h2,
[data-theme-version="dark"] .h2,
[data-theme-version="dark"] h3,
[data-theme-version="dark"] .h3,
[data-theme-version="dark"] h4,
[data-theme-version="dark"] .h4,
[data-theme-version="dark"] h5,
[data-theme-version="dark"] .h5,
[data-theme-version="dark"] h6,
[data-theme-version="dark"] .h6 {
  color: #fff !important;
}

[data-theme-version="dark"] a.link {
  color: #ddd;
}

[data-theme-version="dark"] a.link:focus,
[data-theme-version="dark"] a.link:hover {
  color: #b48dd3;
}

[data-theme-version="dark"] a:hover {
  color: #fff;
}

[data-theme-version="dark"] .border-right {
  border-right: 0.0625rem solid #2e2e42 !important;
}

[data-theme-version="dark"] .border-left {
  border-left: 0.0625rem solid #2e2e42 !important;
}

[data-theme-version="dark"] .border-top {
  border-top: 0.0625rem solid #2e2e42 !important;
}

[data-theme-version="dark"] .border-bottom {
  border-bottom: 0.0625rem solid #2e2e42 !important;
}

[data-theme-version="dark"] .border {
  border: 0.0625rem solid #2e2e42 !important;
}

[data-theme-version="dark"] .card {
  background-color: #212130;
  box-shadow: none;
}

[data-theme-version="dark"] .dropdown-menu {
  background-color: #212130;
  box-shadow: 0rem 0rem 0rem 0.0625rem rgba(255, 255, 255, 0.1);
}

[data-theme-version="dark"] .dropdown-menu .dropdown-item {
  color: #777777;
}

[data-theme-version="dark"] .dropdown-menu .dropdown-item.selected,
[data-theme-version="dark"] .dropdown-menu .dropdown-item.selected.active,
[data-theme-version="dark"] .dropdown-menu .dropdown-item.active,
[data-theme-version="dark"] .dropdown-menu .dropdown-item:hover,
[data-theme-version="dark"] .dropdown-menu .dropdown-item:focus {
  background-color: #212130;
  color: #fff;
}

[data-theme-version="dark"] a {
  color: #fff;
}

[data-theme-version="dark"] .text-primary {
  color: #fff !important;
}

[data-theme-version="dark"] .btn-link g [fill] {
  fill: #fff;
}

[data-theme-version="dark"] .btn-light:active,
[data-theme-version="dark"] .btn-light:focus,
[data-theme-version="dark"] .btn-light:hover {
  color: #000;
}

[data-theme-version="dark"] .form-control {
  background-color: #212130;
  border-color: #2e2e42;
  color: #fff;
}

[data-theme-version="dark"] .modal-content {
  background: #212130;
}

[data-theme-version="dark"] .modal-footer,
[data-theme-version="dark"] .modal-header {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .close {
  font-weight: 400;
  color: #fff;
  text-shadow: none;
}

[data-theme-version="dark"] .close:hover {
  color: #fff;
}

[data-theme-version="dark"] .star-rating .product-review,
[data-theme-version="dark"] .new-arrival-content .item {
  color: #fff;
}

[data-theme-version="dark"] .custom-dropdown .dropdown-menu {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .widget-stat .media>span {
  background: #2e2e42;
  border-color: #2e2e42;
  color: #fff;
}

[data-theme-version="dark"] .plus-minus-input .custom-btn {
  background: #171622;
  border-color: #2e2e42;
}

[data-theme-version="dark"] .dropdown-divider,
[data-theme-version="dark"] .size-filter ul li {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .custom-select {
  border-color: #2e2e42;
  color: #828690;
  background: #171622;
}

[data-theme-version="dark"] .nav-tabs {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .mail-list .list-group-item.active i {
  color: #fff;
}

[data-theme-version="dark"] hr {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .grid-col {
  background: #171622;
}

[data-theme-version="dark"] .noUi-target {
  border-color: #2e2e42;
  border-radius: 0.5rem;
  box-shadow: none;
}

[data-theme-version="dark"] .noUi-target .noUi-connects {
  background: #2e2e42;
}

[data-theme-version="dark"] .noUi-marker-large,
[data-theme-version="dark"] .noUi-marker {
  background: #2e2e42;
}

[data-theme-version="dark"] .input-group-text {
  background: #212130;
  color: #969ba0;
  border-color: #2e2e42;
  border: 0;
}

[data-theme-version="dark"] .note-editor.note-frame {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .note-editor.note-frame .btn {
  color: #fff;
}

[data-theme-version="dark"] .note-editor.note-frame .note-editing-area .note-editable {
  color: #fff;
}

[data-theme-version="dark"] .widget-media .timeline .timeline-panel {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .notification_dropdown .dropdown-menu-right .all-notification {
  border-color: #2e2e42;
}

[data-theme-version="dark"] #user-activity .nav-tabs .nav-link {
  border-color: #2e2e42;
}

[data-theme-version="dark"] #user-activity .nav-tabs .nav-link.active {
  background: #171622;
  color: #fff;
}

[data-theme-version="dark"] .list-group-item-action {
  color: #969ba0;
}

[data-theme-version="dark"] .list-group-item-action:focus,
[data-theme-version="dark"] .list-group-item-action:hover,
[data-theme-version="dark"] .list-group-item-action:focus {
  background-color: #171622;
  border-color: #171622;
}

[data-theme-version="dark"] .list-group-item.active {
  color: #fff;
  border-color: var(--primary);
}

[data-theme-version="dark"] .list-group-item.active:focus,
[data-theme-version="dark"] .list-group-item.active:hover,
[data-theme-version="dark"] .list-group-item.active:focus {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

[data-theme-version="dark"] .swal2-popup {
  background: #212130;
}

[data-theme-version="dark"] .form-head .btn-outline-primary {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .form-head .btn-outline-primary:hover {
  border-color: var(--primary);
}

[data-theme-version="dark"] .review-tab.nav-pills li a.nav-link.active {
  background: transparent;
}

[data-theme-version="dark"] .new-arrival-content h4 a,
[data-theme-version="dark"] .new-arrival-content .h4 a {
  color: #fff;
}

[data-theme-version="dark"] .text-black {
  color: #fff !important;
}

[data-theme-version="dark"] .abilities-chart .ct-chart .ct-label {
  fill: #fff;
}

[data-theme-version="dark"] .morris_chart_height text tspan {
  fill: #fff;
}

[data-theme-version="dark"] .btn-link {
  color: #fff;
}

[data-theme-version="dark"] .order-bg {
  background: #171622;
}

[data-theme-version="dark"] .detault-daterange .input-group-text {
  background: #171622;
  color: #fff;
}

[data-theme-version="dark"] .dataTablesCard {
  background-color: #212130;
}

[data-theme-version="dark"] .compose-content .dropzone {
  background: #171622;
}

[data-theme-version="dark"] .compose-content .dropzone .dz-message .dz-button {
  color: #fff;
}

[data-theme-version="dark"] .daterangepicker {
  background: #171622;
  border-color: var(--primary);
}

[data-theme-version="dark"] .daterangepicker .calendar-table {
  border-color: var(--primary);
  background: #171622;
}

[data-theme-version="dark"] .daterangepicker .calendar-table .table-condensed td:hover {
  background-color: var(--primary);
  color: #fff;
}

[data-theme-version="dark"] .daterangepicker:after {
  border-bottom: 0.375rem solid #171622;
}

[data-theme-version="dark"] .daterangepicker select.hourselect,
[data-theme-version="dark"] .daterangepicker select.minuteselect,
[data-theme-version="dark"] .daterangepicker select.secondselect,
[data-theme-version="dark"] .daterangepicker select.ampmselect {
  background: #171622;
  border: 0.0625rem solid #2e2e42;
  color: #fff;
}

[data-theme-version="dark"] .daterangepicker td.off,
[data-theme-version="dark"] .daterangepicker td.off.in-range,
[data-theme-version="dark"] .daterangepicker td.off.start-date,
[data-theme-version="dark"] .daterangepicker td.off.end-date {
  background-color: #212130;
}

[data-theme-version="dark"] .daterangepicker td.off:hover,
[data-theme-version="dark"] .daterangepicker td.off.in-range:hover,
[data-theme-version="dark"] .daterangepicker td.off.start-date:hover,
[data-theme-version="dark"] .daterangepicker td.off.end-date:hover {
  background-color: var(--primary);
  color: #fff;
}

[data-theme-version="dark"] .app-fullcalendar .fc-button {
  background-color: #171622;
  border-color: var(--primary);
  color: #fff;
  text-shadow: none;
}

[data-theme-version="dark"] .app-fullcalendar .fc-button:hover,
[data-theme-version="dark"] .app-fullcalendar .fc-button.fc-stat-hover {
  background-color: var(--primary);
}

[data-theme-version="dark"] .swal2-popup .swal2-styled:focus {
  outline: 0;
  box-shadow: 0 0 0 0.125rem #2e2e42, 0 0 0 0.25rem var(--rgba-primary-1);
}

[data-theme-version="dark"] .dd-handle {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .menu-toggle .deznav .metismenu li>ul {
  background: #212130 !important;
}

[data-theme-version="dark"] .header-right .notification_dropdown .nav-link {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .nav-tabs .nav-link:hover,
[data-theme-version="dark"] .nav-tabs .nav-link.active {
  border-color: var(--primary);
}

[data-theme-version="dark"] .clockpicker-popover .popover-content {
  background-color: #212130;
}

[data-theme-version="dark"] .clockpicker-plate {
  background-color: #171622;
}

[data-theme-version="dark"] .clockpicker-popover .popover-title {
  background-color: #171622;
  color: #fff;
}

[data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link span {
  background-color: #171622;
}

[data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link:after {
  background: #171622;
}

[data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link.active:after,
[data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link.done:after {
  background: var(--primary);
}

[data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link.active span,
[data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link.done span {
  background: var(--primary);
}

[data-theme-version="dark"] .check-switch .custom-control-label:after,
[data-theme-version="dark"] .check-switch .custom-control-label:before {
  border-color: var(--primary);
}

[data-theme-version="dark"] .fc-unthemed .fc-today {
  background: #171622;
}

[data-theme-version="dark"] .fc-unthemed .fc-divider,
[data-theme-version="dark"] .fc-unthemed .fc-list-heading td,
[data-theme-version="dark"] .fc-unthemed .fc-popover .fc-header {
  background: #2e2e42;
}

[data-theme-version="dark"] .picker__box {
  background: #171622;
}

[data-theme-version="dark"] .picker__box .picker__button--clear,
[data-theme-version="dark"] .picker__box .picker__button--close,
[data-theme-version="dark"] .picker__box .picker__button--today {
  background: #212130;
  color: #fff;
}

[data-theme-version="dark"] .picker__box .picker__button--clear:hover:before,
[data-theme-version="dark"] .picker__box .picker__button--close:hover:before,
[data-theme-version="dark"] .picker__box .picker__button--today:hover:before {
  color: #fff;
}

[data-theme-version="dark"] .picker {
  color: #999;
}

[data-theme-version="dark"] .dtp>.dtp-content {
  background: #171622;
}

[data-theme-version="dark"] .dtp table.dtp-picker-days tr>td>a {
  color: #68686a;
}

[data-theme-version="dark"] .dtp table.dtp-picker-days tr>td>a.selected {
  color: #fff;
}

[data-theme-version="dark"] .order-request tbody tr {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .card-list li {
  color: #fff;
}

[data-theme-version="dark"] .card-bx .change-btn:hover {
  color: var(--primary);
}

[data-theme-version="dark"] .invoice-card.bg-warning {
  background-color: #5b3c1f !important;
}

[data-theme-version="dark"] .invoice-card.bg-success {
  background-color: #2a6729 !important;
}

[data-theme-version="dark"] .invoice-card.bg-info {
  background-color: #4c276a !important;
}

[data-theme-version="dark"] .invoice-card.bg-secondary {
  background-color: #1c3e52 !important;
}

[data-theme-version="dark"] .user-list li {
  border-color: #212130;
}

[data-theme-version="dark"] .toggle-switch {
  color: #fff;
}

[data-theme-version="dark"] .bar-chart .apexcharts-text tspan {
  fill: #969ba0;
}

[data-theme-version="dark"] .bar-chart line {
  stroke: #2e2e42;
}

[data-theme-version="dark"] .coin-card {
  background: #0f6a62;
  background: -moz-linear-gradient(left, #0f6a62 0%, #084355 100%);
  background: -webkit-linear-gradient(left, #0f6a62 0%, #084355 100%);
  background: linear-gradient(to right, #0f6a62 0%, #084355 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0f6a62', endColorstr='#084355', GradientType=1);
}

[data-theme-version="dark"] .coin-card .coin-icon {
  background: rgba(0, 0, 0, 0.2);
}

[data-theme-version="dark"] .accordion.style-1 .accordion-item,
[data-theme-version="dark"] .invoice-list {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .accordion.style-1 .accordion-header.collapsed .user-info a,
[data-theme-version="dark"] .accordion.style-1 .accordion-header.collapsed .user-info,
[data-theme-version="dark"] .accordion.style-1 .accordion-header.collapsed>span {
  color: #fff;
}

[data-theme-version="dark"] .ic-card>a {
  background: #25479f;
}

[data-theme-version="dark"] .ic-card>a:first-child {
  border-color: #25479f;
}

[data-theme-version="dark"] .ic-card span {
  color: #fff;
}

[data-theme-version="dark"] table.dataTable thead th,
[data-theme-version="dark"] table.dataTable thead td {
  border-color: #2e2e42 !important;
}

[data-theme-version="dark"] .form-check .form-check-input {
  background: transparent;
}

[data-theme-version="dark"] .form-check .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

[data-theme-version="dark"] .paging_simple_numbers.dataTables_paginate {
  background: #171622;
}

[data-theme-version="dark"] .dataTables_wrapper .dataTables_paginate span .paginate_button.current,
[data-theme-version="dark"] .dataTables_wrapper .dataTables_paginate span .paginate_button:hover {
  background: var(--primary);
  color: #fff !important;
}

[data-theme-version="dark"] .dashboard-select {
  background: #212130;
}

[data-theme-version="dark"] .dashboard-select .list {
  background: #212130;
}

[data-theme-version="dark"] .dashboard-select .option:hover,
[data-theme-version="dark"] .dashboard-select .option.focus,
[data-theme-version="dark"] .dashboard-select .option.selected.focus {
  background: #171622;
}

[data-theme-version="dark"] .card-tabs.style-1 .nav-tabs {
  background: #212130;
}

[data-theme-version="dark"] .transaction-details {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .description {
  color: #fff;
}

[data-theme-version="dark"] .transaction-details .amount-bx {
  background: #3f250d;
}

[data-theme-version="dark"] .transaction-details .amount-bx i {
  background: #8d3b0c;
}

[data-theme-version="dark"] .booking-calender .bootstrap-datetimepicker-widget table thead tr:first-child th {
  color: #fff;
}

[data-theme-version="dark"] .booking-calender .bootstrap-datetimepicker-widget table thead tr:first-child th:hover {
  background: #171622;
}

[data-theme-version="dark"] .booking-calender .bootstrap-datetimepicker-widget table td,
[data-theme-version="dark"] .booking-calender .patient-calender .bootstrap-datetimepicker-widget table th {
  color: #fff;
}

[data-theme-version="dark"] .booking-calender .bootstrap-datetimepicker-widget table td:hover,
[data-theme-version="dark"] .booking-calender .patient-calender .bootstrap-datetimepicker-widget table th:hover {
  background: #171622;
}

[data-theme-version="dark"] .booking-calender .bootstrap-datetimepicker-widget table th {
  color: #fff;
}

[data-theme-version="dark"] .booking-calender .bootstrap-datetimepicker-widget table th:hover {
  background: #171622;
}

[data-theme-version="dark"] .progress-bar-legend .result {
  color: #fff;
}

[data-theme-version="dark"] .dz-image-bx,
[data-theme-version="dark"] .dz-review-bx,
[data-theme-version="dark"] .customer-review-list {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .bootstrap-datetimepicker-widget table td span:hover,
[data-theme-version="dark"] .bootstrap-datetimepicker-widget table td i:hover {
  background: #171622;
}

[data-theme-version="dark"] .card-tabs .nav-tabs,
[data-theme-version="dark"] .dataTables_wrapper .dataTables_paginate span,
[data-theme-version="dark"] .guest-bx .carousel .carousel-control-prev,
[data-theme-version="dark"] .guest-bx .carousel .carousel-control-next {
  background: #212130;
}

[data-theme-version="dark"] .card-tabs .nav-tabs .nav-link.active {
  background: #212130;
  color: #fff;
}

[data-theme-version="dark"] .profile-card .user-info-list li span,
[data-theme-version="dark"] .room-list-tbl tr td span,
[data-theme-version="dark"] .dataTables_wrapper .dataTables_info,
[data-theme-version="dark"] .dataTables_wrapper .dataTables_paginate span .paginate_button {
  color: #fff !important;
}

[data-theme-version="dark"] .copyright p {
  color: #fff;
}

[data-theme-version="dark"] .dz-image-bx .dz-media.active:after {
  border-color: #212130;
}

[data-theme-version="dark"] .react-calendar {
  background: #212130;
}

[data-theme-version="dark"] button.react-calendar__tile.react-calendar__month-view__days__day {
  color: #fff;
}

[data-theme-version="dark"] span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  color: #fff;
}

[data-theme-version="dark"] button.react-calendar__navigation__label:hover {
  background: #212130 !important;
}

[data-theme-version="dark"] .react-calendar__tile:enabled:hover,
[data-theme-version="dark"] .react-calendar__tile:enabled:focus {
  background: #212130 !important;
}

[data-theme-version="dark"] .react-calendar__navigation button {
  color: #fff;
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-position="fixed"] .header {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .header {
  /*  background-color: $d-bg; */
  border-color: #2e2e42;
}

[data-theme-version="dark"] .header-left .dashboard_bar {
  color: #fff;
}

[data-theme-version="dark"] .header-right .search-area .form-control {
  background: #212130;
}

[data-theme-version="dark"] .header-right .search-area .input-group-text {
  border: 0;
  background: #212130;
}

[data-theme-version="dark"] .header-right .search-area .input-group-text a {
  color: #4f7086;
}

[data-theme-version="dark"] .header-right .notification_dropdown .nav-link {
  background: #212130;
}

[data-theme-version="dark"] .header-right .notification_dropdown .nav-link .badge {
  border-color: #212130;
}

[data-theme-version="dark"] .header-right .dropdown .nav-link {
  color: #fff;
}

[data-theme-version="dark"] .header-right .dropdown .nav-link:hover {
  color: #fff;
}

[data-theme-version="dark"] .header-right .header-profile .nav-link .header-info span,
[data-theme-version="dark"] .header-right .header-profile .nav-link .header-info small,
[data-theme-version="dark"] .header-right .header-profile .nav-link .header-info .small {
  color: #fff;
}

[data-theme-version="dark"] .nav-header .hamburger .line {
  background: #fff !important;
}

[data-theme-version="dark"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #fff !important;
}

[data-theme-version="dark"] .nav-header {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .nav-control {
  color: #fff;
}

[data-theme-version="dark"] .brand-logo {
  color: #fff;
}

[data-theme-version="dark"] .brand-logo:hover {
  color: #fff;
}

[data-theme-version="dark"] .brand-title path {
  fill: #fff;
}

[data-theme-version="dark"] .svg-title-path {
  fill: var(--primary);
}

[data-theme-version="dark"] .nav-header {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .nav-control {
  color: #fff;
}

[data-theme-version="dark"] .brand-logo {
  color: #fff;
}

[data-theme-version="dark"] .brand-logo:hover {
  color: #fff;
}

[data-theme-version="dark"] .brand-title path {
  fill: #fff;
}

[data-theme-version="dark"] .svg-title-path {
  fill: var(--primary);
}

[data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu li>ul {
  background-color: #212130;
}

[data-theme-version="dark"] .fixed-content-box {
  background-color: #212130;
}

[data-theme-version="dark"] .fixed-content-box .head-name {
  background: #212130;
  color: #fff;
  border-color: #2e2e42;
}

[data-theme-version="dark"] .fixed-content-box+.header+.deznav {
  background-color: #171622;
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-position="fixed"] .deznav {
  border-color: #2e2e42;
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a {
  background: transparent !important;
  color: #fff;
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a i,
[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a i {
  color: #fff;
  background: var(--primary);
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu>li a>i {
  color: rgba(255, 255, 255, 0.3);
}

[data-theme-version="dark"] .deznav .header-profile>a.nav-link {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-theme-version="dark"] .deznav .metismenu>li>a {
  color: #b3b3b3;
}

[data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-theme-version="dark"] .deznav .metismenu>li:focus>a,
[data-theme-version="dark"] .deznav .metismenu>li.mm-active>a {
  color: #fff;
  background: var(--primary) !important;
}

[data-theme-version="dark"] .deznav .metismenu>li:hover>a::after,
[data-theme-version="dark"] .deznav .metismenu>li:focus>a::after,
[data-theme-version="dark"] .deznav .metismenu>li.mm-active>a::after {
  border-top: 0.3125rem solid #b3b3b3;
  border-left: 0.3125rem solid #b3b3b3;
  border-bottom: 0.3125rem solid transparent;
  border-right: 0.3125rem solid transparent;
}

[data-theme-version="dark"] .deznav .metismenu>li:hover>a i,
[data-theme-version="dark"] .deznav .metismenu>li:focus>a i,
[data-theme-version="dark"] .deznav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-theme-version="dark"] .deznav .metismenu>li.mm-active ul ul {
  background-color: transparent;
}

[data-theme-version="dark"] .deznav .metismenu ul:after {
  background-color: #2e2e42;
}

[data-theme-version="dark"] .deznav .metismenu ul a:hover,
[data-theme-version="dark"] .deznav .metismenu ul a:focus,
[data-theme-version="dark"] .deznav .metismenu ul a.mm-active {
  color: #fff;
}

[data-theme-version="dark"] .deznav .metismenu ul a:before {
  background-color: #fff;
}

[data-theme-version="dark"] .deznav .metismenu a {
  color: #b3b3b3;
}

[data-theme-version="dark"] .deznav .metismenu .has-arrow:after {
  border-top: 0.3125rem solid #b3b3b3;
  border-left: 0.3125rem solid #b3b3b3;
  border-bottom: 0.3125rem solid transparent;
  border-right: 0.3125rem solid transparent;
}

[data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs {
  background: transparent;
  border-color: #f5f5f5;
}

[data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs .nav-item .nav-link {
  color: #000;
}

[data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs .nav-item .nav-link.active {
  background: var(--rgba-primary-1);
}

[data-theme-version="dark"] .sidebar-right .form-control {
  background: #fff;
  color: #000;
  border-color: #F2F2F2;
}

[data-theme-version="dark"] .sidebar-right .default-select .list {
  background: #fff;
}

[data-theme-version="dark"] .sidebar-right .default-select .list .option.selected,
[data-theme-version="dark"] .sidebar-right .default-select .list .option.focus,
[data-theme-version="dark"] .sidebar-right .default-select .list .option:hover {
  background: rgba(0, 0, 0, 0.05);
}

[data-theme-version="dark"] .sidebar-right .sidebar-right-inner>h4,
[data-theme-version="dark"] .sidebar-right .sidebar-right-inner>.h4 {
  color: #000 !important;
}

[data-theme-version="dark"] .footer {
  background-color: transparent;
}

[data-theme-version="dark"] .footer .copyright {
  background-color: transparent;
}

[data-theme-version="dark"] .footer .copyright a {
  color: #fff;
}

/* =============
   Calendar
============= */
.app-fullcalender button {
  border-radius: 0rem;
  color: #6e6e6e;
}

.app-fullcalender td {
  border-color: #F2F2F2;
}

.calendar {
  float: left;
  margin-bottom: 0;
}

.fc-view {
  margin-top: 1.875rem;
}

.fc-toolbar {
  margin-bottom: 0.3125rem;
  margin-top: 0.9375rem;
}

.fc-toolbar .fc-left {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  float: none;
}

.fc-toolbar .fc-right {
  display: flex;
  justify-content: center;
  float: none;
  margin-bottom: 0.3125rem;
}

.fc-toolbar .fc-center {
  display: flex;
  justify-content: center;
}

.fc-toolbar .fc-center * {
  float: none;
}

.fc-toolbar h2,
.fc-toolbar .h2 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.875rem;
  text-transform: uppercase;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar .ui-state-hover,
.fc-toolbar button:focus,
.fc-toolbar button:hover {
  z-index: 0;
  box-shadow: none;
}

.fc-widget-header {
  border: 0.0625rem solid #F2F2F2;
  border-bottom: 0 !important;
}

.fc th.fc-widget-header {
  background: #F2F2F2 !important;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.625rem 0;
  text-transform: uppercase;
}

.fc-button {
  border: 0.0625rem solid #F2F2F2;
  text-transform: capitalize;
}

.fc-button.active {
  box-shadow: none !important;
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 1rem;
}

.fc-event,
.external-event {
  border-radius: 0.125rem;
  border: none;
  cursor: move;
  font-size: 0.8125rem;
  margin: 0.3125rem 0.4375rem;
  padding: 0.3125rem;
  text-align: center;
}

.external-event {
  cursor: move;
  margin: 0.625rem 0;
  padding: 0.125rem 0;
}

.fc-basic-view td.fc-week-number span {
  padding-right: 0.3125rem;
}

.fc-basic-view td.fc-day-number {
  padding-right: 0.3125rem;
}

#drop-remove {
  margin-right: 0.5rem;
  top: 0.1875rem;
}

#add-category .modal-dialog,
#event-modal .modal-dialog {
  max-width: 37.5rem;
}

.fc-content {
  color: #fff;
}

.fc th.fc-widget-header {
  background: transparent !important;
}

.fc-button {
  background: #fff;
}

.fc-state-hover {
  background: #fff !important;
}

.fc-state-highlight {
  background: #f2f4fa !important;
}

[data-theme-version="dark"] .fc-state-highlight {
  color: #fff !important;
}

.fc-cell-overlay {
  background: #fff !important;
}

.fc-unthemed .fc-today {
  background: #f2f4fa !important;
}

.fc-day-top {
  color: #6e6e6e !important;
}

[data-theme-version="dark"] .fc-day-top {
  color: #fff !important;
}

.external-event {
  color: #fff;
}

[data-theme-version="dark"] .external-event {
  color: #fff !important;
}

.fc-basic-view .fc-body .fc-row {
  min-height: 1rem;
}

.fc-scroller.fc-day-grid-container {
  height: 30.625rem !important;
}

.fc-row.fc-week.fc-widget-content.fc-rigid {
  height: 5.0625rem !important;
}

@media only screen and (max-width: 90rem) {
  .email_left_pane {
    display: none;
  }
}

#external-events .external-event:before {
  content: "";
  display: block;
  width: 0.875rem;
  min-width: 0.875rem;
  height: 0.875rem;
  border-radius: 50%;
  margin-right: .9rem;
  position: relative;
  top: 0.125rem;
}

[data-theme-version="dark"] #external-events [data-class="bg-primary"] {
  color: #fff !important;
}

#external-events [data-class="bg-primary"]::before {
  background: var(--primary);
}

#external-events [data-class="bg-success"]::before {
  background: #37D159;
}

#external-events [data-class="bg-warning"]::before {
  background: #FFC368;
}

#external-events [data-class="bg-dark"]::before {
  background: #6e6e6e;
}

#external-events [data-class="bg-danger"]::before {
  background: #FF6746;
}

#external-events [data-class="bg-info"]::before {
  background: #b48dd3;
}

#external-events [data-class="bg-pink"]::before {
  background: #e83e8c;
}

#external-events [data-class="bg-secondary"]::before {
  background: #627EEA;
}

.fc .fc-row .fc-content-skeleton table,
.fc .fc-row .fc-content-skeleton td,
.fc .fc-row .fc-helper-skeleton td {
  border-color: #F2F2F2;
}

[data-theme-version="dark"] .fc .fc-row .fc-content-skeleton table,
[data-theme-version="dark"] .fc .fc-row .fc-content-skeleton td,
[data-theme-version="dark"] .fc .fc-row .fc-helper-skeleton td {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .fc-unthemed .fc-content,
[data-theme-version="dark"] .fc-unthemed .fc-divider,
[data-theme-version="dark"] .fc-unthemed .fc-list-heading td,
[data-theme-version="dark"] .fc-unthemed .fc-list-view,
[data-theme-version="dark"] .fc-unthemed .fc-popover,
[data-theme-version="dark"] .fc-unthemed .fc-row,
[data-theme-version="dark"] .fc-unthemed tbody,
[data-theme-version="dark"] .fc-unthemed td,
[data-theme-version="dark"] .fc-unthemed th,
[data-theme-version="dark"] .fc-unthemed thead {
  border-color: #2e2e42;
}

.email-left-box {
  width: 15rem;
  float: left;
  padding: 0 1.25rem 1.25rem 1rem;
  border-top: 0;
  border-left: 0;
}

@media screen and (min-width: 40.5625em) and (max-width: 106.25em) {
  .email-left-box.generic-width {
    width: 15rem;
  }
}

.email-left-box .intro-title {
  background: var(--rgba-primary-1);
  padding: 1rem;
  margin: 1.875rem 0 1.25rem 0;
}

.email-left-box .intro-title h5,
.email-left-box .intro-title .h5 {
  margin-bottom: 0;
  color: #6a707e;
  font-size: 0.875em;
}

.email-left-box .intro-title h5 i,
.email-left-box .intro-title .h5 i {
  font-size: 0.75rem;
  position: relative;
  bottom: 0.0625em;
}

.email-left-box .intro-title i {
  color: var(--primary);
}

@media only screen and (max-width: 74.9375rem) {
  .email-left-box {
    width: 100%;
  }
}

.email-right-box {
  padding-left: 16rem;
  padding-right: 1rem;
  margin-left: 0 !important;
}

.email-right-box.generic-position {
  padding-left: 15rem;
}

@media (min-width: 106.25em) {
  .email-right-box.generic-position {
    padding-left: 18.75rem;
  }
}

.email-right-box .right-box-border {
  border-right: 0.125em solid var(--rgba-primary-1);
}

@media screen and (min-width: 40.5625em) and (max-width: 75em) {
  .email-right-box .right-box-padding {
    padding-left: 1.25rem;
  }
}

@media (min-width: 106.25em) {
  .email-right-box .right-box-padding {
    padding-left: 0.9375rem;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .email-right-box {
    padding-left: 0rem;
  }
}

.toolbar .btn-group .btn {
  border: 0;
}

.toolbar .btn-group input {
  position: relative;
  top: 0.125em;
}

.read-content textarea {
  height: 9.375em;
  padding: 0.9375em 1.25em;
}

.read-content-email {
  font-size: 0.875rem;
}

.read-content h5,
.read-content .h5 {
  color: #6a707e;
}

.read-content p strong {
  color: #6a707e;
}

.read-content-body p {
  margin-bottom: 1.875rem;
}

.read-content-attachment {
  padding: 0.5rem 0;
}

.read-content-attachment h6,
.read-content-attachment .h6 {
  font-size: 1.125rem;
  color: #6a707e;
}

.read-content-attachment h6 i,
.read-content-attachment .h6 i {
  padding-right: 0.3125rem;
}

.read-content-attachment .attachment>div:not(:last-child) {
  border-right: 0.0625em solid #DDDFE1;
}

.compose-content .wysihtml5-toolbar {
  border-color: #eaeaea;
}

.compose-content .dropzone {
  background: #f2f4fa !important;
}

.compose-content h5,
.compose-content .h5 {
  font-size: 1.0625rem;
  color: #6a707e;
}

.compose-content h5 i,
.compose-content .h5 i {
  font-size: 1.125rem;
  transform: rotate(90deg);
}

.compose-content .dropzone {
  border: 0.0625em dashed #DDDFE1;
  min-height: 13.125rem;
  position: relative;
}

.compose-content .dropzone .dz-message {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.email-list {
  display: block;
  padding-left: 0;
}

.email-list .message {
  position: relative;
  display: block;
  height: 3.125rem;
  line-height: 3.125rem;
  cursor: default;
  transition-duration: 0.3s;
}

.email-list .message a {
  color: #828690;
}

.email-list .message-single .custom-checkbox {
  margin-top: 0.125em;
}

.email-list .message-single i {
  color: #89879f;
  font-size: 1.125rem;
  padding-left: .4rem;
}

.email-list .message:hover {
  transition-duration: 0.05s;
  background: rgba(152, 166, 173, 0.15);
}

.email-list .message .col-mail {
  float: left;
  position: relative;
}

.email-list .message .col-mail-1 {
  width: 5.625rem;
}

.email-list .message .col-mail-1 .star-toggle {
  display: block;
  float: left;
  margin-top: 1.125rem;
  font-size: 1rem;
  margin-left: 0.3125rem;
}

.email-list .message .col-mail-1 .email-checkbox {
  display: block;
  float: left;
  margin: 0.9375rem 0.625rem 0 1.25rem;
}

.email-list .message .col-mail-1 .dot {
  display: block;
  float: left;
  border: .4rem solid transparent;
  border-radius: 6.25rem;
  margin: 1.375rem 1.625rem 0;
  height: 0;
  width: 0;
  line-height: 0;
  font-size: 0;
}

.email-list .message .col-mail-2 {
  position: absolute;
  top: 0;
  left: 5.625rem;
  right: 0;
  bottom: 0;
}

.email-list .message .col-mail-2 .subject {
  position: absolute;
  top: 0;
  left: 0;
  right: 5.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.email-list .message .col-mail-2 .date {
  position: absolute;
  top: 0;
  right: 0;
}

.email-checkbox {
  cursor: pointer;
  height: 0.9375rem;
  width: 0.9375rem;
  position: relative;
  display: inline-block;
  border-radius: .1rem;
  position: relative;
  top: 0.3125rem;
  box-shadow: inset 0 0 0 0.1rem #828690;
}

.email-checkbox input {
  opacity: 0;
  cursor: pointer;
}

.email-checkbox input:checked label {
  opacity: 1;
}

.email-checkbox label {
  position: absolute;
  top: .3rem;
  left: .3rem;
  right: .3rem;
  bottom: .3rem;
  cursor: pointer;
  opacity: 0;
  margin-bottom: 0 !important;
  transition-duration: 0.05s;
}

.mail-list a {
  vertical-align: middle;
  padding: 0.625rem 0.9375rem;
  display: block;
  background: transparent;
  color: #464a53;
  font-weight: 600;
}

.mail-list .list-group-item {
  color: #6a707e;
  padding: 0.75rem 1.0625rem;
}

.mail-list .list-group-item i {
  font-size: 1rem;
  padding-right: 0.625rem;
  color: #cccccc;
}

.mail-list .list-group-item.active {
  color: #fff;
}

.mail-list .list-group-item.active i {
  color: #fff;
}

.chat-wrap {
  padding: 1.0625rem 1.875rem;
}

.chat-wrap .media .media-body h6,
.chat-wrap .media .media-body .h6 {
  font-size: 1.0625rem;
  color: #6a707e;
}

.chat-wrap .media .media-body p {
  font-size: 0.875rem;
}

@media (min-width: 40.5em) {
  .email-filter {
    padding-left: 1.25rem;
  }
}

@media (min-width: 106.25em) {
  .email-filter {
    padding-left: 1.875rem;
  }
}

.email-filter .input-group-prepend i {
  font-size: 0.875rem;
  color: #89879f;
}

.email-filter .input-group-prepend .input-group-text {
  border: 0;
  border-bottom: 0.0625em solid #DDDFE1 !important;
  background: transparent;
}

.email-filter .input-group .form-control {
  padding: 0 0 0 0.3125rem;
  border: 0;
  font-size: 0.875rem;
  height: 1.875rem;
  color: #89879f;
  border-bottom: 0.0625em solid #DDDFE1;
}

.email-filter .input-group .form-control::placeholder {
  font-size: 0.875rem;
  color: #89879f;
}

.email-filter .input-group>.form-control {
  min-height: 1.875rem;
}

.single-mail {
  display: block;
  padding: 1.5625rem 0;
}

.single-mail .media {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

@media (min-width: 106.25em) {
  .single-mail .media {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
}

.single-mail .media img {
  width: 3.4375em;
  height: 3.4375em;
  border-radius: 50%;
  margin-right: 0.9375rem;
}

@media (min-width: 106.25em) {
  .single-mail .media img {
    margin-right: 1.875rem;
  }
}

.single-mail .media-body h6,
.single-mail .media-body .h6 {
  color: #abafb3;
}

.single-mail .media-body h4,
.single-mail .media-body .h4 {
  font-size: 1rem;
  color: #6a707e;
}

.single-mail .media-body h4 button i,
.single-mail .media-body .h4 button i {
  font-size: 1.125rem;
  color: #abafb3;
  font-weight: bold;
  transform: rotate(90deg);
}

.single-mail .media-body p {
  font-size: 0.875rem;
  color: #abafb3;
}

.single-mail.active {
  background: var(--primary);
}

.single-mail.active h6,
.single-mail.active .h6,
.single-mail.active h4,
.single-mail.active .h4,
.single-mail.active p,
.single-mail.active i {
  color: #fff !important;
}

[direction="rtl"] .email-right-box {
  padding-left: 1rem;
  padding-right: 15rem;
}

@media only screen and (max-width: 61.9375rem) {
  [direction="rtl"] .email-right-box {
    padding-left: 0;
    padding-right: 0;
    margin-right: 0;
  }
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .email-right-box {
    padding-left: 0;
    padding-right: 0;
  }
}

[direction="rtl"] .email-left-box {
  float: right;
}

[direction="rtl"] .email-list .message .col-mail-2 {
  right: 5.625rem;
  left: 0;
  float: right;
}

[direction="rtl"] .email-list .message .col-mail-2 .date {
  right: auto;
  left: 0;
}

[direction="rtl"] .email-list .message .col-mail-2 .subject {
  right: 0;
  left: 5.5rem;
}

.photo-content {
  position: relative;
}

.photo-content .cover-photo {
  background-size: cover;
  background-position: center;
  min-height: 15.625rem;
  width: 100%;
}

.profile .profile-photo {
  max-width: 6.25rem;
  position: relative;
  z-index: 1;
  margin-top: -2.5rem;
  margin-right: 0.625rem;
}

@media only screen and (max-width: 35.9375rem) {
  .profile .profile-photo {
    width: 5rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.25rem;
  }
}

[direction="rtl"] .profile .profile-photo {
  left: auto;
  right: 0;
  margin-right: 0;
  margin-left: 0.9375rem;
}

@media only screen and (max-width: 74.9375rem) {
  [direction="rtl"] .profile .profile-photo {
    right: 0.9375rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  [direction="rtl"] .profile .profile-photo {
    width: 5rem;
    right: calc(50% - 2.5rem);
    top: -6.25rem;
  }
}

.profile-info {
  padding: 0.9375rem 1.25rem;
}

@media only screen and (max-width: 35.9375rem) {
  .profile-info {
    padding: 0 0 1.25rem;
    text-align: center;
  }
}

.profile-info h4,
.profile-info .h4 {
  color: #464a53 !important;
}

.profile-info h4.text-primary,
.profile-info .text-primary.h4 {
  color: var(--primary) !important;
}

.profile-info p {
  color: #828690;
}

.profile-info .prf-col {
  min-width: 15.625rem;
  padding: 0.625rem 3.125rem 0;
}

.profile-interest .row {
  margin: 0 -0.0437rem;
}

.profile-interest .row .int-col {
  padding: 0 0.0437rem;
}

.profile-interest .row .int-col .interest-cat {
  margin-bottom: 0.0875rem;
  position: relative;
  display: block;
}

.profile-interest .row .int-col .interest-cat:after {
  background: #000;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}

.profile-interest .row .int-col .interest-cat p {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0.3125rem;
  left: 0;
  margin: 0;
  z-index: 1;
  color: white;
  font-size: 0.075rem;
}

.profile-tab .nav-item .nav-link {
  font-size: 1rem;
  margin-right: 1.875rem;
  transition: all 0.5s ease-in-out;
  border: none;
  border-bottom: 0.0125rem solid transparent;
  color: #828690;
}

.profile-tab .nav-item .nav-link:hover,
.profile-tab .nav-item .nav-link.active {
  border: 0;
  background: transparent;
  background: transparent;
  border-bottom: 0.0125rem solid var(--primary);
  color: var(--primary);
}

@media only screen and (max-width: 35.9375rem) {
  .profile-tab .nav-item .nav-link {
    margin-right: 0rem;
  }
}

.profile-info {
  display: flex;
}

@media only screen and (max-width: 35.9375rem) {
  .profile-info {
    display: block;
  }
}

.profile-info .profile-details {
  display: flex;
  width: 100%;
}

@media only screen and (max-width: 35.9375rem) {
  .profile-info .profile-details {
    display: block;
  }

  .profile-info .profile-details .dropdown {
    position: absolute;
    top: 1.875rem;
    right: 1.875rem;
  }
}

.post-input {
  margin-bottom: 1.875rem;
}

.post-input .form-control {
  height: 4.6875rem;
  font-weight: 400;
  margin: 0.9375rem 0;
}

.post-input .btn-social {
  font-size: 1.25rem;
  height: 3.4375rem;
  display: inline-block;
  padding: 0;
  text-align: center;
  border-radius: 1.375rem;
  color: #fff;
  width: 3.4375rem;
  line-height: 3.375rem;
}

.post-input .btn-social.facebook {
  background-color: #3B5998;
}

.post-input .btn-social.google-plus {
  background-color: #DE4E43;
}

.post-input .btn-social.linkedin {
  background-color: #007BB6;
}

.post-input .btn-social.instagram {
  background-color: #8A5A4E;
}

.post-input .btn-social.twitter {
  background-color: #1EA1F3;
}

.post-input .btn-social.youtube {
  background-color: #CE201F;
}

.post-input .btn-social.whatsapp {
  background-color: #01C854;
}

.post-input .btn-social i {
  margin: 0 !important;
}

.profile-uoloaded-post img {
  margin-bottom: 1.25rem;
}

.profile-uoloaded-post a h4,
.profile-uoloaded-post a .h4 {
  margin-bottom: 0.625rem;
  color: #464a53;
}

.event-chat-ryt .chat-area .chat-reciver,
.event-chat-ryt .chat-area .chat-sender {
  margin-bottom: 1.875rem;
  padding: 0;
}

.event-chat-ryt .chat-area .chat-reciver img,
.event-chat-ryt .chat-area .chat-sender img {
  border-radius: 1.875rem;
}

.event-chat-ryt .chat-area .media {
  position: relative;
}

.event-chat-ryt .chat-area .media-body p {
  margin: 0;
  max-width: 100%;
  display: inline-block;
  position: relative;
}

.event-chat-ryt .chat-area .media-body p span {
  padding: 1rem;
  display: inline-block;
  top: 103%;
  position: relative;
  border: 0.0625rem solid #F2F2F2;
}

.event-chat-ryt .chat-reciver {
  padding: .5rem 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.event-chat-ryt .chat-reciver .media {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.event-chat-ryt .chat-reciver .media .media-body {
  margin-right: 2rem;
  text-align: right;
}

[direction="rtl"] .event-chat-ryt .chat-reciver .media .media-body {
  text-align: left;
  margin-left: 2rem;
  margin-right: auto;
}

.event-chat-ryt .chat-reciver .media .media-body p {
  background: #fff;
  margin-bottom: 0;
  border-radius: 0.3125rem 0.3125rem 0 0.3125rem;
}

.event-chat-ryt .chat-reciver .media .media-body p span {
  text-align: left;
  border: 0.0625rem solid #F2F2F2;
}

.event-chat-ryt .chat-reciver .media .media-body p span::after {
  content: "";
  width: 1.25rem;
  height: 1.25rem;
  border-bottom: 0.0625rem solid #F2F2F2;
  border-right: 0.0625rem solid #F2F2F2;
  position: absolute;
  right: 0;
  bottom: 0;
  background: #fff;
  -webkit-transform: rotate(-45deg) translateX(0.9375rem);
  transform: rotate(-45deg) translateX(0.9375rem);
}

[direction="rtl"] .event-chat-ryt .chat-reciver .media .media-body p span::after {
  left: 0;
  right: auto;
  -webkit-transform: rotate(135deg) translateY(0.9375rem);
  transform: rotate(135deg) translateY(0.9375rem);
}

.event-chat-ryt .chat-reciver .media .media-body p .time {
  position: absolute;
  font-size: 0.75rem;
  color: #969ba0;
  font-weight: 400;
  bottom: 0;
  left: -5rem;
}

[direction="rtl"] .event-chat-ryt .chat-reciver .media .media-body p .time {
  right: -5rem;
  left: auto;
}

.event-chat-ryt .chat-sender {
  text-align: left;
  padding: .5rem 1rem;
}

.event-chat-ryt .chat-sender .media .media-body {
  margin-left: 2rem;
}

[direction="rtl"] .event-chat-ryt .chat-sender .media .media-body {
  text-align: right;
  margin-right: 2rem;
  margin-left: auto;
}

.event-chat-ryt .chat-sender .media .media-body p {
  background-color: #fff;
  margin-bottom: 0;
}

.event-chat-ryt .chat-sender .media .media-body p span::after {
  content: "";
  width: 1.25rem;
  height: 1.25rem;
  border-bottom: 0.0625rem solid #F2F2F2;
  border-left: 0.0625rem solid #F2F2F2;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #fff;
  -webkit-transform: rotate(45deg) translateX(-0.9375rem);
  transform: rotate(45deg) translateX(-0.9375rem);
}

[direction="rtl"] .event-chat-ryt .chat-sender .media .media-body p span::after {
  left: auto;
  right: 0;
  -webkit-transform: rotate(-135deg) translateY(0.9375rem);
  transform: rotate(-135deg) translateY(0.9375rem);
}

.event-chat-ryt .chat-sender .media .media-body p .time {
  position: absolute;
  font-size: 0.625rem;
  color: #969ba0;
  font-weight: 400;
  bottom: 0;
  right: -5rem;
}

[direction="rtl"] .event-chat-ryt .chat-sender .media .media-body p .time {
  left: -5rem;
  right: auto;
}

.char-type {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}

.char-type form .form-control {
  height: 2.8125rem;
  padding-left: 1.125rem;
  background: #FCFCFC;
  border-right: 0;
}

.char-type form .input-group-append i {
  color: #898989;
  font-size: 1.125rem;
}

.char-type form .input-group-append .input-group-text {
  padding-left: .7rem;
  padding-right: .7rem;
  background: #FCFCFC;
  border-color: #F2F2F2;
  border-left: 0;
}

.char-type form .input-group-append .input-group-text:last-child {
  padding-right: 1.8rem;
}

.media-avatar {
  padding: 1.5625rem 0;
  border-bottom: 0.0625rem solid #F2F2F2;
}

.media-avatar:last-child {
  border-bottom: 0rem;
}

.media-avatar p {
  margin-bottom: 0;
}

.media-avatar .avatar-status {
  position: relative;
}

.media-avatar .avatar-status i {
  position: absolute;
  right: 0;
  bottom: 0;
}

.ct-golden-section:before {
  float: none;
}

.ct-chart {
  max-height: 15.7rem;
}

.ct-chart .ct-label {
  fill: #a3afb7;
  color: #a3afb7;
  font-size: 0.75rem;
  line-height: 1;
}

.ct-grid {
  stroke: rgba(49, 58, 70, 0.1);
}

.ct-chart.simple-pie-chart-chartist .ct-label {
  color: #ffffff;
  fill: #ffffff;
  font-size: 0.625rem;
}

.ct-chart .ct-series.ct-series-a .ct-bar,
.ct-chart .ct-series.ct-series-a .ct-line,
.ct-chart .ct-series.ct-series-a .ct-point,
.ct-chart .ct-series.ct-series-a .ct-slice-donut {
  stroke: var(--primary);
}

.ct-chart .ct-series.ct-series-b .ct-bar,
.ct-chart .ct-series.ct-series-b .ct-line,
.ct-chart .ct-series.ct-series-b .ct-point,
.ct-chart .ct-series.ct-series-b .ct-slice-donut {
  stroke: #37D159;
}

.ct-chart .ct-series.ct-series-c .ct-bar,
.ct-chart .ct-series.ct-series-c .ct-line,
.ct-chart .ct-series.ct-series-c .ct-point,
.ct-chart .ct-series.ct-series-c .ct-slice-donut {
  stroke: #FFC368;
}

.ct-chart .ct-series.ct-series-d .ct-bar,
.ct-chart .ct-series.ct-series-d .ct-line,
.ct-chart .ct-series.ct-series-d .ct-point,
.ct-chart .ct-series.ct-series-d .ct-slice-donut {
  stroke: #FF6746;
}

.ct-chart .ct-series.ct-series-e .ct-bar,
.ct-chart .ct-series.ct-series-e .ct-line,
.ct-chart .ct-series.ct-series-e .ct-point,
.ct-chart .ct-series.ct-series-e .ct-slice-donut {
  stroke: #b48dd3;
}

.ct-chart .ct-series.ct-series-f .ct-bar,
.ct-chart .ct-series.ct-series-f .ct-line,
.ct-chart .ct-series.ct-series-f .ct-point,
.ct-chart .ct-series.ct-series-f .ct-slice-donut {
  stroke: #6e6e6e;
}

.ct-chart .ct-series.ct-series-g .ct-bar,
.ct-chart .ct-series.ct-series-g .ct-line,
.ct-chart .ct-series.ct-series-g .ct-point,
.ct-chart .ct-series.ct-series-g .ct-slice-donut {
  stroke: #8d6e63;
}

.ct-series-a .ct-area,
.ct-series-a .ct-slice-pie {
  fill: #627EEA;
}

.ct-series-b .ct-area,
.ct-series-b .ct-slice-pie {
  fill: #00A2FF;
}

.ct-series-c .ct-area,
.ct-series-c .ct-slice-pie {
  fill: #ff9800;
}

.ct-series-d .ct-area,
.ct-series-d .ct-slice-pie {
  fill: #ff9800;
}

.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 0.625rem;
  padding: 0.125rem 0.625rem;
  border-radius: 0.1875rem;
  background: #313a46;
  color: #ffffff;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  -webkit-transition: opacity .2s linear;
  -moz-transition: opacity .2s linear;
  -o-transition: opacity .2s linear;
  transition: opacity .2s linear;
}

.chartist-tooltip.tooltip-show {
  opacity: 1;
}

#donught_graph .ct-series.ct-series-a .ct-slice-donut {
  stroke: #3FC6D4;
}

#donught_graph .ct-series.ct-series-b .ct-slice-donut {
  stroke: #333333;
}

#donught_graph .ct-series.ct-series-c .ct-slice-donut {
  stroke: #F63465;
}

@media only screen and (max-width: 47.9375rem) {
  #pie-chart .ct-label {
    color: #fff;
    fill: #fff;
  }
}

#visitor_graph {
  height: 15.8125rem !important;
}

#user_rating_graph {
  height: 17.5rem !important;
}

#activity {
  height: 16.875rem !important;
}

#visitorOnline {
  height: 4.5rem !important;
}

#trendMeter {
  height: 4.5rem !important;
}

#widget-revenue1,
#widget-revenue2,
#widget-revenue3 {
  height: 7.3125rem !important;
}

#widget-profit1,
#widget-profit2,
#widget-profit3 {
  height: 10rem !important;
}

#comparison-rate {
  height: 14.375rem;
}

#session_day {
  height: 10.9375rem;
  width: auto !important;
  margin: 0 auto;
}

#walet-status {
  height: 8.75rem;
}

#bar1 {
  height: 9.375rem;
}

#sold-product {
  height: 14.375rem;
}

#chart-venue-expenses,
#chart-online-sale,
#chart-gross-sale {
  height: 9.375rem !important;
}

#areaChart_3 {
  height: 18.4375rem !important;
}

.chart-point {
  display: flex;
  align-items: center;
}

.chart-point .check-point-area {
  width: 6.25rem;
  height: 6.25rem;
  margin-top: -0.625rem;
  margin-left: -0.625rem;
}

.chart-point .chart-point-list {
  margin: 0;
  padding-left: 0.3125rem;
}

.chart-point .chart-point-list li {
  list-style: none;
  font-size: 0.8125rem;
  padding: 0.125rem 0;
}

.chart-point .chart-point-list li i {
  margin-right: 0.3125rem;
  font-size: 0.6875rem;
  position: relative;
  top: -0.0625rem;
}

.c3 {
  height: 15.625rem;
}

.c3-legend-item {
  fill: #9fabb1;
}

.c3 .c3-axis-x path,
.c3 .c3-axis-x line,
.c3 .c3-axis-y path,
.c3 .c3-axis-y line,
.tick text {
  stroke: #fff;
}

.flot-chart {
  height: 15.7rem;
}

.tooltipflot {
  background-color: transparent;
  font-size: 1.4rem;
  padding: .5rem 1rem;
  color: rgba(255, 255, 255, 0.7);
  border-radius: .2rem;
}

.legendColorBox>div {
  border: 0 !important;
  padding: 0 !important;
}

.legendLabel {
  font-size: 0.825rem;
  padding-left: .5rem;
  color: #fff;
}

.flotTip {
  background: #000;
  border: 0.0625rem solid #000;
  color: #fff;
}

.legend>div {
  background: transparent !important;
}

#balance_graph {
  height: 16.25rem;
}

.morris-hover {
  position: absolute;
  z-index: 1;
  background: var(--primary);
  color: #fff;
}

.morris-hover .morris-hover-point {
  color: #fff !important;
  margin: 0.1875rem 0;
  text-align: center;
  padding: 0 1.5625rem;
}

.morris-hover .morris-hover-row-label {
  background-color: #6e6e6e;
  text-align: center;
  padding: 0.3125rem;
  margin-bottom: 0.3125rem;
}

.morris-hover.morris-default-style {
  border-radius: 0.3125rem;
  padding: 0;
  margin: 0;
  border: none;
  overflow: hidden;
}

/* svg text {
    font-weight: 600 !important;
} */
#morris_donught,
#morris_donught_2,
#line_chart_2,
#morris_bar,
#morris_bar_stalked,
#morris_bar_2,
#morris_area_2,
#morris_area {
  height: 15rem !important;
}

#morris_line {
  height: 17.375rem !important;
}

#crypto-btc-card,
#crypto-eth-card,
#crypto-rpl-card,
#crypto-ltc-card {
  height: 9.375rem;
}

#daily-sales,
#comparison-rate,
#usage-chart,
#walet-status {
  width: 100%;
  display: block;
}

#daily-sales canvas,
#comparison-rate canvas,
#usage-chart canvas,
#walet-status canvas {
  max-width: 100% !important;
  width: 100% !important;
}

#sparkline-composite-chart canvas,
#composite-bar canvas,
#sparkline11 canvas,
#StackedBarChart canvas,
#spark-bar canvas,
#tristate canvas {
  height: 6.25rem !important;
}

#sparkline11 canvas {
  width: 6.25rem !important;
}

.easy-pie-chart {
  position: relative;
  text-align: center;
}

.easy-pie-chart .inner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
}

.easy-pie-chart img {
  width: 4.6875rem;
  height: 4.6875rem;
  border-radius: 50%;
}

.easy-pie-chart canvas {
  display: block;
  margin: 0 auto;
}

#revenue-chart {
  height: 27.7rem;
}

#duration-value-axis {
  height: 27.6rem;
  width: 100%;
}

#combined-bullet {
  height: 28.125rem;
  width: 100%;
}

#zoomable-chart {
  height: 28.125rem;
  width: 100%;
}

#chartMap {
  height: 28.125rem;
  width: 100%;
}

#professional-candlesticks {
  width: 100%;
  height: 28.125rem;
}

#comparing-stock-indices {
  width: 100%;
  height: 28.125rem;
}

#multiple-panel-data {
  width: 100%;
  height: 28.125rem;
}

#depth-chart {
  width: 100%;
  height: 28.125rem;
}

.amcharts-export-menu {
  display: none;
}

.amcharts-data-set-selector-div {
  position: absolute;
  left: 0;
  right: 0;
  text-align: center;
  width: 16.875rem;
  margin: 0 auto;
}

.amcharts-data-set-selector-div select {
  border: 0;
  margin-left: 0.625rem;
  background: #ddd;
  color: #000;
}

.amChartsInputField {
  border: 0;
  background: var(--primary);
  color: #fff;
  padding: 0.3125rem 0.9375rem;
  margin: 0 0.9375rem;
}

.amcharts-data-set-select {
  border: 0;
  background: #ddd;
  color: #000;
}

.amcharts-period-input,
.amcharts-period-input-selected {
  border: 0;
  margin-left: 0.625rem;
  background: var(--primary);
  color: #fff;
  padding: 0.3125rem 0.9375rem;
}

.amcharts-graph-g2 .amcharts-graph-stroke {
  stroke-dasharray: 0.1875rem 0.1875rem;
  stroke-linejoin: round;
  stroke-linecap: round;
  -webkit-animation: am-moving-dashes 1s linear infinite;
  animation: am-moving-dashes 1s linear infinite;
}

@-webkit-keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -1.9375rem;
  }
}

@keyframes am-moving-dashes {
  100% {
    stroke-dashoffset: -1.9375rem;
  }
}

.lastBullet {
  -webkit-animation: am-pulsating 1s ease-out infinite;
  animation: am-pulsating 1s ease-out infinite;
}

@-webkit-keyframes am-pulsating {
  0% {
    stroke-opacity: 1;
    stroke-width: 0rem;
  }

  100% {
    stroke-opacity: 0;
    stroke-width: 3.125rem;
  }
}

@keyframes am-pulsating {
  0% {
    stroke-opacity: 1;
    stroke-width: 0rem;
  }

  100% {
    stroke-opacity: 0;
    stroke-width: 3.125rem;
  }
}

.amcharts-graph-column-front {
  -webkit-transition: all .3s .3s ease-out;
  transition: all .3s .3s ease-out;
}

.amcharts-graph-column-front:hover {
  fill: #496375;
  stroke: #496375;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
}

@-webkit-keyframes am-draw {
  0% {
    stroke-dashoffset: 500%;
  }

  100% {
    stroke-dashoffset: 0%;
  }
}

@keyframes am-draw {
  0% {
    stroke-dashoffset: 500%;
  }

  100% {
    stroke-dashoffset: 0%;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .amChartsPeriodSelector>fieldset>div {
    float: none !important;
    display: block !important;
    margin-bottom: 0.625rem;
  }
}

.highcharts-root text {
  font-weight: 300 !important;
}

.highcharts-credits {
  display: none;
}

#chart_employee_gender,
#chart_employee_status {
  width: auto;
  height: 21.875rem;
}

.form-control {
  background: #fff;
  border: 0.0625rem solid #F2F2F2;
  padding: 0.3125rem 1.25rem;
  color: #6e6e6e;
  height: 3.5rem;
  border-radius: 1rem;
}

@media only screen and (max-width: 87.5rem) {
  .form-control {
    height: 2.75rem;
  }
}

.form-control:hover,
.form-control:focus,
.form-control.active {
  box-shadow: none;
  background: #fff;
  color: #6e6e6e;
}

.form-control:focus {
  border-color: var(--primary);
}

.form-file .form-control {
  margin: 0;
  border-radius: 0;
  border: 0;
  height: auto;
}

.input-rounded {
  border-radius: 6.25rem;
}

[data-theme-version="light"] .input-primary .form-control,
[data-theme-version="dark"] .input-primary .form-control {
  border-color: var(--primary);
}

[data-theme-version="light"] .input-primary .input-group-text,
[data-theme-version="dark"] .input-primary .input-group-text {
  background-color: var(--primary);
  color: #fff;
}

[data-theme-version="light"] .input-danger .form-control,
[data-theme-version="dark"] .input-danger .form-control {
  border-color: #FF6746;
}

[data-theme-version="light"] .input-danger .input-group-text,
[data-theme-version="dark"] .input-danger .input-group-text {
  background-color: #FF6746;
  color: #fff;
}

[data-theme-version="light"] .input-info .form-control,
[data-theme-version="dark"] .input-info .form-control {
  border-color: #b48dd3;
}

[data-theme-version="light"] .input-info .input-group-text,
[data-theme-version="dark"] .input-info .input-group-text {
  background-color: #b48dd3;
  color: #fff;
}

[data-theme-version="light"] .input-success .form-control,
[data-theme-version="dark"] .input-success .form-control {
  border-color: #37D159;
}

[data-theme-version="light"] .input-success .input-group-text,
[data-theme-version="dark"] .input-success .input-group-text {
  background-color: #37D159;
  color: #fff;
}

[data-theme-version="light"] .input-warning .form-control,
[data-theme-version="dark"] .input-warning .form-control {
  border-color: #FFC368;
}

[data-theme-version="light"] .input-warning .input-group-text,
[data-theme-version="dark"] .input-warning .input-group-text {
  background-color: #FFC368;
  color: #fff;
}

[data-theme-version="light"] .input-primary-o .form-control,
[data-theme-version="dark"] .input-primary-o .form-control {
  border-color: var(--primary);
}

[data-theme-version="light"] .input-primary-o .input-group-text,
[data-theme-version="dark"] .input-primary-o .input-group-text {
  background-color: transparent;
  border-color: var(--primary);
  color: var(--primary);
}

[data-theme-version="light"] .input-danger-o .form-control,
[data-theme-version="dark"] .input-danger-o .form-control {
  border-color: #FF6746;
}

[data-theme-version="light"] .input-danger-o .input-group-text,
[data-theme-version="dark"] .input-danger-o .input-group-text {
  background-color: transparent;
  border-color: #FF6746;
  color: #FF6746;
}

[data-theme-version="light"] .input-info-o .form-control,
[data-theme-version="dark"] .input-info-o .form-control {
  border-color: #b48dd3;
}

[data-theme-version="light"] .input-info-o .input-group-text,
[data-theme-version="dark"] .input-info-o .input-group-text {
  background-color: transparent;
  border-color: #b48dd3;
  color: #b48dd3;
}

[data-theme-version="light"] .input-success-o .form-control,
[data-theme-version="dark"] .input-success-o .form-control {
  border-color: #37D159;
}

[data-theme-version="light"] .input-success-o .input-group-text,
[data-theme-version="dark"] .input-success-o .input-group-text {
  background-color: transparent;
  border-color: #37D159;
  color: #37D159;
}

[data-theme-version="light"] .input-warning-o .form-control,
[data-theme-version="dark"] .input-warning-o .form-control {
  border-color: #FFC368;
}

[data-theme-version="light"] .input-warning-o .input-group-text,
[data-theme-version="dark"] .input-warning-o .input-group-text {
  background-color: transparent;
  border-color: #FFC368;
  color: #FFC368;
}

.input-group-text {
  background: #d7dae3;
  border: 0.0625rem solid transparent;
  min-width: 3.125rem;
  display: flex;
  justify-content: center;
  padding: 0.532rem 0.75rem;
}

.input-group-text i {
  font-size: 1rem;
}

.form-file-label {
  height: 2.5rem;
  padding: 0.5rem 0.75rem;
}

.input-group-prepend .btn,
.input-group-append .btn {
  z-index: 0;
}

.custom-select {
  background: none;
  border-color: #F2F2F2;
  color: #6e6e6e;
}

.custom-select:focus {
  box-shadow: none;
  border-color: var(--primary);
  color: var(--primary);
}

.form-file-label {
  background: #656C73;
  white-space: nowrap;
  color: #fff;
}

[data-theme-version="dark"] .form-file-label {
  background: #2e2e42;
  border-color: #2e2e42;
  color: #969ba0;
}

.custom_file_input .form-file-label::after {
  height: 100%;
}

.form-control:disabled,
.form-control[readonly] {
  background: #fff;
  opacity: 1;
}

.form-file {
  border: 0.0625rem solid #F2F2F2;
  background: #fff;
}

[data-theme-version="dark"] .form-file {
  background: #171622;
  border-color: #2e2e42;
}

.input-group>.form-control-plaintext,
.input-group>.form-select,
.input-group>.form-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.input-group>.form-file {
  display: flex;
  align-items: center;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  border-radius: 1.375rem;
}

:root {
  --primary: #1362FC;
  --secondary: #627EEA;
  --primary-hover: #034bd9;
  --primary-light: #aac7fe;
  --primary-dark: #012875;
  --rgba-primary-1: rgba(19, 98, 252, 0.1);
  --rgba-primary-2: rgba(19, 98, 252, 0.2);
  --rgba-primary-3: rgba(19, 98, 252, 0.3);
  --rgba-primary-4: rgba(19, 98, 252, 0.4);
  --rgba-primary-5: rgba(19, 98, 252, 0.5);
  --rgba-primary-6: rgba(19, 98, 252, 0.6);
  --rgba-primary-7: rgba(19, 98, 252, 0.7);
  --rgba-primary-8: rgba(19, 98, 252, 0.8);
  --rgba-primary-9: rgba(19, 98, 252, 0.9);
  --font-family-base: Roboto, sans-serif;
  --font-family-title: Roboto, sans-serif;
  --title: #000;
}

/* input[type="checkbox"] {
    &:after{
        content: '';
        display: block;
        width: 1rem;
        height: 1rem;
        margin-top: 0px;
        margin-left: -0.0625rem;
        border: 0.0625rem solid transparent;
        border-radius: 3px;
        background: #d4d7da;
        line-height: 1.3;
    }

    &:checked:after{
        width: 1rem;
        height: 1rem;
        display: block;
        content: "\f00c";
        font-family: 'FontAwesome';
        color: $white;
        font-weight: 100;
        font-size: 12px;
        text-align: center;
        border-radius: 3px;
        background: $primary;
    }

} */
.form-check-label {
  margin-left: 0.3125rem;
  margin-top: 0.1875rem;
}

.form-check-inline .form-check-input {
  margin-right: 0.625rem;
}

.form-check-input {
  top: 0.125rem;
  border-width: 0.125rem;
  width: 1.25rem;
  height: 1.25rem;
  border-color: #e7e7e7;
}

.rtl .form-check-label:before,
.rtl .form-check-label:after {
  right: -1.5rem !important;
  left: inherit;
}

.form-check {
  line-height: normal;
}

.form-check.style-1 .form-check-input {
  position: relative;
}

.form-check.style-1 .form-check-input:after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: var(--primary);
  top: 50%;
  left: 50%;
  border-radius: 0.1875rem;
  transform: translate(-50%, -50%) scale(0);
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.form-check.style-1 .form-check-input:checked {
  background-image: none;
  background-color: transparent;
}

.form-check.style-1 .form-check-input:checked:after {
  transform: translate(-50%, -50%) scale(0.6);
}

.toggle-switch {
  padding-left: 3.125rem;
  line-height: 1.25;
  display: inline-block;
  color: #000;
  font-weight: 600;
}

.toggle-switch .form-check-input {
  border: 0;
  cursor: pointer;
  background: #d8d8d8;
  width: 2.3125rem;
  border-radius: 1.25rem !important;
  height: 0.875rem;
  position: relative;
  left: -0.3125rem;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  background-image: none;
}

.toggle-switch .form-check-input:focus {
  background-image: none !important;
}

.toggle-switch .form-check-input:checked {
  background: var(--rgba-primary-2);
  background-image: none !important;
}

.toggle-switch .form-check-input:checked:after {
  left: 1.25rem;
  background: var(--primary);
}

.toggle-switch .form-check-input:focus {
  box-shadow: none;
}

.toggle-switch .form-check-input:after {
  width: 1.25rem;
  background: #909090;
  height: 1.25rem;
  content: "";
  position: absolute;
  border-radius: 1.5rem;
  top: -0.1875rem;
  left: 0;
  box-shadow: 0 0 0.3125rem rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.toggle-switch.text-end {
  padding-right: 0rem;
  padding-left: 0;
}

.toggle-switch.text-end .form-check-input {
  left: auto;
  margin-left: 0;
  float: right;
  right: 0rem;
}

.toggle-switch.text-end .form-check-label {
  margin-right: 0.9375rem;
  margin-left: 0;
}

.toggle-switch .form-check-label {
  cursor: pointer;
}

.form-check-input:focus~.form-check-label::before {
  box-shadow: none !important;
}

.form-check-label::before {
  background-color: transparent;
  border-color: #c8c8c8;
  border-width: 0.125rem;
  border-radius: 0.125rem !important;
}

[data-theme-version="dark"] .form-check-label::before {
  background-color: transparent;
  border-color: #2e2e42;
}

.check-xs .form-check-input {
  width: 1.125rem;
  height: 1.125rem;
}

.check-lg .form-check-input {
  width: 1.5rem;
  height: 1.5rem;
}

.check-xl .form-check-input {
  width: 1.75rem;
  height: 1.75rem;
}

.checkbox-info .form-check-input:focus {
  border-color: #b48dd3;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(180, 141, 211, 0.25);
}

.checkbox-info .form-check-input:checked {
  background-color: #b48dd3;
  border-color: #b48dd3;
}

[data-theme-version="dark"] .checkbox-info .form-check-input:checked {
  background-color: rgba(180, 141, 211, 0.1);
  border-color: transparent;
}

.checkbox-danger .form-check-input:focus {
  border-color: #FF6746;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 103, 70, 0.25);
}

.checkbox-danger .form-check-input:checked {
  background-color: #FF6746;
  border-color: #FF6746;
}

[data-theme-version="dark"] .checkbox-danger .form-check-input:checked {
  background-color: rgba(255, 103, 70, 0.15);
  border-color: transparent;
}

.checkbox-success .form-check-input:focus {
  border-color: #37D159;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(55, 209, 89, 0.25);
}

.checkbox-success .form-check-input:checked {
  background-color: #37D159;
  border-color: #37D159;
}

[data-theme-version="dark"] .checkbox-success .form-check-input:checked {
  background-color: rgba(55, 209, 89, 0.1);
  border-color: transparent;
}

.checkbox-warning .form-check-input:focus {
  border-color: #FFC368;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 195, 104, 0.25);
}

.checkbox-warning .form-check-input:checked {
  background-color: #FFC368;
  border-color: #FFC368;
}

[data-theme-version="dark"] .checkbox-warning .form-check-input:checked {
  background-color: rgba(255, 195, 104, 0.1);
  border-color: transparent;
}

.checkbox-secondary .form-check-input:focus {
  border-color: #627EEA;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(98, 126, 234, 0.25);
}

.checkbox-secondary .form-check-input:checked {
  background-color: #627EEA;
  border-color: #627EEA;
}

[data-theme-version="dark"] .checkbox-secondary .form-check-input:checked {
  background-color: rgba(98, 126, 234, 0.5);
  border-color: transparent;
}

.check-switch {
  padding-left: 2.5rem;
}

.check-switch .form-check-label {
  line-height: 1.875rem;
  font-weight: 500;
}

.check-switch .form-check-label span {
  line-height: 1;
}

.check-switch .form-check-label:after,
.check-switch .form-check-label:before {
  height: 1.5rem;
  width: 1.5rem;
  left: -2rem;
  border-radius: 3rem !important;
  border-color: var(--rgba-primary-3);
}

.check-switch .form-check-input:checked~.form-check-label::after {
  background-image: url("../images/svg/check.svg");
}

.check-switch .form-check-input:checked~.form-check-label::before {
  background: #fff;
}

.form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}

.form-check-input:focus {
  border-color: var(--primary);
  box-shadow: var(--rgba-primary-1);
}

.js-switch+.switchery {
  border-radius: 3.125rem;
  margin-right: 4rem;
}

@media (max-width: 35.98rem) {
  .js-switch+.switchery {
    margin-right: 1rem;
  }
}

.js-switch+.switchery>small,
.js-switch+.switchery>.small {
  top: 0.125rem;
}

.js-switch.js-switch-lg+.switchery {
  height: 2rem;
  width: 4.5rem;
}

.js-switch.js-switch-lg+.switchery>small,
.js-switch.js-switch-lg+.switchery>.small {
  width: 1.75rem;
  height: 1.75rem;
}

.js-switch.js-switch-md+.switchery {
  height: 1.5rem;
  width: 3.5rem;
}

.js-switch.js-switch-md+.switchery>small,
.js-switch.js-switch-md+.switchery>.small {
  width: 1.25rem;
  height: 1.25rem;
}

.js-switch.js-switch-sm+.switchery {
  height: 1rem;
  width: 2.2rem;
}

.js-switch.js-switch-sm+.switchery>small,
.js-switch.js-switch-sm+.switchery>.small {
  width: 0.875rem;
  height: 0.875rem;
  top: 0.0625rem;
}

.js-switch-square+.switchery {
  border-radius: 0;
}

.js-switch-square+.switchery>small,
.js-switch-square+.switchery>.small {
  border-radius: 0;
  top: 0.125rem;
}

.js-switch-square.js-switch-lg+.switchery {
  height: 2rem;
  width: 4.5rem;
}

.js-switch-square.js-switch-lg+.switchery>small,
.js-switch-square.js-switch-lg+.switchery>.small {
  width: 1.75rem;
  height: 1.75rem;
}

.js-switch-square.js-switch-md+.switchery {
  height: 1.5rem;
  width: 3.5rem;
}

.js-switch-square.js-switch-md+.switchery>small,
.js-switch-square.js-switch-md+.switchery>.small {
  width: 1.25rem;
  height: 1.25rem;
}

.js-switch-square.js-switch-sm+.switchery {
  height: 1rem;
  width: 2.2rem;
}

.js-switch-square.js-switch-sm+.switchery>small,
.js-switch-square.js-switch-sm+.switchery>.small {
  width: 0.875rem;
  height: 0.875rem;
  top: 0.0625rem;
}

.form-control.is-valid {
  border-color: #37D159 !important;
  border-right: 0rem !important;
}

.form-control.is-valid:focus {
  box-shadow: none;
}

.form-control.is-warning {
  border-color: #FFC368 !important;
  border-right: 0rem !important;
}

.form-control.is-warning:focus {
  box-shadow: none;
}

.form-control.is-invalid {
  border-color: #FF6746 !important;
  border-right: 0rem !important;
}

.form-control.is-invalid:focus {
  box-shadow: none;
}

.is-valid .input-group-prepend .input-group-text i {
  color: #37D159;
}

.is-invalid .input-group-prepend .input-group-text i {
  color: var(--rgba-primary-2);
}

.show-pass {
  cursor: pointer;
}

.show-pass .fa-eye {
  display: none;
}

.show-pass.active .fa-eye-slash {
  display: none;
}

.show-pass.active .fa-eye {
  display: inline-block;
}

.asColorPicker-dropdown {
  max-width: 26rem;
}

.asColorPicker-trigger {
  border: 0 none;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 2.1875rem;
}

[direction="rtl"] .asColorPicker-trigger {
  left: 0;
  right: auto;
}

.asColorPicker-clear {
  display: none;
  position: absolute;
  right: 1rem;
  text-decoration: none;
  top: .5rem;
}

.daterangepicker td.active {
  background-color: var(--primary);
}

.daterangepicker td.active:hover {
  background-color: var(--primary);
}

.daterangepicker button.applyBtn {
  background-color: var(--primary);
  border-color: var(--primary);
}

.datepicker.datepicker-dropdown {
  background: #f2f4fa;
  border-radius: 0.0625rem;
  border: 0.0625rem solid #EEEEEE;
}

.datepicker.datepicker-dropdown td.day,
.datepicker.datepicker-dropdown th.next,
.datepicker.datepicker-dropdown th.prev {
  height: 1.875rem;
  width: 1.875rem !important;
  padding: 0;
  text-align: center;
  font-weight: 300;
  border-radius: 3.125rem;
}

.datepicker.datepicker-dropdown td.day:hover,
.datepicker.datepicker-dropdown th.next:hover,
.datepicker.datepicker-dropdown th.prev:hover {
  box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243, 30, 122, 0.3);
  color: #fff;
}

.datepicker.datepicker-dropdown th.datepicker-switch,
.datepicker.datepicker-dropdown th.next,
.datepicker.datepicker-dropdown th.prev {
  font-weight: 300;
  color: #333;
}

.datepicker.datepicker-dropdown th.dow {
  font-weight: 300;
}

.datepicker table tr td.selected,
.datepicker table tr td.active.active {
  box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243, 30, 122, 0.3);
  border: 0;
}

.datepicker table tr td.today {
  box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243, 30, 122, 0.3);
  color: #ffffff;
}

.datepicker table tr td.today:hover {
  box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243, 30, 122, 0.3);
  color: #ffffff;
}

.datepicker table tr td.today.disabled {
  box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243, 30, 122, 0.3);
  color: #ffffff;
}

.datepicker table tr td.today.disabled:hover {
  box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243, 30, 122, 0.3);
  color: #ffffff;
}

.picker__select--month,
.picker__select--year {
  height: 2.5em;
}

.picker__input {
  background-color: transparent !important;
}

[data-theme-version="dark"] .picker__input {
  background-color: transparent !important;
  border: 0.0625rem solid #2e2e42;
}

.asColorPicker-wrap .form-control {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

#image {
  max-width: 100%;
}

.docs-options .dropdown-menu {
  padding: 1.5rem;
}

.docs-preview {
  margin-bottom: 3rem;
}

.docs-preview .img-preview {
  float: left;
  margin-right: .5rem;
  margin-bottom: .5rem;
  overflow: hidden;
  max-width: 100% !important;
}

.docs-preview .img-preview>img {
  max-width: 100% !important;
}

.docs-preview .img-preview.preview-lg {
  width: 16rem;
  height: 9rem;
}

.docs-preview .img-preview.preview-md {
  width: 8rem;
  height: 4.5rem;
}

.docs-preview .img-preview.preview-sm {
  width: 4rem;
  height: 2.25rem;
}

.docs-preview .img-preview.preview-xs {
  width: 2rem;
  height: 1.125rem;
  margin-right: 0;
}

.select2-container {
  width: 100% !important;
}

.select2-container--default .select2-selection--single {
  border-radius: 1.375rem;
  border: 0.0625rem solid #c8c8c8;
  height: 2.5rem;
  background: #fff;
}

[data-theme-version="dark"] .select2-container--default .select2-selection--single {
  background: #171622;
  border-color: #2e2e42;
}

.select2-container--default .select2-selection--single:hover,
.select2-container--default .select2-selection--single:focus,
.select2-container--default .select2-selection--single.active {
  box-shadow: none;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 2.5rem;
  color: #969ba0;
  padding-left: 0.9375rem;
  min-height: 2.5rem;
}

.select2-container--default .select2-selection--multiple {
  border-color: #F2F2F2;
  border-radius: 0;
}

.select2-dropdown {
  border-radius: 0;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: var(--primary);
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #F2F2F2;
  background: #fff;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 0.375rem;
  right: 0.9375rem;
}

.select2-container .select2-selection--multiple {
  min-height: 2.5rem;
  color: #969ba0;
  border-radius: 1.375rem;
  border: 0.0625rem solid #c8c8c8;
}

[data-theme-version="dark"] .select2-container .select2-selection--multiple {
  background: #171622;
  border-color: #2e2e42;
}

[data-theme-version="dark"] .select2-search--dropdown .select2-search__field {
  background: #212130;
  border-color: #2e2e42;
}

.select2-dropdown {
  border-color: #c8c8c8;
}

[data-theme-version="dark"] .select2-dropdown {
  background: #171622;
  border-color: #2e2e42;
}

.swal2-popup .swal2-content {
  color: #969ba0;
}

:root {
  --primary: #1362FC;
  --secondary: #627EEA;
  --primary-hover: #034bd9;
  --primary-light: #aac7fe;
  --primary-dark: #012875;
  --rgba-primary-1: rgba(19, 98, 252, 0.1);
  --rgba-primary-2: rgba(19, 98, 252, 0.2);
  --rgba-primary-3: rgba(19, 98, 252, 0.3);
  --rgba-primary-4: rgba(19, 98, 252, 0.4);
  --rgba-primary-5: rgba(19, 98, 252, 0.5);
  --rgba-primary-6: rgba(19, 98, 252, 0.6);
  --rgba-primary-7: rgba(19, 98, 252, 0.7);
  --rgba-primary-8: rgba(19, 98, 252, 0.8);
  --rgba-primary-9: rgba(19, 98, 252, 0.9);
  --font-family-base: Roboto, sans-serif;
  --font-family-title: Roboto, sans-serif;
  --title: #000;
}

/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/
.form-wizard {
  border: 0;
}

.form-wizard .nav-wizard {
  box-shadow: none !important;
  margin-bottom: 2rem;
}

.form-wizard .nav-wizard li .nav-link {
  position: relative;
}

.form-wizard .nav-wizard li .nav-link span {
  border-radius: 3.125rem;
  width: 3rem;
  height: 3rem;
  border: 0.125rem solid var(--primary);
  display: block;
  line-height: 3rem;
  color: var(--primary);
  font-size: 1.125rem;
  margin: auto;
  background-color: #fff;
  position: relative;
  z-index: 1;
}

.form-wizard .nav-wizard li .nav-link:after {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 0.1875rem;
  transform: translateY(-50%);
  background: #eeeeee !important;
  z-index: 0;
  width: 100%;
}

.form-wizard .nav-wizard li .nav-link.active:after {
  background: var(--primary) !important;
}

.form-wizard .nav-wizard li .nav-link.active span {
  background: var(--primary);
  color: #fff;
}

.form-wizard .nav-wizard li .nav-link.done:after {
  background: var(--primary) !important;
}

.form-wizard .nav-wizard li .nav-link.done span {
  background-color: var(--primary);
  color: #fff;
}

.form-wizard .nav-wizard li:last-child .nav-link:after {
  content: none;
}

.form-wizard .toolbar-bottom .btn {
  background-color: var(--primary);
  border: 0;
  padding: 0.75rem 1.125rem;
}

.form-wizard .tab-content .tab-pane {
  padding: 0;
}

.form-wizard .emial-setup label.mailclinet {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: #EEF5F9;
  text-align: center;
  margin: auto;
}

[data-theme-version="dark"] .form-wizard .emial-setup label.mailclinet {
  background-color: #171622;
}

@media only screen and (max-width: 35.9375rem) {
  .form-wizard .emial-setup label.mailclinet {
    width: 7rem;
    height: 7rem;
  }
}

.form-wizard .emial-setup label.mailclinet .mail-icon {
  font-size: 3rem;
  display: inline-block;
  line-height: 1;
  margin-top: -1rem;
}

@media only screen and (max-width: 35.9375rem) {
  .form-wizard .emial-setup label.mailclinet .mail-icon {
    font-size: 2rem;
  }
}

.form-wizard .emial-setup label.mailclinet .mail-text {
  font-size: 1rem;
  text-align: center;
  margin-top: .5rem;
}

@media only screen and (max-width: 35.9375rem) {
  .form-wizard .emial-setup label.mailclinet .mail-text {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}

.form-wizard .emial-setup label.mailclinet input[type="radio"] {
  display: none;
}

.form-wizard .emial-setup label.mailclinet {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: #EEF5F9;
  text-align: center;
  margin: auto;
}

[data-theme-version="dark"] .form-wizard .emial-setup label.mailclinet {
  background-color: #171622;
}

@media only screen and (max-width: 35.9375rem) {
  .form-wizard .emial-setup label.mailclinet {
    width: 7rem;
    height: 7rem;
  }
}

.form-wizard .emial-setup label.mailclinet .mail-icon {
  font-size: 3rem;
  display: inline-block;
  line-height: 1;
  margin-top: -1rem;
}

@media only screen and (max-width: 35.9375rem) {
  .form-wizard .emial-setup label.mailclinet .mail-icon {
    font-size: 2rem;
  }
}

.form-wizard .emial-setup label.mailclinet .mail-text {
  font-size: 1rem;
  text-align: center;
  margin-top: .5rem;
}

@media only screen and (max-width: 35.9375rem) {
  .form-wizard .emial-setup label.mailclinet .mail-text {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}

.form-wizard .emial-setup label.mailclinet input[type="radio"] {
  display: none;
}

@media only screen and (max-width: 47.9375rem) {
  .form-wizard .nav-wizard {
    flex-direction: unset !important;
  }

  .form-wizard .tab-content {
    height: 100% !important;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .form-wizard .nav-wizard li .nav-link {
    padding: 0;
  }
}

.custom-ekeditor ul {
  padding-left: 1.25rem;
}

.custom-ekeditor ul li {
  list-style: unset;
}

.custom-ekeditor ol li {
  list-style: decimal;
}

.ql-container {
  height: 25rem;
}

#world-datamap {
  padding-bottom: 46% !important;
}

.datamaps-hoverover {
  background: #fff;
  padding: 0.3125rem;
  border-radius: 0.3125rem;
  font-family: 'Roboto' !important;
  color: var(--primary);
  border: 0.0625rem solid var(--rgba-primary-3);
}

@media only screen and (max-width: 90rem) {
  .world_map_card ul.list-group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 2.1875rem;
  }
}

.jqvmap-zoomin,
.jqvmap-zoomout {
  height: 1.25rem;
  width: 1.25rem;
  line-height: 0.875rem;
  background-color: var(--primary);
  color: #fff;
}

.jqvmap-zoomout {
  top: 2.1875rem;
}

#world-map,
#usa {
  height: 25rem;
}

@media only screen and (max-width: 61.9375rem) {

  #world-map,
  #usa {
    height: 21.875rem;
  }
}

@media only screen and (max-width: 35.9375rem) {

  #world-map,
  #usa {
    height: 14.375rem;
  }
}

.blockUI.blockMsg.blockPage {
  border: 0 !important;
}

#loginForm {
  cursor: auto;
}

.blockMsg {
  border: 0rem;
  width: 20% !important;
}

.blockMsg h1,
.blockMsg .h1 {
  font-size: 1rem;
  padding: 0.5rem 0;
  margin-bottom: 0;
}

.bootstrap-select {
  margin-bottom: 0;
}

.bootstrap-select .btn {
  border: 0.0625rem solid #F2F2F2 !important;
  background-color: transparent !important;
  font-weight: 400;
  color: #969ba0 !important;
}

[data-theme-version="dark"] .bootstrap-select .btn {
  border-color: #2e2e42 !important;
  background: #171622 !important;
}

.bootstrap-select .btn:active,
.bootstrap-select .btn:focus,
.bootstrap-select .btn:hover {
  outline: none !important;
  outline-offset: 0;
}

[data-theme-version="dark"] .bootstrap-select .btn:active,
[data-theme-version="dark"] .bootstrap-select .btn:focus,
[data-theme-version="dark"] .bootstrap-select .btn:hover {
  color: #969ba0 !important;
}

.bootstrap-select .dropdown-menu {
  border-color: #F2F2F2 !important;
  box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
}

.bootstrap-select .dropdown-menu .dropdown-item {
  padding: 0.25rem 1rem;
}

[data-theme-version="dark"] .bootstrap-select .dropdown-menu {
  border-color: #F2F2F2 !important;
}

.input-group>.bootstrap-select:not(:first-child) .dropdown-toggle {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group>.bootstrap-select:not(:last-child) .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

:root {
  --primary: #1362FC;
  --secondary: #627EEA;
  --primary-hover: #034bd9;
  --primary-light: #aac7fe;
  --primary-dark: #012875;
  --rgba-primary-1: rgba(19, 98, 252, 0.1);
  --rgba-primary-2: rgba(19, 98, 252, 0.2);
  --rgba-primary-3: rgba(19, 98, 252, 0.3);
  --rgba-primary-4: rgba(19, 98, 252, 0.4);
  --rgba-primary-5: rgba(19, 98, 252, 0.5);
  --rgba-primary-6: rgba(19, 98, 252, 0.6);
  --rgba-primary-7: rgba(19, 98, 252, 0.7);
  --rgba-primary-8: rgba(19, 98, 252, 0.8);
  --rgba-primary-9: rgba(19, 98, 252, 0.9);
  --font-family-base: Roboto, sans-serif;
  --font-family-title: Roboto, sans-serif;
  --title: #000;
}

.clipboard-btn {
  transition: all .1s ease-in-out;
}

.clipboard-btn:hover {
  background-color: var(--primary);
  color: #fff;
}

.crypto-ticker {
  background: rgba(0, 0, 0, 0.5);
  margin-top: 1.25rem;
  padding: 0.625rem 1.25rem;
  border-radius: 0.1875rem;
  box-shadow: 0 0 2.1875rem 0 rgba(154, 161, 171, 0.15);
}

[data-theme-version="dark"] .crypto-ticker {
  background: #212130;
}

#webticker-big {
  font: inherit !important;
  font-size: inherit !important;
  font-weight: normal !important;
}

#webticker-big li i {
  font-size: 1.125rem;
  margin-right: 0.4375rem;
}

#webticker-big li p {
  margin-bottom: 0rem;
  font-size: 0.75rem;
  font-weight: 700;
}

:root {
  --primary: #1362FC;
  --secondary: #627EEA;
  --primary-hover: #034bd9;
  --primary-light: #aac7fe;
  --primary-dark: #012875;
  --rgba-primary-1: rgba(19, 98, 252, 0.1);
  --rgba-primary-2: rgba(19, 98, 252, 0.2);
  --rgba-primary-3: rgba(19, 98, 252, 0.3);
  --rgba-primary-4: rgba(19, 98, 252, 0.4);
  --rgba-primary-5: rgba(19, 98, 252, 0.5);
  --rgba-primary-6: rgba(19, 98, 252, 0.6);
  --rgba-primary-7: rgba(19, 98, 252, 0.7);
  --rgba-primary-8: rgba(19, 98, 252, 0.8);
  --rgba-primary-9: rgba(19, 98, 252, 0.9);
  --font-family-base: Roboto, sans-serif;
  --font-family-title: Roboto, sans-serif;
  --title: #000;
}

.twitter-typeahead {
  width: 100%;
}

.twitter-typeahead .tt-dataset.tt-dataset-states {
  border: 0.0625rem solid #F2F2F2;
}

.twitter-typeahead .tt-menu {
  width: 100%;
  background-color: #fff;
}

.twitter-typeahead .tt-menu .tt-suggestion {
  padding: 0.625rem;
  cursor: pointer;
}

.twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: var(--primary);
  color: #fff;
}

:root {
  --primary: #1362FC;
  --secondary: #627EEA;
  --primary-hover: #034bd9;
  --primary-light: #aac7fe;
  --primary-dark: #012875;
  --rgba-primary-1: rgba(19, 98, 252, 0.1);
  --rgba-primary-2: rgba(19, 98, 252, 0.2);
  --rgba-primary-3: rgba(19, 98, 252, 0.3);
  --rgba-primary-4: rgba(19, 98, 252, 0.4);
  --rgba-primary-5: rgba(19, 98, 252, 0.5);
  --rgba-primary-6: rgba(19, 98, 252, 0.6);
  --rgba-primary-7: rgba(19, 98, 252, 0.7);
  --rgba-primary-8: rgba(19, 98, 252, 0.8);
  --rgba-primary-9: rgba(19, 98, 252, 0.9);
  --font-family-base: Roboto, sans-serif;
  --font-family-title: Roboto, sans-serif;
  --title: #000;
}

.twitter-typeahead {
  width: 100%;
}

.twitter-typeahead .tt-dataset.tt-dataset-states {
  border: 0.0625rem solid #F2F2F2;
}

.twitter-typeahead .tt-menu {
  width: 100%;
  background-color: #fff;
}

.twitter-typeahead .tt-menu .tt-suggestion {
  padding: 0.625rem;
  cursor: pointer;
}

.twitter-typeahead .tt-menu .tt-suggestion .weather-one i {
  font-size: 8rem;
  position: relative;
  top: .5rem;
}

.twitter-typeahead .tt-menu .tt-suggestion .weather-one h2,
.twitter-typeahead .tt-menu .tt-suggestion .weather-one .h2 {
  display: inline-block;
  float: right;
  font-size: 4.8rem;
}

.twitter-typeahead .tt-menu .tt-suggestion .weather-one .city {
  position: relative;
  text-align: right;
  top: -2.5rem;
}

.twitter-typeahead .tt-menu .tt-suggestion .weather-one .currently {
  font-size: 1.6rem;
  font-weight: 400;
  position: relative;
  top: 2.5rem;
}

.twitter-typeahead .tt-menu .tt-suggestion .weather-one .celcious {
  text-align: right;
  font-size: 2rem;
}

.twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: var(--primary);
  color: #fff;
}

:root {
  --primary: #1362FC;
  --secondary: #627EEA;
  --primary-hover: #034bd9;
  --primary-light: #aac7fe;
  --primary-dark: #012875;
  --rgba-primary-1: rgba(19, 98, 252, 0.1);
  --rgba-primary-2: rgba(19, 98, 252, 0.2);
  --rgba-primary-3: rgba(19, 98, 252, 0.3);
  --rgba-primary-4: rgba(19, 98, 252, 0.4);
  --rgba-primary-5: rgba(19, 98, 252, 0.5);
  --rgba-primary-6: rgba(19, 98, 252, 0.6);
  --rgba-primary-7: rgba(19, 98, 252, 0.7);
  --rgba-primary-8: rgba(19, 98, 252, 0.8);
  --rgba-primary-9: rgba(19, 98, 252, 0.9);
  --font-family-base: Roboto, sans-serif;
  --font-family-title: Roboto, sans-serif;
  --title: #000;
}

.noUi-target {
  border-color: transparent;
  border-radius: 0;
}

.noUi-connect {
  background-color: var(--primary);
}

.noUi-connects {
  background-color: #D2D6DE;
}

.noUi-connect.c-1-color {
  background-color: #37D159;
}

.noUi-connect.c-2-color {
  background-color: #b48dd3;
}

.noUi-connect.c-3-color {
  background-color: var(--primary);
}

.noUi-connect.c-4-color {
  background-color: #FFC368;
}

.noUi-vertical {
  width: 0.375rem;
}

.noUi-horizontal {
  height: 0.125rem;
  border: 0;
  margin-bottom: 0.625rem;
}

.noUi-horizontal .noUi-handle,
.noUi-vertical .noUi-handle {
  height: 0.9375rem;
  width: 0.9375rem;
  border-radius: 3.125rem;
  box-shadow: none;
  border: none;
  background-color: var(--primary);
}

.noUi-horizontal .noUi-handle::after,
.noUi-horizontal .noUi-handle::before,
.noUi-vertical .noUi-handle::after,
.noUi-vertical .noUi-handle::before {
  display: none;
}

.noUi-vertical .noUi-handle {
  left: -0.25rem;
  top: -0.375rem;
}

.noUi-horizontal .noUi-handle {
  top: -0.4375rem;
  cursor: pointer;
}

html:not([dir=rtl]) .noUi-horizontal .noUi-handle {
  right: -0.375rem;
}

#slider-toggle {
  height: 3.125rem;
}

#slider-toggle.off .noUi-handle {
  border-color: var(--primary);
}

.colorpicker-slider .sliders.noUi-target#red,
.colorpicker-slider .sliders.noUi-target#green,
.colorpicker-slider .sliders.noUi-target#blue {
  margin: 0.625rem;
  display: inline-block;
  height: 12.5rem;
}

.colorpicker-slider .sliders.noUi-target#red .noUi-connect {
  background: #c0392b;
}

.colorpicker-slider .sliders.noUi-target#green .noUi-connect {
  background: #27ae60;
}

.colorpicker-slider .sliders.noUi-target#blue .noUi-connect {
  background: #2980b9;
}

.colorpicker-slider #result {
  margin: 3.75rem 1.625rem;
  height: 6.25rem;
  width: 6.25rem;
  display: inline-block;
  vertical-align: top;
  color: #7f7f7f;
  background: #7f7f7f;
  border: 0.0625rem solid #fff;
  box-shadow: 0 0 0.625rem;
}

.slider-vertical {
  height: 18rem;
}

.nestable-cart {
  overflow: hidden;
}

.dd-handle {
  color: #fff;
  background: var(--primary);
  border-radius: 0.3125rem;
  padding: 0.5rem 0.9375rem;
  height: auto;
  border: 0.0625rem solid #F2F2F2;
}

.dd-handle:hover {
  color: #fff;
  background: var(--primary);
}

.dd3-content:hover {
  color: #fff;
  background: var(--primary);
}

.dd3-content {
  color: #fff;
}

.dd-item>button {
  line-height: 1.75rem;
  color: #fff;
}

.pignose-calendar {
  box-shadow: none;
  width: 100%;
  max-width: none;
  border-color: var(--primary);
}

.pignose-calendar .pignose-calendar-top-date {
  background-color: var(--primary);
}

.pignose-calendar .pignose-calendar-top-date .pignose-calendar-top-month {
  color: #fff;
}

.pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: var(--primary);
  box-shadow: none;
}

.pignose-calendar .pignose-calendar-top {
  box-shadow: none;
  border-bottom: 0;
}

.pignose-calendar.pignose-calendar-blue {
  background-color: rgba(0, 0, 0, 0.15);
}

.pignose-calendar .pignose-calendar-unit {
  height: 4.8em;
}

.cd-h-timeline {
  opacity: 0;
  transition: opacity 0.2s;
}

.cd-h-timeline--loaded {
  opacity: 1;
}

.cd-h-timeline__container {
  position: relative;
  height: 6.25rem;
  max-width: 50rem;
}

.cd-h-timeline__dates {
  position: relative;
  height: 100%;
  margin: 0 2.5rem;
  overflow: hidden;
}

.cd-h-timeline__dates::after,
.cd-h-timeline__dates::before {
  content: '';
  position: absolute;
  z-index: 2;
  top: 0;
  height: 100%;
  width: 1.25rem;
}

.cd-h-timeline__dates::before {
  left: 0;
  background: var(--primary);
}

.cd-h-timeline__dates::after {
  right: 0;
  background: var(--primary);
}

.cd-h-timeline__line {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 3.0625rem;
  height: 0.125rem;
  background-color: var(--primary);
  transition: transform 0.4s;
}

.cd-h-timeline__filling-line {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #37D159;
  transform: scaleX(0);
  transform-origin: left center;
  transition: transform 0.3s;
}

.cd-h-timeline__date {
  position: absolute;
  bottom: 0;
  z-index: 2;
  text-align: center;
  font-size: 0.8em;
  padding-bottom: var(--space-sm);
  color: var(--cd-color-1);
  user-select: none;
  text-decoration: none;
}

.cd-h-timeline__date::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -0.3125rem;
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 50%;
  border-width: 0.125rem;
  border-style: solid;
  border-color: var(--rgba-primary-6);
  background-color: var(--primary);
  transition: background-color 0.3s, border-color .3s;
}

.cd-h-timeline__date:hover::after {
  background-color: #37D159;
  border-color: #37D159;
}

.cd-h-timeline__date--selected {
  pointer-events: none;
}

.cd-h-timeline__date--selected::after {
  background-color: #37D159;
  border-color: #37D159;
}

.cd-h-timeline__date--older-event::after {
  border-color: #37D159;
}

.cd-h-timeline__navigation {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  height: 2.125rem;
  width: 2.125rem;
  border-radius: 50%;
  border-width: 0.125rem;
  border-style: solid;
  border-color: var(--rgba-primary-6);
  transition: border-color 0.3s;
}

.cd-h-timeline__navigation::after {
  content: '';
  position: absolute;
  height: 1rem;
  width: 1rem;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.cd-h-timeline__navigation:hover {
  border-color: #37D159;
}

.cd-h-timeline__navigation--prev {
  left: 0;
  transform: translateY(-50%) rotate(180deg);
}

.cd-h-timeline__navigation--next {
  right: 0;
}

.cd-h-timeline__navigation--inactive {
  cursor: not-allowed;
}

.cd-h-timeline__navigation--inactive::after {
  background-position: 0 -1rem;
}

.cd-h-timeline__navigation--inactive:hover {
  border-color: var(--rgba-primary-6);
}

.cd-h-timeline__events {
  position: relative;
  width: 100%;
  overflow: hidden;
  transition: height .4s;
}

.cd-h-timeline__event {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  top: 0;
  transform: translateX(-100%);
  padding: 0.0625rem 5%;
  opacity: 0;
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;
}

.cd-h-timeline__event--selected {
  position: relative;
  z-index: 2;
  opacity: 1;
  transform: translateX(0);
}

.cd-h-timeline__event--enter-right,
.cd-h-timeline__event--leave-right {
  animation-name: cd-enter-right;
}

.cd-h-timeline__event--enter-left,
.cd-h-timeline__event--leave-left {
  animation-name: cd-enter-left;
}

.cd-h-timeline__event--leave-right,
.cd-h-timeline__event--leave-left {
  animation-direction: reverse;
}

.cd-h-timeline__event-content {
  max-width: 50rem;
}

.cd-h-timeline__event-title {
  color: var(--cd-color-1);
  font-family: var(--font-secondary);
  font-weight: 700;
  font-size: var(--text-xxxl);
}

.cd-h-timeline__event-date {
  display: block;
  font-style: italic;
  margin: var(--space-xs) auto;
}

.cd-h-timeline__event-date::before {
  content: '- ';
}

@keyframes cd-enter-right {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes cd-enter-left {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.toast-success {
  background-color: var(--primary);
}

.toast-info {
  background-color: #b48dd3;
}

.toast-warning {
  background-color: #FFC368;
}

.toast-error {
  background-color: #FF6746;
}

#toast-container>div {
  box-shadow: none;
  border-radius: 0;
  width: auto;
  max-width: 15.625rem;
  opacity: 1;
}

[direction="rtl"] #toast-container>div {
  padding: 0.9375rem 3.125rem 0.9375rem 0.9375rem;
  background-position: calc(100% - 0.9375rem);
  text-align: right;
}

#toast-container>div:hover {
  box-shadow: none;
}

#toast-container .toast-title {
  margin-bottom: 0.3125rem;
  font-weight: 600;
}

#toast-container .toast-message {
  font-size: 0.75rem;
}

#toast-container .toast-close-button {
  opacity: 1;
  font-size: 1.25rem;
  font-weight: normal;
  text-shadow: none;
}

[direction="rtl"] .toast-top-right.demo_rtl_class {
  left: 0.75rem;
  right: auto;
}

/* Light Gallery */
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-sub-html,
.lg-toolbar {
  background-color: rgba(30, 30, 30, 0.6);
}

.lg-outer .lg-toogle-thumb,
.lg-outer .lg-thumb-outer,
.lg-outer .lg-img-wrap,
.lg-outer .lg-item {
  background-color: transparent;
}

.lg-thumb-outer.lg-grab,
.lg-toogle-thumb.lg-icon {
  background-color: rgba(30, 30, 30, 0.6);
}

.lg-backdrop {
  background-color: rgba(30, 30, 30, 0.9);
}

.lg-outer .lg-toogle-thumb,
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-toolbar .lg-icon,
#lg-counter {
  color: #fff;
}

.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
  border-color: var(--primary);
}

.lightimg {
  cursor: pointer;
}

.jqvmap-zoomin,
.jqvmap-zoomout {
  position: absolute;
  left: 0.625rem;
  -webkit-border-radius: 0.1875rem;
  -moz-border-radius: 0.1875rem;
  border-radius: 0.1875rem;
  background: #000000;
  padding: 0.1875rem;
  color: white;
  width: 1.0625rem;
  height: 1.0625rem;
  cursor: pointer;
  line-height: 0.625rem;
  text-align: center;
}

.jqvmap-zoomin {
  top: 0.625rem;
}

.jqvmap-zoomout {
  top: 1.875rem;
}

.ps__rail-x:hover,
.ps__rail-y:hover,
.ps__rail-x:focus,
.ps__rail-y:focus,
.ps__rail-x.ps--clicking,
.ps__rail-y.ps--clicking {
  background-color: transparent;
  opacity: 0.9;
}

.ps__rail-y:hover>.ps__thumb-y,
.ps__rail-y:focus>.ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #DAE2F3;
  width: 0.25rem;
}

.ps__thumb-y {
  background-color: #DAE2F3;
  width: 0.25rem;
}

.total-average {
  position: relative;
  height: 18.75rem;
}

.widget-chat {
  position: relative;
  height: 15.625rem;
}

.widget-todo {
  position: relative;
  height: 13.125rem;
}

.widget-team {
  position: relative;
  height: 17.8125rem;
}

.widget-timeline {
  position: relative;
}

.widget-comments {
  position: relative;
  height: 25rem;
}

.sidebar-right-inner {
  position: relative;
  height: 100%;
}

.widget-team .ps .ps__rail-x:hover,
.widget-team .ps .ps__rail-y:hover,
.widget-team .ps .ps__rail-x:focus,
.widget-team .ps .ps__rail-y:focus,
.widget-team .ps .ps__rail-x.ps--clicking,
.widget-team .ps .ps__rail-y.ps--clicking {
  background-color: transparent !important;
  opacity: 0.9;
}

.fc-h-event,
.fc-v-event {
  background: var(--primary);
  border-radius: .42rem;
}

.fc-h-event .fc-event-title {
  color: #fff;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border-color: #ebedf3;
}

.fc-unthemed .fc-h-event,
.fc-unthemed .fc-event-dot {
  padding: 0;
  border-radius: .42rem;
}

.fc-theme-standard th {
  padding: .75rem .5rem;
  font-size: 1rem;
  font-weight: 500;
  color: #b5b5c3;
}

@media only screen and (max-width: 35.9375rem) {
  .fc-theme-standard th {
    font-size: 0.875rem;
    font-weight: 400;
    padding: 0.1875rem 0rem;
  }
}

.fc-theme-standard .fc-scrollgrid.fc-scrollgrid-liquid,
.fc-scrollgrid,
table {
  border-color: #ebedf3;
}

.fc-daygrid-dot-event {
  background: #fff;
  border: 0.0625rem solid #ebedf3;
  -webkit-box-shadow: 0 0 0.5625rem 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0.5625rem 0 rgba(0, 0, 0, 0.1);
}

.fc-daygrid-dot-event .fc-daygrid-event-dot {
  border-color: var(--primary);
}

.fc-daygrid-dot-event .fc-event-title {
  font-weight: 500;
}

.fc-event.bg-primary,
.fc-event.bg-success,
.fc-event.bg-warning,
.fc-event.bg-secondary,
.fc-event.bg-dark,
.fc-event.bg-info {
  color: #fff !important;
  border-radius: 0.5rem;
}

.fc-event.bg-primary .fc-daygrid-event-dot,
.fc-event.bg-success .fc-daygrid-event-dot,
.fc-event.bg-warning .fc-daygrid-event-dot,
.fc-event.bg-secondary .fc-daygrid-event-dot,
.fc-event.bg-dark .fc-daygrid-event-dot,
.fc-event.bg-info .fc-daygrid-event-dot {
  border-color: #fff;
}

.fc .fc-scroller-liquid-absolute,
.fc-scroller {
  position: relative;
  overflow: visible !important;
}

.fc .fc-button-group>.fc-button {
  color: #b5b5c3;
  background: 0 0;
  border: 0.0625rem solid #ebedf3;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.fc .fc-button-group>.fc-button:focus,
.fc .fc-button-group>.fc-button:active,
.fc .fc-button-group>.fc-button:hover,
.fc .fc-button-group>.fc-button.fc-button-active {
  background: var(--primary);
  color: #fff;
  border-color: var(--primary);
}

.fc-button.fc-button-primary.fc-today-button {
  background: var(--primary);
  color: #fff;
  border: 0;
  opacity: 1;
}

.fc-unthemed .fc-toolbar .fc-button.fc-button-active,
.fc-unthemed .fc-toolbar .fc-button:active,
.fc-unthemed .fc-toolbar .fc-button:focus {
  background: var(--primary);
  color: #fff;
  border: 0.0625rem solid var(--primary);
  -webkit-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
}

.fc .fc-toolbar-title {
  font-size: 1.25rem;
  margin: 0;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: -0.5em;
  background: #fff;
  z-index: 2;
  padding: 5px 0;
}

.external-event {
  padding: 0.5rem 0.625rem;
  display: flex;
  align-items: center;
  border-radius: 0.3125rem;
}

.external-event:hover:before {
  background: #fff !important;
}

.fc .fc-event {
  overflow: hidden;
}

@media only screen and (max-width: 35.9375rem) {
  .fc .fc-event {
    margin: 0;
  }
}

.fc .fc-view-harness {
  height: 50rem !important;
  overflow-y: auto;
}

@media only screen and (max-width: 35.9375rem) {
  .fc .fc-toolbar.fc-header-toolbar {
    display: block;
  }

  .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk {
    display: flex;
    justify-content: center;
  }

  .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:first-child {
    justify-content: space-between;
  }

  .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-title {
    margin-bottom: 0.5rem;
  }
}

#external-events .external-event {
  text-align: left;
  font-size: 1rem;
}

.accordion-primary .accordion-header {
  background: var(--primary);
  border-color: var(--primary);
  color: #fff;
  box-shadow: 0 0.9375rem 1.25rem 0 var(--rgba-primary-1);
}

.accordion-primary .accordion-header.collapsed {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: var(--primary);
  box-shadow: none;
}

[data-theme-version="dark"] .accordion-primary .accordion-header.collapsed {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: #969ba0;
}

.accordion-primary-solid .accordion-header {
  background: var(--primary);
  border-color: var(--primary);
  color: #fff;
  box-shadow: 0 -0.625rem 1.25rem 0 var(--rgba-primary-1);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion-primary-solid .accordion-header.collapsed {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: var(--primary);
  box-shadow: none;
  border-bottom-left-radius: 1.375rem;
  border-bottom-right-radius: 1.375rem;
}

[data-theme-version="dark"] .accordion-primary-solid .accordion-header.collapsed {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: #969ba0;
}

.accordion-primary-solid .accordion__body {
  border: 0.125rem solid var(--primary);
  border-top: none;
  box-shadow: 0 0.9375rem 1.25rem 0 var(--rgba-primary-1);
  border-bottom-left-radius: 1.375rem;
  border-bottom-right-radius: 1.375rem;
}

.accordion-danger .accordion-header {
  background: #FF6746;
  border-color: #FF6746;
  color: #fff;
  box-shadow: 0 0.9375rem 1.25rem 0 rgba(255, 103, 70, 0.15);
}

.accordion-danger .accordion-header.collapsed {
  background: #ffd4cb;
  border-color: #ffd4cb;
  color: #211c37;
  box-shadow: none;
}

.accordion-danger-solid .accordion-header {
  background: #FF6746;
  border-color: #FF6746;
  color: #fff;
  box-shadow: 0 -0.625rem 1.25rem 0 rgba(255, 103, 70, 0.15);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion-danger-solid .accordion-header.collapsed {
  background: #ffd4cb;
  border-color: #ffd4cb;
  color: #211c37;
  box-shadow: none;
  border-bottom-left-radius: 1.375rem;
  border-bottom-right-radius: 1.375rem;
}

[data-theme-version="dark"] .accordion-danger-solid .accordion-header.collapsed {
  background: rgba(255, 103, 70, 0.15);
  border-color: rgba(255, 103, 70, 0.15);
  color: #969ba0;
}

.accordion-danger-solid .accordion__body {
  border: 0.125rem solid #FF6746;
  border-top: none;
  box-shadow: 0 0.9375rem 1.25rem 0 rgba(255, 103, 70, 0.15);
  border-bottom-left-radius: 1.375rem;
  border-bottom-right-radius: 1.375rem;
}

.accordion-item {
  margin-bottom: 1.25rem;
}

.accordion-header {
  padding: 1rem 1.75rem;
  border: 0.0625rem solid #F2F2F2;
  cursor: pointer;
  position: relative;
  color: #333;
  font-weight: 400;
  border-radius: 1.375rem;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

[data-theme-version="dark"] .accordion-header {
  color: #fff;
  border-color: #2e2e42;
}

.accordion-header-indicator {
  font-family: 'themify';
  position: absolute;
  right: 1.5625rem;
  top: 50%;
  transform: translateY(-50%);
}

[direction="rtl"] .accordion-header-indicator {
  right: auto;
  left: 1.5625rem;
}

.accordion-header-indicator.indicator_bordered {
  display: inline-block;
  width: 1.5625rem;
  text-align: center;
  height: 1.5625rem;
  border: 0.0625rem solid #F2F2F2;
  border-radius: 50%;
  line-height: 1.5625rem;
}

.accordion-header:not(.collapsed) .accordion-header-indicator::before {
  content: "\e622";
}

.accordion-header:not(.collapsed) .accordion-header-indicator.style_two::before {
  content: "\e648";
}

.accordion-header.collapsed .accordion-header-indicator::before {
  content: "\e61a";
}

.accordion-header.collapsed .accordion-header-indicator.style_two::before {
  content: "\e64b";
}

.accordion-body-text {
  padding: 0.875rem 1.25rem;
}

.accordion-bordered .accordion__body {
  border: 0.0625rem solid #F2F2F2;
  border-top: none;
  border-bottom-left-radius: 1.375rem;
  border-bottom-right-radius: 1.375rem;
}

[data-theme-version="dark"] .accordion-bordered .accordion__body {
  border-color: #2e2e42;
}

.accordion-bordered .accordion-header.collapsed {
  border-radius: 1.375rem;
}

.accordion-bordered .accordion-header {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion-no-gutter .accordion-item {
  margin-bottom: 0;
}

.accordion-no-gutter .accordion-item .accordion-header.collapsed {
  border-bottom: none;
}

.accordion-no-gutter .accordion-item:last-child .accordion-header {
  border-bottom: 0.0625rem solid #F2F2F2;
}

[data-theme-version="dark"] .accordion-no-gutter .accordion-item:last-child .accordion-header {
  border-color: #2e2e42;
}

.accordion-no-gutter.accordion__bordered .accordion-item:not(:last-child) .accordion__body {
  border-bottom: none;
}

.accordion-left-indicator .accordion-header-text {
  padding-left: 2.5rem;
}

.accordion-left-indicator .accordion-header-indicator {
  right: auto;
  left: 1.5625rem;
}

.accordion-with-icon .accordion-header-text {
  padding-left: 2.5rem;
}

[direction="rtl"] .accordion-with-icon .accordion-header-text {
  padding-left: 0;
  padding-right: 2.5rem;
}

.accordion-with-icon .accordion-header-icon {
  position: absolute;
  right: auto;
  left: 1.5625rem;
  font-family: 'themify';
}

[direction="rtl"] .accordion-with-icon .accordion-header-icon {
  left: auto;
  right: 1.5625rem;
}

.accordion-with-icon .accordion-header-icon::before {
  content: "\e645";
}

.accordion-header-bg .accordion-header {
  background-color: #c8c8c8;
}

[data-theme-version="dark"] .accordion-header-bg .accordion-header {
  background-color: #171622;
  color: #fff;
}

.accordion-header-bg .accordion-header-primary {
  background-color: var(--primary);
  color: #fff;
  border-color: var(--primary);
}

[data-theme-version="dark"] .accordion-header-bg .accordion-header-primary {
  background-color: var(--primary);
}

.accordion-header-bg .accordion-header-info {
  background-color: #b48dd3;
  color: #fff;
  border-color: #b48dd3;
}

[data-theme-version="dark"] .accordion-header-bg .accordion-header-info {
  background-color: #b48dd3;
}

.accordion-header-bg .accordion-header-success {
  background-color: #37D159;
  color: #fff;
  border-color: #37D159;
}

[data-theme-version="dark"] .accordion-header-bg .accordion-header-success {
  background-color: #37D159;
}

.accordion-header-bg.accordion-no-gutter .accordion-header {
  border-color: transparent;
  border-radius: 0;
}

.accordion-header-bg.accordion-no-gutter .accordion-item:first-child .accordion-header {
  border-top-left-radius: 1.375rem;
  border-top-right-radius: 1.375rem;
}

.accordion-header-bg.accordion-no-gutter .accordion-item:last-child .accordion-header {
  border-bottom-left-radius: 1.375rem;
  border-bottom-right-radius: 1.375rem;
}

.accordion.accordion-no-gutter .accordion-header {
  border-radius: 0;
}

.accordion.accordion-no-gutter .accordion-header.collapsed {
  border-radius: 0;
}

.accordion.accordion-no-gutter .accordion__body {
  border-radius: 0;
}

.accordion.accordion-no-gutter .accordion-item:first-child .accordion-header {
  border-top-left-radius: 1.375rem;
  border-top-right-radius: 1.375rem;
}

.accordion.accordion-no-gutter .accordion-item:last-child .accordion-header.collapsed {
  border-bottom-left-radius: 1.375rem;
  border-bottom-right-radius: 1.375rem;
}

.accordion.accordion-no-gutter .accordion-item:last-child .accordion__body {
  border-bottom-left-radius: 1.375rem;
  border-bottom-right-radius: 1.375rem;
}

.accordion-solid-bg .accordion-header {
  border-color: transparent;
  background-color: var(--rgba-primary-1);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

[data-theme-version="dark"] .accordion-solid-bg .accordion-header {
  background-color: #171622;
}

.accordion-solid-bg .accordion-header.collapsed {
  border-radius: 1.375rem;
}

.accordion-solid-bg .accordion__body {
  border-color: transparent;
  background-color: var(--rgba-primary-1);
  border-bottom-left-radius: 1.375rem;
  border-bottom-right-radius: 1.375rem;
}

[data-theme-version="dark"] .accordion-solid-bg .accordion__body {
  background-color: #171622;
}

.accordion-active-header .accordion-header:not(.collapsed) {
  background-color: #b48dd3;
  border-color: #b48dd3;
  color: #fff;
}

.accordion-header-shadow .accordion-header {
  border: none;
  box-shadow: 0 0 0.9375rem -0.1875rem rgba(0, 0, 0, 0.3);
}

.accordion-rounded-stylish .accordion-header {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.accordion-rounded-stylish .accordion__body {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.accordion-rounded .accordion-header {
  border-radius: 0.3125rem;
}

.accordion-gradient .accordion-header {
  color: #fff;
  background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%, rgba(103, 25, 255, 0.85) 100%);
  border-color: transparent;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion-gradient .accordion-header.collapsed {
  border-bottom-left-radius: 1.375rem;
  border-bottom-right-radius: 1.375rem;
}

.accordion-gradient .accordion__body {
  color: #fff;
  background-image: linear-gradient(to right, rgba(186, 1, 181, 0.85) 0%, rgba(103, 25, 255, 0.85) 100%);
  border-color: transparent;
}

.alert {
  border-radius: 1.375rem;
  padding: 1rem 1.5rem;
}

.alert p {
  line-height: 1.5;
}

.alert-rounded {
  border-radius: 1.875rem;
}

.alert-primary {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: var(--primary);
}

[data-theme-version="dark"] .alert-primary {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
}

.alert-secondary {
  background: #f2f4fd;
  border-color: #f2f4fd;
  color: #627EEA;
}

[data-theme-version="dark"] .alert-secondary {
  background: rgba(98, 126, 234, 0.5);
  border-color: rgba(98, 126, 234, 0.5);
  color: #969ba0;
}

.alert-success {
  background: #c8f2d1;
  border-color: #c8f2d1;
  color: #37D159;
}

[data-theme-version="dark"] .alert-success {
  background: rgba(55, 209, 89, 0.1);
  border-color: rgba(55, 209, 89, 0.1);
}

.alert-warning {
  background: #ffecce;
  border-color: #ffecce;
  color: #FFC368;
}

[data-theme-version="dark"] .alert-warning {
  background: rgba(255, 195, 104, 0.1);
  border-color: rgba(255, 195, 104, 0.1);
}

.alert-danger {
  background: #ffd4cb;
  border-color: #ffd4cb;
  color: #FF6746;
}

[data-theme-version="dark"] .alert-danger {
  background: rgba(255, 103, 70, 0.15);
  border-color: rgba(255, 103, 70, 0.15);
}

.alert-info {
  background: white;
  border-color: white;
  color: #b48dd3;
}

[data-theme-version="dark"] .alert-info {
  background: rgba(180, 141, 211, 0.1);
  border-color: rgba(180, 141, 211, 0.1);
}

.alert-dark {
  background: white;
  border-color: white;
  color: #6e6e6e;
}

[data-theme-version="dark"] .alert-dark {
  background: rgba(110, 110, 110, 0.35);
  border-color: rgba(110, 110, 110, 0.35);
  color: #969ba0;
}

.alert-light {
  background: #c8c8c8;
  border-color: #c8c8c8;
  color: #6e6e6e;
}

.alert-alt.alert-primary {
  border-left: 0.25rem solid var(--primary);
}

.alert-alt.alert-secondary {
  border-left: 0.25rem solid #627EEA;
}

.alert-alt.alert-success {
  border-left: 0.25rem solid #37D159;
}

.alert-alt.alert-warning {
  border-left: 0.25rem solid #FFC368;
}

.alert-alt.alert-danger {
  border-left: 0.25rem solid #FF6746;
}

.alert-alt.alert-info {
  border-left: 0.25rem solid #b48dd3;
}

.alert-alt.alert-dark {
  border-left: 0.25rem solid #6e6e6e;
}

.alert-alt.alert-light {
  border-left: 0.25rem solid #a2a2a2;
}

.alert-alt.alert-primary.solid {
  border-left: 0.25rem solid var(--primary-dark) !important;
}

.alert-alt.alert-secondary.solid {
  border-left: 0.25rem solid #1838b4 !important;
}

.alert-alt.alert-success.solid {
  border-left: 0.25rem solid #1a6f2c !important;
}

.alert-alt.alert-warning.solid {
  border-left: 0.25rem solid #e88c00 !important;
}

.alert-alt.alert-danger.solid {
  border-left: 0.25rem solid #c62300 !important;
}

.alert-alt.alert-info.solid {
  border-left: 0.25rem solid #763fa2 !important;
}

.alert-alt.alert-dark.solid {
  border-left: 0.25rem solid #2e2e2e !important;
}

.alert-alt.alert-light.solid {
  border-left: 0.25rem solid #888888 !important;
}

.alert-dismissible.solid .close:hover {
  color: #fff;
  opacity: 1;
}

.alert.alert-primary.solid {
  background: var(--primary);
  color: #fff;
  border-color: var(--primary);
}

.alert.alert-secondary.solid {
  background: #627EEA;
  color: #fff;
  border-color: #627EEA;
}

.alert.alert-success.solid {
  background: #37D159;
  color: #fff;
  border-color: #37D159;
}

.alert.alert-warning.solid {
  background: #FFC368;
  color: #fff;
  border-color: #FFC368;
}

.alert.alert-danger.solid {
  background: #FF6746;
  color: #fff;
  border-color: #FF6746;
}

.alert.alert-info.solid {
  background: #b48dd3;
  color: #fff;
  border-color: #b48dd3;
}

.alert.alert-dark.solid {
  background: #6e6e6e;
  color: #fff;
  border-color: #6e6e6e;
}

.alert.alert-light.solid {
  background: #c8c8c8;
  color: #6e6e6e;
  border-color: #c8c8c8;
}

.alert-right-icon>span i {
  font-size: 1.125rem;
  margin-right: 0.3125rem;
}

.alert-right-icon .close i {
  font-size: 1rem;
}

.alert.alert-outline-primary {
  background: transparent;
  color: var(--primary);
  border-color: var(--primary);
}

.alert.alert-outline-secondary {
  background: transparent;
  color: #969ba0;
  border-color: #627EEA;
}

.alert.alert-outline-success {
  background: transparent;
  color: #37D159;
  border-color: #37D159;
}

.alert.alert-outline-info {
  background: transparent;
  color: #b48dd3;
  border-color: #b48dd3;
}

.alert.alert-outline-warning {
  background: transparent;
  color: #FFC368;
  border-color: #FFC368;
}

.alert.alert-outline-danger {
  background: transparent;
  color: #FF6746;
  border-color: #FF6746;
}

.alert.alert-outline-dark {
  background: transparent;
  color: #969ba0;
  border-color: #6e6e6e;
}

.alert.alert-outline-light {
  background: transparent;
  color: #6e6e6e;
  border-color: #c8c8c8;
}

.alert-social {
  color: #fff;
}

.alert-social .alert-social-icon {
  align-self: center;
  margin-right: 0.9375rem;
}

.alert-social .alert-social-icon i {
  font-size: 2.625rem;
}

.alert-social.facebook {
  background-color: #3b5998;
}

.alert-social.twitter {
  background-color: #1da1f2;
}

.alert-social.linkedin {
  background-color: #007bb6;
}

.alert-social.google-plus {
  background-color: #db4439;
}

.alert-social .close:hover {
  opacity: 1 !important;
  color: #fff !important;
}

.left-icon-big .alert-left-icon-big {
  align-self: center;
  margin-right: 0.9375rem;
}

.left-icon-big .alert-left-icon-big i {
  font-size: 2.1875rem;
  line-height: 1;
}

[direction="rtl"] .left-icon-big .alert-left-icon-big,
[direction="rtl"] .alert-social .alert-social-icon {
  margin-right: 0;
  margin-left: 0.9375rem;
}

.badge {
  line-height: 1.5;
  border-radius: 1.03125rem;
  padding: 0.25rem 0.625rem;
  border: 0.0625rem solid transparent;
}

.badge-rounded {
  border-radius: 1.25rem;
  padding: 0.1875rem 0.8125rem;
}

.badge-circle {
  border-radius: 6.25rem;
  padding: 0.1875rem 0.4375rem;
}

.badge-outline-primary {
  border: 0.0625rem solid var(--primary);
  color: var(--primary);
}

.badge-outline-secondary {
  border: 0.0625rem solid #627EEA;
  color: #627EEA;
}

[data-theme-version="dark"] .badge-outline-secondary {
  color: #969ba0;
}

.badge-outline-success {
  border: 0.0625rem solid #37D159;
  color: #37D159;
}

.badge-outline-info {
  border: 0.0625rem solid #b48dd3;
  color: #b48dd3;
}

.badge-outline-warning {
  border: 0.0625rem solid #FFC368;
  color: #FFC368;
}

.badge-outline-danger {
  border: 0.0625rem solid #FF6746;
  color: #FF6746;
}

.badge-outline-light {
  border: 0.0625rem solid #F2F2F2;
  color: #6e6e6e;
}

[data-theme-version="dark"] .badge-outline-light {
  color: #969ba0;
}

.badge-outline-dark {
  border: 0.0625rem solid #6e6e6e;
  color: #6e6e6e;
}

[data-theme-version="dark"] .badge-outline-dark {
  color: #969ba0;
}

.badge-xs {
  font-size: 0.625rem;
  padding: 0rem 0.3125rem;
  line-height: 1.125rem;
}

.badge-sm {
  font-size: 0.6875rem;
  padding: 0.3125rem 0.5rem;
  line-height: 0.6875rem;
}

.badge-lg {
  font-size: 0.875rem;
  padding: 0rem 0.625rem;
  line-height: 1.875rem;
}

.badge-xl {
  font-size: 1rem;
  padding: 0rem 0.9375rem;
  line-height: 2.1875rem;
}

.badge-default {
  background: #ADB6C7;
}

.badge-success {
  background-color: #37D159;
}

.badge-secondary {
  background-color: #627EEA;
}

.badge-info {
  background-color: #b48dd3;
}

.badge-primary {
  background-color: var(--primary);
}

.badge-warning {
  background-color: #FFC368;
}

.badge-danger {
  background-color: #FF6746;
}

.badge-dark {
  background-color: #6e6e6e;
}

.badge-light {
  background-color: #c8c8c8;
}

.light.badge-default {
  background: #ADB6C7;
}

.light.badge-success {
  background-color: #c8f2d1;
  color: #37D159;
}

[data-theme-version="dark"] .light.badge-success {
  background-color: rgba(55, 209, 89, 0.1);
}

.light.badge-info {
  background-color: #e4d7ef;
  color: #b48dd3;
}

[data-theme-version="dark"] .light.badge-info {
  background-color: rgba(180, 141, 211, 0.1);
}

.light.badge-primary {
  background-color: var(--rgba-primary-1);
  color: var(--primary);
}

[data-theme-version="dark"] .light.badge-primary {
  background-color: var(--rgba-primary-1);
}

.light.badge-secondary {
  background-color: #f2f4fd;
  color: #627EEA;
}

[data-theme-version="dark"] .light.badge-secondary {
  background-color: rgba(98, 126, 234, 0.5);
  color: #969ba0;
}

.light.badge-warning {
  background-color: #ffecce;
  color: #FFC368;
}

[data-theme-version="dark"] .light.badge-warning {
  background-color: rgba(255, 195, 104, 0.1);
}

.light.badge-danger {
  background-color: #ffd4cb;
  color: #FF6746;
}

[data-theme-version="dark"] .light.badge-danger {
  background-color: rgba(255, 103, 70, 0.15);
}

.light.badge-dark {
  background-color: #eeeeee;
  color: #6e6e6e;
}

[data-theme-version="dark"] .light.badge-dark {
  background-color: rgba(110, 110, 110, 0.35);
  color: #969ba0;
}

.bootstrap-label .label {
  display: inline-block;
  margin-right: 1rem;
}

.bootstrap-label .label:last-child {
  margin-right: 0;
}

.badge-demo .badge {
  margin-right: 0.3125rem;
  margin-bottom: 0.3125rem;
}

.badge-demo .badge:last-child {
  margin-right: 0;
}

.bootstrap-badge-buttons button {
  margin-right: .2rem;
  margin-bottom: 1rem;
}

.bootstrap-badge-buttons button:last-child {
  margin-right: 0;
}

.breadcrumb {
  font-size: 1.1875rem;
}

.breadcrumb .breadcrumb-item.active a {
  color: var(--primary);
}

.breadcrumb .breadcrumb-item+.breadcrumb-item::before {
  color: var(--primary);
}

.page-titles {
  padding: 0.9375rem 2.5rem;
  background: #fff;
  margin-bottom: 1.875rem;
  border-radius: 1rem;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

[data-theme-version="dark"] .page-titles {
  background: #212130;
}

@media only screen and (max-width: 74.9375rem) {
  .page-titles {
    margin-top: -1.875rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .page-titles {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0.9375rem;
    padding: 0.9375rem 1.25rem;
    margin-top: 0;
  }
}

.page-titles .justify-content-sm-end {
  align-items: center;
}

.page-titles h4,
.page-titles .h4 {
  margin-bottom: 0;
  margin-top: 0;
  color: var(--primary);
  font-size: 1.25rem;
}

.page-titles h4 span,
.page-titles .h4 span {
  font-size: 0.875rem;
  font-weight: 400;
}

.page-titles .breadcrumb {
  margin-bottom: 0;
  padding: 0;
  background: transparent;
}

.page-titles .breadcrumb li {
  margin-top: 0;
  margin-bottom: 0;
}

.page-titles .breadcrumb li a {
  color: #828690;
}

.page-titles .breadcrumb li.active {
  color: var(--primary);
  font-weight: 600;
}

.page-titles .breadcrumb li.active a {
  color: #627EEA;
}

.page-titles .breadcrumb .breadcrumb-item+.breadcrumb-item:before {
  /* font-family: 'simple-line-icons';
                font-weight: 400;
				position:absolute;
                font-size: 10px; */
  color: #627EEA;
}

.page-titles .breadcrumb-datepicker {
  font-size: 0.75rem;
  color: #89879f;
}

.page-titles .breadcrumb-datepicker__icon {
  font-size: 0.875rem;
}

.page-titles .breadcrumb-widget .border-dark {
  border-color: #dee2e6 !important;
}

.page-titles .breadcrumb-widget h4,
.page-titles .breadcrumb-widget .h4 {
  color: #646c9a;
  font-weight: 600;
}

@media only screen and (max-width: 35.9375rem) {
  .page-titles .breadcrumb-widget {
    text-align: left !important;
    margin-bottom: 0.9375rem;
  }
}

button {
  cursor: pointer;
}

button:focus {
  outline: 0;
  box-shadow: none;
}

.btn {
  padding: 0.938rem 1.5rem;
  border-radius: 1.125rem;
  font-weight: 400;
  font-size: 1rem;
}

.btn:hover,
.btn:focus,
.btn:active,
.btn.active {
  outline: 0 !important;
}

@media only screen and (max-width: 87.5rem) {
  .btn {
    padding: 0.625rem 1rem;
  }
}

.btn.btn-success,
.btn.btn-secondary,
.btn.btn-warning,
.btn.btn-primary,
.btn.btn-danger,
.btn.btn-info {
  color: #fff;
}

.btn-transparent {
  background-color: transparent;
}

.btn-primary,
.btn-success,
.btn-danger {
  color: #fff;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active {
  color: #fff;
}

.btn-primary {
  border-color: var(--primary);
  background-color: var(--primary);
}

.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
  border-color: var(--primary-hover);
  background-color: var(--primary-hover);
}

.btn-primary:focus {
  box-shadow: 0 0 0 0.25rem var(--rgba-primary-5);
}

.btn-primary:disabled,
.btn-primary.disabled {
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-link {
  color: var(--primary);
  text-decoration: none;
}

.btn-link:hover {
  color: var(--primary-hover);
}

.btn-outline-primary {
  color: var(--primary);
  border-color: var(--primary);
}

.btn-outline-primary:hover {
  border-color: var(--primary-hover);
  background-color: var(--primary-hover);
}

.sharp {
  min-width: 2.5rem;
  padding: 0.4375rem;
  height: 2.5rem;
  min-height: 2.5rem;
}

.sharp.btn-xs {
  padding: 0.1875rem;
  width: 1.625rem;
  height: 1.625rem;
  min-width: 1.625rem;
  min-height: 1.625rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.light.tp-btn {
  background-color: transparent;
}

.light.btn-default {
  background: #ADB6C7;
}

.light.btn-success {
  background-color: #c8f2d1;
  border-color: #c8f2d1;
  color: #37D159;
}

.light.btn-success g [fill] {
  fill: #37D159;
}

[data-theme-version="dark"] .light.btn-success {
  background-color: rgba(55, 209, 89, 0.1);
  border-color: transparent;
}

.light.btn-success:hover {
  background-color: #37D159;
  border-color: #37D159;
  color: #fff;
}

.light.btn-success:hover g [fill] {
  fill: #fff;
}

.light.btn-info {
  background-color: #e4d7ef;
  border-color: #e4d7ef;
  color: #b48dd3;
}

.light.btn-info g [fill] {
  fill: #b48dd3;
}

[data-theme-version="dark"] .light.btn-info {
  background-color: rgba(180, 141, 211, 0.1);
  border-color: transparent;
}

.light.btn-info:hover {
  background-color: #b48dd3;
  border-color: #b48dd3;
  color: #fff;
}

.light.btn-info:hover g [fill] {
  fill: #fff;
}

.light.btn-primary {
  background-color: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: var(--primary);
}

.light.btn-primary g [fill] {
  fill: var(--primary);
}

[data-theme-version="dark"] .light.btn-primary {
  background-color: var(--rgba-primary-1);
  border-color: transparent;
  color: #fff;
}

.light.btn-primary:hover {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

.light.btn-primary:hover g [fill] {
  fill: #fff;
}

.light.btn-secondary {
  background-color: #f2f4fd;
  border-color: #f2f4fd;
  color: #627EEA;
}

.light.btn-secondary g [fill] {
  fill: #627EEA;
}

[data-theme-version="dark"] .light.btn-secondary {
  background-color: rgba(98, 126, 234, 0.5);
  border-color: transparent;
  color: #fff;
}

.light.btn-secondary:hover {
  background-color: #627EEA;
  border-color: #627EEA;
  color: #fff;
}

.light.btn-secondary:hover g [fill] {
  fill: #fff;
}

.light.btn-warning {
  background-color: #ffecce;
  border-color: #ffecce;
  color: #FFC368;
}

.light.btn-warning g [fill] {
  fill: #FFC368;
}

[data-theme-version="dark"] .light.btn-warning {
  background-color: rgba(255, 195, 104, 0.1);
  border-color: transparent;
}

.light.btn-warning:hover {
  background-color: #FFC368;
  border-color: #FFC368;
  color: #fff;
}

.light.btn-warning:hover g [fill] {
  fill: #fff;
}

.light.btn-danger {
  background-color: #ffd4cb;
  border-color: #ffd4cb;
  color: #FF6746;
}

.light.btn-danger g [fill] {
  fill: #FF6746;
}

[data-theme-version="dark"] .light.btn-danger {
  background-color: rgba(255, 103, 70, 0.15);
  border-color: transparent;
}

.light.btn-danger:hover {
  background-color: #FF6746;
  border-color: #FF6746;
  color: #fff;
}

.light.btn-danger:hover g [fill] {
  fill: #fff;
}

.light.btn-dark {
  background-color: #eeeeee;
  border-color: #eeeeee;
  color: #6e6e6e;
}

.light.btn-dark g [fill] {
  fill: #6e6e6e;
}

[data-theme-version="dark"] .light.btn-dark {
  background-color: rgba(110, 110, 110, 0.35);
  border-color: transparent;
  color: #fff;
}

.light.btn-dark:hover {
  background-color: #6e6e6e;
  border-color: #6e6e6e;
  color: #fff;
}

.light.btn-dark:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn {
  background-color: transparent;
  border-color: transparent;
}

.btn.tp-btn.btn-default {
  background: #ADB6C7;
}

.btn.tp-btn.btn-success {
  color: #37D159;
}

.btn.tp-btn.btn-success g [fill] {
  fill: #37D159;
}

.btn.tp-btn.btn-success:hover {
  background-color: #37D159;
  border-color: #37D159;
  color: #fff;
}

.btn.tp-btn.btn-success:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-info {
  color: #b48dd3;
}

.btn.tp-btn.btn-info g [fill] {
  fill: #b48dd3;
}

.btn.tp-btn.btn-info:hover {
  background-color: #b48dd3;
  border-color: #b48dd3;
  color: #fff;
}

.btn.tp-btn.btn-info:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-primary {
  color: var(--primary);
}

.btn.tp-btn.btn-primary g [fill] {
  fill: var(--primary);
}

.btn.tp-btn.btn-primary:hover {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

.btn.tp-btn.btn-primary:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-secondary {
  color: #627EEA;
}

.btn.tp-btn.btn-secondary g [fill] {
  fill: #627EEA;
}

.btn.tp-btn.btn-secondary:hover {
  background-color: #627EEA;
  border-color: #627EEA;
  color: #fff;
}

.btn.tp-btn.btn-secondary:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-warning {
  color: #FFC368;
}

.btn.tp-btn.btn-warning g [fill] {
  fill: #FFC368;
}

.btn.tp-btn.btn-warning:hover {
  background-color: #FFC368;
  border-color: #FFC368;
  color: #fff;
}

.btn.tp-btn.btn-warning:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-danger {
  color: #FF6746;
}

.btn.tp-btn.btn-danger g [fill] {
  fill: #FF6746;
}

.btn.tp-btn.btn-danger:hover {
  background-color: #FF6746;
  border-color: #FF6746;
  color: #fff;
}

.btn.tp-btn.btn-danger:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-light {
  color: #6e6e6e;
}

.btn.tp-btn.btn-light g [fill] {
  fill: #6e6e6e;
}

.btn.tp-btn.btn-light:hover {
  background-color: #c8c8c8;
  border-color: #c8c8c8;
  color: #6e6e6e;
}

.btn.tp-btn.btn-light:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-dark {
  color: #6e6e6e;
}

.btn.tp-btn.btn-dark g [fill] {
  fill: #6e6e6e;
}

.btn.tp-btn.btn-dark:hover {
  background-color: #6e6e6e;
  border-color: #6e6e6e;
  color: #fff;
}

.btn.tp-btn.btn-dark:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn-light {
  background-color: transparent;
  border-color: transparent;
}

.btn.tp-btn-light.btn-success {
  color: #37D159;
}

.btn.tp-btn-light.btn-success g [fill] {
  fill: #37D159;
}

.btn.tp-btn-light.btn-success:hover {
  background-color: #c8f2d1;
  border-color: #c8f2d1;
  color: #37D159;
}

.btn.tp-btn-light.btn-success:hover g [fill] {
  fill: #37D159;
}

.btn.tp-btn-light.btn-info {
  color: #b48dd3;
}

.btn.tp-btn-light.btn-info g [fill] {
  fill: #b48dd3;
}

.btn.tp-btn-light.btn-info:hover {
  background-color: #e4d7ef;
  border-color: #e4d7ef;
  color: #b48dd3;
}

.btn.tp-btn-light.btn-info:hover g [fill] {
  fill: #b48dd3;
}

.btn.tp-btn-light.btn-primary {
  color: var(--primary);
}

.btn.tp-btn-light.btn-primary g [fill] {
  fill: var(--primary);
}

.btn.tp-btn-light.btn-primary:hover {
  background-color: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: var(--primary);
}

.btn.tp-btn-light.btn-primary:hover g [fill] {
  fill: var(--primary);
}

.btn.tp-btn-light.btn-secondary {
  color: #627EEA;
}

.btn.tp-btn-light.btn-secondary g [fill] {
  fill: #627EEA;
}

.btn.tp-btn-light.btn-secondary:hover {
  background-color: #f2f4fd;
  border-color: #f2f4fd;
  color: #627EEA;
}

.btn.tp-btn-light.btn-secondary:hover g [fill] {
  fill: #627EEA;
}

.btn.tp-btn-light.btn-warning {
  color: #FFC368;
}

.btn.tp-btn-light.btn-warning g [fill] {
  fill: #FFC368;
}

.btn.tp-btn-light.btn-warning:hover {
  background-color: #ffecce;
  border-color: #ffecce;
  color: #FFC368;
}

.btn.tp-btn-light.btn-warning:hover g [fill] {
  fill: #FFC368;
}

.btn.tp-btn-light.btn-danger {
  color: #FF6746;
}

.btn.tp-btn-light.btn-danger g [fill] {
  fill: #FF6746;
}

.btn.tp-btn-light.btn-danger:hover {
  background-color: #ffd4cb;
  border-color: #ffd4cb;
  color: #FF6746;
}

.btn.tp-btn-light.btn-danger:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn-light.btn-dark {
  color: #6e6e6e;
}

.btn.tp-btn-light.btn-dark g [fill] {
  fill: #6e6e6e;
}

.btn.tp-btn-light.btn-dark:hover {
  background-color: #eeeeee;
  border-color: #eeeeee;
  color: #6e6e6e;
}

.btn.tp-btn-light.btn-dark:hover g [fill] {
  fill: #fff;
}

.shadow.btn-primary {
  -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 var(--rgba-primary-2) !important;
  box-shadow: 0 0.3125rem 0.9375rem 0 var(--rgba-primary-2) !important;
}

.shadow.btn-secondary {
  -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 rgba(98, 126, 234, 0.2) !important;
  box-shadow: 0 0.3125rem 0.9375rem 0 rgba(98, 126, 234, 0.2) !important;
}

.shadow.btn-warning {
  -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 rgba(255, 195, 104, 0.2) !important;
  box-shadow: 0 0.3125rem 0.9375rem 0 rgba(255, 195, 104, 0.2) !important;
}

.shadow.btn-danger {
  -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 rgba(255, 103, 70, 0.2) !important;
  box-shadow: 0 0.3125rem 0.9375rem 0 rgba(255, 103, 70, 0.2) !important;
}

.shadow.btn-info {
  -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 rgba(180, 141, 211, 0.2) !important;
  box-shadow: 0 0.3125rem 0.9375rem 0 rgba(180, 141, 211, 0.2) !important;
}

.shadow.btn-success {
  -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 rgba(55, 209, 89, 0.2) !important;
  box-shadow: 0 0.3125rem 0.9375rem 0 rgba(55, 209, 89, 0.2) !important;
}

.btn-xxs {
  padding: 0.375rem 0.9375rem;
  font-size: 0.6875rem;
  line-height: 1.3;
}

.btn-xs {
  font-size: 0.75rem;
  padding: 0.438rem 1rem;
  font-weight: 600;
}

.btn-sm,
.btn-group-sm>.btn {
  font-size: 0.813rem !important;
  padding: 0.625rem 1rem;
}

.btn-md {
  font-size: 0.875rem !important;
  padding: 0.875rem 1.25rem;
}

.btn-lg,
.btn-group-lg>.btn {
  padding: 1rem 2rem;
  font-size: 1.125rem !important;
}

@media only screen and (max-width: 35.9375rem) {

  .btn-lg,
  .btn-group-lg>.btn {
    padding: 0.75rem 1.25rem;
  }
}

.btn-xl {
  padding: 0.6rem 1rem;
}

.btn-xl.btn-default {
  font-weight: 600;
}

.btn-square {
  border-radius: 0;
}

.btn-rounded {
  border-radius: 2.5rem !important;
}

.btn-icon-end {
  border-left: 0.0625rem solid white;
  display: inline-block;
  margin: -.8rem 0 -.8rem 1rem;
  padding: 0.4375rem 0 0.4375rem 1rem;
  margin: -1rem -0.25rem -1rem 1rem;
  padding: 1rem 0 1rem 1.25rem;
}

.btn-icon-start {
  background: #fff;
  border-radius: 10rem;
  display: inline-block;
  margin: -0.5rem 0.75rem -0.5rem -1.188rem;
  padding: 0.5rem 0.80rem 0.5rem;
  float: left;
}

@media only screen and (max-width: 87.5rem) {
  .btn-icon-start {
    margin: -0.5rem 0.75rem -0.5rem -0.880rem;
  }
}

[direction="rtl"] .btn-icon-start {
  margin: -.5rem 0.5rem -0.5rem -1rem;
}

@media only screen and (max-width: 87.5rem) {
  [direction="rtl"] .btn-icon-start {
    margin: -0.5rem 0.75rem -0.5rem -0.880rem;
  }
}

[direction="rtl"] .btn-icon-end {
  border-left: 0rem solid white;
  display: inline-block;
  margin: -.8rem 1rem -.8rem 0;
  padding: 0.4375rem 1rem 0.4375rem 0;
  border-right: 0.0625rem solid white;
}

.toggle-dropdown::after {
  margin-left: 0.755em;
}

.social-btn-icon .btn {
  min-width: 7.5rem;
  margin-bottom: 1.5rem;
}

.social-icon .btn {
  padding: .7rem 1.4rem;
}

.btn-circle {
  height: 5rem;
  width: 5rem;
  border-radius: 50% !important;
}

.btn-circle-sm {
  width: 4.5rem;
  height: 4.5rem;
  font-size: 1.8rem;
}

.btn-circle-md {
  width: 6rem;
  height: 6rem;
  font-size: 2.5rem;
}

.btn-circle-md i {
  font-size: 2.4rem;
}

.btn-circle-lg {
  width: 8rem;
  height: 8rem;
  font-size: 3.2rem;
}

.btn-circle-lg i {
  font-size: 3.1rem;
}

.btn-page .btn {
  min-width: 6.875rem;
  margin-right: 0.25rem;
  margin-bottom: 0.5rem;
}

.size-1 {
  min-width: 10rem !important;
  font-size: 1.5rem;
  padding: 0.68rem 0.75rem;
}

.size-2 {
  font-size: 1.25rem;
  min-width: 8.125rem !important;
  padding: 0.57rem 0.75rem;
}

.size-3 {
  font-size: 0.875rem;
  min-width: 6.875rem !important;
  padding: 0.536rem 0.75rem;
}

.size-4 {
  font-size: 0.875rem;
  min-width: 6.25rem !important;
}

.size-5 {
  font-size: 0.875rem;
  min-width: 5.625rem !important;
  padding: .22rem 0.75rem;
}

.size-6 {
  font-size: 0.8125rem;
  min-width: 5rem !important;
  padding: 0.097rem 0.75rem;
}

.size-7 {
  font-size: 0.75rem;
  min-width: 3.75rem !important;
  padding: 0.001rem 0.75rem;
}

.btn-light {
  background: #c8c8c8;
  border-color: #c8c8c8;
  color: #fff;
}

.btn-light:active,
.btn-light:focus,
.btn-light:hover {
  background: white;
  color: #6e6e6e;
  border-color: white;
}

.btn-outline-primary:hover {
  color: #fff;
}

.btn-outline-warning:hover {
  color: #fff;
}

.btn-outline-light {
  color: #6e6e6e;
}

[data-theme-version="dark"] .btn-outline-light {
  color: #969ba0;
}

[data-theme-version="dark"] .btn-outline-secondary {
  color: #969ba0;
}

[data-theme-version="dark"] .btn-outline-dark {
  color: #969ba0;
}

.btn-dark {
  background: #6e6e6e;
  border-color: #6e6e6e;
  color: #fff;
}

.btn-dark:active,
.btn-dark:focus,
.btn-dark:hover {
  background: #555555;
  color: #fff;
  border-color: #555555;
}

.btn-group.btn-rounded .btn:first-child {
  border-top-left-radius: 1.875rem;
  border-bottom-left-radius: 1.875rem;
}

.btn-group.btn-rounded .btn:last-child {
  border-top-right-radius: 1.875rem;
  border-bottom-right-radius: 1.875rem;
}

.btn-facebook {
  background: #3b5998;
  border-color: #3b5998;
  color: #fff;
}

.btn-facebook:active,
.btn-facebook:focus,
.btn-facebook:hover {
  background: #2d4373;
  color: #fff;
  border-color: #2d4373;
}

.btn-twitter {
  background: #1da1f2;
  border-color: #1da1f2;
  color: #fff;
}

.btn-twitter:active,
.btn-twitter:focus,
.btn-twitter:hover {
  background: #0c85d0;
  color: #fff;
  border-color: #0c85d0;
}

.btn-youtube {
  background: #FF0000;
  border-color: #FF0000;
  color: #fff;
}

.btn-youtube:active,
.btn-youtube:focus,
.btn-youtube:hover {
  background: #cc0000;
  color: #fff;
  border-color: #cc0000;
}

.btn-instagram {
  background: #c32aa3;
  border-color: #c32aa3;
  color: #fff;
}

.btn-instagram:active,
.btn-instagram:focus,
.btn-instagram:hover {
  background: #992180;
  color: #fff;
  border-color: #992180;
}

.btn-pinterest {
  background: #bd081c;
  border-color: #bd081c;
  color: #fff;
}

.btn-pinterest:active,
.btn-pinterest:focus,
.btn-pinterest:hover {
  background: #8c0615;
  color: #fff;
  border-color: #8c0615;
}

.btn-linkedin {
  background: #007bb6;
  border-color: #007bb6;
  color: #fff;
}

.btn-linkedin:active,
.btn-linkedin:focus,
.btn-linkedin:hover {
  background: #005983;
  color: #fff;
  border-color: #005983;
}

.btn-google-plus {
  background: #db4439;
  border-color: #db4439;
  color: #fff;
}

.btn-google-plus:active,
.btn-google-plus:focus,
.btn-google-plus:hover {
  background: #be2d23;
  color: #fff;
  border-color: #be2d23;
}

.btn-google {
  background: #4285f4;
  border-color: #4285f4;
  color: #fff;
}

.btn-google:active,
.btn-google:focus,
.btn-google:hover {
  background: #1266f1;
  color: #fff;
  border-color: #1266f1;
}

.btn-snapchat {
  background: #fffc00;
  border-color: #fffc00;
  color: #000;
}

.btn-snapchat:active,
.btn-snapchat:focus,
.btn-snapchat:hover {
  background: #ccca00;
  color: #000;
  border-color: #ccca00;
}

.btn-whatsapp {
  background: #25d366;
  border-color: #25d366;
  color: #fff;
}

.btn-whatsapp:active,
.btn-whatsapp:focus,
.btn-whatsapp:hover {
  background: #1da851;
  color: #fff;
  border-color: #1da851;
}

.btn-tumblr {
  background: #35465d;
  border-color: #35465d;
  color: #fff;
}

.btn-tumblr:active,
.btn-tumblr:focus,
.btn-tumblr:hover {
  background: #222e3d;
  color: #fff;
  border-color: #222e3d;
}

.btn-reddit {
  background: #ff4500;
  border-color: #ff4500;
  color: #fff;
}

.btn-reddit:active,
.btn-reddit:focus,
.btn-reddit:hover {
  background: #cc3700;
  color: #fff;
  border-color: #cc3700;
}

.btn-spotify {
  background: #1ed760;
  border-color: #1ed760;
  color: #fff;
}

.btn-spotify:active,
.btn-spotify:focus,
.btn-spotify:hover {
  background: #18aa4c;
  color: #fff;
  border-color: #18aa4c;
}

.btn-yahoo {
  background: #430297;
  border-color: #430297;
  color: #fff;
}

.btn-yahoo:active,
.btn-yahoo:focus,
.btn-yahoo:hover {
  background: #2d0165;
  color: #fff;
  border-color: #2d0165;
}

.btn-dribbble {
  background: #ea4c89;
  border-color: #ea4c89;
  color: #fff;
}

.btn-dribbble:active,
.btn-dribbble:focus,
.btn-dribbble:hover {
  background: #e51e6b;
  color: #fff;
  border-color: #e51e6b;
}

.btn-skype {
  background: #00aff0;
  border-color: #00aff0;
  color: #fff;
}

.btn-skype:active,
.btn-skype:focus,
.btn-skype:hover {
  background: #008abd;
  color: #fff;
  border-color: #008abd;
}

.btn-quora {
  background: #aa2200;
  border-color: #aa2200;
  color: #fff;
}

.btn-quora:active,
.btn-quora:focus,
.btn-quora:hover {
  background: #771800;
  color: #fff;
  border-color: #771800;
}

.btn-vimeo {
  background: #1ab7ea;
  border-color: #1ab7ea;
  color: #fff;
}

.btn-vimeo:active,
.btn-vimeo:focus,
.btn-vimeo:hover {
  background: #1295bf;
  color: #fff;
  border-color: #1295bf;
}

.btn-check:checked+.btn-outline-primary,
.btn-check:active+.btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

.btn-check:checked+.btn-outline-primary:focus,
.btn-check:active+.btn-outline-primary:focus,
.btn-outline-primary:active:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem var(--rgba-primary-5);
}

.btn-check:focus+.btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem var(--rgba-primary-5);
}

.btn-close:focus {
  box-shadow: 0 0 0 0.25rem var(--rgba-primary-5);
}

.card {
  background-color: #fff;
  transition: all .5s ease-in-out;
  border: 0rem solid transparent;
  border-radius: 1.375rem;
  box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
}

@media only screen and (max-width: 35.9375rem) {
  .card {
    margin-bottom: 0.938rem;
    height: calc(100% - 0.938rem);
  }
}

.card-body {
  padding: 1.875rem;
}

@media only screen and (max-width: 35.9375rem) {
  .card-body {
    padding: 1rem;
  }
}

.card-title {
  font-size: 1.25rem;
  font-weight: 500;
  color: #000;
  text-transform: capitalize;
}

.card-title--large {
  font-size: 1.5rem;
}

.card-title--medium {
  font-size: 1rem;
}

.card-title--small {
  font-size: 0.875rem;
}

.card-header {
  border-color: #F2F2F2;
  position: relative;
  background: transparent;
  padding: 1.5rem 1.875rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 35.9375rem) {
  .card-header {
    padding: 1.25rem 1rem 1.25rem;
  }
}

[data-theme-version="dark"] .card-header {
  border-color: #2e2e42;
}

.card-header .card-title {
  margin-bottom: 0rem;
}

.card-header .subtitle {
  padding-top: 0.3125rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.card-footer {
  border-color: #F2F2F2;
  background: transparent;
  padding: 1.25rem 1.875rem 1.25rem;
}

[data-theme-version="dark"] .card-footer {
  border-color: #2e2e42;
}

.transparent-card.card {
  background: transparent;
  border: 0.0625rem solid transparent;
  box-shadow: none;
}

.card-action>a {
  display: inline-block;
  width: 1.875rem;
  height: 1.875rem;
  line-height: 1.875rem;
  border-radius: 0.3125rem;
  border-color: transparent;
  text-align: center;
  background: var(--primary-dark);
  color: #fff;
  margin-right: 0.5rem;
}

[data-theme-version="dark"] .card-action>a {
  background: #171622;
}

.card-action>a:last-child {
  margin-right: 0;
}

.card-action>a:hover,
.card-action>a:focus {
  background: var(--primary-dark);
}

[data-theme-version="dark"] .card-action>a:hover,
[data-theme-version="dark"] .card-action>a:focus {
  background: #171622;
}

.card-action>a i,
.card-action>a span {
  font-size: 1rem;
}

.card-action .dropdown {
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 0.3125rem;
  border-color: transparent;
  text-align: center;
  margin-right: 0.5rem;
  top: -0.125rem;
  position: relative;
  display: inline-block;
  background: var(--primary-dark);
  color: var(--primary);
}

[data-theme-version="dark"] .card-action .dropdown {
  background: #171622;
}

.card-action .dropdown:hover,
.card-action .dropdown:focus {
  background: var(--primary-dark);
}

[data-theme-version="dark"] .card-action .dropdown:hover,
[data-theme-version="dark"] .card-action .dropdown:focus {
  background: #171622;
}

.card-action .dropdown .btn {
  padding: 0;
  line-height: 1.6875rem;
  color: #fff;
}

.card-action .dropdown .btn:focus {
  box-shadow: none;
}

.card-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  overflow: auto;
}

.card-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999;
}

.card-loader i {
  margin: 0 auto;
  background: var(--primary-dark);
  padding: 0.625rem;
  border-radius: 50%;
  color: #fff;
  font-size: 1rem;
}

.rotate-refresh {
  -webkit-animation: mymove 0.8s infinite linear;
  animation: mymove 0.8s infinite linear;
  display: inline-block;
}

.card-header .date_picker {
  display: inline-block;
  padding: 0.5rem;
  border: 0.0625rem solid #F2F2F2;
  cursor: pointer;
  border-radius: .375rem;
}

.card-header .border-0 {
  padding-bottom: 0;
}

@-webkit-keyframes mymove {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes mymove {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.carousel-custom-prev,
.carousel-custom-next {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  display: inline-block;
  border-radius: 50%;
  background: #fff;
  text-align: center;
}

.carousel-custom-prev i,
.carousel-custom-next i {
  font-size: 1rem;
}

.carousel-custom-prev:hover,
.carousel-custom-next:hover {
  background: linear-gradient(to right, rgba(245, 60, 121, 0.99) 0%, rgba(246, 104, 47, 0.99) 100%);
  color: #fff;
}

.carousel-custom-next {
  position: absolute;
  top: 50%;
  right: 1.875rem;
  transform: translateY(-50%);
}

.carousel-custom-prev {
  position: absolute;
  top: 50%;
  left: 1.875rem;
  transform: translateY(-50%);
}

.carousel-caption {
  text-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.78);
  z-index: 1;
  background: rgba(0, 0, 0, 0.6);
}

.carousel-caption h5,
.carousel-caption .h5 {
  color: #fff;
  font-size: 1.8rem;
}

.carousel-caption p {
  margin-bottom: 0;
}

.carousel-indicators {
  z-index: 1;
}

.dropdown-toggle:focus {
  box-shadow: none !important;
}

.dropdown-outline {
  border: 0.1rem solid var(--primary);
}

.dropdown-menu {
  font-size: inherit;
  border: 0;
  z-index: 2;
  overflow: hidden;
  border-radius: 1.375rem;
  box-shadow: 0 0 3.125rem 0 rgba(82, 63, 105, 0.15);
  margin-top: 0;
}

.dropdown-menu .dropdown-item {
  font-size: 1rem;
  color: #969ba0;
  padding: 0.5rem 1.75rem;
}

@media only screen and (max-width: 87.5rem) {
  .dropdown-menu .dropdown-item {
    padding: 0.375rem 1rem;
    font-size: 0.875rem;
  }
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-item:active,
.dropdown-menu .dropdown-item.active {
  color: #514e5f;
}

.dropdown-menu .dropdown-item.active,
.dropdown-menu .dropdown-item:active {
  color: var(--primary);
  background: var(--rgba-primary-1);
}

[direction="rtl"] .dropdown-menu {
  right: auto !important;
}

.dropdown-menu.show {
  right: 0;
}

.dropdown-toggle-split {
  padding: 0 0.625rem;
  opacity: 0.85;
}

.dropdown-toggle-split:after {
  margin-left: 0 !important;
}

.dropdown-toggle-split:active,
.dropdown-toggle-split:focus,
.dropdown-toggle-split:hover {
  opacity: 1;
}

.dropleft .dropdown-toggle:before,
.dropright .dropdown-toggle:before,
.dropup .dropdown-toggle:after,
.dropdown-toggle:after {
  /* content:"\f0d7";
  	font-family:FontAwesome;
  	border: 0;
      vertical-align: middle;
      margin-left: 0.25em;
  	line-height: 1; */
}

.dropup .dropdown-toggle:after {
  /* content:"\f106"; */
}

.dropleft .dropdown-toggle::before {
  content: "\f104";
}

.dropright .dropdown-toggle::before {
  content: "\f105";
}

.dropright .dropdown-toggle::after {
  content: none;
}

.custom-dropdown {
  display: inline-block;
  margin-bottom: 1rem;
}

.custom-dropdown .dropdown-menu {
  border: 0rem;
  min-width: 10rem;
}

.card-action .custom-dropdown {
  margin: 0rem;
  background: var(--rgba-primary-1);
}

.card-action .custom-dropdown.show,
.card-action .custom-dropdown:focus,
.card-action .custom-dropdown:hover {
  background: var(--primary);
  color: #fff;
}

.card-action .custom-dropdown i {
  display: inline-block;
  padding-top: 0.5625rem;
}

.dropdown .dropdown-dots {
  position: relative;
  height: 0.3125rem;
  width: 0.3125rem;
  background: rgba(110, 110, 110, 0.4);
  border-radius: 0.3125rem;
  display: block;
}

.dropdown .dropdown-dots:after,
.dropdown .dropdown-dots:before {
  content: "";
  height: 0.3125rem;
  width: 0.3125rem;
  background: rgba(110, 110, 110, 0.4);
  position: absolute;
  border-radius: 0.3125rem;
}

.dropdown .dropdown-dots:after {
  right: -0.5rem;
}

.dropdown .dropdown-dots:before {
  left: -0.5rem;
}

.dropdown .dropdown-dots.text-white {
  background: rgba(255, 255, 255, 0.7);
}

.dropdown .dropdown-dots.text-white:after,
.dropdown .dropdown-dots.text-white:before {
  background: rgba(255, 255, 255, 0.7);
}

.grid-col {
  padding: 0.5rem !important;
  background: #f2f4fa;
}

.row.grid {
  margin-bottom: 1.5rem;
  text-align: center;
}

.row.grid .grid-col:first-child {
  text-align: left;
}

.label {
  display: inline-block;
  text-align: center;
  font-size: 0.75rem;
  padding: .2rem .8rem;
}

.label-fixed {
  width: 7.5rem;
  padding: .6rem 0;
}

.label-fixed-lg {
  width: 9.5rem;
  padding: .6rem 0;
}

.label-big {
  width: 16.8rem;
  font-size: 1.4rem;
  padding: 1.1rem 0;
}

.label-xl {
  width: 10.5rem;
  padding: 1.1rem 0;
  font-size: 1.5rem;
}

.label-lg {
  width: 9.5rem;
  padding: 1.1rem 0;
}

.label-md {
  width: 8.5rem;
  padding: 1.1rem 0;
}

.label-sm {
  width: 7.5rem;
  padding: 1.1rem 0;
}

.label-default {
  background: #ADB6C7;
}

.label-primary {
  background: var(--primary);
  color: #fff;
}

.label-secondary {
  background: #627EEA;
  color: #fff;
}

.label-info {
  background: #b48dd3;
  color: #fff;
}

.label-success {
  background: #37D159;
  color: #fff;
}

.label-warning {
  background: #FFC368;
  color: #fff;
}

.label-danger {
  background: #FF6746;
  color: #fff;
}

.label-light {
  background: #c8c8c8;
  color: #000;
}

.label-dark {
  background: #6e6e6e;
  color: #fff;
}

code {
  word-break: break-word;
  padding: 0.125rem 0.3125rem;
  border-radius: 0.1875rem;
  background: #fff1ee;
  color: #FF6746;
}

[data-theme-version="dark"] code {
  background: rgba(255, 103, 70, 0.1);
}

.heading-labels {
  color: #333333;
}

.heading-labels>* {
  margin-bottom: .8rem;
}

.heading-labels h1 .label,
.heading-labels .h1 .label {
  font-size: 1.125rem;
  font-weight: normal;
  padding: .4rem .9rem;
}

.heading-labels h2 .label,
.heading-labels .h2 .label {
  font-size: 1rem;
  font-weight: normal;
  padding: 0.3rem .9rem;
}

.heading-labels h3 .label,
.heading-labels .h3 .label {
  font-size: 0.875rem;
  font-weight: normal;
}

.heading-labels h4 .label,
.heading-labels .h4 .label {
  font-size: 0.75rem;
  font-weight: normal;
}

.heading-labels h5 .label,
.heading-labels .h5 .label {
  font-size: 0.75rem;
  font-weight: normal;
}

.heading-labels h6 .label,
.heading-labels .h6 .label {
  font-size: 0.75rem;
  font-weight: normal;
}

.list-group-item {
  background-color: rgba(255, 255, 255, 0);
  border: 0.0625rem solid #F2F2F2;
  padding: 1rem 1.5rem;
}

.list-group-item.active {
  background-color: var(--primary);
  border-color: var(--primary);
}

[data-theme-version="dark"] .list-group-item {
  border-color: #2e2e42;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #fff;
  background-color: #627EEA;
  border-color: #627EEA;
}

[class*="bg-"] .list-group-item {
  border-color: rgba(255, 255, 255, 0.05);
}

.bg-warning .list-group-item {
  border-color: rgba(0, 0, 0, 0.05);
}

.media img {
  border-radius: 0.1875rem;
}

.vertical-card__menu:hover {
  box-shadow: none;
}

.vertical-card__menu--image {
  text-align: center;
}

.vertical-card__menu--image img {
  width: 100%;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.vertical-card__menu--status.closed {
  background: #FF6746;
}

.vertical-card__menu--status.closed:after {
  border-top: 0.625rem solid #FF6746;
}

.vertical-card__menu--status.closed .ribbon-curve {
  border-top: 0.6875rem solid #FF6746;
  border-bottom: 0.625rem solid #FF6746;
}

.vertical-card__menu--desc p {
  margin-bottom: 0.625rem;
}

.vertical-card__menu--price {
  font-size: 1.25rem;
  margin: 0;
  font-weight: 700;
}

.vertical-card__menu--rating {
  font-size: 0.75rem;
}

.vertical-card__menu--title {
  font-weight: 700;
}

.vertical-card__menu--button button {
  font-size: 0.75rem;
}

.vertical-card__menu .card-footer {
  padding: 0.9375rem 1.25rem;
  background-color: #F2F2F2;
  border-top: none;
}

@media only screen and (min-width: 75rem) and (max-width: 90rem) {
  .vertical-card__menu .card-footer {
    padding: 0.9375rem;
  }
}

.vertical-card__menu .card-footer span {
  color: #6e6e6e;
  margin-right: 0.3125rem;
}

.horizontal-card__menu {
  box-shadow: 0 0 0.4375rem rgba(173, 173, 173, 0.32);
  display: flex;
  flex-direction: row;
  display: block;
}

.horizontal-card__menu .card-body {
  padding: 0;
  padding: 1.25rem 1.875rem;
}

.horizontal-card__menu--image {
  flex-basis: 30%;
  max-width: 30%;
  max-width: unset;
  flex-basis: 100%;
}

.horizontal-card__menu--image img {
  height: 100%;
  width: 100%;
}

.horizontal-card__menu--title {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.3125rem;
}

.horizontal-card__menu--fav {
  margin-right: 0.5rem;
}

.horizontal-card__menu--price {
  margin: 0;
  font-size: 1rem;
  font-weight: 700;
}

.horizontal-card__menu--rating {
  font-size: 0.625rem;
}

.horizontal-card__menu--footer {
  margin-top: 0.625rem;
}

.prev_price {
  text-decoration: line-through;
  font-size: 80%;
  opacity: 0.5;
}

.modal-header {
  padding: 1rem 1.875rem;
}

.modal-header .close {
  padding: 0.875rem 1.815rem;
  margin: 0;
  position: absolute;
  right: 0;
  float: none;
  top: 0;
  font-size: 1.875rem;
  font-weight: 100;
}

.modal-body {
  padding: 1.875rem;
}

.modal-footer {
  padding: 1rem 1.875rem;
}

.modal-content {
  border-radius: 1.375rem;
}

.modal-backdrop {
  z-index: 10 !important;
}

.pagination .page-item .page-link:hover {
  background: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

.pagination .page-item.active .page-link {
  background: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

.pagination {
  margin-bottom: 1.25rem;
}

.pagination .page-item.page-indicator .page-link {
  padding: .65rem .8rem;
  font-size: 0.875rem;
}

.pagination .page-item.page-indicator:hover .page-link {
  color: #6e6e6e;
}

.pagination .page-item .page-link {
  text-align: center;
  z-index: 1;
  padding: 0.55rem 1rem;
  font-size: 1rem;
  background: rgba(255, 255, 255, 0.15);
  color: #6e6e6e;
  border: 0.0625rem solid #F2F2F2;
}

[data-theme-version="dark"] .pagination .page-item .page-link {
  border-color: #2e2e42;
  color: #828690;
  background: rgba(255, 255, 255, 0);
}

.pagination .page-item .page-link:hover i,
.pagination .page-item .page-link span {
  color: #fff;
}

.pagination .page-item .page-link:focus {
  outline: 0;
  box-shadow: none;
}

.pagination .page-item .page-link:hover {
  background: var(--primary);
  color: #fff;
  border-color: var(--primary);
}

.pagination .page-item.active .page-link {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
  box-shadow: 0 0.625rem 1.25rem 0rem var(--rgba-primary-2);
}

[data-theme-version="dark"] .pagination .page-item.active .page-link {
  color: #fff;
}

.pagination .page-item .page-link {
  color: #6e6e6e;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.pagination .page-item:last-child .page-link {
  margin-right: 0;
}

[direction="rtl"] .pagination .page-item:first-child .page-link {
  margin-right: 0;
}

.pagination.no-bg li:not(.page-indicator):not(.active) .page-link {
  background: transparent;
  border-color: transparent;
}

.pagination.no-bg.pagination-primary li:not(.page-indicator):not(.active):hover .page-link {
  background: var(--primary);
  border-color: var(--primary);
}

[data-theme-version="dark"] .pagination.no-bg.pagination-primary li:not(.page-indicator):not(.active):hover .page-link {
  background: var(--primary);
  border-color: var(--primary);
}

.pagination.no-bg.pagination-danger li:not(.page-indicator):not(.active):hover .page-link {
  background: #FF6746;
  border-color: #FF6746;
}

[data-theme-version="dark"] .pagination.no-bg.pagination-danger li:not(.page-indicator):not(.active):hover .page-link {
  background: #FF6746;
  border-color: #FF6746;
}

.pagination.no-bg.pagination-info li:not(.page-indicator):not(.active):hover .page-link {
  background: #b48dd3;
  border-color: #b48dd3;
}

[data-theme-version="dark"] .pagination.no-bg.pagination-info li:not(.page-indicator):not(.active):hover .page-link {
  background: #b48dd3;
  border-color: #b48dd3;
}

.pagination.no-bg.pagination-warning li:not(.page-indicator):not(.active):hover .page-link {
  background: #FFC368;
  border-color: #FFC368;
}

[data-theme-version="dark"] .pagination.no-bg.pagination-warning li:not(.page-indicator):not(.active):hover .page-link {
  background: #FFC368;
  border-color: #FFC368;
}

.pagination-primary .page-item .page-link {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: var(--primary);
}

[data-theme-version="dark"] .pagination-primary .page-item .page-link {
  background: var(--rgba-primary-1);
  border-color: transparent;
  color: var(--primary);
}

.pagination-primary .page-item:hover .page-link,
.pagination-primary .page-item.active .page-link {
  background: var(--primary);
  border-color: var(--primary);
  box-shadow: 0 0.625rem 1.25rem 0rem var(--rgba-primary-2);
}

[data-theme-version="dark"] .pagination-primary .page-item:hover .page-link,
[data-theme-version="dark"] .pagination-primary .page-item.active .page-link {
  color: #fff;
}

.pagination-danger .page-item .page-link {
  background: #ffd4cb;
  border-color: #ffd4cb;
  color: #FF6746;
}

[data-theme-version="dark"] .pagination-danger .page-item .page-link {
  background: rgba(255, 103, 70, 0.15);
  border-color: transparent;
  color: #FF6746;
}

.pagination-danger .page-item:hover .page-link,
.pagination-danger .page-item.active .page-link {
  background: #FF6746;
  border-color: #FF6746;
  box-shadow: 0 0.625rem 1.25rem 0rem rgba(255, 103, 70, 0.2);
}

[data-theme-version="dark"] .pagination-danger .page-item:hover .page-link,
[data-theme-version="dark"] .pagination-danger .page-item.active .page-link {
  color: #fff;
}

.pagination-info .page-item .page-link {
  background: #e4d7ef;
  border-color: #e4d7ef;
  color: #b48dd3;
}

[data-theme-version="dark"] .pagination-info .page-item .page-link {
  background: rgba(180, 141, 211, 0.1);
  border-color: transparent;
  color: #b48dd3;
}

.pagination-info .page-item:hover .page-link,
.pagination-info .page-item.active .page-link {
  background: #b48dd3;
  border-color: #b48dd3;
  box-shadow: 0 0.625rem 1.25rem 0rem rgba(180, 141, 211, 0.2);
}

.pagination-warning .page-item .page-link {
  background: #ffecce;
  border-color: #ffecce;
  color: #FFC368;
}

[data-theme-version="dark"] .pagination-warning .page-item .page-link {
  background: rgba(255, 195, 104, 0.1);
  border-color: transparent;
  color: #FFC368;
}

.pagination-warning .page-item:hover .page-link,
.pagination-warning .page-item.active .page-link {
  background: #FFC368;
  border-color: #FFC368;
  box-shadow: 0 0.625rem 1.25rem 0rem rgba(255, 195, 104, 0.2);
}

.pagination-gutter .page-item {
  margin-right: 0.4375rem;
}

.pagination-gutter .page-item .page-link {
  border-radius: 1.375rem !important;
}

.pagination-circle .page-item {
  margin-right: 0.4375rem;
}

.pagination-circle .page-item .page-link,
.pagination-circle .page-item.page-indicator .page-link {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  line-height: 2.5rem;
  border-radius: 50% !important;
  padding: 0;
}

.pagination.pagination-md .page-item .page-link {
  width: 1.875rem;
  height: 1.875rem;
  line-height: 1.875rem;
  font-size: 0.875rem;
}

.pagination.pagination-sm .page-item.page-indicator .page-link {
  font-size: 0.75rem;
}

.pagination.pagination-sm .page-item .page-link {
  padding: 0;
  width: 1.875rem;
  height: 1.875rem;
  line-height: 1.875rem;
  font-size: 0.875rem;
}

.pagination.pagination-xs .page-item.page-indicator .page-link {
  font-size: 0.625rem;
}

.pagination.pagination-xs .page-item .page-link {
  padding: 0;
  width: 1.5625rem;
  height: 1.5625rem;
  line-height: 1.5625rem;
  font-size: 0.75rem;
}

.popover {
  border: 0.125rem solid #627EEA;
  min-width: 13.125rem;
  box-shadow: 0 0 1.875rem 0 rgba(0, 0, 0, 0.1);
}

[data-theme-version="dark"] .popover {
  background-color: #171622;
}

.popover-header {
  background: #627EEA;
  color: #fff;
  font-weight: 300;
}

.popover-header::before {
  border-bottom: 0 !important;
}

.popover-body {
  font-size: 0.75rem;
}

[data-theme-version="dark"] .popover .popover-header {
  border-color: #212130;
}

@media only screen and (max-width: 47.9375rem) {
  .popover {
    z-index: 1;
  }
}

.bootstrap-popover-wrapper .bootstrap-popover:not(:last-child) {
  margin-right: 0.5rem;
}

.bootstrap-popover-wrapper .bootstrap-popover {
  margin-bottom: .5rem;
}

.bootstrap-popover-wrapper .bootstrap-popover button:hover,
.bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: var(--primary);
  color: #fff;
  box-shadow: none;
}

.bs-popover-top .arrow::after,
.bs-popover-auto[data-popper-placement^="top"] .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: 0rem;
  border-top-color: #627EEA;
}

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  right: 0rem;
  border-left-color: #627EEA;
}

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  left: 0rem;
  border-right-color: #627EEA;
}

.bs-popover-bottom .arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"] .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 0rem;
  border-bottom-color: #627EEA;
}

.note-popover {
  display: none;
}

.progress-bar {
  background-color: var(--primary);
}

.progress-bar-primary {
  background-color: var(--primary);
}

.progress-bar-success {
  background-color: #37D159;
}

.progress-bar-info {
  background-color: #b48dd3;
}

.progress-bar-danger {
  background-color: #FF6746;
}

.progress-bar-warning {
  background-color: #FFC368;
}

.progress-bar-pink {
  background-color: #e83e8c;
}

.progress {
  height: 0.375rem;
  background-color: #f6f6f6;
  overflow: hidden;
}

[data-theme-version="dark"] .progress {
  background-color: #171622;
}

.progress-bar {
  border-radius: 1.375rem;
}

.progress-vertical {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 2rem;
  min-height: 17rem;
  position: relative;
}

@media (max-width: 47.98rem) {
  .progress-vertical {
    margin-right: 1rem;
  }
}

.progress-vertical-bottom {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 2rem;
  min-height: 17rem;
  position: relative;
  transform: rotate(180deg);
}

@media (max-width: 47.98rem) {
  .progress-vertical-bottom {
    margin-right: 1rem;
  }
}

.progress-animated {
  animation-duration: 5s;
  animation-name: myanimation;
  transition: all 5s ease 0s;
}

@keyframes myanimation {
  0% {
    width: 0;
  }
}

@keyframes myanimation {
  0% {
    width: 0;
  }
}

.ribbon {
  position: absolute;
  z-index: 1;
  text-transform: uppercase;
}

.ribbon__one {
  top: 0.9375rem;
  left: -0.6875rem;
  min-height: 1.25rem;
  min-width: 3.25rem;
  text-align: center;
  padding: 0.1875rem 0.625rem;
  background: #3AB54B;
  color: #fff;
  font-size: 0.625rem;
}

.ribbon__one::after {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 0.625rem solid #239132;
  border-left: 0.6875rem solid transparent;
  left: 0;
  content: "";
  bottom: -0.625rem;
}

.ribbon__two {
  width: 3.125rem;
  height: 3.125rem;
  display: inline-block;
  background: #FFC368;
  line-height: 3.125rem;
  text-align: center;
  font-size: 1rem;
  color: #fff;
  right: 0.9375rem;
  top: 0.9375rem;
  border-radius: 0.1875rem;
}

.ribbon__three {
  left: -1.875rem;
  top: 0.875rem;
  width: 6.875rem;
  height: 1.5625rem;
  background-color: #FF6746;
  clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
  transform: rotate(-45deg);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
}

.ribbon__four {
  left: 0.5rem;
  top: -0.5rem;
  width: 6.875rem;
  height: 3.125rem;
  background-color: var(--primary);
  z-index: auto;
  color: #fff;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ribbon__four:after {
  right: -0.3125rem;
}

.ribbon__four:before {
  left: -0.3125rem;
}

.ribbon__four:after,
.ribbon__four:before {
  z-index: -1;
  background-color: var(--rgba-primary-1);
  top: 0.1875rem;
  transform: rotate(45deg);
  content: "";
  height: 0.625rem;
  width: 0.625rem;
  position: absolute;
}

.ribbon__five {
  left: -1.875rem;
  top: 0.625rem;
  width: 6.875rem;
  height: 1.875rem;
  background-color: var(--primary);
  transform: rotate(-45deg);
  font-size: 0.75rem;
  color: #fff;
  padding-bottom: 0.3125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.ribbon__five::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 3.125rem 3.125rem 3.125rem;
  border-color: transparent transparent var(--primary) transparent;
  left: 0.25rem;
  top: -2.8125rem;
}

.ribbon__six {
  left: 0;
  top: 1.125rem;
  width: 6.875rem;
  height: 2.375rem;
  background-color: var(--primary);
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 0, 85% 50%, 100% 100%, 100% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0%, 100% 0, 85% 50%, 100% 100%, 100% 100%, 0 100%);
  display: flex;
  font-size: 1.25rem;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.ribbon-curve {
  position: absolute;
  top: 0;
  right: -0.375rem;
  width: 0.625rem;
  height: 0.6875rem;
  border-top: 0.6875rem solid #3AB54B;
  border-bottom: 0.625rem solid #3AB54B;
  border-right: 0.3125rem solid transparent;
}

.jsgrid-grid-body::-webkit-scrollbar,
.dataTables_scrollBody::-webkit-scrollbar,
.table-responsive::-webkit-scrollbar {
  background-color: #f5f5f5;
  width: 0.5rem;
  height: 0.5rem;
}

.jsgrid-grid-body::-webkit-scrollbar-track,
.dataTables_scrollBody::-webkit-scrollbar-track,
.table-responsive::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
  border-radius: 0.625rem;
  background-color: #f5f5f5;
}

.jsgrid-grid-body::-webkit-scrollbar-thumb,
.dataTables_scrollBody::-webkit-scrollbar-thumb,
.table-responsive::-webkit-scrollbar-thumb {
  border-radius: 0.625rem;
  -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
  background-color: var(--primary);
}

.multi-steps>li.is-active:before,
.multi-steps>li.is-active~li:before {
  content: counter(stepNum);
  font-family: inherit;
  font-weight: 400;
}

.multi-steps>li.is-active:after,
.multi-steps>li.is-active~li:after {
  background-color: #F2F2F2;
}

.multi-steps {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.multi-steps>li {
  counter-increment: stepNum;
  text-align: center;
  display: table-cell;
  position: relative;
  color: var(--primary);
  font-size: 0.75rem;
}

.multi-steps>li:before {
  content: '\2713';
  display: block;
  margin: 0 auto 0.25rem;
  background-color: #fff;
  width: 1.5625rem;
  height: 1.5625rem;
  line-height: 1.375rem;
  text-align: center;
  font-weight: bold;
  position: relative;
  z-index: 1;
  width: 1.5625rem;
  height: 1.5625rem;
  line-height: 1.3125rem;
  border-width: 0.125rem;
  border-style: solid;
  border-color: var(--primary);
  border-radius: 0.3125rem;
}

.multi-steps>li:after {
  content: '';
  height: 0.125rem;
  width: 100%;
  background-color: var(--primary);
  position: absolute;
  top: 0.75rem;
  left: 50%;
  top: 0.75rem;
}

[direction="rtl"] .multi-steps>li:after {
  left: auto;
  right: 50%;
}

.multi-steps>li:last-child:after {
  display: none;
}

.multi-steps>li.is-active:before {
  background-color: #fff;
  border-color: var(--primary);
}

.multi-steps>li.is-active~li {
  color: #969ba0;
}

.multi-steps>li.is-active~li:before {
  background-color: #F2F2F2;
  border-color: #F2F2F2;
}

.nav-pills .nav-link {
  border-radius: 1.375rem;
  padding: 0.75rem 1.25rem;
}

.default-tab .nav-link {
  background: transparent;
  border-radius: 0rem;
  font-weight: 500;
}

.default-tab .nav-link i {
  display: inline-block;
  transform: scale(1.5);
  color: var(--primary);
}

.default-tab .nav-link:focus,
.default-tab .nav-link:hover,
.default-tab .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff #ebeef6;
  border-radius: 1.375rem 1.375rem 0 0;
  color: var(--primary);
}

[data-theme-version="dark"] .default-tab .nav-link:focus,
[data-theme-version="dark"] .default-tab .nav-link:hover,
[data-theme-version="dark"] .default-tab .nav-link.active {
  background-color: var(--rgba-primary-1);
  border-color: transparent transparent #2e2e42 transparent;
}

.custom-tab-1 .nav-link {
  background: transparent;
  border-radius: 0rem;
  font-weight: 500;
  border-bottom: 0.1875rem solid transparent;
  border-width: 0 0rem 0.1875rem 0;
}

.custom-tab-1 .nav-link i {
  display: inline-block;
  transform: scale(1.5);
  color: var(--primary);
}

.custom-tab-1 .nav-link:focus,
.custom-tab-1 .nav-link:hover,
.custom-tab-1 .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: var(--primary);
  border-radius: 0;
  color: var(--primary);
  border-width: 0 0rem 0.1875rem 0;
}

[data-theme-version="dark"] .custom-tab-1 .nav-link:focus,
[data-theme-version="dark"] .custom-tab-1 .nav-link:hover,
[data-theme-version="dark"] .custom-tab-1 .nav-link.active {
  background-color: var(--rgba-primary-1);
}

.nav-pills.light .nav-link.active,
.nav-pills.light .show>.nav-link {
  background: var(--rgba-primary-1);
  color: var(--primary);
  box-shadow: none;
}

[data-theme-version="dark"] .nav-pills.light .nav-link.active,
[data-theme-version="dark"] .nav-pills.light .show>.nav-link {
  background: var(--rgba-primary-1);
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  background-color: var(--primary);
  box-shadow: 0 0 1.25rem 0 var(--rgba-primary-2);
}

.tooltip-wrapper button:not(:last-child) {
  margin-right: 0.5rem;
}

.tooltip-wrapper button:hover {
  background: var(--primary);
  color: #fff;
}

.tooltip-wrapper button {
  margin-bottom: .5rem;
}

.tooltip-wrapper button:focus {
  box-shadow: none;
}

.tooltip-inner {
  border-radius: 0;
  background: #333333;
  font-size: 0.75rem;
  font-weight: 300;
  padding: 0.35rem 0.7rem;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #333333;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #333333;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #333333;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #333333;
}

.widget-timeline .timeline {
  list-style: none;
  position: relative;
}

.widget-timeline .timeline:before {
  top: 1.25rem;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 0.1875rem;
  left: 0.5625rem;
  margin-right: -0.0938rem;
  background: #c8c8c8;
}

[data-theme-version="dark"] .widget-timeline .timeline:before {
  background: #2e2e42;
}

[direction="rtl"] .widget-timeline .timeline:before {
  left: auto;
  right: 0.5625rem;
  margin-right: auto;
  margin-left: -0.0938rem;
}

.widget-timeline .timeline>li {
  margin-bottom: 0.9375rem;
  position: relative;
}

.widget-timeline .timeline>li:before {
  content: " ";
  display: table;
}

.widget-timeline .timeline>li:after {
  content: " ";
  display: table;
  clear: both;
}

.widget-timeline .timeline>li>.timeline-panel {
  border-radius: 1.375rem;
  padding: 0.9375rem 1.25rem;
  position: relative;
  display: block;
  margin-left: 2.5rem;
  border-width: 0.0625rem;
  border-style: solid;
}

.widget-timeline .timeline>li>.timeline-panel span {
  font-size: 0.75rem;
  display: block;
  margin-bottom: 0.3125rem;
  opacity: 0.8;
  letter-spacing: 0.0625rem;
}

.widget-timeline .timeline>li>.timeline-panel p {
  font-size: 0.875rem;
}

.widget-timeline .timeline>li>.timeline-panel:after {
  content: "";
  width: 0.625rem;
  height: 0.625rem;
  background: inherit;
  border-color: inherit;
  border-style: solid;
  border-width: 0rem 0rem 0.0625rem 0.0625rem;
  display: block;
  position: absolute;
  left: -0.3125rem;
  transform: rotate(45deg);
  top: 0.9375rem;
}

.widget-timeline .timeline>li>.timeline-badge {
  border-radius: 50%;
  height: 1.375rem;
  left: 0;
  position: absolute;
  top: 0.625rem;
  width: 1.375rem;
  border-width: 0.125rem;
  border-style: solid;
  background: #fff;
  padding: 0.25rem;
}

[data-theme-version="dark"] .widget-timeline .timeline>li>.timeline-badge {
  background-color: #212130;
}

.widget-timeline .timeline>li>.timeline-badge:after {
  content: "";
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 100%;
  display: block;
}

[direction="rtl"] .widget-timeline .timeline>li>.timeline-badge {
  left: auto;
  right: 1.1875rem;
}

.widget-timeline .timeline-body>p {
  font-size: 0.75rem;
}

.widget-timeline .timeline-badge.primary {
  border-color: var(--rgba-primary-1);
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.primary {
  border-color: var(--rgba-primary-1);
}

.widget-timeline .timeline-badge.primary:after {
  background-color: var(--primary);
  box-shadow: 0 0.3125rem 0.625rem 0 var(--rgba-primary-2);
}

.widget-timeline .timeline-badge.primary+.timeline-panel {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.primary+.timeline-panel {
  border-color: transparent;
  background-color: var(--rgba-primary-1);
}

.widget-timeline .timeline-badge.success {
  border-color: #c8f2d1;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.success {
  border-color: rgba(55, 209, 89, 0.1);
}

.widget-timeline .timeline-badge.success:after {
  background-color: #37D159 !important;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(55, 209, 89, 0.2);
}

.widget-timeline .timeline-badge.success+.timeline-panel {
  background: #c8f2d1;
  border-color: #c8f2d1;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.success+.timeline-panel {
  background-color: rgba(55, 209, 89, 0.1);
  border-color: transparent;
}

.widget-timeline .timeline-badge.warning {
  border-color: #ffecce;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.warning {
  border-color: rgba(255, 195, 104, 0.1);
}

.widget-timeline .timeline-badge.warning:after {
  background-color: #FFC368 !important;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(255, 195, 104, 0.2);
}

.widget-timeline .timeline-badge.warning+.timeline-panel {
  background: #ffecce;
  border-color: #ffecce;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.warning+.timeline-panel {
  background-color: rgba(255, 195, 104, 0.1);
  border-color: transparent;
}

.widget-timeline .timeline-badge.danger {
  border-color: #ffd4cb;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.danger {
  border-color: rgba(255, 103, 70, 0.15);
}

.widget-timeline .timeline-badge.danger:after {
  background-color: #FF6746 !important;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(255, 103, 70, 0.2);
}

.widget-timeline .timeline-badge.danger+.timeline-panel {
  background: #ffd4cb;
  border-color: #ffd4cb;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.danger+.timeline-panel {
  background-color: rgba(255, 103, 70, 0.15);
  border-color: transparent;
}

.widget-timeline .timeline-badge.info {
  border-color: #e4d7ef;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.info {
  border-color: rgba(180, 141, 211, 0.1);
}

.widget-timeline .timeline-badge.info:after {
  background-color: #b48dd3 !important;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(180, 141, 211, 0.2);
}

.widget-timeline .timeline-badge.info+.timeline-panel {
  background: #e4d7ef;
  border-color: #e4d7ef;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.info+.timeline-panel {
  background-color: rgba(180, 141, 211, 0.1);
  border-color: transparent;
}

.widget-timeline .timeline-badge.dark {
  border-color: #eeeeee;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.dark {
  border-color: rgba(110, 110, 110, 0.35);
}

.widget-timeline .timeline-badge.dark:after {
  background-color: #6e6e6e !important;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(110, 110, 110, 0.2);
}

.widget-timeline .timeline-badge.dark+.timeline-panel {
  background: #eeeeee;
  border-color: #eeeeee;
}

[data-theme-version="dark"] .widget-timeline .timeline-badge.dark+.timeline-panel {
  background-color: rgba(110, 110, 110, 0.35);
  border-color: transparent;
}

.widget-timeline.style-1 .timeline-panel {
  background: transparent;
}

.widget-timeline.style-1 .timeline .timeline-badge.timeline-badge+.timeline-panel {
  background: transparent !important;
  border-style: solid;
  border-width: 0 0 0 0.3125rem;
  border-radius: 0;
  padding: 0.3125rem 0.625rem 0.3125rem 0.9375rem;
}

.widget-timeline.style-1 .timeline .timeline-badge.timeline-badge+.timeline-panel:after {
  border: 0;
  left: -0.5625rem;
  width: 0.4375rem;
  height: 0.4375rem;
}

.widget-timeline.style-1 .timeline .timeline-badge.primary+.timeline-panel {
  border-color: var(--primary);
}

.widget-timeline.style-1 .timeline .timeline-badge.primary+.timeline-panel:after {
  background: var(--primary);
}

.widget-timeline.style-1 .timeline .timeline-badge.success+.timeline-panel {
  border-color: #37D159;
}

.widget-timeline.style-1 .timeline .timeline-badge.success+.timeline-panel:after {
  background: #37D159;
}

.widget-timeline.style-1 .timeline .timeline-badge.warning+.timeline-panel {
  border-color: #FFC368;
}

.widget-timeline.style-1 .timeline .timeline-badge.warning+.timeline-panel:after {
  background: #FFC368;
}

.widget-timeline.style-1 .timeline .timeline-badge.danger+.timeline-panel {
  border-color: #FF6746;
}

.widget-timeline.style-1 .timeline .timeline-badge.danger+.timeline-panel:after {
  background: #FF6746;
}

.widget-timeline.style-1 .timeline .timeline-badge.info+.timeline-panel {
  border-color: #b48dd3;
}

.widget-timeline.style-1 .timeline .timeline-badge.info+.timeline-panel:after {
  background: #b48dd3;
}

.widget-timeline.style-1 .timeline .timeline-badge.dark+.timeline-panel {
  border-color: #6e6e6e;
}

.widget-timeline.style-1 .timeline .timeline-badge.dark+.timeline-panel:after {
  background: #6e6e6e;
}

#chart_widget_4 {
  height: 15.9375rem !important;
}

#chart_widget_5 .ct-series-a .ct-line,
#chart_widget_5 .ct-series-a .ct-point {
  stroke: #46ffc8;
}

#chart_widget_5 .ct-line {
  stroke-width: 0.0625rem;
}

#chart_widget_5 .ct-point {
  stroke-width: 0.125rem;
}

#chart_widget_5 .ct-series-a .ct-area {
  fill: #20dea6;
}

#chart_widget_5 .ct-area {
  fill-opacity: 1;
}

#chart_widget_6 .ct-series-a .ct-line,
#chart_widget_6 .ct-series-a .ct-point {
  stroke: #b48dd3;
}

#chart_widget_6 .ct-line {
  stroke-width: 0.125rem;
}

#chart_widget_6 .ct-point {
  stroke-width: 0.3125rem;
}

#chart_widget_6 .ct-series-a .ct-area {
  fill: #b48dd3;
}

#chart_widget_6 .ct-area {
  fill-opacity: .5;
}

#chart_widget_8 {
  height: 15.9375rem;
}

#chart_widget_8 .ct-series-a .ct-line,
#chart_widget_8 .ct-series-a .ct-point {
  stroke: #b48dd3;
}

#chart_widget_8 .ct-line {
  stroke-width: 0.125rem;
}

#chart_widget_8 .ct-point {
  stroke-width: 0.3125rem;
}

#chart_widget_8 .ct-series-a .ct-area {
  fill: #b48dd3;
}

#chart_widget_8 .ct-area {
  fill-opacity: .5;
}

#chart_widget_9,
#chart_widget_10 {
  height: 15.625rem !important;
}

#chart_widget_11 .ct-slice-donut,
#chart_widget_12 .ct-slice-donut,
#chart_widget_13 .ct-slice-donut {
  stroke-width: 1.5625rem !important;
}

#chart_widget_11 {
  height: 16.875rem !important;
}

#chart_widget_17 {
  height: 9.375rem !important;
}

.chart_widget_tab_one .nav-link {
  border: 0.0625rem solid #ddd;
}

.chart_widget_tab_one .nav-link.active {
  background-color: var(--primary);
  border: 0.0625rem solid var(--primary);
  color: #fff;
}

.chart_widget_tab_one .nav-link.active:hover {
  border: 0.0625rem solid var(--primary);
}

.chart_widget_tab_one .nav-link:hover {
  border: 0.0625rem solid #ddd;
}

[data-theme-version="dark"] .ccc-widget>div {
  background: #212130 !important;
  border-color: #2e2e42 !important;
}

.social-icon {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  border-radius: 0.25rem;
  text-align: center;
  background: #FCFCFC;
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
}

.social-icon i {
  color: #fff;
}

.social-icon.youtube {
  background: #FF0000;
}

.social-icon.facebook {
  background: #3b5998;
}

.social-icon.twitter {
  background: #1da1f2;
}

.social-graph-wrapper {
  text-align: center;
  padding: 1.25rem;
  position: relative;
  color: #fff;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.social-graph-wrapper.widget-facebook {
  background-color: #3b5998;
}

.social-graph-wrapper.widget-twitter {
  background-color: #1da1f2;
}

.social-graph-wrapper.widget-linkedin {
  background-color: #007bb6;
}

.social-graph-wrapper.widget-googleplus {
  background-color: #db4439;
}

.social-graph-wrapper .s-icon {
  font-size: 1.5rem;
  position: relative;
  padding: 0 0.625rem;
}

.social-icon2 a {
  border: 0.1rem solid var(--primary);
  display: block;
  padding: 1rem;
  margin-top: 1.5rem;
  margin-bottom: .5rem;
  border-radius: .3rem;
  font-size: 1.6rem;
}

.social-icon2 i {
  font-size: 0.75rem;
  color: var(--primary);
}

.social-icon3 ul li {
  display: inline-block;
}

.social-icon3 ul li a {
  display: block;
}

.social-icon3 ul li a i {
  font-size: 1.4rem;
  padding: 1rem .7rem;
  color: #464a53;
}

.social-icon3 ul li a:hover i {
  color: var(--primary);
}

.social-icons-muted ul li a i {
  color: #89879f;
}

.social-links a {
  padding: .5rem;
}

.widget-stat .media {
  padding: 0rem 0;
  align-items: center;
}

.widget-stat .media>span {
  height: 5.3125rem;
  width: 5.3125rem;
  border-radius: 3.125rem;
  padding: 0.625rem 0.75rem;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #464a53;
  min-width: 5.3125rem;
}

.widget-stat .media .media-body p {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.875rem;
}

[data-theme-version="dark"] .widget-stat .media .media-body p {
  color: #c4c9d5;
}

.widget-stat .media .media-body h3,
.widget-stat .media .media-body .h3 {
  font-size: 2.5rem;
  font-weight: 600;
  margin: 0;
  line-height: 1.2;
}

.widget-stat .media .media-body h4,
.widget-stat .media .media-body .h4 {
  font-size: 1.5rem;
  display: inline-block;
  vertical-align: middle;
}

.widget-stat .media .media-body span {
  margin-left: 0.3125rem;
}

.widget-stat[class*="bg-"] .media>span {
  background-color: rgba(255, 255, 255, 0.25);
  color: #fff;
}

.widget-stat[class*="bg-"] .progress {
  background-color: rgba(255, 255, 255, 0.25) !important;
}

[direction="rtl"] .widget-stat .media .media-body span {
  margin-left: 0;
  margin-right: 0.625rem;
}

.dez-widget-1 .card {
  background: #FFE7DB;
}

.dez-widget-1 .card .card-body p {
  color: #f87533;
}

.dez-widget-1 .icon {
  float: right;
  width: 3.125rem;
  height: 3.125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  font-size: 1.75rem;
}

.bgl-primary {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
}

[data-theme-version="dark"] .bgl-primary {
  background-color: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
}

.bgl-secondary {
  background: #f2f4fd;
  border-color: #f2f4fd;
}

[data-theme-version="dark"] .bgl-secondary {
  background-color: rgba(98, 126, 234, 0.5);
  border-color: rgba(98, 126, 234, 0.5);
}

.bgl-success {
  background: #c8f2d1;
  border-color: #c8f2d1;
}

[data-theme-version="dark"] .bgl-success {
  background-color: rgba(55, 209, 89, 0.1);
  border-color: rgba(55, 209, 89, 0.1);
}

.bgl-warning {
  background: #ffecce;
  border-color: #ffecce;
}

[data-theme-version="dark"] .bgl-warning {
  background-color: rgba(255, 195, 104, 0.1);
  border-color: rgba(255, 195, 104, 0.1);
}

.bgl-danger {
  background: #ffd4cb;
  border-color: #ffd4cb;
}

[data-theme-version="dark"] .bgl-danger {
  background-color: rgba(255, 103, 70, 0.15);
  border-color: rgba(255, 103, 70, 0.15);
}

.bgl-info {
  background: #e4d7ef;
  border-color: #e4d7ef;
}

[data-theme-version="dark"] .bgl-info {
  background-color: rgba(180, 141, 211, 0.1);
  border-color: rgba(180, 141, 211, 0.1);
}

.bg-primary-light {
  background: var(--rgba-primary-5);
}

[data-theme-version="dark"] .bg-primary-light {
  background-color: var(--rgba-primary-1);
}

.bg-secondary-light {
  background: rgba(242, 244, 253, 0.5);
}

[data-theme-version="dark"] .bg-secondary-light {
  background-color: rgba(98, 126, 234, 0.05);
}

.bg-success-light {
  background: rgba(200, 242, 209, 0.5);
}

[data-theme-version="dark"] .bg-success-light {
  background-color: rgba(55, 209, 89, 0.05);
}

.bg-warning-light {
  background: rgba(255, 236, 206, 0.5);
}

[data-theme-version="dark"] .bg-warning-light {
  background-color: rgba(255, 195, 104, 0.05);
}

.bg-danger-light {
  background: rgba(255, 212, 203, 0.5);
}

[data-theme-version="dark"] .bg-danger-light {
  background-color: rgba(255, 103, 70, 0.05);
}

.bg-info-light {
  background: rgba(228, 215, 239, 0.5);
}

[data-theme-version="dark"] .bg-info-light {
  background-color: rgba(180, 141, 211, 0.05);
}

.bgl-dark {
  background: #eeeeee;
  border-color: #eeeeee;
}

.bgl-light {
  background: #c8c8c8;
  border-color: #c8c8c8;
}

.overlay-box {
  position: relative;
  z-index: 1;
}

.overlay-box:after {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  opacity: 0.85;
  background: var(--primary);
  z-index: -1;
}

.rating-bar {
  font-size: 0.8125rem;
}

.tdl-holder {
  margin: 0 auto;
}

.tdl-holder ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tdl-holder li {
  background-color: #fff;
  border-bottom: 0.0625rem solid #F2F2F2;
  list-style: none none;
  margin: 0;
  padding: 0;
}

.tdl-holder li span {
  margin-left: 2.1875rem;
  font-size: 1rem;
  vertical-align: middle;
  transition: all 0.2s linear;
}

[direction="rtl"] .tdl-holder li span {
  margin-left: auto;
  margin-right: 2.1875rem;
}

.tdl-holder label {
  cursor: pointer;
  display: block;
  line-height: 3.125rem;
  padding-left: 1.5rem;
  position: relative;
  margin: 0 !important;
}

[direction="rtl"] .tdl-holder label {
  padding-left: 0;
  padding-right: 1.5rem;
}

.tdl-holder label:hover {
  background-color: #eef1f6;
  color: #6e6e6e;
}

.tdl-holder label:hover a {
  color: #FF6746;
}

.tdl-holder label a {
  color: #fff;
  display: inline-block;
  line-height: normal;
  height: 100%;
  text-align: center;
  text-decoration: none;
  width: 3.125rem;
  -webkit-transition: all .2s linear;
  -moz-transition: all .2s linear;
  -o-transition: all .2s linear;
  transition: all 0.2s linear;
  padding: 1.125rem 0rem;
  font-size: 1.125rem;
  position: absolute;
  right: 0;
}

[direction="rtl"] .tdl-holder label a {
  right: auto;
  left: 0;
}

.tdl-holder input[type=checkbox] {
  cursor: pointer;
  opacity: 0;
  position: absolute;
}

.tdl-holder input[type=checkbox]+i {
  background-color: #fff;
  border: 0.0625rem solid #e7e7e7;
  display: block;
  height: 1.25rem;
  position: absolute;
  top: 0.9375rem;
  width: 1.25rem;
  z-index: 1;
  border-radius: 0rem;
  font-size: 0.6875rem;
  border-radius: 0.25rem;
}

.tdl-holder input[type=checkbox]:checked+i {
  background: var(--primary);
  border-color: transparent;
}

.tdl-holder input[type=checkbox]:checked+i::after {
  content: "\f00c";
  font-family: 'fontAwesome';
  display: block;
  left: 0.125rem;
  position: absolute;
  top: -0.875rem;
  z-index: 2;
  color: #fff;
}

.tdl-holder input[type=checkbox]:checked~span {
  text-decoration: line-through;
  position: relative;
}

.tdl-holder input[type=text] {
  background-color: #fff;
  height: 3.125rem;
  margin-top: 1.8125rem;
  border-radius: 0.1875rem;
}

.tdl-holder input[type=text]::placeholder {
  color: #6e6e6e;
}

.widget-message p {
  font-size: 0.875rem;
  line-height: 1.5;
}

.picker .picker__frame {
  min-width: 28.125rem;
  max-width: 28.125rem;
}

.picker .picker__frame .picker__box {
  padding: 1.25rem;
  border: 0;
  box-shadow: 0 0.3125rem 0.625rem rgba(0, 0, 0, 0.1);
}

.picker .picker__frame .picker__box .picker__header {
  margin-top: 0;
}

.picker .picker__frame .picker__box .picker__header .picker__year {
  color: var(--primary);
  font-style: inherit;
  font-size: 1.25rem;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev,
.picker .picker__frame .picker__box .picker__header .picker__nav--next {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  line-height: 2.5rem;
  border-radius: 0.125rem;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev:before,
.picker .picker__frame .picker__box .picker__header .picker__nav--next:before {
  content: none !important;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev:after,
.picker .picker__frame .picker__box .picker__header .picker__nav--next:after {
  font-family: fontawesome;
  border: 0;
  margin: 0 auto;
  font-size: 1.375rem;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev:hover,
.picker .picker__frame .picker__box .picker__header .picker__nav--next:hover {
  background-color: var(--primary);
  color: var(--primary);
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev {
  left: 0;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev:after {
  content: "\f0d9";
}

.picker .picker__frame .picker__box .picker__header .picker__nav--next {
  right: 0;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--next:after {
  content: "\f0da";
}

.picker .picker__frame .picker__box .picker__table .picker__weekday {
  padding: 0.625rem 0;
  font-size: 1rem;
}

.picker .picker__frame .picker__box .picker__table .picker__day {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 3.125rem;
  padding: 0 !important;
  font-size: 1rem;
  line-height: 2.5rem;
  margin: auto;
  border: 0 !important;
}

.picker .picker__frame .picker__box .picker__table .picker__day.picker__day--today:before {
  content: none !important;
}

.picker .picker__frame .picker__box .picker__table .picker__day.picker__day--highlighted {
  border: 0 !important;
  padding: 0;
  background-color: var(--primary);
  color: #fff !important;
}

.picker .picker__frame .picker__box .picker__table .picker__day:hover {
  background-color: var(--primary);
  color: #fff !important;
}

.picker .picker__frame .picker__box .picker__footer .picker__button--today {
  background-color: var(--primary);
  color: #fff !important;
}

.picker .picker__frame .picker__box .picker__footer .picker__button--today:before {
  content: none !important;
}

.picker .picker__frame .picker__box .picker__footer .picker__button--clear,
.picker .picker__frame .picker__box .picker__footer .picker__button--close,
.picker .picker__frame .picker__box .picker__footer .picker__button--today {
  border: 0;
  border-radius: 0.125rem;
  font-size: 1rem;
}

.picker .picker__frame .picker__box .picker__footer .picker__button--clear:hover,
.picker .picker__frame .picker__box .picker__footer .picker__button--close:hover,
.picker .picker__frame .picker__box .picker__footer .picker__button--today:hover {
  background-color: var(--primary);
  color: #fff !important;
}

@media only screen and (max-width: 35.9375rem) {
  .picker .picker__frame {
    min-width: 100% !important;
    max-width: 100% !important;
  }

  .picker .picker__frame .picker__box {
    padding: 0.9375rem;
    margin: 0 0.625rem;
  }
}

.card-list {
  overflow: unset;
  height: calc(100% - 3.125rem);
}

.card-list .card-header {
  padding-top: 0;
  padding-bottom: 0;
}

.card-list .card-header .photo {
  overflow: hidden;
  border-radius: 0.3125rem;
  width: 100%;
}

@media only screen and (max-width: 74.9375rem) {
  .card-list {
    height: calc(100% - 2.5rem);
  }
}

.card-list.list-left {
  margin-top: 0.9375rem;
  margin-left: 0.9375rem;
}

.card-list.list-left .card-header {
  padding-left: 0;
}

.card-list.list-left .card-header .photo {
  margin: -0.9375rem 0.9375rem 0 -0.9375rem;
}

.card-list.list-right {
  margin-top: 0.9375rem;
  margin-right: 0.9375rem;
  text-align: right;
}

.card-list.list-right .card-header {
  padding-right: 0;
}

.card-list.list-right .card-header .photo {
  margin: -0.9375rem -0.9375rem 0 0.9375rem;
}

.card-list.list-right .card-header .photo img {
  width: 100%;
}

.card-list.list-center {
  margin-top: 0.9375rem;
}

.card-list.list-center .card-header .photo {
  margin: -0.9375rem 0 0 0;
}

.card-list .photo img {
  width: 100%;
}

.card-profile .profile-photo {
  margin-top: -1.25rem;
}

.widget-media .timeline .timeline-panel {
  display: flex;
  align-items: center;
  border-bottom: 0.0625rem solid #eaeaea;
  padding-bottom: 0.9375rem;
  margin-bottom: 0.9375rem;
}

.widget-media .timeline .timeline-panel .media {
  width: 3.125rem;
  height: 3.125rem;
  background: #eee;
  border-radius: 0.75rem;
  overflow: hidden;
  font-size: 1.25rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  align-self: start;
}

.widget-media .timeline .timeline-panel .media-primary {
  background: var(--rgba-primary-1);
  color: var(--primary);
}

[data-theme-version="dark"] .widget-media .timeline .timeline-panel .media-primary {
  background-color: var(--rgba-primary-1);
}

.widget-media .timeline .timeline-panel .media-info {
  background: #e4d7ef;
  color: #b48dd3;
}

[data-theme-version="dark"] .widget-media .timeline .timeline-panel .media-info {
  background-color: rgba(180, 141, 211, 0.1);
}

.widget-media .timeline .timeline-panel .media-warning {
  background: #ffecce;
  color: #FFC368;
}

[data-theme-version="dark"] .widget-media .timeline .timeline-panel .media-warning {
  background-color: rgba(255, 195, 104, 0.1);
}

.widget-media .timeline .timeline-panel .media-danger {
  background: #ffd4cb;
  color: #FF6746;
}

[data-theme-version="dark"] .widget-media .timeline .timeline-panel .media-danger {
  background-color: rgba(255, 103, 70, 0.15);
}

.widget-media .timeline .timeline-panel .media-success {
  background: #c8f2d1;
  color: #37D159;
}

[data-theme-version="dark"] .widget-media .timeline .timeline-panel .media-success {
  background-color: rgba(55, 209, 89, 0.1);
}

.widget-media .timeline .timeline-panel .media-body p {
  font-size: 0.875rem;
  line-height: 1.5;
}

.widget-media .timeline .timeline-panel .dropdown {
  align-self: self-end;
  margin-top: 0.3125rem;
}

.widget-media .timeline li:last-child .timeline-panel {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

.card[class*="bg-"] .timeline .timeline-panel {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.table {
  color: strong;
  color-color: #6e6e6e;
}

.table th,
.table td {
  border-color: #F2F2F2;
  padding: 0.9375rem 0.625rem;
}

[data-theme-version="dark"] .table th,
[data-theme-version="dark"] .table td {
  border-color: #2e2e42;
}

.table.table-striped tbody tr:nth-of-type(odd),
.table.table-hover tr:hover {
  background-color: #f2f4fa;
}

[data-theme-version="dark"] .table.table-striped tbody tr:nth-of-type(odd),
[data-theme-version="dark"] .table.table-hover tr:hover {
  background-color: #171622;
}

.table.shadow-hover tbody tr:hover {
  background-color: #fff;
  box-shadow: 0 0rem 1.875rem var(--rgba-primary-2);
}

[data-theme-version="dark"] .table.shadow-hover tbody tr:hover {
  background-color: #171622;
}

.table.tr-rounded tr td:first-child,
.table.tr-rounded tr th:first-child {
  border-radius: 2.8125rem 0 0 2.8125rem;
}

.table.tr-rounded tr td:last-child,
.table.tr-rounded tr th:last-child {
  border-radius: 0 2.8125rem 2.8125rem 0;
}

.table.border-hover tr td {
  border-width: 0.0625rem 0 0.0625rem 0;
  border-bottom: 0.0625rem solid transparent;
  border-color: transparent;
}

.table.border-hover tr td:first-child {
  border-width: 0.0625rem 0 0.0625rem 0.0625rem;
}

.table.border-hover tr td:last-child {
  border-width: 0.0625rem 0.0625rem 0.0625rem 0;
}

.table.border-hover tr:hover td {
  border-color: #F2F2F2;
}

.table.bg-primary-hover th,
.table.bg-primary-hover td {
  border: none;
  font-weight: 500;
}

.table.bg-primary-hover td {
  color: #000;
}

.table.bg-primary-hover th {
  color: #6C6C6C;
}

.table.bg-primary-hover tr:hover td,
.table.bg-primary-hover tr:hover th {
  background: var(--primary);
  color: #fff;
}

.table.bg-secondary-hover th,
.table.bg-secondary-hover td {
  border: none;
  font-weight: 500;
}

.table.bg-secondary-hover td {
  color: #000;
}

.table.bg-secondary-hover th {
  color: #6C6C6C;
}

.table.bg-secondary-hover tr:hover td,
.table.bg-secondary-hover tr:hover th {
  background: #627EEA;
  color: #fff !important;
}

.table.bg-info-hover th,
.table.bg-info-hover td {
  border: none;
  font-weight: 500;
}

.table.bg-info-hover td {
  color: #000;
}

.table.bg-info-hover th {
  color: #6C6C6C;
}

.table.bg-info-hover tr:hover td,
.table.bg-info-hover tr:hover th {
  background: #b48dd3;
  color: #fff !important;
}

.table.bg-warning-hover th,
.table.bg-warning-hover td {
  border: none;
  font-weight: 500;
}

.table.bg-warning-hover td {
  color: #000;
}

.table.bg-warning-hover th {
  color: #6C6C6C;
}

.table.bg-warning-hover tr:hover td,
.table.bg-warning-hover tr:hover th {
  background: #FFC368;
  color: #fff !important;
}

.table.border-no td {
  border: 0;
}

.table.short-one tr td:first-child,
.table.short-one tr th:first-child {
  width: 3.75rem;
}

.table thead th {
  border-bottom: 0.0625rem solid #F2F2F2;
  text-transform: capitalize;
  font-size: 1.125rem;
  white-space: nowrap;
  font-weight: 500;
  letter-spacing: 0.0313rem;
  border-color: #F2F2F2 !important;
}

[data-theme-version="dark"] .table thead th {
  border-color: #2e2e42;
}

.table tbody tr td {
  vertical-align: middle;
  border-color: #F2F2F2;
}

[data-theme-version="dark"] .table tbody tr td {
  border-color: #2e2e42;
}

.table:not(.table-bordered) thead th {
  border-top: none;
}

.table .thead-primary th {
  background-color: var(--primary);
  color: #fff;
}

.table .thead-info th {
  background-color: #b48dd3;
  color: #fff;
}

.table .thead-warning th {
  background-color: #FFC368;
  color: #fff;
}

.table .thead-danger th {
  background-color: #FF6746;
  color: #fff;
}

.table .thead-success th {
  background-color: #37D159;
  color: #fff;
}

.table.primary-table-bordered {
  border: 0.0625rem solid #F2F2F2;
}

[data-theme-version="dark"] .table.primary-table-bordered {
  border-color: #2e2e42;
}

.table.primary-table-bg-hover thead th {
  background-color: var(--primary-dark);
  color: #fff;
  border-bottom: none;
}

.table.primary-table-bg-hover tbody tr {
  background-color: var(--primary);
  color: #fff;
  transition: all .1s ease;
}

.table.primary-table-bg-hover tbody tr:hover {
  background-color: var(--rgba-primary-8);
}

.table.primary-table-bg-hover tbody tr:not(:last-child) td,
.table.primary-table-bg-hover tbody tr:not(:last-child) th {
  border-bottom: 0.0625rem solid var(--primary-dark);
}

.table-responsive-tiny {
  min-width: 18.75rem;
}

.table-responsive-sm {
  min-width: 30rem !important;
}

.table-responsive-md {
  min-width: 36rem !important;
}

.table-responsive-lg {
  min-width: 60.9375rem !important;
}

.table-responsive-xl {
  min-width: 70.9375rem !important;
}

.table-primary,
.table-primary>th,
.table-primary>td {
  background-color: var(--rgba-primary-1);
  color: var(--primary);
}

[data-theme-version="dark"] .table-primary,
[data-theme-version="dark"] .table-primary>th,
[data-theme-version="dark"] .table-primary>td {
  background-color: var(--rgba-primary-1);
}

.table-success,
.table-success>th,
.table-success>td {
  background-color: #c8f2d1;
  color: #37D159;
}

[data-theme-version="dark"] .table-success,
[data-theme-version="dark"] .table-success>th,
[data-theme-version="dark"] .table-success>td {
  background-color: rgba(55, 209, 89, 0.1);
}

.table-info,
.table-info>th,
.table-info>td {
  background-color: #e4d7ef;
  color: #b48dd3;
}

[data-theme-version="dark"] .table-info,
[data-theme-version="dark"] .table-info>th,
[data-theme-version="dark"] .table-info>td {
  background-color: rgba(180, 141, 211, 0.1);
}

.table-warning,
.table-warning>th,
.table-warning>td {
  background-color: #ffecce;
  color: #FFC368;
}

[data-theme-version="dark"] .table-warning,
[data-theme-version="dark"] .table-warning>th,
[data-theme-version="dark"] .table-warning>td {
  background-color: rgba(255, 195, 104, 0.1);
}

.table-danger,
.table-danger>th,
.table-danger>td {
  background-color: #ffd4cb;
  color: #FF6746;
}

[data-theme-version="dark"] .table-danger,
[data-theme-version="dark"] .table-danger>th,
[data-theme-version="dark"] .table-danger>td {
  background-color: rgba(255, 103, 70, 0.15);
}

.table-active,
.table-active>th,
.table-active>td {
  background-color: #f2f4fa;
}

[data-theme-version="dark"] .table-active,
[data-theme-version="dark"] .table-active>th,
[data-theme-version="dark"] .table-active>td {
  background-color: #171622;
}

.card-table th:first-child,
.card-table td:first-child {
  padding-left: 1.875rem;
}

@media only screen and (max-width: 35.9375rem) {

  .card-table th:first-child,
  .card-table td:first-child {
    padding-left: 0.9375rem;
  }
}

.card-table th:last-child,
.card-table td:last-child {
  padding-right: 1.875rem;
}

@media only screen and (max-width: 35.9375rem) {

  .card-table th:last-child,
  .card-table td:last-child {
    padding-right: 0.9375rem;
  }
}

.bootgrid-header {
  padding: 0 !important;
  margin: 0;
}

@media only screen and (max-width: 35.9375rem) {
  .bootgrid-header .actionBar {
    padding: 0;
  }

  .bootgrid-header .search {
    margin: 0 0 0.625rem 0;
  }
}

table#example {
  padding: 0rem 0 2rem 0;
}

table.dataTable {
  font-size: 0.875rem;
}

#example2_wrapper .dataTables_scrollBody {
  max-height: 33.25rem !important;
}

#employees,
#custommers {
  padding: .5rem 0 1rem 0;
}

.dataTables_wrapper .dataTables_paginate {
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}

table.dataTable thead th,
table.dataTable thead td {
  border-bottom: 0.125rem solid #F2F2F2;
  border-top: 0;
}

table.dataTable tfoot th,
table.dataTable tfoot td {
  border-top: 0;
}

table.dataTable tbody tr,
table.dataTable tbody td {
  background: transparent !important;
}

table.dataTable thead th {
  color: #000;
  white-space: nowrap;
  font-size: 1.125rem;
  text-transform: capitalize;
  font-weight: 600;
  padding: 1.25rem 0.9375rem;
}

[data-theme-version="dark"] table.dataTable thead th {
  color: #fff;
}

@media only screen and (max-width: 87.5rem) {
  table.dataTable thead th {
    font-size: 1rem;
  }
}

table.dataTable.default-table tbody td {
  padding-top: 1.8rem;
  padding-bottom: 1.8rem;
}

table.dataTable.default-table tbody tr {
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

table.dataTable.default-table tbody tr:hover {
  box-shadow: 0 0.125rem 3.0625rem rgba(0, 0, 0, 0.19);
}

table.dataTable tbody td {
  padding: 1.25rem 0.9375rem;
  font-size: 1rem;
  font-weight: 400;
  border-bottom: 0;
}

@media only screen and (max-width: 35.9375rem) {
  table.dataTable tbody td {
    padding: 0.5rem 0.3125rem;
  }
}

@media only screen and (max-width: 87.5rem) {
  table.dataTable tbody td {
    font-size: 0.875rem;
    padding: 0.5rem 0.9375rem;
  }
}

table.dataTable tr.selected {
  color: var(--primary);
}

table.dataTable tfoot th {
  color: #6e6e6e;
  font-weight: 600;
}

[data-theme-version="dark"] table.dataTable tfoot th {
  color: #fff;
}

.dataTables_wrapper .dataTables_info {
  font-size: 1rem;
  color: #000;
  font-weight: 600;
}

.dataTables_wrapper .dataTables_paginate>.paginate_button {
  font-size: 1.125rem;
  border-radius: 1.375rem;
  padding: 0.9rem 1.5625rem;
  color: var(--primary) !important;
  border: 0.0625rem solid var(--primary) !important;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.dataTables_wrapper .dataTables_paginate>.paginate_button.disabled {
  border-color: #666 !important;
}

.dataTables_wrapper .dataTables_paginate>.paginate_button:hover {
  background: var(--primary) !important;
}

.dataTables_wrapper .dataTables_paginate>.paginate_button:hover.disabled {
  border-color: #666 !important;
  background: transparent !important;
}

@media only screen and (max-width: 35.9375rem) {
  .dataTables_wrapper .dataTables_paginate>.paginate_button {
    font-size: 0;
    padding: 0.5rem 0.5rem;
  }

  .dataTables_wrapper .dataTables_paginate>.paginate_button i {
    font-size: 1.25rem;
    margin: 0 !important;
    padding: 0.1875rem 0.3125rem;
  }
}

.dataTables_wrapper .dataTables_paginate span {
  background-color: #F4F4F4;
  display: inline-flex;
  padding: 0.4rem 0.625rem;
  border-radius: 1.375rem;
  margin: 0 1rem;
}

.dataTables_wrapper .dataTables_paginate span .paginate_button {
  height: 3.125rem;
  width: 3.125rem;
  line-height: 3.125rem;
  text-align: center;
  border-radius: 1.375rem;
  border: 0 !important;
  font-size: 1.125rem;
  padding: 0;
  background: transparent;
  color: #202020 !important;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.dataTables_wrapper .dataTables_paginate span .paginate_button.current,
.dataTables_wrapper .dataTables_paginate span .paginate_button:hover,
.dataTables_wrapper .dataTables_paginate span .paginate_button:active,
.dataTables_wrapper .dataTables_paginate span .paginate_button:focus {
  background: var(--primary);
  color: #fff !important;
}

.dataTables_wrapper input[type="search"],
.dataTables_wrapper input[type="text"],
.dataTables_wrapper select {
  border: 0.0625rem solid #e2e2e2;
  padding: .3rem 0.5rem;
  color: #715d5d;
  border-radius: 0.3125rem;
}

[data-theme-version="dark"] .dataTables_wrapper input[type="search"],
[data-theme-version="dark"] .dataTables_wrapper input[type="text"],
[data-theme-version="dark"] .dataTables_wrapper select {
  background: #171622;
  border-color: #2e2e42;
  color: #fff;
}

.dataTables_wrapper .dataTables_length {
  margin-bottom: 0.9375rem;
}

.dataTables_wrapper .dataTables_length .bootstrap-select {
  width: 5rem;
  margin: 0 0.3125rem;
}

table.dataTable.no-footer {
  border-bottom: 0;
}

.rounded-lg {
  min-width: 1.875rem;
}

.dataTables_scroll {
  padding: 1rem 0;
}

.dataTables_scrollFoot {
  padding-top: 1rem;
}

.dataTablesCard {
  background-color: #fff;
  border-radius: 1.375rem;
}

.dataTablesCard.border-no td {
  border-top: 0 !important;
}

@media (max-width: 47.98rem) {

  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dataTables_filter {
    text-align: left;
  }
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
  color: #969ba0;
  border-radius: 1rem;
  padding: 0.125rem 0;
  margin-bottom: 1.25rem;
}

.paging_simple_numbers.dataTables_paginate {
  padding: 0.3125rem 0.3125rem;
}

table.dataTable.row-border tbody th,
table.dataTable.row-border tbody td,
table.dataTable.display tbody th,
table.dataTable.display tbody td {
  border-color: #F2F2F2;
}

[data-theme-version="dark"] table.dataTable.row-border tbody th,
[data-theme-version="dark"] table.dataTable.row-border tbody td,
[data-theme-version="dark"] table.dataTable.display tbody th,
[data-theme-version="dark"] table.dataTable.display tbody td {
  border-color: #2e2e42;
}

.dataTables_wrapper .dataTables_length .bootstrap-select .dropdown-toggle {
  font-size: 0.813rem !important;
  padding: 0.625rem 1rem;
}

.fooicon {
  font-size: 1.25rem;
  color: #6e6e6e;
}

.jsgrid-table .jsgrid-header-row>.jsgrid-header-cell {
  color: #6e6e6e;
}

.jsgrid-table>tbody>tr>td {
  padding: 1.2em;
}

.jsgrid-table .jsgrid-insert-row input,
.jsgrid-table .jsgrid-insert-row select,
.jsgrid-table .jsgrid-edit-row input,
.jsgrid-table .jsgrid-edit-row select {
  border: 0.0625rem solid #DDDFE1;
}

.jsgrid .jsgrid-button {
  border: 0 !important;
  margin-left: 0.625rem;
}

.error-page .error-text {
  font-size: 9.375rem;
  line-height: 1;
}

@media only screen and (max-width: 35.9375rem) {
  .error-page .error-text {
    font-size: 5rem;
  }
}

.error-page h4,
.error-page .h4 {
  font-size: 2.5rem;
  margin-bottom: 0.3125rem;
}

@media only screen and (max-width: 35.9375rem) {

  .error-page h4,
  .error-page .h4 {
    font-size: 1.25rem;
  }
}

.error-page p {
  font-size: 1rem;
}

@media only screen and (max-width: 35.9375rem) {
  .error-page p {
    font-size: 0.875rem;
  }
}

.flex-row-fluid {
  -webkit-box-flex: 1;
  flex: 1 auto;
  -ms-flex: 1 0 0rem;
  min-width: 0;
}

.authincation {
  background: var(--rgba-primary-1);
  display: flex;
  min-height: 100vh;
}

.authincation .login-aside {
  background: #fff;
  padding-top: 5rem;
  max-width: 35rem;
  width: 100%;
  z-index: 1;
  position: relative;
}

.authincation .login-aside:after {
  content: "";
  clip-path: polygon(0% 100%, 100% 0%, 0% 0%);
  width: 8.75rem;
  height: 100%;
  position: absolute;
  right: -8.75rem;
  z-index: -1;
  top: 0;
  background: #fff;
  box-shadow: 0.125rem 0rem 1.875rem rgba(0, 0, 0, 0.15);
}

.authincation .login-aside .aside-image {
  min-height: 28.125rem;
  margin: auto 0;
  min-width: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@media only screen and (max-width: 87.5rem) {
  .authincation .login-aside {
    max-width: 22.5rem;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .authincation .login-aside {
    max-width: 100%;
    padding-top: 0;
  }

  .authincation .login-aside:after {
    content: none;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .authincation .login-aside .aside-image {
    min-height: 18.75rem;
  }
}

.authincation-content {
  background: #fff;
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  border-radius: 5px;
}

[data-theme-version="dark"] .authincation-content {
  background: #212130;
  box-shadow: none;
}

.authincation-content.style-1 {
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(20px);
}

.authincation-content.style-1 .form-control {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
}

.authincation-content.style-1 .user-icon {
  height: 100px;
  background: #1362FC;
  width: 100px;
  text-align: center;
  border-radius: 100px;
  line-height: 100px;
  margin-left: auto;
  font-size: 60px;
  text-align: center;
  color: white;
  margin-right: auto;
  margin-top: -100px;
  margin-bottom: 20px;
}

.authincation-content.style-2 {
  background: transparent;
  box-shadow: none;
  max-width: 530px;
  width: 100%;
}

.authincation-content.style-2 .form-control {
  border: 0;
  border-radius: 5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
}

@media only screen and (max-width: 35.9375rem) {
  .authincation-content.style-2 .auth-form {
    padding: 30px 0px;
  }
}

.welcome-content {
  background-size: cover;
  background-position: center;
  height: 100%;
  padding: 75px 50px;
  position: relative;
  z-index: 1;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.welcome-content::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #1362FC;
  opacity: 0.75;
  z-index: -1;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.welcome-content .welcome-title {
  color: #fff;
  font-weight: 500;
  font-size: 20px;
}

.welcome-content p {
  color: #fff;
}

.welcome-content .brand-logo a {
  display: inline-block;
  margin-bottom: 20px;
  font-weight: 700;
  color: #fff;
  font-size: 20px;
}

.welcome-content .brand-logo a img {
  width: 100px;
}

.welcome-content .intro-social {
  position: absolute;
  bottom: 75px;
}

.welcome-content .intro-social ul {
  margin-bottom: 0;
}

.welcome-content .intro-social ul li {
  display: inline-block;
}

.welcome-content .intro-social ul li a {
  color: #fff;
  font-size: 14px;
  padding: 0px 7px;
}

.auth-form {
  padding: 50px 50px;
}

@media only screen and (max-width: 35.9375rem) {
  .auth-form {
    padding: 30px;
  }
}

.auth-form .btn {
  height: 50px;
  font-weight: 700;
}

.auth-form .page-back {
  display: inline-block;
  margin-bottom: 15px;
}

.pricing_table_content {
  background: #fff;
  text-align: center;
  border: 0.0625rem solid #e7e7e7;
  border-radius: 0.1875rem;
  padding: 1.5625rem;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.pricing_table_content .package {
  font-weight: 700;
  font-size: 1.125rem;
}

.pricing_table_content .price {
  font-weight: 700;
  font-size: 3.125rem;
  line-height: 6.25rem;
  color: #6e6e6e;
}

.pricing_table_content hr {
  margin: 0;
}

.pricing_table_content .price_list {
  padding: 1.875rem 0;
  text-align: left;
  max-width: 10.9375rem;
  margin: 0 auto;
}

.pricing_table_content .price_list li {
  color: #909093;
  font-size: 0.875rem;
  line-height: 1.5625rem;
  padding: 0.4375rem 0;
}

.pricing_table_content .price_list li i {
  margin-right: 0.9375rem;
}

.pricing_table_content .price-btn {
  padding: 0.9375rem 3.125rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0.0625rem solid #eaeaea;
  border-radius: 0.3125rem;
  font-weight: 700;
  font-size: 0.875rem;
  margin-bottom: 1.5625rem;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.pricing_table_content:focus,
.pricing_table_content:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.175);
  box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.175);
}

.pricing_table_content:focus .price-btn,
.pricing_table_content:hover .price-btn {
  color: #FF6746;
}

.page-timeline .timeline {
  list-style: none;
  padding: 2.5rem 0;
  position: relative;
}

.page-timeline .timeline-workplan.page-timeline .timeline {
  padding-bottom: 0;
  margin-bottom: 0;
}

.page-timeline .timeline-workplan.page-timeline .timeline.timeline>li>.timeline-badge {
  top: 4.5rem;
}

.page-timeline .timeline-workplan.page-timeline .timeline>li {
  margin-bottom: 2.6rem;
}

@media only screen and (min-width: 75rem) and (max-width: 103.125rem) {
  .page-timeline .timeline-workplan.page-timeline .timeline li>p {
    max-width: 8rem;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .page-timeline .timeline-workplan.page-timeline .timeline li>p {
    max-width: 7rem;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .page-timeline .timeline-workplan.page-timeline .timeline li>p {
    max-width: 100%;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .page-timeline .timeline-workplan.page-timeline .timeline li>p {
    max-width: 7rem;
  }
}

.page-timeline .timeline-workplan.page-timeline .timeline::before {
  left: 20%;
  top: 6rem;
}

[direction="rtl"] .page-timeline .timeline-workplan.page-timeline .timeline::before {
  right: 22%;
  left: auto;
}

@media only screen and (max-width: 35.9375rem) {
  .page-timeline .timeline-workplan.page-timeline .timeline::before {
    left: 22%;
  }
}

.page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
  left: 21.4%;
  height: 0.9rem;
  width: 0.9rem;
  background-color: var(--primary);
}

[direction="rtl"] .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
  right: 19.2%;
  left: auto;
}

@media only screen and (min-width: 75rem) and (max-width: 103.125rem) {
  .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
    left: 22.4%;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
    left: 22.4%;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
    left: 21.7%;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
    left: 19.5%;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge {
    left: 21.4%;
  }
}

.page-timeline .timeline-workplan.page-timeline .timeline .timeline-badge::after {
  position: absolute;
  width: 1.9rem;
  height: 1.9rem;
  background-color: var(--primary-dark);
  content: "";
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.page-timeline .timeline-workplan.page-timeline .timeline .timeline-panel {
  width: 70%;
}

.page-timeline .timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 0.125rem;
  background-color: #ECEFF2;
  left: 50%;
  margin-left: -0.0938rem;
}

.page-timeline .timeline>li {
  margin-bottom: 1.25rem;
  position: relative;
}

.page-timeline .timeline>li:after,
.page-timeline .timeline>li:before {
  content: " ";
  display: table;
}

.page-timeline .timeline>li:after {
  clear: both;
}

.page-timeline .timeline>li:after,
.page-timeline .timeline>li:before {
  content: " ";
  display: table;
}

.page-timeline .timeline>li:after {
  clear: both;
}

.page-timeline .timeline>li>.timeline-panel {
  width: 46%;
  float: left;
  border-radius: 0.125rem;
  position: relative;
}

.page-timeline .timeline>li>.timeline-badge {
  background-color: #f2f4fa;
  border: 0.0625rem solid #DDDFE1;
  border-radius: 50%;
  color: #6e6e6e;
  height: 2.5rem;
  left: 50%;
  line-height: 2.5rem;
  margin-left: -0.8125rem;
  position: absolute;
  text-align: center;
  top: 1.875rem;
  width: 2.5rem;
  z-index: 1;
  transform: translate(-25%, -3rem);
}

@media (min-width: 36rem) {
  .page-timeline .timeline>li>.timeline-badge {
    width: 3.125rem;
    height: 3.125rem;
    line-height: 3.125rem;
  }
}

.page-timeline .timeline>li.timeline-inverted>.timeline-panel {
  float: right;
}

.page-timeline .timeline>li.timeline-inverted>.timeline-panel:before {
  border-left-width: 0;
  border-right-width: 0.9375rem;
  left: -0.9375rem;
  right: auto;
}

.page-timeline .timeline>li.timeline-inverted>.timeline-panel:after {
  border-left-width: 0;
  border-right-width: 0.875rem;
  left: -0.875rem;
  right: auto;
}

.page-timeline .timeline-title {
  margin-top: 0;
  color: inherit;
}

.page-timeline .event_time {
  font-size: 0.875rem;
  font-weight: 600;
}

.page-timeline .event_vanue {
  font-size: 0.875rem;
  margin: 0.3125rem 0;
  font-weight: 600;
}

.page-timeline .timeline_img {
  height: 6.25rem;
  width: 6.25rem;
}

.page-timeline .timeline-body>p,
.page-timeline .timeline-body>ul {
  margin-bottom: 0;
}

@media (max-width: 47.9375rem) {
  .page-timeline ul.timeline:before {
    left: 2.5rem;
  }

  .page-timeline ul.timeline>li>.timeline-panel {
    width: calc(100% - 5.625rem);
    width: -webkit-calc(100% - 5.625rem);
  }

  .page-timeline ul.timeline>li>.timeline-badge {
    left: 1.75rem;
    margin-left: 0;
    top: 1rem;
  }

  .page-timeline ul.timeline>li>.timeline-panel {
    float: right;
  }

  .page-timeline ul.timeline>li>.timeline-panel:before {
    border-left-width: 0;
    border-right-width: 0.9375rem;
    left: -0.9375rem;
    right: auto;
  }

  .page-timeline ul.timeline>li>.timeline-panel:after {
    border-left-width: 0;
    border-right-width: 0.875rem;
    left: -0.875rem;
    right: auto;
  }

  .page-timeline .timeline_img {
    height: 30%;
    width: 30%;
  }
}

.page-timeline .timeline-timestamp {
  text-align: center;
}

.page-timeline .timeline-timestamp .badge {
  padding: .8rem 2rem;
  border-radius: 3.125rem;
  font-size: 0.8125rem;
}

.review-table {
  padding: 1.5625rem;
  box-shadow: none;
  border-radius: 0;
  border-bottom: 0.0625rem solid #eee;
  height: auto;
  margin-bottom: 0;
}

.review-table .disease {
  border-left: 0.0625rem solid #eee;
  padding-left: 1.25rem;
}

@media only screen and (max-width: 61.9375rem) {
  .review-table .disease {
    border-left: 0;
    padding-left: 0;
    margin-right: 0.625rem !important;
    margin-left: 0;
  }
}

.review-table .star-review i {
  font-size: 1.25rem;
}

@media only screen and (max-width: 61.9375rem) {
  .review-table .star-review i {
    font-size: 216x;
  }
}

.review-table .media-body p {
  color: #3e4954;
  font-size: 1.125rem;
  line-height: 1.5;
}

@media only screen and (max-width: 61.9375rem) {
  .review-table .media-body p {
    font-size: 0.875rem;
  }
}

.review-table .media-footer {
  min-width: 31.25rem;
}

@media only screen and (max-width: 87.5rem) {
  .review-table .media-footer {
    min-width: 18.75rem;
    margin-left: 0.625rem;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .review-table .media-footer {
    margin-left: 0;
    margin-top: 1.5625rem;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .review-table img {
    float: left;
    width: 5rem;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .review-table .media {
    display: block !important;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .review-table .custom-control {
    float: right;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .review-table {
    padding: 0.9375rem;
  }
}

.review-tab.nav-pills {
  margin-bottom: 0;
}

.review-tab.nav-pills li {
  display: inline-block;
}

.review-tab.nav-pills li a.nav-link {
  color: #6b6b6b;
  background: #e9e9e9;
  box-shadow: none;
  border-radius: 0;
  font-weight: 600;
  font-size: 1rem;
  padding: 0.9375rem 2.5rem;
  margin-right: 0.0625rem;
}

.review-tab.nav-pills li a.nav-link.active {
  color: var(--primary);
  background: #fff;
}

@media only screen and (max-width: 61.9375rem) {
  .review-tab.nav-pills li a.nav-link {
    font-size: 0.875rem;
    padding: 0.625rem 0.9375rem;
  }
}

.review-tab.nav-pills li:first-child a.nav-link {
  border-radius: 1.375rem 0 0 0;
}

.review-tab.nav-pills li:last-child a.nav-link {
  border-radius: 0 1.375rem 0 0;
}

.heart-blast {
  background-position: -1680px 0 !important;
  transition: background 1s steps(28);
}

.heart {
  width: 60px;
  height: 60px;
  display: inline-block;
  cursor: pointer;
  margin: -25px -15px;
}

.progress-bar-legend .marker {
  height: 12px;
  width: 12px;
  margin-right: 8px;
  border-radius: 12px;
  display: inline-block;
}

.progress-bar-legend .status {
  font-size: 12px;
  margin-bottom: 8px;
  line-height: 1;
}

.progress-bar-legend .result {
  font-size: 16px;
  color: #000;
  font-weight: 700;
}

.customer-review-bx {
  height: 600px;
}

.dz-review-bx {
  display: flex;
  padding: 1.5rem 1.875rem;
  border-bottom: 1px solid #F2F2F2;
}

.dz-review-bx .dz-media {
  height: 2.875rem;
  width: 2.875rem;
  border-radius: 2.875rem;
  object-fit: cover;
}

.dz-review-bx .dz-name {
  display: flex;
  justify-content: space-between;
}

.dz-review-bx .title {
  font-size: 1rem;
  font-weight: 600;
}

.dz-review-bx .date {
  font-size: 0.75rem;
}

.dz-review-bx .star-review {
  display: flex;
}

.dz-review-bx .star-review li {
  margin-right: 5px;
}

.dz-review-bx .star-review i {
  color: #DCDFF1;
  font-size: 16px;
}

.dz-review-bx .star-review i.orange {
  color: #FFBC39;
}

.dz-review-bx .dz-footer {
  display: flex;
}

@media only screen and (max-width: 35.9375rem) {
  .dz-review-bx {
    display: inline-block;
  }

  .dz-review-bx .dz-footer {
    display: initial;
  }

  .dz-review-bx .dz-media {
    float: left;
  }
}

.review-icon {
  height: 3rem;
  width: 3rem;
  min-width: 3rem;
  line-height: 3rem;
  font-size: 1.5rem;
  text-align: center;
  display: inline-block;
}

.card-footer.style-1 .dz-load-more {
  height: 3.75rem;
  width: 3.75rem;
  border-radius: 50%;
  font-size: 1.75rem;
  color: var(--primary);
  text-align: center;
  line-height: 3.75rem;
  box-shadow: 0px 13px 22px rgba(0, 0, 0, 0.12);
  background: #fff;
}

.dz-image-bx {
  border: 1px solid #F2F2F2;
  padding: 1.25rem 1.25rem;
  display: flex;
  margin-bottom: 1.875rem;
}

.dz-image-bx .dz-media {
  display: inline-block;
}

.dz-image-bx .dz-media img {
  height: 3.5rem;
  width: 3.5rem;
  min-width: 3.5rem;
  object-fit: cover;
}

.dz-image-bx .dz-media.active {
  position: relative;
}

.dz-image-bx .dz-media.active:after {
  content: "";
  position: absolute;
  height: 1.4rem;
  width: 1.4rem;
  border-radius: 1.4rem;
  right: -0.3125rem;
  top: -0.3125rem;
  border: 3px solid #fff;
  background: #FFC368;
}

@media only screen and (max-width: 87.5rem) {
  .dz-image-bx {
    padding: 1rem 1rem;
  }
}

.booking-calender .bootstrap-datetimepicker-widget table thead tr:first-child th {
  font-size: 1.25rem;
  color: #000;
  font-weight: 600;
  padding: 1rem 0.625rem;
}

.booking-calender .bootstrap-datetimepicker-widget table td,
.booking-calender .patient-calender .bootstrap-datetimepicker-widget table th {
  padding: 1.3rem 0.625rem;
  font-size: 1.25rem;
  color: #161616;
  border-radius: 1.375rem;
}

.booking-calender .bootstrap-datetimepicker-widget table th {
  font-size: 0.875rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  padding-top: 1.5625rem;
  padding-bottom: 1.25rem;
}

.booking-calender .bootstrap-datetimepicker-widget .datepicker tbody td.active {
  background: var(--primary) !important;
  color: #fff;
  box-shadow: none;
}

.booking-calender .bootstrap-datetimepicker-widget .datepicker tbody td.today {
  box-shadow: none;
  background: var(--primary) !important;
}

.booking-calender .bootstrap-datetimepicker-widget .datepicker tbody td.today:before {
  height: 1.4rem;
  width: 1.4rem;
  border-radius: 1.4rem;
  right: -0.3125rem;
  top: -0.3125rem;
  border: 3px solid #fff;
  background: #FFC368;
}

.radialChart .apexcharts-series path {
  stroke: var(--primary);
}

.card-tabs .nav-tabs {
  border-bottom: 0;
  font-weight: 500;
  background: #fff;
  padding: 0 1.875rem;
  border-radius: 1.375rem;
  box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.03);
}

.card-tabs .nav-tabs .nav-link {
  font-size: 16px;
  color: #666666;
  padding: 1.25rem 1.25rem;
  border: 0;
  position: relative;
}

.card-tabs .nav-tabs .nav-link:after {
  opacity: 0;
  content: "";
  position: absolute;
  background: var(--primary);
  width: 100%;
  height: 0.25rem;
  border-radius: 1rem;
  bottom: -.25rem;
  left: 0;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.card-tabs .nav-tabs .nav-link.active {
  background: #fff;
  color: #000;
}

.card-tabs .nav-tabs .nav-link.active:after {
  opacity: 1;
  bottom: -3px;
}

@media only screen and (max-width: 35.9375rem) {
  .card-tabs .nav-tabs {
    padding: 0 1rem;
  }

  .card-tabs .nav-tabs .nav-link {
    font-size: 12px;
    padding: 12px 10px;
  }
}

.media-bx {
  display: flex;
  align-items: center;
}

.media-bx img {
  height: 3.625rem;
  width: 3.625rem;
  object-fit: cover;
}

.bg-none {
  background-image: none !important;
}

@media only screen and (max-width: 74.9375rem) {

  .table-search .search-area,
  .table-search .input-group-text {
    height: 40px;
  }
}

@media only screen and (max-width: 74.9375rem) {

  .table-search .search-area .form-control,
  .table-search .input-group-text .form-control {
    height: 40px;
  }
}

@media only screen and (max-width: 35.9375rem) {
  table.dataTable.default-table tbody td {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .card-bx svg {
    width: 40px;
  }
}

.image-placeholder {
  position: relative;
  max-width: 130px;
  margin: 10px auto;
}

.image-placeholder .avatar-edit {
  position: absolute;
  right: -10px;
  z-index: 1;
  top: 0px;
}

.image-placeholder .avatar-edit input {
  display: none;
}

.image-placeholder .avatar-edit input+label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #FFFFFF;
  border: 1px solid transparent;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all .2s ease-in-out;
}

.image-placeholder .avatar-edit input+label:hover {
  background: #f1f1f1;
  border-color: #d6d6d6;
}

.image-placeholder .avatar-edit input+label:after {
  content: "\f303";
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  color: #757575;
  position: absolute;
  top: 6px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}

.image-placeholder .avatar-preview {
  width: 130px;
  height: 95px;
  position: relative;
  border-radius: 30px;
  border: 3px solid #F8F8F8;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.image-placeholder .avatar-preview>div {
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
}

.image-placeholder .avatar-preview>div img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.project-boxed {
  overflow: hidden;
}

.owl-carousel.gallery-carousel img {
  width: 100%;
  border-radius: 0.875rem;
  object-fit: cover;
  height: 13.4375rem;
}

.owl-carousel.gallery-carousel .owl-nav .owl-prev,
.owl-carousel.gallery-carousel .owl-nav .owl-next {
  height: 3.75rem;
  width: 3.75rem;
  border-radius: 3.75rem;
  line-height: 3.75rem;
  background: #fff;
  color: var(--primary);
  font-size: 1.75rem;
  cursor: pointer;
  text-align: center;
  box-shadow: 0 0.8125rem 1.375rem rgba(0, 0, 0, 0.12);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  z-index: 1;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.owl-carousel.gallery-carousel .owl-nav .owl-prev {
  left: -5.9375rem;
}

.owl-carousel.gallery-carousel .owl-nav .owl-next {
  right: -5.9375rem;
}

.owl-carousel.gallery-carousel:hover .owl-nav .owl-prev {
  left: -2.8125rem;
  opacity: 1;
}

.owl-carousel.gallery-carousel:hover .owl-nav .owl-next {
  right: -2.8125rem;
  opacity: 1;
}

.owl-carousel.gallery-carousel:after {
  content: "";
  height: 100%;
  width: 6.25rem;
  z-index: 0;
  position: absolute;
  right: 0;
  top: 0;
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 14%, white 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 14%, white 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 14%, white 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1);
}

.key-icon {
  height: 5.375rem;
  width: 5.375rem;
  line-height: 5.375rem;
  border-radius: 5.375rem;
  text-align: center;
  background: var(--primary);
}

@media only screen and (max-width: 35.9375rem) {
  .key-icon {
    height: 3.375rem;
    width: 3.375rem;
    line-height: 3.375rem;
  }

  .key-icon svg {
    width: 1.5625rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .card-title {
    font-size: 0.75rem;
  }
}

.profile-card .profile-img {
  height: 6.625rem;
  width: 6.625rem;
  object-fit: cover;
}

.profile-card .user-info-list i {
  height: 3.125rem;
  width: 3.125rem;
  min-width: 3.125rem;
  border-radius: 3.125rem;
  line-height: 3.125rem;
  text-align: center;
  border: 0.0625rem solid var(--primary);
  color: var(--primary);
  margin-right: 0.75rem;
}

.profile-card .user-info-list li {
  font-size: 1rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;
}

.profile-card .user-info-list li span {
  color: #262626;
}

.rooms {
  margin-bottom: 2rem;
  margin-top: 1.25rem;
}

.rooms h4,
.rooms .h4 {
  font-size: 1.75rem;
  margin-bottom: 0;
}

.guest-bx {
  display: flex;
  align-items: center;
}

.guest-bx img {
  width: 12.875rem;
  height: 6rem;
  object-fit: cover;
  border-radius: 0.625rem;
}

.guest-bx .carousel {
  width: 12.875rem;
  height: 6rem;
  border-radius: 0.625rem;
}

.guest-bx .carousel .carousel-control-prev,
.guest-bx .carousel .carousel-control-next {
  height: 2.375rem;
  width: 2.375rem;
  background: #fff;
  margin: 0 0.25rem;
  top: 50%;
  border-radius: 50%;
  transform: translateY(-50%);
}

.concierge-bx {
  display: flex;
}

.concierge-bx img {
  height: 4.375rem;
  width: 4.375rem;
}

.job-desk {
  width: 20%;
}

.room-list-tbl tr td span {
  color: #262626;
}

.star-review {
  display: flex;
}

.star-review li {
  margin-right: 0.3125rem;
}

.star-review li i.orange {
  color: #FFBC39;
}

.review-bx {
  display: flex;
}

.review-bx img {
  width: 4rem;
  height: 4rem;
  border-radius: 1.375rem;
}

.customer-review-list {
  position: relative;
  border-bottom: 0.0625rem solid #F2F2F2;
  padding: 1.25rem 0;
}

@media only screen and (max-width: 74.9375rem) {
  .customer-review-list .action-btn {
    position: absolute;
    top: 0.3125rem;
    right: 0;
    width: auto;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .customer-review-list .action-btn {
    position: unset;
    padding-top: 0.625rem;
  }
}

/*
===================================
    Product detail
===================================*/
.produtct-detail-tag {
  display: inline-block;
}

.produtct-detail-tag a {
  font-style: 0.8125rem;
  color: #6e6e6e;
}

.product-detail-content .item-tag {
  background: #828690;
  border-radius: 0.375rem;
  display: inline-block;
  font-size: 0.75rem;
  margin-right: 0.25rem;
  padding: 0.125rem 0.75rem;
  color: #fff;
}

.filtaring-area h4,
.filtaring-area .h4 {
  color: #1d1d1d;
  font-size: 1rem;
  font-weight: 400;
  text-transform: lowercase;
}

.plus-minus-input .input-icon {
  font-size: 0.8125rem;
  color: #6e6e6e;
}

.plus-minus-input {
  display: flex;
  width: 7.5rem;
}

.plus-minus-input .custom-btn {
  border-radius: 0;
  height: 2.5rem;
  padding: 0.75rem 0.5rem;
  background: #fff;
  border: 0.0625rem solid #F2F2F2;
}

.plus-minus-input .form-control:hover,
.plus-minus-input .form-control:focus,
.plus-minus-input .form-control:active {
  border: 0.0625rem solid #F2F2F2;
}

.btn-reveal-trigger .avatar-xl {
  min-width: 1.875rem;
}

.share-view {
  display: inline-block;
}

.share-view ul li {
  display: inline-block;
}

.share-view .share-icon {
  width: 2.5rem;
  height: 2.5rem;
  display: inline-block;
  border: 0.0625rem solid #F2F2F2;
  text-align: center;
  line-height: 2.5rem;
  font-style: 1rem;
  color: #F2F2F2;
  margin-right: 0.5rem;
}

/*--------tab----------*/
.veritical-line {
  padding: 1.25rem 1.875rem;
  border-top: 0.0625rem solid #F2F2F2;
  border-right: 0.0625rem solid #F2F2F2;
  border-bottom: 0.0625rem solid #F2F2F2;
  position: relative;
}

.veritical-line:before {
  background: #F2F2F2;
  bottom: 0;
  content: "";
  height: 100%;
  left: -0.0625rem;
  max-height: 40%;
  position: absolute;
  width: 0.0625rem;
}

.tab-content-text p {
  color: #6e6e6e;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5rem;
  margin-bottom: 1.5625rem;
}

.tab-item-list li a {
  background: #fff;
  border-top: 0.0625rem solid #F2F2F2;
  border-left: 0.0625rem solid #F2F2F2;
  border-right: 0.0625rem solid #F2F2F2;
  color: #6e6e6e;
  display: block;
  font-size: 1rem;
  padding: 1rem;
  text-transform: uppercase;
}

.tab-item-list li a:hover,
.tab-item-list li a:focus {
  background: #fff;
  color: #6e6e6e;
  border-right: 0rem;
}

.tab-item-list li:last-child {
  border-bottom: 0.0625rem solid #F2F2F2;
}

.tab-list li {
  margin-bottom: 0.4375rem;
  font-size: 0.8125rem;
}

.tab-list li i {
  font-size: 0.8125rem;
  margin-right: 0.875rem;
}

.slide-item-list {
  text-align: center;
  margin: 0 -0.3125rem;
}

.slide-item-list li {
  display: inline-block;
  flex: 0 0 25%;
  width: 25%;
  padding: 0 0.3125rem;
}

.slide-item-list li a {
  display: inline-block;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  background: transparent;
  padding-bottom: 0rem;
}

.slide-item-list li a:hover,
.slide-item-list li a:focus {
  background: transparent;
}

.slide-item-list li a img {
  width: 100%;
}

.product-detail-text {
  padding-top: 1.75rem;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  padding-bottom: 4.375rem;
}

.star-rating .product-review {
  font-style: 0.8125rem;
  color: #6e6e6e;
  font-weight: 400;
  text-decoration: underline !important;
}

.product-detail .tab-content img {
  display: inline-block;
  width: 100%;
}

.popular-tag ul {
  margin: 0rem;
  padding: 0rem;
}

.popular-tag ul li {
  display: inline-block;
  padding: 0.5rem 0.9375rem;
  background: #f8f8f8;
  font-size: 0.8125rem;
  color: #fff;
  margin-right: 0.625rem;
  margin-bottom: 0.625rem;
}

.size-filter ul li {
  display: inline-block;
}

.intro {
  border: 0.0625rem solid red;
  color: #1d1d1d;
}

#listResults .slider {
  margin: 1.5625rem 0;
}

#listResults .slider-box {
  width: 90%;
  margin: 1.5625rem auto;
}

#listResults input {
  width: 10%;
}

#listResults label {
  border: none;
  display: inline-block;
  margin-right: -0.25rem;
  vertical-align: top;
  width: 30%;
}

.plus-minus-input .input-icon {
  font-size: 0.8125rem;
  color: #aaaaaa;
}

.plus-minus-input .custom-btn {
  border-radius: 0;
  height: 2.5rem;
  padding: 0.5rem 0.75rem;
  background: #ffffff;
  border: 0.0625rem solid #c8c8c8;
}

.plus-minus-input .custom-btn:hover,
.plus-minus-input .custom-btn:focus,
.plus-minus-input .custom-btn.active {
  box-shadow: none;
  outline: none;
}

.plus-minus-input .form-control {
  height: 2.5rem;
  border-top: 0.0625rem solid #c8c8c8;
  border-bottom: 0.0625rem solid #c8c8c8;
  border-left: 0rem solid #c8c8c8;
  border-right: 0.0625rem solid #c8c8c8;
}

.plus-minus-input .form-control:hover,
.plus-minus-input .form-control:focus,
.plus-minus-input .form-control:active {
  border-top: 0.0625rem solid #c8c8c8;
  border-bottom: 0.0625rem solid #c8c8c8;
  border-left: 0rem solid #c8c8c8;
  border-right: 0rem solid #c8c8c8;
}

.new-arrival-product .new-arrivals-img-contnent {
  overflow: hidden;
}

.new-arrival-product .new-arrivals-img-contnent img {
  width: 100%;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.new-arrival-product:hover .new-arrivals-img-contnent img {
  transform: scale(1.5) translateY(12%);
  -moz-transform: scale(1.5) translateY(12%);
  -webkit-transform: scale(1.5) translateY(12%);
  -ms-transform: scale(1.5) translateY(12%);
  -o-transform: scale(1.5) translateY(12%);
}

/*
===================================
    list view
===================================*/
.new-arrival-content .item {
  font-size: 0.75rem;
  color: #6e6e6e;
}

.new-arrival-content h4,
.new-arrival-content .h4 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.625rem;
}

.new-arrival-content h4 a,
.new-arrival-content .h4 a {
  color: #000;
}

.new-arrival-content .price {
  font-weight: 600;
  color: var(--primary);
  font-size: 1.5rem;
  margin-bottom: 0;
  float: right;
}

@media only screen and (max-width: 35.9375rem) {
  .new-arrival-content .price {
    float: none;
    margin-top: 0.625rem;
  }
}

.new-arrival-content p {
  font-size: 0.875rem;
  color: #828690;
  margin-bottom: 0.375rem;
  line-height: 1.5rem;
}

.new-arrival-content .text-content {
  margin-top: 1.125rem;
}

.new-arrival-content.text-center .price {
  float: unset !important;
}

.success-icon {
  color: #37D159;
  font-size: 1rem;
}

.comment-review {
  margin-bottom: 0.9375rem;
  display: table;
  width: 100%;
}

.comment-review .client-review {
  color: #828690;
  padding-right: 1.25rem;
  text-decoration: underline !important;
  font-size: 0.875rem;
}

.comment-review .span {
  color: #828690;
  font-size: 0.875rem;
}

@media only screen and (max-width: 35.9375rem) {
  .comment-review {
    margin-bottom: 0;
  }
}

.star-rating li {
  display: inline-block;
}

.star-rating li i {
  color: gold;
}

:root {
  --primary: #1362FC;
  --secondary: #627EEA;
  --primary-hover: #034bd9;
  --primary-light: #aac7fe;
  --primary-dark: #012875;
  --rgba-primary-1: rgba(19, 98, 252, 0.1);
  --rgba-primary-2: rgba(19, 98, 252, 0.2);
  --rgba-primary-3: rgba(19, 98, 252, 0.3);
  --rgba-primary-4: rgba(19, 98, 252, 0.4);
  --rgba-primary-5: rgba(19, 98, 252, 0.5);
  --rgba-primary-6: rgba(19, 98, 252, 0.6);
  --rgba-primary-7: rgba(19, 98, 252, 0.7);
  --rgba-primary-8: rgba(19, 98, 252, 0.8);
  --rgba-primary-9: rgba(19, 98, 252, 0.9);
  --font-family-base: Roboto, sans-serif;
  --font-family-title: Roboto, sans-serif;
  --title: #000;
}

[data-theme-version="dark"] {
  background: #171622;
  color: #828690;
  --nav-headbg: #171622;
  --sidebar-bg: #171622;
  --headerbg: #171622;
}

[data-theme-version="dark"] h1,
[data-theme-version="dark"] .h1,
[data-theme-version="dark"] h2,
[data-theme-version="dark"] .h2,
[data-theme-version="dark"] h3,
[data-theme-version="dark"] .h3,
[data-theme-version="dark"] h4,
[data-theme-version="dark"] .h4,
[data-theme-version="dark"] h5,
[data-theme-version="dark"] .h5,
[data-theme-version="dark"] h6,
[data-theme-version="dark"] .h6 {
  color: #fff !important;
}

[data-theme-version="dark"] a.link {
  color: #ddd;
}

[data-theme-version="dark"] a.link:focus,
[data-theme-version="dark"] a.link:hover {
  color: #b48dd3;
}

[data-theme-version="dark"] a:hover {
  color: #fff;
}

[data-theme-version="dark"] .border-right {
  border-right: 0.0625rem solid #2e2e42 !important;
}

[data-theme-version="dark"] .border-left {
  border-left: 0.0625rem solid #2e2e42 !important;
}

[data-theme-version="dark"] .border-top {
  border-top: 0.0625rem solid #2e2e42 !important;
}

[data-theme-version="dark"] .border-bottom {
  border-bottom: 0.0625rem solid #2e2e42 !important;
}

[data-theme-version="dark"] .border {
  border: 0.0625rem solid #2e2e42 !important;
}

[data-theme-version="dark"] .card {
  background-color: #212130;
  box-shadow: none;
}

[data-theme-version="dark"] .dropdown-menu {
  background-color: #212130;
  box-shadow: 0rem 0rem 0rem 0.0625rem rgba(255, 255, 255, 0.1);
}

[data-theme-version="dark"] .dropdown-menu .dropdown-item {
  color: #777777;
}

[data-theme-version="dark"] .dropdown-menu .dropdown-item.selected,
[data-theme-version="dark"] .dropdown-menu .dropdown-item.selected.active,
[data-theme-version="dark"] .dropdown-menu .dropdown-item.active,
[data-theme-version="dark"] .dropdown-menu .dropdown-item:hover,
[data-theme-version="dark"] .dropdown-menu .dropdown-item:focus {
  background-color: #212130;
  color: #fff;
}

[data-theme-version="dark"] a {
  color: #fff;
}

[data-theme-version="dark"] .text-primary {
  color: #fff !important;
}

[data-theme-version="dark"] .btn-link g [fill] {
  fill: #fff;
}

[data-theme-version="dark"] .btn-light:active,
[data-theme-version="dark"] .btn-light:focus,
[data-theme-version="dark"] .btn-light:hover {
  color: #000;
}

[data-theme-version="dark"] .form-control {
  background-color: #212130;
  border-color: #2e2e42;
  color: #fff;
}

[data-theme-version="dark"] .modal-content {
  background: #212130;
}

[data-theme-version="dark"] .modal-footer,
[data-theme-version="dark"] .modal-header {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .close {
  font-weight: 400;
  color: #fff;
  text-shadow: none;
}

[data-theme-version="dark"] .close:hover {
  color: #fff;
}

[data-theme-version="dark"] .star-rating .product-review,
[data-theme-version="dark"] .new-arrival-content .item {
  color: #fff;
}

[data-theme-version="dark"] .custom-dropdown .dropdown-menu {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .widget-stat .media>span {
  background: #2e2e42;
  border-color: #2e2e42;
  color: #fff;
}

[data-theme-version="dark"] .plus-minus-input .custom-btn {
  background: #171622;
  border-color: #2e2e42;
}

[data-theme-version="dark"] .dropdown-divider,
[data-theme-version="dark"] .size-filter ul li {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .custom-select {
  border-color: #2e2e42;
  color: #828690;
  background: #171622;
}

[data-theme-version="dark"] .nav-tabs {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .mail-list .list-group-item.active i {
  color: #fff;
}

[data-theme-version="dark"] hr {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .grid-col {
  background: #171622;
}

[data-theme-version="dark"] .noUi-target {
  border-color: #2e2e42;
  border-radius: 0.5rem;
  box-shadow: none;
}

[data-theme-version="dark"] .noUi-target .noUi-connects {
  background: #2e2e42;
}

[data-theme-version="dark"] .noUi-marker-large,
[data-theme-version="dark"] .noUi-marker {
  background: #2e2e42;
}

[data-theme-version="dark"] .input-group-text {
  background: #212130;
  color: #969ba0;
  border-color: #2e2e42;
  border: 0;
}

[data-theme-version="dark"] .note-editor.note-frame {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .note-editor.note-frame .btn {
  color: #fff;
}

[data-theme-version="dark"] .note-editor.note-frame .note-editing-area .note-editable {
  color: #fff;
}

[data-theme-version="dark"] .widget-media .timeline .timeline-panel {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .notification_dropdown .dropdown-menu-right .all-notification {
  border-color: #2e2e42;
}

[data-theme-version="dark"] #user-activity .nav-tabs .nav-link {
  border-color: #2e2e42;
}

[data-theme-version="dark"] #user-activity .nav-tabs .nav-link.active {
  background: #171622;
  color: #fff;
}

[data-theme-version="dark"] .list-group-item-action {
  color: #969ba0;
}

[data-theme-version="dark"] .list-group-item-action:focus,
[data-theme-version="dark"] .list-group-item-action:hover,
[data-theme-version="dark"] .list-group-item-action:focus {
  background-color: #171622;
  border-color: #171622;
}

[data-theme-version="dark"] .list-group-item.active {
  color: #fff;
  border-color: var(--primary);
}

[data-theme-version="dark"] .list-group-item.active:focus,
[data-theme-version="dark"] .list-group-item.active:hover,
[data-theme-version="dark"] .list-group-item.active:focus {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

[data-theme-version="dark"] .swal2-popup {
  background: #212130;
}

[data-theme-version="dark"] .form-head .btn-outline-primary {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .form-head .btn-outline-primary:hover {
  border-color: var(--primary);
}

[data-theme-version="dark"] .review-tab.nav-pills li a.nav-link.active {
  background: transparent;
}

[data-theme-version="dark"] .new-arrival-content h4 a,
[data-theme-version="dark"] .new-arrival-content .h4 a {
  color: #fff;
}

[data-theme-version="dark"] .text-black {
  color: #fff !important;
}

[data-theme-version="dark"] .abilities-chart .ct-chart .ct-label {
  fill: #fff;
}

[data-theme-version="dark"] .morris_chart_height text tspan {
  fill: #fff;
}

[data-theme-version="dark"] .btn-link {
  color: #fff;
}

[data-theme-version="dark"] .order-bg {
  background: #171622;
}

[data-theme-version="dark"] .detault-daterange .input-group-text {
  background: #171622;
  color: #fff;
}

[data-theme-version="dark"] .dataTablesCard {
  background-color: #212130;
}

[data-theme-version="dark"] .compose-content .dropzone {
  background: #171622;
}

[data-theme-version="dark"] .compose-content .dropzone .dz-message .dz-button {
  color: #fff;
}

[data-theme-version="dark"] .daterangepicker {
  background: #171622;
  border-color: var(--primary);
}

[data-theme-version="dark"] .daterangepicker .calendar-table {
  border-color: var(--primary);
  background: #171622;
}

[data-theme-version="dark"] .daterangepicker .calendar-table .table-condensed td:hover {
  background-color: var(--primary);
  color: #fff;
}

[data-theme-version="dark"] .daterangepicker:after {
  border-bottom: 0.375rem solid #171622;
}

[data-theme-version="dark"] .daterangepicker select.hourselect,
[data-theme-version="dark"] .daterangepicker select.minuteselect,
[data-theme-version="dark"] .daterangepicker select.secondselect,
[data-theme-version="dark"] .daterangepicker select.ampmselect {
  background: #171622;
  border: 0.0625rem solid #2e2e42;
  color: #fff;
}

[data-theme-version="dark"] .daterangepicker td.off,
[data-theme-version="dark"] .daterangepicker td.off.in-range,
[data-theme-version="dark"] .daterangepicker td.off.start-date,
[data-theme-version="dark"] .daterangepicker td.off.end-date {
  background-color: #212130;
}

[data-theme-version="dark"] .daterangepicker td.off:hover,
[data-theme-version="dark"] .daterangepicker td.off.in-range:hover,
[data-theme-version="dark"] .daterangepicker td.off.start-date:hover,
[data-theme-version="dark"] .daterangepicker td.off.end-date:hover {
  background-color: var(--primary);
  color: #fff;
}

[data-theme-version="dark"] .app-fullcalendar .fc-button {
  background-color: #171622;
  border-color: var(--primary);
  color: #fff;
  text-shadow: none;
}

[data-theme-version="dark"] .app-fullcalendar .fc-button:hover,
[data-theme-version="dark"] .app-fullcalendar .fc-button.fc-stat-hover {
  background-color: var(--primary);
}

[data-theme-version="dark"] .swal2-popup .swal2-styled:focus {
  outline: 0;
  box-shadow: 0 0 0 0.125rem #2e2e42, 0 0 0 0.25rem var(--rgba-primary-1);
}

[data-theme-version="dark"] .dd-handle {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .menu-toggle .deznav .metismenu li>ul {
  background: #212130 !important;
}

[data-theme-version="dark"] .header-right .notification_dropdown .nav-link {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .nav-tabs .nav-link:hover,
[data-theme-version="dark"] .nav-tabs .nav-link.active {
  border-color: var(--primary);
}

[data-theme-version="dark"] .clockpicker-popover .popover-content {
  background-color: #212130;
}

[data-theme-version="dark"] .clockpicker-plate {
  background-color: #171622;
}

[data-theme-version="dark"] .clockpicker-popover .popover-title {
  background-color: #171622;
  color: #fff;
}

[data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link span {
  background-color: #171622;
}

[data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link:after {
  background: #171622;
}

[data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link.active:after,
[data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link.done:after {
  background: var(--primary);
}

[data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link.active span,
[data-theme-version="dark"] .form-wizard .nav-wizard li .nav-link.done span {
  background: var(--primary);
}

[data-theme-version="dark"] .check-switch .custom-control-label:after,
[data-theme-version="dark"] .check-switch .custom-control-label:before {
  border-color: var(--primary);
}

[data-theme-version="dark"] .fc-unthemed .fc-today {
  background: #171622;
}

[data-theme-version="dark"] .fc-unthemed .fc-divider,
[data-theme-version="dark"] .fc-unthemed .fc-list-heading td,
[data-theme-version="dark"] .fc-unthemed .fc-popover .fc-header {
  background: #2e2e42;
}

[data-theme-version="dark"] .picker__box {
  background: #171622;
}

[data-theme-version="dark"] .picker__box .picker__button--clear,
[data-theme-version="dark"] .picker__box .picker__button--close,
[data-theme-version="dark"] .picker__box .picker__button--today {
  background: #212130;
  color: #fff;
}

[data-theme-version="dark"] .picker__box .picker__button--clear:hover:before,
[data-theme-version="dark"] .picker__box .picker__button--close:hover:before,
[data-theme-version="dark"] .picker__box .picker__button--today:hover:before {
  color: #fff;
}

[data-theme-version="dark"] .picker {
  color: #999;
}

[data-theme-version="dark"] .dtp>.dtp-content {
  background: #171622;
}

[data-theme-version="dark"] .dtp table.dtp-picker-days tr>td>a {
  color: #68686a;
}

[data-theme-version="dark"] .dtp table.dtp-picker-days tr>td>a.selected {
  color: #fff;
}

[data-theme-version="dark"] .order-request tbody tr {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .card-list li {
  color: #fff;
}

[data-theme-version="dark"] .card-bx .change-btn:hover {
  color: var(--primary);
}

[data-theme-version="dark"] .invoice-card.bg-warning {
  background-color: #5b3c1f !important;
}

[data-theme-version="dark"] .invoice-card.bg-success {
  background-color: #2a6729 !important;
}

[data-theme-version="dark"] .invoice-card.bg-info {
  background-color: #4c276a !important;
}

[data-theme-version="dark"] .invoice-card.bg-secondary {
  background-color: #1c3e52 !important;
}

[data-theme-version="dark"] .user-list li {
  border-color: #212130;
}

[data-theme-version="dark"] .toggle-switch {
  color: #fff;
}

[data-theme-version="dark"] .bar-chart .apexcharts-text tspan {
  fill: #969ba0;
}

[data-theme-version="dark"] .bar-chart line {
  stroke: #2e2e42;
}

[data-theme-version="dark"] .coin-card {
  background: #0f6a62;
  background: -moz-linear-gradient(left, #0f6a62 0%, #084355 100%);
  background: -webkit-linear-gradient(left, #0f6a62 0%, #084355 100%);
  background: linear-gradient(to right, #0f6a62 0%, #084355 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0f6a62', endColorstr='#084355', GradientType=1);
}

[data-theme-version="dark"] .coin-card .coin-icon {
  background: rgba(0, 0, 0, 0.2);
}

[data-theme-version="dark"] .accordion.style-1 .accordion-item,
[data-theme-version="dark"] .invoice-list {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .accordion.style-1 .accordion-header.collapsed .user-info a,
[data-theme-version="dark"] .accordion.style-1 .accordion-header.collapsed .user-info,
[data-theme-version="dark"] .accordion.style-1 .accordion-header.collapsed>span {
  color: #fff;
}

[data-theme-version="dark"] .ic-card>a {
  background: #25479f;
}

[data-theme-version="dark"] .ic-card>a:first-child {
  border-color: #25479f;
}

[data-theme-version="dark"] .ic-card span {
  color: #fff;
}

[data-theme-version="dark"] table.dataTable thead th,
[data-theme-version="dark"] table.dataTable thead td {
  border-color: #2e2e42 !important;
}

[data-theme-version="dark"] .form-check .form-check-input {
  background: transparent;
}

[data-theme-version="dark"] .form-check .form-check-input:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

[data-theme-version="dark"] .paging_simple_numbers.dataTables_paginate {
  background: #171622;
}

[data-theme-version="dark"] .dataTables_wrapper .dataTables_paginate span .paginate_button.current,
[data-theme-version="dark"] .dataTables_wrapper .dataTables_paginate span .paginate_button:hover {
  background: var(--primary);
  color: #fff !important;
}

[data-theme-version="dark"] .dashboard-select {
  background: #212130;
}

[data-theme-version="dark"] .dashboard-select .list {
  background: #212130;
}

[data-theme-version="dark"] .dashboard-select .option:hover,
[data-theme-version="dark"] .dashboard-select .option.focus,
[data-theme-version="dark"] .dashboard-select .option.selected.focus {
  background: #171622;
}

[data-theme-version="dark"] .card-tabs.style-1 .nav-tabs {
  background: #212130;
}

[data-theme-version="dark"] .transaction-details {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .description {
  color: #fff;
}

[data-theme-version="dark"] .transaction-details .amount-bx {
  background: #3f250d;
}

[data-theme-version="dark"] .transaction-details .amount-bx i {
  background: #8d3b0c;
}

[data-theme-version="dark"] .booking-calender .bootstrap-datetimepicker-widget table thead tr:first-child th {
  color: #fff;
}

[data-theme-version="dark"] .booking-calender .bootstrap-datetimepicker-widget table thead tr:first-child th:hover {
  background: #171622;
}

[data-theme-version="dark"] .booking-calender .bootstrap-datetimepicker-widget table td,
[data-theme-version="dark"] .booking-calender .patient-calender .bootstrap-datetimepicker-widget table th {
  color: #fff;
}

[data-theme-version="dark"] .booking-calender .bootstrap-datetimepicker-widget table td:hover,
[data-theme-version="dark"] .booking-calender .patient-calender .bootstrap-datetimepicker-widget table th:hover {
  background: #171622;
}

[data-theme-version="dark"] .booking-calender .bootstrap-datetimepicker-widget table th {
  color: #fff;
}

[data-theme-version="dark"] .booking-calender .bootstrap-datetimepicker-widget table th:hover {
  background: #171622;
}

[data-theme-version="dark"] .progress-bar-legend .result {
  color: #fff;
}

[data-theme-version="dark"] .dz-image-bx,
[data-theme-version="dark"] .dz-review-bx,
[data-theme-version="dark"] .customer-review-list {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .bootstrap-datetimepicker-widget table td span:hover,
[data-theme-version="dark"] .bootstrap-datetimepicker-widget table td i:hover {
  background: #171622;
}

[data-theme-version="dark"] .card-tabs .nav-tabs,
[data-theme-version="dark"] .dataTables_wrapper .dataTables_paginate span,
[data-theme-version="dark"] .guest-bx .carousel .carousel-control-prev,
[data-theme-version="dark"] .guest-bx .carousel .carousel-control-next {
  background: #212130;
}

[data-theme-version="dark"] .card-tabs .nav-tabs .nav-link.active {
  background: #212130;
  color: #fff;
}

[data-theme-version="dark"] .profile-card .user-info-list li span,
[data-theme-version="dark"] .room-list-tbl tr td span,
[data-theme-version="dark"] .dataTables_wrapper .dataTables_info,
[data-theme-version="dark"] .dataTables_wrapper .dataTables_paginate span .paginate_button {
  color: #fff !important;
}

[data-theme-version="dark"] .copyright p {
  color: #fff;
}

[data-theme-version="dark"] .dz-image-bx .dz-media.active:after {
  border-color: #212130;
}

[data-theme-version="dark"] .react-calendar {
  background: #212130;
}

[data-theme-version="dark"] button.react-calendar__tile.react-calendar__month-view__days__day {
  color: #fff;
}

[data-theme-version="dark"] span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  color: #fff;
}

[data-theme-version="dark"] button.react-calendar__navigation__label:hover {
  background: #212130 !important;
}

[data-theme-version="dark"] .react-calendar__tile:enabled:hover,
[data-theme-version="dark"] .react-calendar__tile:enabled:focus {
  background: #212130 !important;
}

[data-theme-version="dark"] .react-calendar__navigation button {
  color: #fff;
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-position="fixed"] .header {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .header {
  /*  background-color: $d-bg; */
  border-color: #2e2e42;
}

[data-theme-version="dark"] .header-left .dashboard_bar {
  color: #fff;
}

[data-theme-version="dark"] .header-right .search-area .form-control {
  background: #212130;
}

[data-theme-version="dark"] .header-right .search-area .input-group-text {
  border: 0;
  background: #212130;
}

[data-theme-version="dark"] .header-right .search-area .input-group-text a {
  color: #4f7086;
}

[data-theme-version="dark"] .header-right .notification_dropdown .nav-link {
  background: #212130;
}

[data-theme-version="dark"] .header-right .notification_dropdown .nav-link .badge {
  border-color: #212130;
}

[data-theme-version="dark"] .header-right .dropdown .nav-link {
  color: #fff;
}

[data-theme-version="dark"] .header-right .dropdown .nav-link:hover {
  color: #fff;
}

[data-theme-version="dark"] .header-right .header-profile .nav-link .header-info span,
[data-theme-version="dark"] .header-right .header-profile .nav-link .header-info small,
[data-theme-version="dark"] .header-right .header-profile .nav-link .header-info .small {
  color: #fff;
}

[data-theme-version="dark"] .nav-header .hamburger .line {
  background: #fff !important;
}

[data-theme-version="dark"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #fff !important;
}

[data-theme-version="dark"] .nav-header {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .nav-control {
  color: #fff;
}

[data-theme-version="dark"] .brand-logo {
  color: #fff;
}

[data-theme-version="dark"] .brand-logo:hover {
  color: #fff;
}

[data-theme-version="dark"] .brand-title path {
  fill: #fff;
}

[data-theme-version="dark"] .svg-title-path {
  fill: var(--primary);
}

[data-theme-version="dark"] .nav-header {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .nav-control {
  color: #fff;
}

[data-theme-version="dark"] .brand-logo {
  color: #fff;
}

[data-theme-version="dark"] .brand-logo:hover {
  color: #fff;
}

[data-theme-version="dark"] .brand-title path {
  fill: #fff;
}

[data-theme-version="dark"] .svg-title-path {
  fill: var(--primary);
}

[data-theme-version="dark"][data-sidebar-style="mini"] .deznav .metismenu li>ul {
  background-color: #212130;
}

[data-theme-version="dark"] .fixed-content-box {
  background-color: #212130;
}

[data-theme-version="dark"] .fixed-content-box .head-name {
  background: #212130;
  color: #fff;
  border-color: #2e2e42;
}

[data-theme-version="dark"] .fixed-content-box+.header+.deznav {
  background-color: #171622;
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-position="fixed"] .deznav {
  border-color: #2e2e42;
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a,
[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a {
  background: transparent !important;
  color: #fff;
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu>li.mm-active>a i,
[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu>li:hover>a i {
  color: #fff;
  background: var(--primary);
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .deznav .metismenu>li a>i {
  color: rgba(255, 255, 255, 0.3);
}

[data-theme-version="dark"] .deznav .header-profile>a.nav-link {
  border-color: #2e2e42;
}

[data-theme-version="dark"] .deznav .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-theme-version="dark"] .deznav .metismenu>li>a {
  color: #b3b3b3;
}

[data-theme-version="dark"] .deznav .metismenu>li:hover>a,
[data-theme-version="dark"] .deznav .metismenu>li:focus>a,
[data-theme-version="dark"] .deznav .metismenu>li.mm-active>a {
  color: #fff;
  background: var(--primary) !important;
}

[data-theme-version="dark"] .deznav .metismenu>li:hover>a::after,
[data-theme-version="dark"] .deznav .metismenu>li:focus>a::after,
[data-theme-version="dark"] .deznav .metismenu>li.mm-active>a::after {
  border-top: 0.3125rem solid #b3b3b3;
  border-left: 0.3125rem solid #b3b3b3;
  border-bottom: 0.3125rem solid transparent;
  border-right: 0.3125rem solid transparent;
}

[data-theme-version="dark"] .deznav .metismenu>li:hover>a i,
[data-theme-version="dark"] .deznav .metismenu>li:focus>a i,
[data-theme-version="dark"] .deznav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-theme-version="dark"] .deznav .metismenu>li.mm-active ul ul {
  background-color: transparent;
}

[data-theme-version="dark"] .deznav .metismenu ul:after {
  background-color: #2e2e42;
}

[data-theme-version="dark"] .deznav .metismenu ul a:hover,
[data-theme-version="dark"] .deznav .metismenu ul a:focus,
[data-theme-version="dark"] .deznav .metismenu ul a.mm-active {
  color: #fff;
}

[data-theme-version="dark"] .deznav .metismenu ul a:before {
  background-color: #fff;
}

[data-theme-version="dark"] .deznav .metismenu a {
  color: #b3b3b3;
}

[data-theme-version="dark"] .deznav .metismenu .has-arrow:after {
  border-top: 0.3125rem solid #b3b3b3;
  border-left: 0.3125rem solid #b3b3b3;
  border-bottom: 0.3125rem solid transparent;
  border-right: 0.3125rem solid transparent;
}

[data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs {
  background: transparent;
  border-color: #f5f5f5;
}

[data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs .nav-item .nav-link {
  color: #000;
}

[data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs .nav-item .nav-link.active {
  background: var(--rgba-primary-1);
}

[data-theme-version="dark"] .sidebar-right .form-control {
  background: #fff;
  color: #000;
  border-color: #F2F2F2;
}

[data-theme-version="dark"] .sidebar-right .default-select .list {
  background: #fff;
}

[data-theme-version="dark"] .sidebar-right .default-select .list .option.selected,
[data-theme-version="dark"] .sidebar-right .default-select .list .option.focus,
[data-theme-version="dark"] .sidebar-right .default-select .list .option:hover {
  background: rgba(0, 0, 0, 0.05);
}

[data-theme-version="dark"] .sidebar-right .sidebar-right-inner>h4,
[data-theme-version="dark"] .sidebar-right .sidebar-right-inner>.h4 {
  color: #000 !important;
}

[data-theme-version="dark"] .footer {
  background-color: transparent;
}

[data-theme-version="dark"] .footer .copyright {
  background-color: transparent;
}

[data-theme-version="dark"] .footer .copyright a {
  color: #fff;
}




.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}